Vue.component('user-delete-modal', {
	data: function() {
		return {
			data: {},
			processing: false,
			successCallback: null
		}
	},
	methods: {
		/**
		 * Удаление пользователя
		 */
		deleteUser: function () {
			var self = this;
			this.processing = true;
			axios.delete('/settings/users/' + this.data.id)
				.then(response => {
					$('.modal-user-delete').modal('hide');
					self.successCallback(response);
					self.processing = false;
				})
				.catch(error => {
					console.warn('Ошибка при удалении пользователя', error);
					self.processing = false;
				})
		},
		init: function (data, successCallback) {
			this.data = data;
			this.successCallback = successCallback;
			$('.modal-user-delete').modal('show');
		}
	}
});