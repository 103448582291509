module.exports = {
    STATUS_SUCCESS: 1,
    STATUS_DECLINED: 2,
    STATUS_REFUNDED: 3,
    STATUS_ICONS: {
        1: '/images/icons/check-circle-full.svg',
        2: '/images/icons/exclamation-full.svg',
        3: '/images/icons/refund.svg'
    },
    CHECK_STATUS_ICONS: {
        'done': '/images/icons/check-circle-full.svg',
        'fail': '/images/icons/exclamation-full.svg',
    }
};
