Vue.component('swim-program-list', {
    data: function () {
        return {
            currentRow: null
        }
    },
    mounted() {
        Bus.$emit('changeMenuState', {
            itemCode: 'dictionaries',
            childCode: 'swim-programs'
        });
    },
    methods:{
        /**
         * Подтверждение удаления программы
         */
        confirmDelete: function(row) {
            this.currentRow = row;
            this.$refs.confirmModal.init({
                title: 'Перенос программы в архив',
                confirmText: 'Вы действительно хотите перенести в архив данную программу?',
                confirmButtonTitle: 'Перенeсти',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteSwimProgram
            })
        },
        /**
         * Удаление программы
         */
        deleteSwimProgram: function() {
            this.$refs.swimProgramGrid.setLoading(true);
            var self = this;
            axios.delete('/dictionaries/swim-programs/' + this.currentRow.id)
                .then(
                    response => {
                        self.$refs.swimProgramGrid.reloadData();
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при удалении программы', error);
                    self.$refs.swimProgramGrid.setLoading(false);
                })
        },
    }
});
