<template>
    <div class="modal modal-warning modal-proposal-apply-restriction" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <img src="/images/icons/warning-open.svg" class="mr-1">
                        Вы не можете подать заявку
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div>Вы не можете подать заявку на соревнование по следующим причинам:</div>
                    <ul>
                        <li v-for="restriction in restrictions">{{restriction}}</li>
                    </ul>
                    <div class="text-center">
                        <a class="btn btn-link" href="/settings/athlete-info" v-if="isAthleteUser">Перейти в профиль</a>
                        <a class="btn btn-link" href="/settings/club-info" v-if="isClubUser">Перейти в профиль</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CompetitionProposalApplyRestrictions",
        mixins: [require('../common/mixins/user')],
        data: function() {
            return  {
                restrictions: []
            }
        },
        methods: {
            /**
             * Инициализация ограничений
             * @param competition
             */
            init: function (competition) {
                this.restrictions = [];
                $('.modal-proposal-apply-restriction').modal('show');
                axios.get('/competitions/' + competition.id + '/proposal-apply-restrictions')
                    .then(response => {
                        this.restrictions = response.data;
                    })
                    .catch(error => {
                        console.warn('Ошибка при загрузке ограничений', error);
                    });
            }
        }
    }
</script>

<style scoped>

</style>
