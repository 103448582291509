Vue.component('coach-view', {
    props: ['id', 'emptyLabel'],
	data: function() {
		return {
			processing: false,
			deleting: false,
			restoring: false,
			data: {},
		}
	},
	mounted: function() {
		Bus.$emit('changeMenuState', {
			itemCode: 'members',
			mode: 'detail',
			backLink: '/coaches',
			backTitle: 'К списку тренеров',
			title: 'Карта тренера'
		});
		this.loadData();
	},
	methods: {
		/**
		 * Загрузка данных тренера
		 */
		loadData: function() {
			this.processing = true;
			axios.get('/coaches/fetch/' + this.id)
				.then(response => {
					this.data = response.data;
					this.processing = false;
				})
				.catch(error => {
					console.warn('Ошибка при загрузке данных тренера', error);
					this.processing = false;
				})
		},
		/**
		 * Обработчик нажатия кнопки удаления тренера
		 */
		onClickDelete: function() {
			this.$refs.confirmModal.init({
				title: 'Удаление тренера',
				confirmText: 'Вы действительно хотите перенести в архив данного тренера?',
				confirmButtonTitle: 'Удалить',
				confirmButtonClass: 'btn-danger',
				confirmCallback: this.deleteCoach
			})
		},
		/**
		 * Удаление тренера
		 */
		deleteCoach: function() {
			this.deleting = true;
			var self = this;
			axios.delete('/coaches/delete/' + this.data.id)
				.then(
					response => {
						this.data = response.data;
						Bus.$emit('pushFlashAlert', {
							type: 'success',
							message: 'Тренер перенесен в архив'
						});
						self.deleting = false;
					}
				)
				.catch(error => {
					console.warn('Ошибка при удалении тренера', error);
					self.deleting = false;
				})
		},
		/**
		 * Восстановление тренера
		 */
		onClickRestore: function() {
			this.restoring = true;
			var self = this;
			axios.post('/coaches/restore', {id: this.data.id})
				.then(
					response => {
						this.data = response.data;
						Bus.$emit('pushFlashAlert', {
							type: 'success',
							message: 'Тренер успешно восстановлен из архива'
						});
						self.restoring = false;
					}
				)
				.catch(error => {
					console.warn('Ошибка при восстановлении тренера', error);
					self.restoring = false;
				})
		}
	}
});
