Vue.component('competition-calendar', {
    props: ['years', 'provisions', 'proposals'],
    mixins: [require('../common/mixins/user')],
    data:function () {
        return {
            year: this.getFilterDefaultYear(),
            ids: []

        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'competitions',
            childCode: 'calendar'
        });
    },
    computed: {
    },
    methods: {
        /**
         * Применяем фильтры для календаря
         * @param filters
         */
        applyFilters: function (filters) {
            this.year = filters.year;
            this.$refs.competitionCalendar.applyFilters(filters);
        },

        /**
         * Обработчик изменения ключевого слова поиска
         * @param keyword
         */
        search: function (keyword) {
            this.$refs.competitionCalendar.search(keyword);
        },
        /**
         * Текущий год
         */
        getCurrentYear: function () {
            return moment().year();
        },
        /**
         * Первый доступный год в календаре
         * @returns {string|*}
         */
        getFilterDefaultYear: function () {
            if (this.years.length > 0) {
                return this.years.includes(this.getCurrentYear()) ? this.getCurrentYear() : this.years[0];
            }
            return '';
        },
        /**
         * Ссылка на файл положения на выбранный год
         * @returns {string}
         */
        getCompetitionProvisionDocLink: function() {;
            let yearValue = this.year;
            let provisionDocId = '';
            this.provisions.forEach(function(element){
                if (element.year == yearValue){
                    provisionDocId = element.provision_doc_id;
                }
            });
            if (provisionDocId != '') {
                return '/files/' + provisionDocId;
            }
        }
    }
});
