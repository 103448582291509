Vue.component('selection-standard-group-list', {
    data: function () {
        return {
            currentRow: null
        }
    },
    mounted() {
        Bus.$emit('changeMenuState', {
            itemCode: 'competitions',
            childCode: 'selection-standard-groups'
        });
    },
    methods:{
        /**
         * Подтверждение удаления отборочного норматива
         */
        confirmDelete: function(row) {
            this.currentRow = row;
            this.$refs.confirmModal.init({
                title: 'Перенос отборочного норматива в архив',
                confirmText: 'Вы действительно хотите перенести в архив данный отборочный норматив?',
                confirmButtonTitle: 'Перенeсти',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteSelectionStandardGroup
            })
        },
        /**
         * Удаление отборочного норматива
         */
        deleteSelectionStandardGroup: function() {
            this.$refs.selectionStandardGroupGrid.setLoading(true);
            var self = this;
            axios.delete('/competitions/selection-standard-groups/' + this.currentRow.id)
                .then(
                    response => {
                        self.$refs.selectionStandardGroupGrid.reloadData();
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при удалении норматива', error);
                    self.$refs.selectionStandardGroupGrid.setLoading(false);
                })
        },
    }
});
