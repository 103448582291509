Vue.component('coach-create-form', {
    props: ['coachingCategories', 'sportRanks', 'educationalTypes', 'employeeTypes', 'clubId'],
    mixins: [require('./mixins/form')],
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'members',
            mode: 'detail',
            backLink: '/coaches',
            backTitle: 'К списку тренеров',
            title: 'Регистрация нового тренера'
        });
    }
});
