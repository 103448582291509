Vue.component('competition-swim-program-setup', {
    props: ['swimPrograms', 'competitionAgeGroups', 'ageGroups', 'athleteRanks', 'genderShortLabels'],
    data: function() {
        return {
            sessionIndex: null,
            mode: 'create',
            successCallback: null,
            selectedSwimProgram: null,
            selectedAthleteRank: null,
            gender: "M",
            isAllAgeGroups: 0,
            selectedAgeGroups: [],
        }
    },
    computed: {
        /**
         * Доступные возрастные группы в зависимости от пола
         */
        availableCompetitionAgeGroups: function() {
            if (!this.competitionAgeGroups) {
                return [];
            }
            let currentGender = this.gender;
            return this.competitionAgeGroups.filter(item => item.gender == currentGender);
        },
        /**
         * Доступный список полов для
         * @returns {{F: *, M: *}|*}
         */
        availableGenderShortLabels: function() {
            if (this.selectedSwimProgram && this.selectedSwimProgram.relay_count > 1) {
                return this.genderShortLabels;
            }
            return {
                'M': this.genderShortLabels['M'],
                'F': this.genderShortLabels['F']
            };
        }
    },
    methods: {
        /**
         * Инициализация данных и открытие модалки
         * @param successCallback
         */
        init: function (params, successCallback) {
            this.mode = params.mode;
            this.gender = params.gender ? params.gender : 'M';
            this.isAllAgeGroups = params.hasOwnProperty('is_all_age_groups') ? params.is_all_age_groups : 1;
            if (params.hasOwnProperty('age_groups')) {
                this.selectedAgeGroups = params.age_groups;
            }
            if (params.swim_program_id) {
                let filteredSwimPrograms = this.swimPrograms.filter(item => item.id == params.swim_program_id);
                if (filteredSwimPrograms.length) {
                    this.selectedSwimProgram = filteredSwimPrograms[0];
                }
            } else {
                // Если ни разу не выбирали программу, или ранее выбранной программы нет в списке,
                // то делаем активным первый элемент
                let selectedSwimProgramId = this.selectedSwimProgram ? this.selectedSwimProgram.id : null;
                if (
                    !selectedSwimProgramId ||
                    !this.swimPrograms.find(function(element) {
                        return element.id == selectedSwimProgramId;
                    })
                ) {
                    this.selectedSwimProgram = this.swimPrograms[0];
                }
            }
            if (params.athlete_rank_id) {
                let filteredAthleteRanks = this.athleteRanks.filter(item => item.value == params.athlete_rank_id);
                if (filteredAthleteRanks.length) {
                    this.selectedAthleteRank = filteredAthleteRanks[0];
                }
            } else {
                this.selectedAthleteRank = null;
            }
            this.successCallback = successCallback;
            this.fillAllAgeGroupsAsChecked();
            $('.modal-competition-swim-program-setup').modal('show');
        },
        /**
         * Обработчик смены программы плавания
         */
        onInputSelectedSwimProgram: function() {
            if (this.selectedSwimProgram) {
                if (this.selectedSwimProgram.relay_count == 1 && this.gender == 'X') {
                    this.gender = 'M';
                    this.fillAllAgeGroupsAsChecked();
                }
            }
        },
        /**
         * Обработчик смены пола
         */
        onChangeGender: function() {
            this.fillAllAgeGroupsAsChecked();
        },
        /**
         * Формируем заголовок для возрастных групп
         * @param competitionAgeGroup
         * @returns {string|*}
         */
        getAgeGroupLabel: function(competitionAgeGroup) {
            if (competitionAgeGroup.age_group_id) {
                let filtered = this.ageGroups.filter(item => item.id == competitionAgeGroup.age_group_id);
                if (filtered.length) {
                    return filtered[0].name;
                }
            }
            return Vue.filter('ageGroupTitle')(competitionAgeGroup);
        },
        /**
         * Обработчик события нажатия на все возрастные группы
         */
        onChangeAllAgeGroups: function() {
            this.fillAllAgeGroupsAsChecked();
        },
        /**
         * Заполнение возрастных групп из всех возможных
         */
        fillAllAgeGroupsAsChecked: function() {
            if (this.isAllAgeGroups) {
                let self = this;
                this.selectedAgeGroups = [];
                this.availableCompetitionAgeGroups.forEach(item => {
                    self.selectedAgeGroups.push(item.id);
                })
            }
        },
        /**
         * Обработчик изменения возрастной группы
         */
        onChangeAgeGroup: function() {
            this.isAllAgeGroups = this.selectedAgeGroups.length == this.availableCompetitionAgeGroups.length ? 1 : 0;
        },
        /**
         * Обработчик нажатия кнопки нажатия сохранения
         */
        onClickSave: function() {
            this.successCallback({
                swim_program_id: this.selectedSwimProgram.id,
                athlete_rank_id: this.selectedAthleteRank ? this.selectedAthleteRank.value : null,
                gender: this.gender,
                is_all_age_groups: this.isAllAgeGroups,
                age_groups: this.selectedAgeGroups
            });
            $('.modal-competition-swim-program-setup').modal('hide');
        }
    }
});
