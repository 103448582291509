<template>
    <div class="multi-input-control" :class="{'is-invalid': hasError}">
        <div v-for="(item, index) in items" class="multi-input-item">
            <input :value="item" class="form-control" @change="onChangeItemValue(index, $event)">
            <span class="remove-item" @click="onClickRemoveItem(index)">&times;</span>
        </div>
        <input type="text" class="form-control" v-model="inputValue"
               :id="inputId" @change="addItem"  v-on:keydown.prevent.enter="addItem"
                :placeholder="inputPlaceholder">
    </div>
</template>

<script>
    export default {
        props: ['hasError', 'value', 'inputId', 'inputPlaceholder'],
        name: "MultiInput",
        data: function() {
            return {
                inputValue: '',
            }
        },
        computed: {
            /**
             * Массив введенных значений
             * @returns {*[]|*}
             */
            items: function() {
                if (this.value) {
                    return this.value;
                }
                return [];
            },
        },
        methods: {
            /**
             * Удаление элемента из выбранных
             * @param index
             */
            onClickRemoveItem: function(index) {
                var currentItems = this.items;
                currentItems.splice(index, 1);
                this.$emit('input', currentItems);
            },
            addItem: function() {
                if (this.inputValue) {
                    var currentItems = this.items;
                    currentItems.push(this.inputValue);
                    this.$emit('input', currentItems);
                }
                this.inputValue = '';

                return false;
            },
            onChangeItemValue: function(index, e) {
                Vue.set(this.items, index, e.target.value);
            }
        }
    }
</script>
