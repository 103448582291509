Vue.component('judge-add-category-modal', {
    props: ['categoryTypes', 'judgeId', 'genderShortLabels'],
    data: function() {
        return {
            judgeCategoryType: null,
            judgeCategory: null,
            form: new TrustyForm({
                id: null,
                judge_id: '',
                judge_category_id: '',
                issue_at	: '',
                expires_at: '',
                doc_date: '',
                doc_number: '',
            }),
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        save: function () {
            let data = {
                id: this.form.id,
                judge_id: this.form.judge_id,
                judge_category_id: this.form.judge_category_id,
                issue_at	: this.form.issue_at,
                expires_at: this.form.expires_at,
                doc_date: this.form.doc_date,
                doc_number: this.form.doc_number,
            };
            Bus.$emit('judge-category-updated', {
                index: this.index,
                judgeCategory: data
            });
            $('.modal-judge-add-category').modal('hide');
        },
        /**
         * Инициализация модалки добавления или редактирование судейской категории
         * @param {Object} data
         */
        init: function (data) {
            this.index = data.index;
            this.judgeCategory = data.judgeCategory;
            this.form = new TrustyForm({
                id: null,
                judge_id: '',
                judge_category_id: '',
                issue_at	: '',
                expires_at: '',
                doc_date: '',
                doc_number: '',
            });

            if (this.judgeCategory) {
                $.extend(true, this.form, data.judgeCategory);
            }

            $('.modal-judge-add-category').modal('show');
        }
    }
});
