Vue.component('competition-tab-pre-proposal', {
    props: ['id'],
    mixins: [require('../../common/mixins/user')],
    data: function() {
        return {
            form: new TrustyForm({
                target_quota: null,
                no_compete: 0,
            }),
            processing: false,
            constants: require('./../proposals/constants')
        }
    },
    computed: {
        /**
         * Проверяем соответствие дат подачи заявки
         */
        checkProposalDate() {
            return this.$parent.checkProposalDate;
        },
        /**
         * Ссылка для печати
         * @returns {string}
         */
        printLink: function() {
            return '/competitions/' + this.id + '/print-my-proposal';
        }
    },
    methods: {
        /**
         * Инициализируем компонент таба предварительной заявки клуба
         */
        init: function (data) {
            this.data = data;
            this.processing = true;
            if (!this.form.id) {
                let self = this;
                axios.get('/competitions/pre-proposal-club-data/' + this.id)
                    .then(response => {
                        if (response.data.preProposal) {
                            $.extend(true, this.form, response.data.preProposal);
                        }
                        self.processing = false;
                    })
                    .catch(error => {
                        console.warn('Ошибка при загрузке данных предварительной заявки', error);
                        self.processing = false;
                    });
            }
        },
        /**
         * Отправка данных предварительной заявки от клуба
         */
        savePreProposal: function () {
            let self = this;
            Trusty.post('/competitions/pre-proposal-save-club-data/' + this.id, this.form)
                .then(response => {
                    $.extend(true, self.form, response);
                })
                .catch(error => {

                })
        }
    }
});
