Vue.component('judge-category-form', {
    props: ['title', 'judgeCategoryId'],
    data: function() {
        return {
            form: new TrustyForm({
                id: '',
                name: '',
                code: '',
                sort_order: 0,
            }),
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'seminars',
            mode: 'detail',
            backLink: '/seminars/judge-categories',
            backTitle: 'К списку судейских категорий',
            title: this.title
        });
        if (this.judgeCategoryId) {
            this.loadJudgeCategory();
        }
    },
    computed: {
        /**
         * Урл для отправки запроса
         * @returns {string}
         */
        sendUrl: function () {
            return this.judgeCategoryId
                ? '/seminars/judge-categories/' + this.judgeCategoryId
                : '/seminars/judge-categories';
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            Trusty.post(this.sendUrl, this.form)
                .then(response => {
                    location.href = '/seminars/judge-categories';
                })
                .catch(error => {

                })
        },
        /**
         * Загружаем данные о судейской категории
         */
        loadJudgeCategory: function() {
            axios.get('/seminars/judge-categories/fetch/' + this.judgeCategoryId)
                .then(response => {
                    $.extend(true, this.form, response.data);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке судейской категории', error);
                })
        }
    }
});
