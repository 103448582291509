module.exports = {
    /**
     * Вспомогательный метод для выполнения запросов HTTP POST.
     */
    post(uri, form) {
        return Trusty.sendForm('post', uri, form);
    },

    postWithFiles(uri, form, fileFields) {
		return new Promise((resolve, reject) => {
			form.startProcessing();
            var formData = new FormData();
			for (var field in form) {
			    if (typeof form[field] == 'function') {
			        continue;
                }
                if ($.inArray(field, fileFields) != -1 && !form[field]) {
			        continue;
                }
                if (form.hasOwnProperty(field) && Array.isArray(form[field])) {
                    form[field].forEach((arrVal, arrIndex) => {
                        if (typeof arrVal == 'object') {
                            for (childIndex in arrVal) {
                                formData.append(field + '[' + arrIndex + ']' + '[' + childIndex + ']', arrVal[childIndex]);
                            }
                        } else {
                            formData.append(field + '[' + arrIndex + ']', arrVal);
                        }
                    });
                    continue;
                }
				formData.append(field, form[field]);
			}
			axios.post(uri, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                form.finishProcessing();

                resolve(response.data);
            })
            .catch(errors => {
                form.setErrors(errors.response.data.errors);

                reject(errors.response.data);
            });
		});
    },


    /**
     * Вспомогательный метод для выполнения запросов HTTP PUT.
     */
    put(uri, form) {
        return Trusty.sendForm('put', uri, form);
    },


    /**
     * Вспомогательный метод для создания запросов HTTP PATCH.
     */
    patch(uri, form) {
        return Trusty.sendForm('patch', uri, form);
    },


    /**
     * Вспомогательный метод для удаления запросов HTTP.
     */
    delete(uri, form) {
        return Trusty.sendForm('delete', uri, form);
    },


    /**
     * Отправляет форму на сервер.
     *
     * Эта функция удаляет старые ошибки, обновляет статус «занято» и т. д.
     */
    sendForm(method, uri, form) {
        return new Promise((resolve, reject) => {
            form.startProcessing();

            axios[method](uri, JSON.parse(JSON.stringify(form)))
                .then(response => {
                    form.finishProcessing();

                    resolve(response.data);
                })
                .catch(errors => {
                    form.setErrors(errors.response.data.errors);

                    reject(errors.response.data);
                });
        });
    }
};
