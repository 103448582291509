Vue.component('selection-standard-group-form', {
    props: ['title', 'selectionStandardGroupId', 'courseTypes', 'genderLabels'],
    data: function() {
        return {
            form: new TrustyForm({
                id: '',
                name: '',
                selection_standards: []
            }),
            swimPrograms: [],
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'competitions',
            mode: 'detail',
            backLink: '/competitions/selection-standard-groups',
            backTitle: 'К списку отборочных нормативов',
            title: this.title
        });
        if (this.selectionStandardGroupId) {
            this.loadSelectionStandardGroup();
        }
        this.loadSwimPrograms();
    },
    computed: {
        /**
         * Урл для отправки запроса
         * @returns {string}
         */
        sendUrl: function () {
            return this.selectionStandardGroupId
                ? '/competitions/selection-standard-groups/' + this.selectionStandardGroupId
                : '/competitions/selection-standard-groups';
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            Trusty.post(this.sendUrl, this.form)
                .then(response => {
                    location.href = '/competitions/selection-standard-groups';
                })
                .catch(error => {
                    console.warn('Ошибка при сохранение отборочных нормативов', error);
                })
        },
        /**
         * Загружаем данные о нормативах
         */
        loadSelectionStandardGroup: function() {
            axios.get('/competitions/selection-standard-groups/fetch/' + this.selectionStandardGroupId)
                .then(response => {
                    $.extend(true, this.form, response.data);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке отборочных нормативов', error);
                })
        },
        /**
         * Загружаем список видов программ
         */
        loadSwimPrograms: function() {
            axios.get('/dictionaries/swim-programs/all?relay_count=1&order_column=distance&order_direction=asc')
                .then(response => {
                    this.swimPrograms = response.data;
                })
                .catch(error => {});
        },
        /**
         * Получаем время для пола, бассейна и типа плавания
         * @param course
         * @param gender
         * @param swimProgram
         * @returns {string}
         */
        getSwimTime: function(course, gender, swimProgram) {
            let filteredSelectionStandards = this.form.selection_standards.filter(item => (item.course == course && item.gender == gender &&  item.swim_program_id == swimProgram.id));
            if (filteredSelectionStandards.length > 0) {
                return filteredSelectionStandards[0].swim_time;
            }
            return '';
        },
        /**
         * Проставление нормативного времени
         * @param value
         * @param course
         * @param gender
         * @param swimProgram
         */
        setSwimTime: function(value, course, gender, swimProgram) {
            let currentIndex = -1;
            let filteredSelectionStandards = this.form.selection_standards.filter(
                (item, index) => {
                    if (item.course == course && item.gender == gender && item.swim_program_id == swimProgram.id) {
                        currentIndex = index;
                        return true;
                    }
                    return false;
                });
            if (filteredSelectionStandards.length > 0) {
                let currentSelectionStandard = filteredSelectionStandards[0];
                currentSelectionStandard.swim_time = value;
                Vue.set(this.form.selection_standards, currentIndex, currentSelectionStandard);
                return ;
            }
            this.form.selection_standards.push({
                course: course,
                gender: gender,
                swim_program_id: swimProgram.id,
                swim_time: value
            });
        },
    }
});
