<template>
    <div class="filter-container">
        <div class="filter-label"
             :class="isNotEmpty ? 'filter-active' : ''"
             aria-haspopup="true"
             aria-expanded="false"
             data-toggle="dropdown"
        >{{getTitle}}</div>

        <div v-on:click.stop.self class="dropdown-menu filter-content athlete-municipality-filter" aria-labelledby="dropdownMenuButton">
            <div class="select-container">
                <v-select v-model="municipality" :options="municipalityList" @search="fetchMunicipalities" placeholder="Начните вводить название муниципалитета...">
                    <div slot="no-options">Муниципалитет не найден</div>
                </v-select>
            </div>

            <hr />
            <div class="filter-buttons">
                <span v-on:click="resetFilter" class="filter-action-span">
                    Очистить
                </span>
                <button class="btn btn-primary float-right" v-on:click="applyFilter">
                    Сохранить
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AthleteMunicipalityFilter",
        data: function () {
            return {
                municipalityList: [],
                municipality: '',
                filters: {}
            }
        },
        methods: {
            applyFilter: function (send = true) {
                this.filters = {
                    municipality: this.municipality
                };
                if (send) {
                    this.$emit('applyFilter', this.filters);
                }
                this.closeDropDown();
            },
            resetFilter: function (apply = true) {
                this.municipality = '';
                this.applyFilter(apply);
            },
            closeDropDown: function () {
                $('.show').removeClass('show');
            },
            /**
             * Получаем муниципалитеты для показа
             * @param search
             * @param loading
             */
            fetchMunicipalities: function(search, loading) {
                loading && loading(true);
                var self = this;
                axios.get('/dictionaries/municipalities/search-municipality-filter?keyword=' + search)
                    .then(response => {
                        self.municipalityList = [];
                        response.data.forEach((row, index) => {
                            row.label = row.name;
                            self.municipalityList[index] = row;
                        })
                        loading && loading(false);
                    })
                    .catch(error => {
                        loading && loading(false);
                    })
            },
        },
        computed: {
            getTitle: function() {
                if (this.municipality) {
                    return this.municipality.name;
                }
                return 'Муниципалитет';
            },
            isNotEmpty: function () {
                return !! this.filters.municipality;
            }
        }
    }
</script>

<style scoped>

</style>
