Vue.component('competition-create-form', {
    mixins: [require('./mixins/form'), require('./mixins/dateFormatter'), require('../common/mixins/user')],
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'competitions',
            mode: 'detail',
            backLink: '/competitions',
            backTitle: 'К списку соревнований',
            title: this.isClubUser ? 'Новая заявка на соревнование' : 'Добавление нового соревнования'
        });
        this.loadCities();
        this.loadAgeGroups();
        this.loadSwimPrograms();
    }

});
