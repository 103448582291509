Vue.component('competition-provision-list', {
    data: function () {
        return {
            currentRow: null
        }
    },
    mounted() {
        Bus.$emit('changeMenuState', {
            itemCode: 'settings',
            childCode: 'competition-provisions'
        });
    },
    methods:{
        /**
         * @brief Форматированный вывод пола
         * @param value
         * @param row
         * @returns {*}
         */
        provisionTypeFormat: function (value, row) {
            return '<a href="' + row.provision_doc_link + '" download="download" target="_blank"> ' + value + ' </a>';
        },
        /**
         * Подтверждение удаления положения
         */
        confirmDelete: function(row) {
            this.currentRow = row;
            this.$refs.confirmModal.init({
                title: 'Перенос положения в архив',
                confirmText: 'Вы действительно хотите перенести в архив данное положение?',
                confirmButtonTitle: 'Перенeсти',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteProvision
            })
        },
        /**
         * Удаление положения
         */
        deleteProvision: function() {
            this.$refs.provisionGrid.setLoading(true);
            var self = this;
            axios.delete('/settings/competition-provisions/' + this.currentRow.id)
                .then(
                    response => {
                        self.$refs.provisionGrid.reloadData();
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при удалении положения', error);
                    self.$refs.provisionGrid.setLoading(false);
                })
        },
    }
});
