Vue.component('seminar-payment-refund-modal', {
    data: function () {
        return {
            processing: false,
            seminar: null,
            seminars: [],
            paymentsCount: 0,
        };
    },
    methods: {
        /**
         * Инициализируем окно возврата платежей
         */
        init: function () {
            $('.modal-seminar-payment-refund').modal('show');
        },
        /**
         * Поиск семинара
         */
        searchSeminars: function(search, loading) {
            if (!search) {
                return;
            }
            loading && loading(true);
            axios.get('/seminars/search?keyword=' + search)
                .then(response => {
                    this.seminars = response.data;
                    loading && loading(false);
                })
                .catch(error => {
                    console.warn('Ошибка при подгрузке семинаров', error);
                    loading && loading(false);
                })
        },
        /**
         * Обработчик смены выбранного семинара
         */
        seminarChanged: function() {
            this.paymentsCount = 0;
            if (this.seminar) {
                axios.post('/seminars/payments/payments-count', {
                    seminar_id: this.seminar.id
                }).then(response => {
                    this.paymentsCount = response.data.payments_count;
                }).catch(error => {
                    console.warn('Ошибка при подсчете количества транзакций', error);
                })
            }
        },
        /**
         * Возврат платежей для семинара
         */
        batchRefund: function() {
            this.processing = true;
            if (this.seminar) {
                axios.post('/seminars/payments/batch-refund', {
                    seminar_id: this.seminar.id
                }).then(response => {
                    $('.modal-seminar-payment-refund').modal('hide');
                    Bus.$emit('pushFlashAlert', {
                        type: 'success',
                        message: 'Возврат платежей за семинар успешно запущен.'
                    })
                    this.processing = false;
                }).catch(error => {
                    console.warn('Ошибка при возврате платежей', error);
                    this.processing = false;
                })
            }
        }
    }
})
