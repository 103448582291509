Vue.component('competition-edit-form', {
    mixins: [require('./mixins/form'), require('./mixins/dateFormatter'), require('../common/mixins/user')],
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'competitions',
            mode: 'detail',
            backLink: '/competitions',
            backTitle: 'К списку соревнований',
            title: this.isClubUser && !this.form.status ? 'Редактирование заявки на соревнование' : 'Редактирование соревнования'
        });
        this.loadCities();
        this.loadAgeGroups();
        this.loadSwimPrograms();
        if (this.id) {
            this.loadCompetition();
        }
    },
    methods: {
        /**
         * Обновление данных соревнования 1 шаг
         */
        update: function() {
            if (this.form.can_edit) {
                if (this.form.need_change_approve) {
                    let self = this;
                    Trusty.postWithFiles('/competitions/store-files/' + this.form.id, this.form, [
                        'regulation_doc_file',
                        'municipality_agreement_doc_file',
                        'safety_plan_doc_file',
                        'medical_plan_doc_file',
                    ])
                        .then(response => {
                            self.form.regulation_doc_id = response.regulation_doc_id;
                            self.form.municipality_agreement_doc_id = response.municipality_agreement_doc_id;
                            self.form.safety_plan_doc_id = response.safety_plan_doc_id;
                            self.form.medical_plan_doc_id = response.medical_plan_doc_id;
                            self.form.regulation_doc_name = response.regulation_doc_name;
                            self.form.municipality_agreement_doc_name = response.municipality_agreement_doc_name;
                            self.form.safety_plan_doc_name = response.safety_plan_doc_name;
                            self.form.medical_plan_doc_name = response.medical_plan_doc_name;
                            for (let index = 0; index < self.form.competition_age_groups.length; index++) {
                                if (!self.form.competition_age_groups[index].id) {
                                    self.form.competition_age_groups[index].id = -index;
                                }
                            }
                            self.step = 2;
                        })
                        .catch(error => {})
                } else {
                    let self = this;
                    Trusty.postWithFiles('/competitions/' + this.form.id, this.form, [
                        'regulation_doc_file',
                        'municipality_agreement_doc_file',
                        'safety_plan_doc_file',
                        'medical_plan_doc_file',
                    ])
                        .then(response => {
                            if (self.form.draft) {
                                location.href = '/my-competitions';
                                return;
                            } else {
                                $.extend(true, self.form, response);
                                self.form.competition_age_groups = response.age_groups;
                                self.step = 2;
                            }
                        })
                        .catch(error => {
                            self.form.draft = 0;
                        })
                }
            }
        },
        /**
         * Обновление в режиме черновика
         */
        updateAsDraft: function() {
            this.form.draft = 1;
            this.update();
        }
    }
});
