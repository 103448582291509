Vue.component('municipality-form', {
    props: ['title', 'municipalityId', 'regions'],
    data: function() {
        return {
            form: new TrustyForm({
                id: '',
                name: '',
                report_name: '',
                region_id: '',
            }),
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'dictionary',
            mode: 'detail',
            backLink: '/dictionaries/municipalities',
            backTitle: 'К списку муниципальных образований',
            title: this.title
        });
        if (this.municipalityId) {
            this.loadMunicipality();
        }
    },
    computed: {
        /**
         * Модель для региона
         */
        region: {
            get: function() {
                let filtered = this.regions.filter(item => item.id == this.form.region_id);
                if (filtered.length) {
                    return filtered[0];
                }
                return null;
            },
            set: function (item) {
                this.form.region_id = item ? item.id : '';
            }
        },
        /**
         * Урл для отправки запроса
         * @returns {string}
         */
        sendUrl: function () {
            return this.municipalityId
                ? '/dictionaries/municipalities/' + this.municipalityId
                : '/dictionaries/municipalities';
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            Trusty.post(this.sendUrl, this.form)
                .then(response => {
                    location.href = '/dictionaries/municipalities';
                })
                .catch(error => {

                })
        },
        /**
         * Загружаем данные о муцинипалитете
         */
        loadMunicipality: function() {
            axios.get('/dictionaries/municipalities/fetch/' + this.municipalityId)
                .then(response => {
                    $.extend(true, this.form, response.data);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке муниципального образования', error);
                })
        }
    }
});
