import constants from './constants';
Vue.component('competition-proposal-swim-programs', {
    mixins: [require('../mixins/athleteCheckers'), require('../../common/mixins/user')],
    props: [
        'competition',
        'competitionProposalAthletes',
        'athleteRanks',
        'genderLabels',
        'courses'
    ],
    data: function() {
        return {
            constants: constants,
            participantTypes: {},
            selectedProposalAthlete: null,
            selectedSwimPrograms: [],
            isAllSwimPrograms: 0,
            swimProgramsParams: {},
            existsSwimTimes: {}
        }
    },
    mounted: function() {
        this.participantTypes[this.constants.PARTICIPANT_TYPE_FREE] = 'Общие основания';
        this.participantTypes[this.constants.PARTICIPANT_TYPE_PAID] = 'Договор';
        $('#left-menu-modal').slimScroll({height: '450px'});
        $('#distance-work-area').slimScroll({height: '400px'});
    },
    computed: {
        /**
         * Разряд выбранного спортсмена
         */
        selectedProposalAthleteRank: function() {
            if (this.selectedProposalAthlete && this.selectedProposalAthlete.athlete.athlete_rank_id) {
                let filteredRanks = this.athleteRanks.filter(item => item.id == this.selectedProposalAthlete.athlete.athlete_rank_id);
                if (filteredRanks.length > 0) {
                    return filteredRanks[0].name;
                }
            }
            return 'Без разряда';
        },
        /**
         * Доступные виды соревнований, в зависимости от пола и
         */
        availableSwimPrograms: function() {
            let athlete = this.selectedProposalAthlete.athlete;
            let isRegionalFederationUser = this.isRegionalFederationUser;
            let isCredentialCommissionUser = this.isCredentialCommissionUser;
            let ageGroupIds = this.availableAgeGroupIds(athlete, this.selectedProposalAthlete.participant_type);
            if (!isRegionalFederationUser && ageGroupIds.length == 0) {
                return [];
            }
            return this.competition.swim_programs.filter(swimProgram => {
                if (!isCredentialCommissionUser && !isRegionalFederationUser && swimProgram.athlete_rank_id) {
                    if (!athlete.athlete_rank_id) return false;
                    let needRankOrder = -1;
                    let athleteRankOrder = 0;
                    for (let index = 0; index < this.athleteRanks.length; index++) {
                        if (this.athleteRanks[index].id == swimProgram.athlete_rank_id) {
                            needRankOrder = this.athleteRanks[index].sort_order;
                        }
                        if (this.athleteRanks[index].id == athlete.athlete_rank_id) {
                            athleteRankOrder = this.athleteRanks[index].sort_order;
                        }
                    }
                    if (athleteRankOrder > needRankOrder) {
                        console.log('РАНГ не подходит', athlete, swimProgram);
                        return false;
                    }
                }
                if (swimProgram.prev_competition_swim_program_id) {
                    return false;
                }
                if (swimProgram.swim_program.relay_count > 1) {
                    return false;
                }
                if (swimProgram.gender != athlete.gender) {
                    return false;
                }
                if (isRegionalFederationUser) {
                    return true;
                }
                if (swimProgram.is_all_age_groups == 1) {
                    return true;
                }
                return swimProgram.age_groups
                    .filter(ageGroupId => ageGroupIds.indexOf(ageGroupId) !== -1)
                    .length > 0;
            })
        }
    },
    methods: {
        /**
         * Сохраняем настройки для текущего спортсмена
         */
        save: function() {
            if (this.selectedProposalAthlete == null) {
                return;
            }
            let saveData = {
                swim_programs: []
            };
            for (let index = 0; index < this.selectedSwimPrograms.length; index++) {
                saveData.swim_programs.push(this.prepareAthleteSwimProgramParam(this.selectedSwimPrograms[index]));
            }
            this.selectedProposalAthlete.swim_programs = saveData.swim_programs;
            this.$emit('updated', this.selectedProposalAthlete);
            var self = this;
            axios.post('/competitions/' + this.competition.id + '/proposals/save-athlete-programs/' + this.selectedProposalAthlete.id, saveData)
                .then(response => {
                    self.$emit('updated', response.data);
                })
                .catch(error => {
                    console.warn('Ошибка при сохраниении программ для спортсмена', error);
                });
        },
        /**
         * Инициализация данных и открытие модалки
         */
        init: function (proposalAthlete) {
            this.selectedProposalAthlete = proposalAthlete;
            this.afterAthleteChanges();
            $('.modal-competition-proposal-swim-programs').modal('show');
        },
        /**
         * Выбран ли текущий атлет
         * @param proposalAthlete
         * @returns {null|boolean}
         */
        isSelectedAthlete: function (proposalAthlete) {
            return this.selectedProposalAthlete && this.selectedProposalAthlete.id == proposalAthlete.id;
        },
        /**
         * Заполнены ли дистанции для данного атлета
         * @param proposalAthlete
         * @returns {boolean}
         */
        isFilledAthlete: function (proposalAthlete) {
            if (this.isSelectedAthlete(proposalAthlete)) {
                return this.selectedSwimPrograms.length > 0;
            }
            return proposalAthlete && proposalAthlete.swim_programs && proposalAthlete.swim_programs.length > 0;
        },
        /**
         * Обработка нажатия выбора атлета
         * @param proposalAthlete
         */
        onClickProposalAthlete: function(proposalAthlete) {
            if (this.selectedProposalAthlete != null && this.selectedProposalAthlete.id != proposalAthlete.id) {
                //сохраняем данные предыдущего атлета при переключении на следущего
                this.save();
            }
            this.selectedProposalAthlete = proposalAthlete;
            this.afterAthleteChanges();
        },
        /**
         * Трансформируем данные для выбранного атлета
         */
        afterAthleteChanges: function() {
            this.selectedSwimPrograms = [];
            this.swimProgramsParams = {};
            for (let index = 0; index < this.selectedProposalAthlete.swim_programs.length; index++) {
                this.selectedSwimPrograms.push(this.selectedProposalAthlete.swim_programs[index].swim_program_id);
                Vue.set(
                    this.swimProgramsParams,
                    this.selectedProposalAthlete.swim_programs[index].swim_program_id,
                    this.selectedProposalAthlete.swim_programs[index]
                );
            }
            this.isAllSwimPrograms = this.selectedSwimPrograms.length == this.availableSwimPrograms.length ? 1 : 0;
            this.loadAthleteExistsSwimTimes(this.selectedProposalAthlete.athlete);
        },
        /**
         * Подгружаем Заявочные времена из истории
         * @param athlete
         */
        loadAthleteExistsSwimTimes: function (athlete) {
            if (this.existsSwimTimes.hasOwnProperty(athlete.id)) {
                this.afterExistsSwimTimeLoaded();
                return;
            }
            let self = this;
            axios.get('/competitions/proposals/athlete-exists-swim-times/' + athlete.id)
                .then(response => {
                    Vue.set(self.existsSwimTimes, athlete.id, response.data.filter(item => item.course == this.competition.course));
                    self.afterExistsSwimTimeLoaded();
                })
                .catch(error => {});
        },
        /**
         * Проставляем заявочные времена после загрузки
         */
        afterExistsSwimTimeLoaded: function () {
            if (!this.existsSwimTimes.hasOwnProperty(this.selectedProposalAthlete.athlete.id)) {
                return;
            }
            if (this.existsSwimTimes[this.selectedProposalAthlete.athlete.id].length == 0) {
                return;
            }
            for (let index = 0; index < this.availableSwimPrograms.length; index++) {
                let existsSwimTimes = this.existsSwimTimes[this.selectedProposalAthlete.athlete.id]
                    .filter(item => item.swim_program_id == this.availableSwimPrograms[index].swim_program_id);
                if (existsSwimTimes.length > 0) {
                    let params = this.prepareAthleteSwimProgramParam(this.availableSwimPrograms[index].id);
                    if (!params.swim_time) {
                        Vue.set(this.swimProgramsParams, this.availableSwimPrograms[index].id, {
                            swim_program_id: this.availableSwimPrograms[index].id,
                            swim_time: existsSwimTimes[0].swim_time,
                            course: existsSwimTimes[0].course,
                            event_date: existsSwimTimes[0].event_date,
                            event_place: existsSwimTimes[0].event_place,
                        });
                    }
                }
            }
        },
        /**
         * Обработчик изменения чекбокса вида программы
         * @param event
         */
        onChangeSwimProgramStatus: function (event) {
            this.isAllSwimPrograms = this.selectedSwimPrograms.length == this.availableSwimPrograms.length ? 1 : 0;
        },
        /**
         * Обработчик включения всех дистанций для спортсмена
         */
        onChangeAllSwimPrograms: function () {
            if (this.isAllSwimPrograms) {
                for (let index = 0; index < this.availableSwimPrograms.length; index++) {
                    if (this.selectedSwimPrograms.indexOf(this.availableSwimPrograms[index].id) === -1) {
                        this.selectedSwimPrograms.push(this.availableSwimPrograms[index].id);
                    }
                }
            } else {
                this.selectedSwimPrograms = [];
            }
        },
        /**
         * Получение заявочного времени для программы
         * @param swimProgram
         */
        getAthleteSwimTime: function (swimProgram) {
            let params = this.prepareAthleteSwimProgramParam(swimProgram.id);
            return params.swim_time ? params.swim_time : '';
        },
        /**
         * Проставление заявочного времени для программы
         * @param swimProgram
         */
        setAthleteSwimTime: function (swimProgram, value) {
            let params = this.prepareAthleteSwimProgramParam(swimProgram.id);
            params.swim_time = value;
            if (value && this.selectedSwimPrograms.indexOf(swimProgram.id) == -1) {
                this.selectedSwimPrograms.push(swimProgram.id);
            }

            Vue.set(this.swimProgramsParams, swimProgram.id, params);
        },
        /**
         * Получение типа бассейна для программы
         * @param swimProgram
         */
        getAthleteCourse: function (swimProgram) {
            let params = this.prepareAthleteSwimProgramParam(swimProgram.id);
            return params.course;
        },
        /**
         * Проставление типа бассейна для программы
         * @param swimProgram
         */
        setAthleteCourse: function (swimProgram, value) {
            let params = this.prepareAthleteSwimProgramParam(swimProgram.id);
            params.course = value;
            Vue.set(this.swimProgramsParams, swimProgram.id, params);
        },
        /**
         * Получение даты достижения заявочного времени для программы
         * @param swimProgram
         */
        getAthleteEventDate: function (swimProgram) {
            let params = this.prepareAthleteSwimProgramParam(swimProgram.id);
            return params.event_date ? params.event_date : '';
        },
        /**
         * Проставление даты достижения для программы
         * @param swimProgram
         */
        setAthleteEventDate: function (swimProgram, value) {
            let params = this.prepareAthleteSwimProgramParam(swimProgram.id);
            params.event_date = value;
            Vue.set(this.swimProgramsParams, swimProgram.id, params);
        },
        /**
         * Получение места достижения заявочного времени для программы
         * @param swimProgram
         */
        getAthleteEventPlace: function (swimProgram) {
            let params = this.prepareAthleteSwimProgramParam(swimProgram.id);
            return params.event_place;
        },
        /**
         * Проставление места достижения для программы
         * @param swimProgram
         */
        setAthleteEventPlace: function (swimProgram, value) {
            let params = this.prepareAthleteSwimProgramParam(swimProgram.id);
            params.event_place = value;
            Vue.set(this.swimProgramsParams, swimProgram.id, params);
        },
        /**
         * Получаем параметры для вида соревнования
         * @param swimProgramId
         * @returns {*}
         */
        prepareAthleteSwimProgramParam: function(swimProgramId) {
            if (!this.swimProgramsParams.hasOwnProperty(swimProgramId)) {
                if (this.existsSwimTimes.hasOwnProperty(this.selectedProposalAthlete.athlete.id)) {
                    let filteredSwimProgram = this.availableSwimPrograms.filter(item => item.id == swimProgramId);
                    if (filteredSwimProgram.length > 0) {
                        let existsSwimTimes = this.existsSwimTimes[this.selectedProposalAthlete.athlete.id].filter(item => item.swim_program_id == filteredSwimProgram[0].swim_program_id);
                        if (existsSwimTimes.length > 0) {
                            Vue.set(this.swimProgramsParams, swimProgramId, {
                                swim_program_id: swimProgramId,
                                swim_time: existsSwimTimes[0].swim_time,
                                course: existsSwimTimes[0].course,
                                event_date: existsSwimTimes[0].event_date,
                                event_place: existsSwimTimes[0].event_place,
                            });
                            return this.swimProgramsParams[swimProgramId];
                        }
                    }
                }
                Vue.set(this.swimProgramsParams, swimProgramId, {
                    swim_program_id: swimProgramId,
                    swim_time: '',
                    course: '',
                    event_date: '',
                    event_place: ''
                });
            }
            return this.swimProgramsParams[swimProgramId];
        }
    }
});
