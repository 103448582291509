<template>
    <div :class="{'is-invalid': hasError}">
        <vue-date-picker
                v-model="model"
                :input-class="inputClass"
                format="DD.MM.YYYY"
                :input-attr="inputAttr"
                placeholder="dd.mm.yyyy"
                :disabled="disabled"
                :disabled-date="disabledDate"
                v-on:input-error="onInputError"
                :default-value="defaultValue"
                :inline="isInline"
                :append-to-body="isAppendToBody"
                v-on:change="onValueChange"
        ></vue-date-picker>
    </div>
</template>

<script>
	import DatePicker from 'vue2-datepicker';
	import 'vue2-datepicker/index.css';
	import 'vue2-datepicker/locale/ru';

	export default {
		props: ['value', 'inputId', 'hasError', 'disabled', 'disabledDate', 'defaultValue', 'inline', 'appendToBody', 'onChange'],
        components: {
			'vue-date-picker': DatePicker
        },
		data: function() {
			return {
			}
		},
        computed: {
            /**
             * Является ли встроенным календарем
             */
            isInline: function() {
                if (this.inline) {
                    return true;
                }
                return false;
            },
            /**
             * Добавить в body
             */
            isAppendToBody: function() {
                if (this.appendToBody) {
                    return true;
                }
                return false;
            },
			/**
             * Классы для компонента
			 * @returns {string}
			 */
			inputClass: function() {
				return this.hasError ? 'form-control is-invalid' : 'form-control';
			},
			/**
             * Атрибуты для компоненты
			 * @returns {{v-mask: string}}
			 */
			inputAttr: function() {
				var attr = {
					'v-mask': "'##.##.####'"
                };
				if (this.inputId) {
					attr.id = this.inputId;
                }
				return attr;
			},
			/**
             * Модель компонента
			 */
			model: {
				get: function() {
                    var date = new Date(this.value);
                    return isNaN(date) ? null : date;
                },
                set: function(value) {
					if (!value) {
                        this.$emit('input', "");
						return null;
                    }
                    this.$emit('input', moment(value).format('YYYY-MM-DD'));
                }
            }
        },
        methods: {
			/**
             * Обработчик некорректного ввода даты
			 * @param event
			 */
			onInputError: function(value) {
				value = value.replace(/[^0-9.]/g, "");
				if (value.length == 8) {
					var date = value.substr(4, 4) + '-' + value.substr(2, 2) + '-' + value.substr(0, 2);
					this.$emit('input', moment(date).format('YYYY-MM-DD'));
                }
            },
            /**
             * Обработка события изменения
             * @param {moment} value
             */
            onValueChange: function (value) {
			    if (this.onChange && typeof(this.onChange) === 'function') {
			        this.onChange(value);
                }
            }
        }
	}
</script>
