Vue.component('notification-view', {
	data: function() {
		return {
			data: {},
		}
	},
	props: ['id'],
	mounted: function() {
		this.loadData();
	},
	methods: {
		/**
		 * Загрузка данных уведомления
		 */
		loadData: function() {
			axios.get('/notifications/fetch/' + this.id)
				.then(response => {
					this.data = response.data;
					Bus.$emit('changeMenuState', {
						itemCode: 'notifications',
						mode: 'detail',
						backLink: this.data.is_read ? '/notifications/read' : '/notifications',
						backTitle: 'К списку уведомлений',
						title: 'Уведомление в системе'
					});
					if (!this.data.is_read) {
						this.markAsRead();
					}
				})
				.catch(error => {
					console.warn('Ошибка при загрузке уведомления', error);
				})
		},
		/**
		 * Отмечаем уведомление как прочитанное
		 */
		markAsRead: function () {
			axios.post('/notifications/mark-as-read', {
				id: this.data.id
			}).then(response => {
				Bus.$emit('reloadMenu');
			}).catch(error => {
				console.warn('Ошибка при отметки о прочтении уведомления', error);
			})
		},
	}
});
