<template>
    <div class="filter-container">
        <div class="filter-label"
             :class="isNotEmpty ? 'filter-active' : ''"
             :dusk="dusk"
             aria-haspopup="true"
             aria-expanded="false"
             data-toggle="dropdown"
        >{{getTitle}}</div>
        <div v-on:click.stop.self class="dropdown-menu filter-content" aria-labelledby="dropdownMenuButton">
            <ul class="checkbox-list">
                <li v-for="(label, index) in insurancePolicyStatusesList">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        name="remember"
                        :dusk="'insuranceStatus-' + index"
                        :id="'insurance-policy-filter-' + index"
                        v-bind:true-value="1" v-bind:false-value="0"
                        v-model="insurancePolicyStatuses[index]"
                    />

                    <label class="form-check-label checkbox-label" :for="'insurance-policy-filter-' + index">
                        {{label}}
                    </label>
                </li>
            </ul>

            <hr />
            <div class="filter-buttons">
                <span v-on:click="resetFilter" class="filter-action-span">
                    Очистить
                </span>
                <button dusk="insuranceSave" class="btn btn-primary float-right" v-on:click="applyFilter">
                    Сохранить
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AthleteInsurancePolicyFilter",
        props: ['insurancePolicyStatusesList', 'dusk'],
        data: function () {
            return {
                insurancePolicyStatuses: [],
                filters: {
                    insurancePolicyStatuses: [],
                }
            }
        },
        methods: {
            applyFilter: function (send = true) {
                let activeStatuses = [];
                for (let i=0;i < this.insurancePolicyStatuses.length; i++) {
                    if (this.insurancePolicyStatuses[i] == 1) {
                        activeStatuses.push(i);
                    }
                }
                this.filters = {
                    insurancePolicyStatuses: activeStatuses,
                };
                let filters = {insurancePolicyStatuses: activeStatuses};

                if (send) {
                    this.$emit('applyFilter', filters);
                }
                this.closeDropDown();
            },
            resetFilter: function (apply = true) {
                this.insurancePolicyStatuses = [];
                this.applyFilter(apply);
            },
            closeDropDown: function () {
                $('.show').removeClass('show');
            }
        },
        computed: {
            getTitle: function() {
                var baseTitle = 'Страховой полис';
                var totalRanksCount = 0;
                for (let rank in this.insurancePolicyStatusesList) {
                    totalRanksCount++;
                }

                if (!this.filters.insurancePolicyStatuses || this.filters.insurancePolicyStatuses.length === 0) {
                    return baseTitle;
                }
                if (this.filters.insurancePolicyStatuses.length === totalRanksCount) {
                    return baseTitle + ': Все';
                }

                if (this.filters.insurancePolicyStatuses.length > 1) {
                    return baseTitle + ': ' + this.filters.insurancePolicyStatuses.length;
                }

                let rankKey = this.filters.insurancePolicyStatuses[0];
                for (let rank in this.insurancePolicyStatusesList){
                    if (this.insurancePolicyStatusesList.hasOwnProperty(rank) && parseInt(rank) === rankKey) {
                        return this.insurancePolicyStatusesList[rank];
                    }
                }

                return baseTitle;
            },
            isNotEmpty: function () {
                return this.filters.insurancePolicyStatuses && this.filters.insurancePolicyStatuses.length !== 0;
            }
        }
    }
</script>

<style scoped>

</style>
