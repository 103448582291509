Vue.component('athlete-rank-form', {
    props: ['title', 'rankTypes', 'athleteRankId'],
    data: function() {
        return {
            form: new TrustyForm({
                id: '',
                name: '',
                code: '',
                age_min: '',
                age_max: '',
                rank_type: 0,
                action_period: '',
                sort_order: 0,
            }),
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'dictionary',
            mode: 'detail',
            backLink: '/dictionaries/athlete-ranks',
            backTitle: 'К списку разрядов',
            title: this.title
        });
        if (this.athleteRankId) {
            this.loadAthleteRank();
        }
    },
    computed: {
        /**
         * Урл для отправки запроса
         * @returns {string}
         */
        sendUrl: function () {
            return this.athleteRankId
                ? '/dictionaries/athlete-ranks/' + this.athleteRankId
                : '/dictionaries/athlete-ranks';
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            Trusty.post(this.sendUrl, this.form)
                .then(response => {
                    location.href = '/dictionaries/athlete-ranks';
                })
                .catch(error => {

                })
        },
        /**
         * Загружаем данные о разряде
         */
        loadAthleteRank: function() {
            axios.get('/dictionaries/athlete-ranks/fetch/' + this.athleteRankId)
                .then(response => {
                    $.extend(true, this.form, response.data);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке разряда', error);
                })
        }
    }
});
