import Paginate from 'vuejs-paginate';

Vue.component('general-athlete-list', {
    props: [
        'genderShortLabels',
    ],
    components: {
        Paginate
    },
    data:function () {
        return {
            data: {
                athletes: [],
                links: {},
                meta: {},
            },
            page: 1,
            orderColumn: 'id',
            orderDirection: 'asc',
            perPage: 20,
            mode: 'default',
            lastNameKeyword: '',
            yearKeyword: '',
            isSearch: false
        }
    },
    mounted: function(){
    },
    computed: {
        /**
         * @brief Получение параметров для запроса обновления данных
         */
        getDataParams: function () {
            let params =  {
                page: this.page,
                per_page: this.perPage,
                mode: this.mode,
                order_column: this.orderColumn,
                order_direction: this.orderDirection,
            };

            if (this.isValidSearchParameters && this.isSearch) {
                params.keyword = this.lastNameKeyword;
                params.yearKeyword = this.yearKeyword;
            }
            return params;
        },
        /**
         * @brief Правильно ли введены значения в поиск
         * @returns {boolean|boolean}
         */
        isValidSearchParameters: function () {
            return (this.lastNameKeyword.length > 2) && (this.yearKeyword.length == 4);
        }
    },
    methods: {
        /**
         * @brief переключение страницы и загрузка спортсменов
         * @param page
         */
        paginate: function(page) {
            this.page = page;
            this.loadData();
        },
        /**
         * @brief Загрузка данных спортсменов
         */
        loadData: function() {
            this.processing = true;
            axios.get('/general/athletes/list', {params: this.getDataParams})
                .then(response => {
                    this.data.athletes = response.data.data;
                    this.data.links = response.data.links;
                    this.data.meta = response.data.meta;
                    this.processing = false;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных спортсменов', error);
                    this.processing = false;
                })
        },
        /**
         * @brief Очистить поиск
         */
        clearSearch: function () {
            this.lastNameKeyword = '';
            this.yearKeyword = '';
            this.isSearch = false;
            this.data.athletes = [];
        },
        /**
         * @brief Переход к профайлу спортсмена
         * @param athleteId
         */
        goToProfileAthlete: function (athleteId) {
            location.href = '/general/athletes/' + athleteId;
        },
        /**
         * @brief Поиск по спортсменам
         */
        search: function () {
            this.isSearch = true;
            this.loadData();
        }
    }
});
