Vue.component('seminar-payment-list', {
    props: ['statusLabels'],
    mounted() {
        Bus.$emit('changeMenuState', {
            itemCode: 'seminars',
            childCode: 'seminar-payments'
        });
    },
    data() {
        return {
            filters: {
                statuses: []
            },
            constants: require('./constants')
        }
    },
    methods:{
        /**
         * Форматированный вывод наименования семинара
         * @param value
         * @param row
         * @returns {*}
         */
        seminarName: function(value, row) {
            return row.seminar ? row.seminar.name : '';
        },
        /**
         * Форматированный вывод статуса транзакции
         * @param value
         * @param row
         * @returns {*}
         */
        statusLabel: function(value, row) {
            return '<img src="' + this.constants.STATUS_ICONS[value] + '" title="' + this.statusLabels[value] + '">';
        },
        /**
         * Форматированный вывод статуса чека
         * @param value
         * @param row
         * @returns {*}
         */
        paymentCheckLabel: function(value, row) {
            if (this.constants.CHECK_STATUS_ICONS[value]) {
                return '<img src="' + this.constants.CHECK_STATUS_ICONS[value] + '">';
            }
        },
        /**
         * Наименование судьи
         * @param value
         * @param row
         * @returns {string}
         */
        judgeName: function (value, row) {
            return row.judge ? (row.judge.last_name + ' ' + row.judge.first_name) : '';
        },
        /**
         * Форматированный вывод номера транзакции и карты
         * @param value
         * @param row
         * @returns {*}
         */
        transaction: function (value, row) {
            let html = value;
            if (row.card_type) {
                html += '<br/>';
                html += '<span class="badge badge-primary p-2">' + row.card_type + '   ***-' + row.card_last_four + '</span>';
            }
            return html;
        },
        /**
         * Применяем фильтры
         */
        applyFilters: function () {
            let filters = {
                statuses: this.filters.statuses
            };
            this.$refs.seminarPaymentsGrid.applyFilters(filters);
        },
        /**
         * Показываем окнов формой группового возврата
         */
        showRefundModal: function () {
            this.$refs.seminarPaymentRefundModal.init();
        }
    }
});
