Vue.component('athlete-info-modal', {
    props: [],
    mixins: [require('../../common/mixins/user')],
    data: function() {
        return {
            emptyLabel: 'Не заполнено',
            processing: false,
            id: null,
            data: {},
        }
    },
    methods: {
        init: function (id) {
            this.id = id;
            this.loadData();
            $('.modal-athlete-info').modal('show');
        },
        /**
         * Загрузка данных спортсмена
         */
        loadData: function() {
            this.processing = true;
            let url =  '/athletes/fetch/' + this.id;
            axios.get(url)
                .then(response => {
                    this.data = response.data;
                    this.processing = false;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных спортсмена', error);
                    this.processing = false;
                })
        },
    }
});
