Vue.component('judge-form', {
    props: ['title', 'judgeId', 'categoryTypes'],
    data: function() {
        return {
            form: new TrustyForm({
                id: '',
                first_name: '',
                last_name: '',
                patronymic: '',
                birth_date: '',
                categories: [],
                municipality_id: '',
            }),
            municipalities: [],
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'seminars',
            mode: 'detail',
            backLink: '/judges',
            backTitle: 'К списку судей',
            title: this.title
        });
        if (this.judgeId) {
            this.loadJudge();
        }

        this.loadMunicipalities();
        Bus.$on('judge-category-updated', this.updateJudgeCategory);
    },
    beforeDestroy() {
        Bus.$off('judge-category-updated', this.updateJudgeCategory);
    },
    computed: {
        /**
         * Модель для муниципалитета
         */
        municipality: {
            get: function() {
                let filtered = this.municipalities.filter(item => item.id == this.form.municipality_id);
                if (filtered.length) {
                    return filtered[0];
                }
                return null;
            },
            set: function (item) {
                this.form.municipality_id = item ? item.id : '';
            }
        },
        /**
         * Урл для отправки запроса
         * @returns {string}
         */
        sendUrl: function () {
            return this.judgeId
                ? '/judges/' + this.judgeId
                : '/judges';
        },
        /**
         * Отсортированный список по дате выдачи категорий
         * @return {string[] | string | [] | *[]}
         */
        sortedCategories: function() {
            return this.form.categories.sort(function(a, b) {
                let momentA = moment(a.issue_at);
                let momentB = moment(b.issue_at);

                return momentA > momentB ? 1 : -1;
            });
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            Trusty.post(this.sendUrl, this.form)
                .then(response => {
                    location.href = '/judges';
                })
                .catch(error => {

                })
        },
        /**
         * Загружаем данные о судье
         */
        loadJudge: function() {
            axios.get('/judges/fetch/' + this.judgeId)
                .then(response => {
                    $.extend(true, this.form, response.data);
                    this.form.categories = response.data.judgeJudgeCategories;

                })
                .catch(error => {
                    console.warn('Ошибка при загрузке судьи', error);
                })
        },

        /**
         * Изменить или добавить судейскуюю категорию к судье
         * @param index
         */
        editJudgeCategory: function(index) {
            let category = null;
            if (index !== null) {
                category = this.form.categories[index];
            }

            this.$refs.judgeAddCategoryModal.init({
                    judgeCategory: category,
                    index: index
                }
            );
        },

        /**
         * Удаление судейский категории из истории
         * @param index
         */
        deleteJudgeCategory: function(index) {
            this.form.categories.splice(index, 1);
        },

        /**
         * Обновляем список категорий
         * @param data
         */
        updateJudgeCategory: function (data) {
            if (data.index === null || typeof(data.index) === 'undefined') {
                this.form.categories.push(data.judgeCategory);
            } else {
                let categories = this.form.categories;
                categories.splice(data.index, 1, data.judgeCategory);
                Vue.set(this.form, 'categories', categories);
            }
        },

        /**
         * Получить название судейской категории по id
         * @param id integer
         * @return {null|*}
         */
        getCategoryTypeName:  function (id) {
            let item = this.categoryTypes.find(item => item.id === id)
            if (typeof(item) === 'undefined') {
                return null;
            }
            return item.name;
        },
        /**
         * Загружаем муниципалитеты
         */
        loadMunicipalities: function() {
            axios.get('/dictionaries/municipalities/all')
                .then(response => {
                    this.municipalities = response.data;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке списка муниципалитетов', error);
                })
        }
    }
});
