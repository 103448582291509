<template>
    <paginate
            :model="currentPage"
            :page-count="count"
            :click-handler="paginate"
            :prev-text="'<img src=\'/images/icons/paginate-angle-left.svg\'>'"
            :next-text="'<img src=\'/images/icons/paginate-angle-right.svg\'>'"
            :container-class="'pagination'"
            :page-class="'page-item'"
    >
    </paginate>
</template>

<script>
    import Paginate from 'vuejs-paginate';

    export default {
        props: [
            'currentPage',
            'count',
            'onPaginate'
        ],
        components: {
            Paginate
        },
        methods: {
            paginate: function(page) {
                this.onPaginate(page);
            },
        }
    }
</script>
