Vue.component('competition-proposal-create-form', {
    mixins: [require('../mixins/form'), require('../mixins/dateFormatter')],
    data: function() {
        return {
            tabSubMenuActiveItem: 'tab-1',
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'competitions',
            mode: 'detail',
            backLink: '/competitions/proposals',
            backTitle: 'К списку заявок на участие',
            title: 'Заявка на участие в соревнованиях'
        });
    },
    methods: {
        /**
         * Если таб в подменю заявки активен
         * @param tabSubMenuItem
         * @returns {boolean}
         */
        isActiveTabSubMenu(tabSubMenuItem) {
            return this.tabSubMenuActiveItem === tabSubMenuItem;
        },
        /**
         * Делаем активным таб в подменю заявки
         * @param tabSubMenuItem
         * @returns {boolean}
         */
        setActiveTabSubMenu (tabSubMenuItem) {
            this.tabSubMenuActiveItem = tabSubMenuItem;
        },
        /**
         * Редактируем техническую заявку-модалку
         */
        editCompetitionProposalModal: function() {
            this.$parent.$refs.competitionProposalModal.init();
        },
        /**
         * Открываем модалку для добавления спортсменов
         */
        addAthletesProposalModal: function() {
            this.$parent.$refs.proposalAddAthleteModal.init();
        }
    }
});
