Vue.component('time-standard-group-list', {
    props: ['ageGroups', 'groupTypes', 'athleteRanks'],
    data: function () {
        return {
            currentRow: null
        }
    },
    mounted() {
        Bus.$emit('changeMenuState', {
            itemCode: 'dictionaries',
            childCode: 'time-standard-groups'
        });
    },
    methods:{
        /**
         * @brief Форматированный вывод возрастной группы
         * @param value
         * @param row
         * @returns {*}
         */
        ageGroupFormat: function (value, row) {
            return this.ageGroups[value];
        },
        /**
         * @brief Форматированный вывод типа группы
         * @param value
         * @param row
         * @returns {*}
         */
        groupTypeFormat: function (value, row) {
            return this.groupTypes[value];
        },
        /**
         * @brief Форматированный вывод разряда спортсмена
         * @param value
         * @param row
         * @returns {*}
         */
        athleteRankFormat: function (value, row) {
            return this.athleteRanks[value];
        },
        /**
         * Подтверждение удаления норматива
         */
        confirmDelete: function(row) {
            this.currentRow = row;
            this.$refs.confirmModal.init({
                title: 'Перенос норматива в архив',
                confirmText: 'Вы действительно хотите перенести в архив данный норматив?',
                confirmButtonTitle: 'Перенeсти',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteTimeStandardGroup
            })
        },
        /**
         * Удаление норматива
         */
        deleteTimeStandardGroup: function() {
            this.$refs.timeStandardGroupGrid.setLoading(true);
            var self = this;
            axios.delete('/dictionaries/time-standard-groups/' + this.currentRow.id)
                .then(
                    response => {
                        self.$refs.timeStandardGroupGrid.reloadData();
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при удалении норматива', error);
                    self.$refs.timeStandardGroupGrid.setLoading(false);
                })
        },
    }
});
