export default {
    getData(url, params = {}) {
        return axios.get(url, {params:params})
    },
    remove(url, params = {}) {
        return axios.delete(url, {params:params})
    },
    sendRequest(url, method, params) {
        if (params) {
            return axios[method](url, params)
        } else {
            return axios[method](url);
        }
    }
}

