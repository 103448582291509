import proposalConstants from './../proposals/constants';
Vue.component('competition-tab-all-quotas', {
    props: ['genderShortLabels'],
    mixins: [require('../../common/mixins/user')],
    data: function() {
        return {
            constants: require('../constants'),
            data: {},
            mode: 'clubs',
            filterGender: null,
            filterTeamName: null,
            proposalConstants: proposalConstants
        }
    },
    computed: {
        /**
         * Загружены ли данные или нет
         * @return bool
         */
        loaded: function () {
            return this.data.hasOwnProperty('all_proposals');
        },

        /**
         * Заявленные клубы на соревнование
         * @returns {*}
         */
        clubs: function() {
            if (!this.data.all_proposals.hasOwnProperty('clubs')) {
                return [];
            }
            return _.sortBy(this.data.all_proposals.clubs, function(item) {
                return moment(item.competition_proposal_date)
            });
        },
        /**
         * Квоты для соревнования
         * @returns {competition-tab-all-proposals.computed.quotas|null|*[]}
         */
        quotas: function() {
            if (!this.data.all_proposals.hasOwnProperty('quotas')) {
                return [];
            }
            return this.data.all_proposals.quotas;
        }
    },
    methods: {
        /**
         * Инициализиция данных для компонента
         * @param data
         */
        init: function(data) {
            this.data = data;
            if (this.data.need_general_teams) {
                this.mode = 'teams';
            }
        },
        /**
         * Количество участников по договору
         * @param clubId
         * @returns {*}
         */
        getPaidParticipantCount: function (clubId) {
            let clubInfo = this.getClubInfo(clubId);
            return clubInfo !== null ? clubInfo.paid_participant_count : 0;
        },
        /**
         * Количество участников по общему положению
         * @param clubId
         * @returns {*}
         */
        getFreeParticipantCount: function (clubId) {
            let clubInfo = this.getClubInfo(clubId);
            return clubInfo !== null ? clubInfo.free_participant_count : 0;
        },
        /**
         * Количество всех участников
         * @param clubId
         * @returns {*}
         */
        getAllParticipantCount: function (clubId) {
            return this.getFreeParticipantCount(clubId) + this.getPaidParticipantCount(clubId);
        },
        /**
         * Количество эстафет
         * @param clubId
         * @returns {number}
         */
        getRelayCount: function (clubId) {
            let clubInfo = this.getClubInfo(clubId);
            return clubInfo !== null ? clubInfo.relay_count : 0;
        },
        /**
         * Получаем данные по клубу
         * @param clubId
         * @returns {null|T}
         */
        getClubInfo: function (clubId) {
            if (clubId == null) {
                clubId = 0;
            }
            let filtered = this.clubs.filter(item => item.id == clubId);
            if (filtered.length > 0) {
                return filtered[0];
            }
            return null;
        },
        /**
         * Задаем пол спортсмена, по которому фильтровать
         * @param event
         */
        selectFilterGender: function (event) {
            if (event.target.checked) {
                if (this.filterGender) {
                    this.filterGender = null; //оба пола
                } else {
                    if (event.target.value == this.proposalConstants.GENDER_MALE) {
                        this.filterGender = this.proposalConstants.GENDER_MALE;
                    }
                    if (event.target.value == this.proposalConstants.GENDER_FEMALE) {
                        this.filterGender = this.proposalConstants.GENDER_FEMALE;
                    }
                }
            } else {
                if (this.filterGender) {
                    this.filterGender = null; //оба пола
                } else {
                    if (event.target.value == this.proposalConstants.GENDER_MALE) {
                        this.filterGender = this.proposalConstants.GENDER_FEMALE;
                    }
                    if (event.target.value == this.proposalConstants.GENDER_FEMALE) {
                        this.filterGender = this.proposalConstants.GENDER_MALE;
                    }
                }
            }
        },
        /**
         * Фильтруем спортсменов по полу и команде/клубу
         * @param athlete
         * @returns {boolean}
         */
        filterAthletes: function (athlete) {
            let isGenderSatisfying = (athlete.athlete.gender == this.filterGender) || (this.filterGender == null);
            let isTeamSatisfying;
            if (this.generalTeams && this.generalTeams.length > 0) {
                isTeamSatisfying = (this.filterTeamName == null)
                    || (this.filterTeamName == athlete.competition_proposal.competition_proposal_general_team.full_name);
            } else {
                if (athlete.athlete.club) {
                    isTeamSatisfying = (this.filterTeamName == null) || (this.filterTeamName == athlete.athlete.club.name);
                } else {
                    isTeamSatisfying = (this.filterTeamName == null) || (this.filterTeamName == 'Независимые спортсмены');
                }
            }
            return isGenderSatisfying && isTeamSatisfying;
        },
        /**
         * Выводим название команды/клуба в фильтре
         * @param event
         */
        selectTeamName(event) {
            if (event) {
                this.filterTeamName = event.name;
            } else {
                this.filterTeamName = null;
            }
        }
    }
});
