Vue.component('main-menu', {
	data: function() {
		return {
			items: [],
			mode: 'submenu',
			currentItemCode: '',
			currentChildCode: '',
			backLink: '',
			backTitle: '',
			title: '',
		}
	},
	mounted() {
		this.items = Trusty.menu.items;
		Bus.$on('changeMenuState', this.changeMenuState);
		Bus.$on('reloadMenu', this.reloadMenu);
	},
	computed: {
		/**
		 * Нужно ли показывать подменю
		 * @returns {boolean}
		 */
		isSubMenuMode: function() {
			return this.mode == 'submenu';
		},
		/**
		 * Нужно ли показывать блок заголовком и возвратом
		 * @returns {boolean}
		 */
		isDetailMode: function() {
			return this.mode == 'detail';
		},
		/**
		 * Список дочерних элементов меню
		 */
		childrenItems: function() {
			let currentItem = this.items.filter(item => item.code == this.currentItemCode);
			if (currentItem.length == 0) {
				return [];
			}
			return currentItem[0].children;
		}
	},
	methods: {
		/**
		 * Смена состояния меню
		 * @param payload
		 */
		changeMenuState: function(payload) {
			if (payload.hasOwnProperty('mode')) {
				this.mode = payload.mode;
			}
			if (payload.hasOwnProperty('itemCode')) {
				this.currentItemCode = payload.itemCode;
			}
			if (payload.hasOwnProperty('childCode')) {
				this.currentChildCode = payload.childCode;
			}
			if (payload.hasOwnProperty('backLink')) {
				this.backLink = payload.backLink;
			}
			if (payload.hasOwnProperty('backTitle')) {
				this.backTitle = payload.backTitle;
			}
			if (payload.hasOwnProperty('title')) {
				this.title = payload.title;
			}
		},
		/**
		 * Есть ли дочерние подменю для показа информера
		 * @param item
		 * @returns {boolean}
		 */
		hasInformer: function (item) {
			if (item.informer_count > 0) {
				return true;
			}
			return item.children.filter(value => value.informer_count > 0).length > 0;
		},
		/**
		 * Обновляем меню
		 */
		reloadMenu: function() {
			axios.get('/menu')
				.then(response => {
					this.items = response.data.items;
				})
				.catch(error => {
					console.warn('Ошибка при загрузке меню', error);
				})
		}
	},
	beforeDestroy() {
		Bus.$off('changeMenuState', this.changeMenuState);
		Bus.$off('reloadMenu', this.reloadMenu);
	}
});
