Vue.component('competition-tab-judges', {
    props: ['competitionId'],
    data: function() {
        return {
            enabled: false,
            judges: [],
            roles: [],
            competitionJudges: [],
            judgeToAdd: null,
            judge_role_id: '',
        }
    },
    methods: {
        init: function () {
            this.loadRoles();
            this.loadCompetitionJudges();
        },
        /**
         * Загрузить список ролей судей
         */
        loadRoles: function() {
            this.processing = true;
            let url = '/seminars/judge-roles/all';
            axios.get(url)
                .then(response => {
                    this.roles = response.data;
                    this.loaded = true;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных ролей судей', error);
                    this.loaded = true;
                })
        },

        /**
         * Загрузить список судей соревнования
         */
        loadCompetitionJudges: function() {
            this.processing = true;
            let url = '/competitions/judges/' + this.competitionId;
            axios.get(url)
                .then(response => {
                    this.competitionJudges = response.data;
                    this.loaded = true;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных судей соревнования', error);
                    this.loaded = true;
                })
        },

        /**
         * Добавить судью соревнования
         */
        addJudge: function () {
            if (!this.judgeToAdd) {
                return;
            }

            axios.post('/competitions/competition-judge/create/' + this.competitionId, {
                judge_id: this.judgeToAdd.id,
                judge_role_id: this.judge_role_id,
            }).then(response => {
                this.competitionJudges.push(response.data);
            }).catch(error => {
                console.warn('Ошибка при добавлении судьи в соревнование', error);
            })
        },

        /**
         * Отвязать судью от соревнования
         * @param id
         */
        deleteCompetitionJudge: function (id) {
            axios.delete('/competitions/competition-judge/delete/' + id).then(response => {
                this.competitionJudges = this.competitionJudges.filter(item => item.id !== id);
            }).catch(error => {
                console.warn('Ошибка при удалении судьи из соревнования', error);
            })
        },

        /**
         * Поиск судей
         * @param search
         * @param loading
         */
        searchJudges: function(search, loading) {
            if (!search) {
                return;
            }
            loading && loading(true);
            this.judges = [];
            axios.get('/judges/search-judge-filter?keyword=' + search + '&limit=50')
                .then(response => {
                    this.judges = response.data;
                    loading && loading(false);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке списка судей', error);
                    loading && loading(false);
                })
        },
    },

    computed: {
        /**
         * Валидна ли форма добавления судьи
         * @return {boolean}
         */
        canAddJudge: function () {
            return this.judge_role_id && this.judgeToAdd;
        },
        /**
         * Список судей, доступных для добавления новой записи результатов семинара
         * @return {T[]}
         */
        availableJudges: function() {
            let self = this;
            return this.judges.filter(function(item) {
                let result = self.competitionJudges.find(competitionJudge => competitionJudge.judge_id === item.id);
                return typeof(result) === 'undefined';
            })
        },
    },
});
