<template>
    <div>
        <div class="alert" role="alert" v-for="(alert, index) in alerts" :class="alertTypes[alert.type].class">
            <img :src="alertTypes[alert.type].icon"><strong>{{alertTypes[alert.type].prefix}}:</strong> {{alert.message}}
            <button type="button" class="close" @click="onClickClose(index)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</template>

<script>
	export default {
		data: function() {
			return {
				alerts: [],
				alertTypes: {
					success: {
						class: 'alert-success',
                        icon: '/images/icons/check-circle-full.svg',
                        prefix: 'Успех'
                    },
                    error: {
                        class: 'alert-danger',
                        icon: '/images/icons/warning-open.svg',
                        prefix: 'Ошибка'
                    }
                }
            }
        },
        mounted: function() {
            Bus.$on('pushFlashAlert', this.pushFlashAlert);
            this.alerts = Trusty.flashAlerts;
        },
        methods: {
			/**
             * Добавляем флеш сообщение в коллекцию
			 * @param alert
			 */
			pushFlashAlert: function (alert) {
                this.alerts.push(alert);
			},
			/**
             * Удаляем сообщение
			 * @param index
			 */
			onClickClose: function (index) {
				this.alerts.splice(index, 1);
            }
        },
        beforeDestroy: function () {
            Bus.$off('pushFlashAlert', this.pushFlashAlert);
		}
	}
</script>
