Vue.component('result-wizard-common-settings', {
    props: ['competition'],
    data: function() {
        return {
            loading: false,
            saving: false,
            resultFooter: {
                left: '',
                center: '',
                right: '',
                print_date: ''
            }
        }
    },
    mounted: function() {
    },
    methods: {
        /**
         * Загружаем данные настроек отчетов
         */
        loadCompetitionReportSetting: function () {
            this.loading = true;
            axios.get('/competitions/' + this.competition.id + '/report-settings/fetch')
                .then(response => {
                    this.resultFooter = response.data.result_footer;
                    this.loading = false;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке настроек отчетов', error);
                    this.loading = false;
                });
        },
        /**
         * Сохранение настроек отчетов
         */
        save: function() {
            this.saving = true;
            axios.post('/competitions/' + this.competition.id + '/report-settings/update',{
                result_footer: this.resultFooter
            })
                .then(response => {
                    this.$parent.setStep(2);
                    this.saving = false;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке настроек отчетов', error);
                    this.saving = false;
                });
        },
        /**
         * Выход из визарда
         */
        cancel: function() {
            this.$parent.$emit('closed');
        }
    }
});
