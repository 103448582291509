Vue.component('time-standard-group-import-form', {
    props: ['title'],
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'dictionary',
            mode: 'detail',
            backLink: '/dictionaries/time-standard-groups',
            backTitle: 'К списку групп нормативов',
            title: this.title
        });
    },
    computed: {
        /**
         * Урл для отправки запроса
         * @returns {string}
         */
        sendUrl: function () {
            return '/dictionaries/time-standard-groups/import-store';
        }
    },
    methods: {
        /**
         * Обработчик загрузки файла
         */
        onChangeLenexFile: function(fileData) {
            var formData = new FormData();
            formData.append("file", fileData);
            axios.post(this.sendUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                if (response.data.success) {
                    Bus.$emit('pushFlashAlert', {
                        type: 'success',
                        message: 'Файл успешно импортирован! ' + response.data.rows + ' нормативов добавлено!'
                    })
                } else {
                    Bus.$emit('pushFlashAlert', {
                        type: 'error',
                        message: 'Ошибка импорта!'
                    })
                }
            }).catch(error => {
                Bus.$emit('pushFlashAlert', {
                    type: 'error',
                    message: 'Ошибка импорта!'
                })
            });
        }
    }
});
