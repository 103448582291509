Vue.component('competition-provision-form', {
    props: ['title', 'provisionId'],
    data: function() {
        return {
            form: new TrustyForm({
                id: '',
                year: '',
                provision_doc_file: '',
            }),
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'settings',
            mode: 'detail',
            backLink: '/settings/competition-provisions',
            backTitle: 'К списку положений',
            title: this.title
        });
        if (this.provisionId) {
            this.loadProvision();
        }
    },
    computed: {
        /**
         * Урл для отправки запроса
         * @returns {string}
         */
        sendUrl: function () {
            return this.provisionId
                ? '/settings/competition-provisions/' + this.provisionId
                : '/settings/competition-provisions';
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            Trusty.postWithFiles(this.sendUrl, this.form, [
                'provision_doc_file',
            ])
                .then(response => {
                    location.href = '/settings/competition-provisions';
                })
                .catch(error => {

                })
        },
        /**
         * Загружаем данные о положении
         */
        loadProvision: function() {
            axios.get('/settings/competition-provisions/fetch/' + this.provisionId)
                .then(response => {
                    $.extend(true, this.form, response.data);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке положения', error);
                })
        },
        /**
         * Обработчик смены файла положение
         * @param fileData
         */
        onChangeProvisionDocFile: function(fileData) {
            this.form.provision_doc_file = fileData;
        }
    }
});
