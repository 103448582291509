Vue.component('seminar-form', {
    props: ['title', 'seminarId'],
    data: function() {
        return {
            form: new TrustyForm({
                id: null,
                name: '',
                city_id: '',
                facility_address: '',
                facility_name: '',
                description: '',
                date_from: '',
                date_to: '',
                check_in_at: '',
                departure_at: '',
                participant_count: '',
                price: '',
                proposal_from: '',
                proposal_to: '',
                status: 0
            }),
            cities: []
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'seminars',
            mode: 'detail',
            backLink: '/seminars',
            backTitle: 'К списку семинаров',
            title: this.title
        });
        this.loadCities();
        if (this.seminarId) {
            this.loadSeminar();
        }
    },
    computed: {
        /**
         * Модель города
         */
        city: {
            get: function() {
                let filtered = this.cities.filter(city => city.id == this.form.city_id);
                if (filtered.length > 0) {
                    return filtered[0];
                }
                return null;
            },
            set: function(value) {
                this.form.city_id = value.id;
            }
        },
        /**
         * Урл для отправки запроса
         * @returns {string}
         */
        sendUrl: function () {
            return this.seminarId
                ? '/seminars/' + this.seminarId
                : '/seminars';
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            Trusty.post(this.sendUrl, this.form)
                .then(response => {
                    location.href = '/seminars';
                })
                .catch(error => {
                    console.log('Ошибка при сохранении семинара', error);
                })
        },
        /**
         * Сохранение семинара
         */
        save: function () {
            this.form.draft = false;
            this.form.status = 1;
            this.send();
        },
        /**
         * Сохраняем как черновик
         */
        saveAsDraft: function() {
            this.form.draft = true;
            this.send();
        },
        /**
         * Загружаем данные о семинаре
         */
        loadSeminar: function() {
            axios.get('/seminars/fetch/' + this.seminarId)
                .then(response => {
                    $.extend(true, this.form, response.data);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке семинара', error);
                })
        },
        /**
         * Загружаем список городов
         */
        loadCities: function() {
            axios.get('/dictionaries/cities/all')
                .then(response => {
                    this.cities = response.data;
                })
                .catch(error => {});
        }
    }
});
