Vue.component('credential-commission-list', {
    data: function() {
        return {

        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'settings',
            childCode: 'credential-commission'
        });
    },
    methods: {
        /**
         * Подтверждение удаления пользователя мандатной комиссии
         * @param row
         */
        confirmDelete: function(row) {
            var self = this;
            this.$refs.userDeleteModal.init(row, function () {
                self.$refs.userGrid.reloadData();
                Bus.$emit('pushFlashAlert', {
                    type: 'success',
                    message: 'Представитель успешно удален'
                })
            });
        },
        userContacts: function(value, row) {
            var contacts = value;
            if (row.phone) {
                contacts += '<br>' + row.phone;
            }
            return contacts;
        }
    }
});
