import constants from './constants';

Vue.component('competition-proposal-athletes-selection', {
    mixins: [require('../mixins/athleteCheckers'), require('../../common/mixins/user')],
    props: [
        'competition',
        'athletes',
        'participantTypes',
        'quotas',
        'athleteRanks'
    ],
    data: function() {
        return {
            constants: require('../constants'),
            data: {
                athletes: []
            },
            team_id: null,
            keyword: '',
            selectedAthletes: [],
            selectedAthletesParams: {},
            participant_count: 0,
            free_participant_count: 0,
            paid_participant_count: 0,
            processing: false,
        }
    },
    computed: {
        /**
         * Доступные спортсмены с учетом поиска
         */
        availableAthletes: function() {
            if (this.isRegionalFederationUser) {
                return this.data.athletes;
            }
            if (!this.keyword) {
                return this.data.athletes;
            }
            let keyword = this.keyword.toLowerCase();
            return this.data.athletes.filter(item => {
                return item.full_name.toLowerCase().indexOf(keyword) >= 0;
            })
        },
        /**
         * Настроены квоты на данное соревнование
         * @returns boolean
         */
        isUseQuotas: function() {
            return this.competition && this.competition.is_use_quotas;
        },
        /**
         * Дефолтный ли алгоритм квот
         * @returns {null|boolean}
         */
        hasQuotasSeparatedLimits: function () {
            return this.competition && this.competition.quotas_alg_type == this.constants.COMPETITION_ALG_TYPE_DEFAULT;
        },
        /**
         * Количество бюджетных мест
         * @returns {*}
         */
        quotedFreeParticipantCount: function() {
            return this.quotas ? this.quotas.free_participant_count : 0;
        },
        /**
         * Количество платных мест
         * @returns {*}
         */
        quotedPaidParticipantCount: function() {
            return this.quotas ? this.quotas.paid_participant_count : 0;
        },
        /**
         * Общее количество мест
         * @returns {*}
         */
        quotedSumParticipantCount: function() {
            return this.quotas ? this.quotas.paid_participant_count + this.quotas.free_participant_count : 0;
        },
        /**
         * Количество общих мест
         * @returns {*}
         */
        quotedAllParticipantCount: function() {
            return this.quotedFreeParticipantCount + this.quotedPaidParticipantCount;
        },
        /**
         * Предупреждение о переполнении  платных мест
         */
        isPaidQuotaOverflow: function () {
            return this.isUseQuotas && this.quotedPaidParticipantCount < this.paid_participant_count;
        },
        /**
         * Предупреждение о переполнении  бюджетных мест
         */
        isFreeQuotaOverflow: function () {
            return this.isUseQuotas && this.quotedFreeParticipantCount < this.free_participant_count;
        },
        /**
         * Предупреждение о переполнении общего числа мест
         */
        isSumQuotaOverflow: function () {
            return this.isUseQuotas && this.quotedSumParticipantCount < this.free_participant_count + this.paid_participant_count;
        },
        /**
         * Могут ли клубы самостоятельно выбирать тип участия - договор или общие основания
         * @returns {boolean}
         */
        canEditParticipantType: function () {
            return this.competition.quotas_alg_type === this.constants.COMPETITION_ALG_TYPE_DEFAULT;
        },
    },
    methods: {
        /**
         * Ввод поиска спортсмена
         */
        keywordInput: function() {
            if (this.isRegionalFederationUser) {
                axios.get('/competitions/' + this.competition.id + '/proposals/athletes?keyword=' + this.keyword)
                    .then(response => {
                        this.data.athletes = response.data;
                    })
                    .catch(error => {
                        console.warn('Ошибка при загрузке атлетов', error);
                    });
            }
        },
        /**
         * Инициализация данных и открытие модалки
         */
        init: function (competitionProposal, teamId) {
            if (teamId != null){
                let self = this;
                this.team_id = teamId;
                Vue.set(self.data, 'athletes', []);
                axios.get('/competition/' + this.competition.id + '/not-exists-athletes-for-team/' + teamId)
                    .then(response => {
                        Vue.set(self.data, 'athletes', response.data);
                        self.processing = false;
                    })
                    .catch(error => {
                        console.warn('Ошибка при загрузке данных заявок спортсменов', error);
                        self.processing = false;
                    });
                this.initProperties(null);
            } else {
                Vue.set(this.data, 'athletes', this.athletes);
                if (competitionProposal && competitionProposal != null) {
                    this.initProperties(competitionProposal);
                }
            }
            $('.modal-competition-proposal-athletes-selection').modal('show');
            setTimeout(function () {
                $('.form-proposals-athletes__list').slimScroll({
                    height: '350px'
                });
            }, 200)
        },
        /**
         * Иниаилазция параметров
         * @param competitionProposal
         */
        initProperties: function(competitionProposal) {
            this.selectedAthletes = [];
            this.selectedAthletesParams = {};
            if (competitionProposal != null) {
                for (let index = 0; index < competitionProposal.athletes.length; index++) {
                    let proposalAthlete = competitionProposal.athletes[index];
                    this.selectedAthletes.push(proposalAthlete.athlete.id);
                    Vue.set(this.selectedAthletesParams, this.paramKey(proposalAthlete.athlete.id), {
                        participant_type: proposalAthlete.participant_type,
                        athlete_id: proposalAthlete.athlete.id
                    });
                }
            }
            this.recalculateParticipantCounts();
        },
        /**
         * Выбран ли атлет в списке
         * @param athleteId
         */
        isAthleteSelected: function (athleteId) {
            return this.selectedAthletes.filter(item => item == athleteId).length;
        },
        /**
         * Проверяем может ли подавать заявку по типу участника
         * @param athlete
         * @param participantType
         */
        canBeParticipant: function (athlete, participantType) {
            if (this.isRegionalFederationUser) {
                return true;
            }
            return this.availableAgeGroupIds(athlete, participantType).length > 0;
        },
        /**
         * Изменение выбранных спортсменов
         * @param event
         */
        changeSelectedAthletes: function (event) {
            if (event.target.checked) {
                let athlete = this.data.athletes.filter(item => item.id == event.target.value)[0];
                Vue.set(this.selectedAthletesParams, this.paramKey(event.target.value), {
                    participant_type: this.canBeParticipant(athlete, constants.PARTICIPANT_TYPE_FREE) ? constants.PARTICIPANT_TYPE_FREE : constants.PARTICIPANT_TYPE_PAID,
                    athlete_id: event.target.value
                });
            } else {
                delete this.selectedAthletesParams[this.paramKey(event.target.value)];
            }
            this.recalculateParticipantCounts();
        },
        /**
         * Проверяем тип участника для атлета
         * @param athleteId
         * @returns {*}
         */
        isParticipantTypeValueChecked: function(athleteId, value) {
            return this.selectedAthletesParams.hasOwnProperty(this.paramKey(athleteId))
                ? this.selectedAthletesParams[this.paramKey(athleteId)].participant_type == value
                : false;
        },
        /**
         * Ключ для параметров
         * @param athleteId
         * @returns {string}
         */
        paramKey: function(athleteId) {
            return 'athlete' + athleteId;
        },
        /**
         * Получаем тип участника для атлета
         * @param athleteId
         * @param value
         */
        setParticipantTypeValue: function(athleteId, value) {
            let athleteParam = this.selectedAthletesParams[this.paramKey(athleteId)];
            athleteParam.participant_type = value;
            Vue.set(this.selectedAthletesParams, this.paramKey(athleteId), athleteParam);
            this.recalculateParticipantCounts();
        },
        /**
         * Пересчитываем количество участников по типам
         */
        recalculateParticipantCounts: function() {
            this.participant_count = 0;
            this.free_participant_count = 0;
            this.paid_participant_count = 0;
            for (var key in this.selectedAthletesParams) {
                if (this.selectedAthletesParams.hasOwnProperty(key)) {
                    this.participant_count++;
                    if (this.selectedAthletesParams[key].participant_type == constants.PARTICIPANT_TYPE_FREE) {
                        this.free_participant_count++;
                    } else {
                        this.paid_participant_count++;
                    }
                }
            }
        },
        /**
         * Сохранение выделенных спортсменов в заявку
         */
        save: function() {
            let teamId = this.team_id ? this.team_id: null;
            this.processing = true;
            let self = this;
            axios.post('/competitions/' + this.competition.id + '/proposals/athletes-save', {
                    athletes: JSON.stringify(this.selectedAthletesParams)
                })
                .then(response => {
                    if (this.$parent.selectedAthletes){
                        Vue.set(this.$parent, 'selectedAthletes', this.selectedAthletes);
                    }
                    self.$emit('saved');
                    self.processing = false;
                    $('.modal-competition-proposal-athletes-selection').modal('hide');
                })
                .catch(error => {
                    console.warn('Ошибка при сохранении', error);
                    self.processing = false;
                })
        },
        /**
         * Заголовок для разряда
         * @param athlete
         * @returns {string|*}
         */
        getAthleteRankTitle: function (athlete) {
            if (athlete.athlete_rank_id) {
                let filtered = this.athleteRanks.filter(item => item.id == athlete.athlete_rank_id);
                if (filtered.length) {
                    return filtered[0].code;
                }
            }
            return 'б/р';
        }
    }
});
