import constants from "../constants";
Vue.component('result-wizard-import-file', {
    props: ['competition'],
    data: function() {
        return {
            processing: false,
            mode: 'file',
            importFile: null,
            startedReportCount: 0,
            processedReportCount: 0,
            competitionEntities: [],
            constants: constants,
            recentImport: null
        }
    },
    mounted: function() {
        this.mode = 'file';
        this.loadRecentImport();
    },
    methods: {
        /**
         * Получаем последнюю версию загруженного файла
         */
        loadRecentImport: function() {
            axios.get('/competitions/' + this.competition.id + '/results/recent-import')
                .then(response => {
                    this.recentImport = response.data;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных файла импорта', error);
                });
        },
        /**
         * Обработчик смены файла результатов
         * @param File importFile
         */
        onChangeResultFile: function(importFile) {
            this.importFile = importFile;
        },
        /**
         * Отмена и выход из визарда
         */
        cancel: function() {
            this.$parent.$emit('closed');
        },
        /**
         * Вернуться назад
         */
        prev: function () {
            this.$parent.setStep(2);
        },
        /**
         * Начать импорт данных
         */
        process: function() {
            this.processing = true;
            let formData = new FormData();
            formData.append('competition_entity_ids', this.$parent.selectedEntityIds);
            formData.append('result_file', this.importFile);
            axios.post('/competitions/' + this.competition.id + '/results/init-import',
                formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    this.processing = false;
                    this.mode = 'waiting';
                    this.startedReportCount = response.data.started_report_count;
                    setTimeout(this.checkImportStatus, 10000);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке результатов соревнования', error);
                    this.processing = false;
                });
        },
        checkImportStatus: function() {
            axios.post('/competitions/' + this.competition.id + '/results/check-status',{
                competition_entity_ids: this.$parent.selectedEntityIds
                })
                .then(response => {
                    this.competitionEntities = response.data;
                    this.processedReportCount = 0;
                    for (let entityIndex = 0; entityIndex < this.competitionEntities.length; entityIndex++) {
                        this.processedReportCount += this.competitionEntities[entityIndex].reports.filter(
                            item => item.status == this.constants.COMPETITION_ENTITY_REPORT_STATUS_PROCESSED
                        ).length;
                    }
                    if (this.startedReportCount > this.processedReportCount) {
                        setTimeout(this.checkImportStatus, 5000);
                    }
                })
                .catch(error => {
                    setTimeout(this.checkImportStatus, 5000);
                    console.warn('Ошибка при проверке статусов результатов соревнования', error);
                });
        }
    }
});
