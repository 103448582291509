Vue.component('system-request-list', {
	data:function () {
		return {
			currentRow: null,
		}
	},
	mounted: function() {
		Bus.$emit('changeMenuState', {
			itemCode: 'members',
			childCode: 'system-requests'
		});
	},
	methods: {
		/**
		 * Ожидает одобрения
		 * @param row
		 * @returns {boolean}
		 */
		isStatusCreated: function(row) {
			return row.request_status == 1;
		},
		/**
		 * Заявка уже обработана
		 * @param row
		 * @returns {boolean}
		 */
		isStatusProcessed: function(row) {
			return row.request_status != 1;
		},
		/**
		 * Заголовок статуса заявки
		 * @param row
		 * @returns {*}
		 */
		requestStatusTitle: function(row) {
			return row.request_status_title;
		},
		/**
		 * Подтверждение одобрения
		 * @param row
		 */
		confirmApprove: function(row) {
			this.currentRow = row;
			this.$refs.confirmModal.init({
				title: row.title,
				confirmText: 'Вы действительно хотите одобрить данную заявку?',
				confirmButtonTitle: 'Одобрить',
				confirmButtonClass: 'btn-primary',
				confirmCallback: this.approve
			})
		},
		/**
		 * Одобрение заявки
		 */
		approve: function() {
			this.$refs.systemRequestGrid.setLoading(true);
			var self = this;
			axios.post('/system-requests/approve', {id: this.currentRow.id})
				.then(response => {
					self.$refs.systemRequestGrid.reloadData();
					Bus.$emit('pushFlashAlert', {
						type: 'success',
						message: 'Заявка успешно обработана'
					});
					Bus.$emit('reloadMenu');
				})
				.catch(error => {
					self.$refs.systemRequestGrid.setLoading(false);
				})
		},
		/**
		 * Подтверждение удаления
		 * @param row
		 */
		confirmDecline: function(row) {
			var self = this;
			this.$refs.declineModal.init(row, function() {
				self.$refs.systemRequestGrid.reloadData();
				Bus.$emit('pushFlashAlert', {
					type: 'success',
					message: 'Заявка успешно отклонена'
				});
				Bus.$emit('reloadMenu');
			});
		}
	}
})

