import constants from "./constants";

Vue.component('competition-view', {
    mixins: [require('./mixins/dateFormatter'), require('../common/mixins/user')],
    props: ['id', 'section', 'emptyLabel', 'genderShortLabels', 'ageGroups', 'athleteRanks'],
    data: function() {
        return {
            processing: false,
            deleting: false,
            restoring: false,
            cancelling: false,
            data: {},
            tabSubMenuActiveItem: 'descriptionTab',
            constants: constants
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'competitions',
            mode: 'detail',
            backLink: '/competitions',
            backTitle: 'К календарю соревнований',
            title: 'Соревнование'
        });

        //Для мандатной комиссии сделать активной вкладку комиссии
        if (this.isCredentialCommissionUser) {
            this.tabSubMenuActiveItem = 'openCredentialCommission';
        }
        //сделать активной вкладку результатов
        if (this.section == 'results') {
            this.tabSubMenuActiveItem = 'openCompetitionResults';
        }
        this.loadData();
    },
    computed: {
        /**
         * Проверяем соответствие дат подачи заявки
         */
        checkProposalDate: function() {
            return moment.now() >= moment(this.data.proposal_from).startOf('day')
                &&  moment.now() <= moment(this.data.proposal_to).endOf('day')
        },
        /**
         * Проверяем начались ли соревнования
         * @returns {boolean}
         */
        checkDateFromHasCome: function() {
            return moment.now() >= moment(this.data.date_from).startOf('day');
        },
        /**
         * Проверяем, что уже прошло распределение квот (за день до начала заявок в 10.00)
         */
        checkQuotasFromDate: function() {
            let algType = this.data.quotas_alg_type;
            if (algType === this.constants.COMPETITION_ALG_TYPE_WITH_PRE_PROPOSALS) {
                return true;
            }
            let quotasDay = moment(this.data.proposal_from).subtract(1,'days').add(10, 'hours');;
            return moment.now() >= quotasDay;
        },
        /**
         * Алгоритм распределения квот с предварительными заявками
         * @returns {null|number|boolean}
         */
        isPreQuotasAlgType: function () {
            return this.data && this.data.is_use_quotas &&
                this.data.quotas_alg_type === this.constants.COMPETITION_ALG_TYPE_WITH_PRE_PROPOSALS;
        },
        /**
         * Проверяем дату начала подачи заявки
         */
        checkProposalFromDate: function() {
            return moment.now() >= moment(this.data.proposal_from).startOf('day');
        },
        /**
         * Проверяем, можно ли текущему пользователю стать представителем команды
         * @returns {boolean}
         */
        checkBecomeProxyUser: function () {
            return this.isClubUser && this.data && !this.data.is_high_level_rank && this.data.need_general_teams && this.data.general_team && !this.data.general_team.proxy;
        },
        /**
         * Проверяем, текущий пользователь является ли создателем соревнования или региональной федерацией
         * @returns {boolean}
         */
        isOwnUser: function()
        {
            if (this.isRegionalFederationUser) {
                return true;
            }

            return Trusty.user.club_id == this.data.club_id;
        },
        /**
         * Проверяем, является ли текущий пользователь представителем команды соревнования
         * @returns {boolean}
         */
        isProxyUser: function() {
            return this.data && this.data.general_team && this.data.general_team.proxy_id == Trusty.user.id;
        },
        /**
         * Проверяем, можеn ли клуб редактировать свое соревнование
         * @param row
         */
        canEditCompetition: function ()
        {
            return this.data && this.data.can_edit;
        },
        /**
         * Проверяем, может ли клуб/рег.федерация удалить свое соревнование в статусе черновик
         * @param row
         */
        canDeleteCompetition: function ()
        {
            return this.data && (this.data.status == this.constants.COMPETITION_STATUS_DRAFT) && this.isOwnUser;
        },
        /**
         * Подана ли уже заявка на соревнование
         * @returns {boolean}
         */
        isApplied: function () {
            return !!this.data.my_competition_proposal;
        },
        /**
         * Показывать ли вкладку квоты
         */
        hasQuotas: function () {
            return this.isOwnUser && (this.data.status >= 1) && this.data.is_use_quotas;
        },
        /**
         * Проверяем, данное соревнование регионального ли уровня
         * @returns {boolean}
         */
        isRegionalCompetition: function () {
            if (this.data && this.data.competition_rank){
                return this.data.competition_rank == this.constants.COMPETITION_RANK_REGION;
            }
            return false;
        },
        /**
         * Проверяем, данное соревнование муниципального ли уровня
         * @returns {boolean}
         */
        isMunicipalityCompetition: function () {
            if (this.data && this.data.competition_rank){
                return this.data.competition_rank == this.constants.COMPETITION_RANK_MUNICIPALITY;
            }
            return false;
        }
    },
    methods: {
        /**
         * Если таб в подменю соревнования активен
         * @param tabSubMenuItem
         * @returns {boolean}
         */
        isActiveTabSubMenu: function(tabSubMenuItem) {
            return this.tabSubMenuActiveItem === tabSubMenuItem;
        },
        /**
         * Делаем активным таб в подменю соревнования
         * @param tabSubMenuItem
         * @returns {boolean}
         */
        setActiveTabSubMenu: function(tabSubMenuItem) {
            this.tabSubMenuActiveItem = tabSubMenuItem;
        },
        /**
         * Загрузка данных соревнования - Описание
         */
        loadData: function() {
            this.processing = true;
            axios.get('/competitions/fetch/' + this.id)
                .then(response => {
                    this.data = response.data;
                    this.processing = false;
                    this.prepareSwimPrograms();
                    if (this.data.need_general_teams && this.isClubUser && moment(this.data.proposal_from) <= moment.now()) {
                        this.loadGeneralTeam(this.data.id);
                    }
                    if (!this.isCredentialCommissionUser) {
                        this.loadMyProposal();
                    }
                    if (this.isClubUser) {
                        this.loadMyPreProposal();
                    }
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных соревнования', error);
                    this.processing = false;
                });
        },
        /**
         * Заполняем виды программ из сессии
         */
        prepareSwimPrograms: function() {
            if (!this.data.hasOwnProperty('swim_programs')) {
                if (this.data.sessions && this.data.sessions.length > 0) {
                    let swimPrograms = [];
                    for (let sessionIndex = 0; sessionIndex < this.data.sessions.length; sessionIndex++) {
                        for (let swimProgramIndex = 0; swimProgramIndex < this.data.sessions[sessionIndex].swim_programs.length; swimProgramIndex++) {
                            swimPrograms.push(this.data.sessions[sessionIndex].swim_programs[swimProgramIndex]);
                        }
                    }
                    Vue.set(this.data, 'swim_programs', swimPrograms);
                }
            }
        },
        /**
         * Открываем вкладку квот
         */
        openAllQuotasTab: function () {
            this.setActiveTabSubMenu('allQuotasTab');
            this.loadAllProposalsData();
        },
        /**
         * Открываем вкладку предварительных заявок
         */
        openAllPreProposalsTab: function () {
            this.setActiveTabSubMenu('allPreProposalsTab');
            this.loadAllPreProposalsData();
        },
        /**
         * Открываем вкладку заявок
         */
        openAllProposalsTab: function () {
            this.setActiveTabSubMenu('allProposalsTab');
            this.loadAllProposalsData();
        },
        /**
         * Загружаем данные всех предварительных заявок
         */
        loadAllPreProposalsData: function () {
            if (this.data.hasOwnProperty('pre_proposals')) {
                return;
            }
            var self = this;
            axios.get('/competitions/pre-proposal-clubs-data/' + this.id)
                .then(response => {
                    Vue.set(self.data, 'pre_proposals', response.data.preProposals);
                    Vue.set(self.data, 'quotas', response.data.quotas);
                    Vue.set(self.data, 'clubs', response.data.clubs);
                    self.$refs.competitionTabAllPreProposals.init(self.data);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке всех предварительных заявок соревнования', error);
                })
        },
        /**
         * Загружаем данные всех заявок
         */
        loadAllProposalsData: function() {
            if (this.data.hasOwnProperty('all_proposals')) {
                return;
            }
            var self = this;
            axios.get('/competitions/' + this.id + '/proposals/all-proposals')
                .then(response => {
                    Vue.set(self.data, 'all_proposals', response.data);
                    self.$refs.competitionTabAllProposals.init(self.data);
                    self.$refs.competitionTabAllQuotas.init(self.data);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке всех заявок соревнования', error);
                })
        },
        /**
         * Загружаем данные моей заявки
         */
        loadMyProposal: function () {
            let self = this;
            this.processing = true;
            axios.get('/competitions/my-proposal/' + this.id)
                .then(response => {
                    Vue.set(self.data, 'my_competition_proposal', response.data);
                    self.processing = false;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных соревнования', error);
                    self.processing = false;
                });
        },
        /**
         * Загружаем данные предварительной заявки клуба
         */
        loadMyPreProposal: function() {
            let self = this;
            this.processing = true;
            axios.get('/competitions/pre-proposal-club-data/' + this.id)
                .then(response => {
                    Vue.set(self.data, 'my_pre_proposal', response.data.preProposal);
                    Vue.set(self.data, 'quotas', response.data.quotas);
                    self.processing = false;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных предварительной заявки', error);
                    self.processing = false;
                });
        },
        /**
         * Загрузка данных соревнования - Моя заявка
         */
        openMyProposalTab: function() {
            this.$refs.competitionTabMyProposal.init(this.data);
            this.setActiveTabSubMenu('myRequestTab');
        },
        /**
         * Загрузка данных для вкладки лога
         */
        openLog: function() {
            this.$refs.competitionTabLog.init();
            this.setActiveTabSubMenu('openLog');
        },
        /**
         * Открытие вкладки судей соревнования
         */
        openJudges: function() {
            this.$refs.competitionTabJudges.init();
            this.setActiveTabSubMenu('judges');
        },
        /**
         * Загружаем данные для вкладки Квоты у соревнования
         */
        loadQuotasData:function () {
            this.$refs.competitionTabCompetitionQuota.init(this.data);
            this.setActiveTabSubMenu('quotasTab');
        },
        /**
         * Загрузка данных сборной заявки
         */
        loadGeneralTeamProposal: function() {
            this.$refs.competitionTabGeneralTeamProposal.init(this.data);
            this.setActiveTabSubMenu('generalTeamTab');
        },
        /**
         * Обработчик нажатия кнопки удаления соревнования
         */
        onClickDelete: function() {
            if (this.canDeleteCompetition) {
                this.$refs.confirmModal.init({
                    title: 'Удаление соревнования',
                    confirmText: 'Вы действительно хотите удалить данное соревнование?',
                    confirmButtonTitle: 'Удалить',
                    confirmButtonClass: 'btn-danger',
                    confirmCallback: this.deleteCompetition
                })
            }
        },
        /**
         * Удаление соревнования
         */
        deleteCompetition: function() {
            if (this.canDeleteCompetition) {
                this.deleting = true;
                var self = this;
                axios.delete('/competitions/delete/' + this.data.id)
                    .then(
                        response => {
                            this.data = response.data;
                            self.deleting = false;
                            location.href = '/my-competitions';
                        }
                    )
                    .catch(error => {
                        console.warn('Ошибка при удалении соревнования', error);
                        self.deleting = false;
                    })
            } else {
                console.warn('Ошибка при удалении соревнования');
            }
        },
        /**
         * Подтверждение отмены соревнования
         */
        confirmCancellation: function() {
            this.$refs.confirmModal.init({
                title: 'Отмена соревнования',
                confirmText: 'Вы действительно хотите отменить данное соревнование?',
                confirmButtonTitle: 'Подтверждаю',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.cancelCompetition
            })
        },
        /**
         * Открываем модалку клонирования соревнования
         */
        cloneCompetition: function () {
            this.$refs.competitionCloneModal.init({
                competition: this.data
            });
        },
        /**
         * Подтверждение, что клуб является представителем команды
         */
        comfirmBecomeProxyUser: function() {
            this.$refs.confirmModal.init({
                title: 'Вы - представитель команды?',
                confirmText: 'После подтверждения вам станет доступна сводная заявка от всех клубов Вашего муниципального образования. Вам необходимо отправить заявку на соревнование или согласовать с клубами поданные ими составы, если есть отклонения от утвержденного списка сборной.',
                confirmButtonTitle: 'Подтверждаю',
                confirmButtonClass: 'btn-primary',
                confirmCallback: this.becomeProxyUser
            })
        },
        /**
         * Отмена соревнования
         */
        cancelCompetition: function() {
            if (this.isOwnUser) {
                this.cancelling = true;
                var self = this;
                axios.post('/competitions/cancel/' + this.data.id)
                    .then(
                        response => {
                            self.cancelling = false;
                            this.data.status = response.data.competition.status;
                            this.data.status_title = response.data.competition.status_title;
                            if (response.data.system_request && response.data.system_request.id) {
                                location.href = '/competitions/requests/' + response.data.system_request.id;
                                return;
                            } else {
                                //Скрываем кнопку 'Отменить'
                                Vue.set(this.data, 'can_cancel', response.data.can_cancel);

                                Bus.$emit('pushFlashAlert', {
                                    type: 'success',
                                    message: 'Соревнование успешно отменено'
                                });
                            }
                        }
                    )
                    .catch(error => {
                        console.warn('Ошибка при отмене соревнования', error);
                        self.cancelling = false;
                    })
            }
        },
        /**
         * Назначаем представителя команды соревнования
         */
        becomeProxyUser: function() {
            if (this.checkBecomeProxyUser) {
                this.processing = true;
                var self = this;
                axios.post('/competitions/become-proxy-user/' + this.data.id)
                    .then(
                        response => {
                            self.processing = false;
                            //Если стали представиетелем, то обновляем данные статуса
                            if (response.data.proxy && response.data.proxy.id) {
                                Vue.set(self.data, 'general_team', response.data);
                                Bus.$emit('pushFlashAlert', {
                                    type: 'success',
                                    message: 'Вы назначены представителем комнады в этом соревновании'
                                });
                            } else {
                                Bus.$emit('pushFlashAlert', {
                                    type: 'error',
                                    message: 'Ошибка при назначении представителя команды для соревнования'
                                });
                            }
                        }
                    )
                    .catch(error => {
                        console.warn('Ошибка при назначении представителя команды для соревнования', error);
                        self.processing = false;
                    })
            }
        },
        /**
         * Загружаем данные сборной команды
         */
        loadGeneralTeam: function(competitionId) {
            this.processing = true;
            var self = this;
            axios.get('/competitions/general-team/' + competitionId)
                .then(
                    response => {
                        self.processing = false;
                        Vue.set(self.data, 'general_team', response.data);
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при загрузке представителя команды для соревнования', error);
                    self.processing = false;
                });
        },
        /**
         * Подтверждение удаления заявки на участие
         */
        confirmDeleteProposal: function() {
            this.$refs.confirmModal.init({
                title: 'Удаление заявки на участие в соревновании',
                confirmText: 'Вы действительно хотите удалить заявку на участие в данном соревновании?',
                confirmButtonTitle: 'Подтверждаю',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteProposal
            })
        },
        /**
         * Удаление заявки на соревнование
         */
        deleteProposal: function() {
            var self = this;
            axios.delete('/competitions/' + this.data.id + '/proposal')
                .then(
                    response => {
                        Bus.$emit('pushFlashAlert', {
                            type: 'success',
                            message: 'Заявка успешно удалена'
                        });
                        Vue.set(this.data, 'my_competition_proposal', null);
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при удалении заявки', error);
                })
        },
        /**
         * Загрузка данных для вкладки результаты соревнований
         */
        openCompetitionResults: function() {
            this.$refs.competitionTabResults.init();
            this.setActiveTabSubMenu('openCompetitionResults');
        },
    }
});
