Vue.component('judge-view', {
	data: function() {
		return {
			processing: false,
			data: {},
		}
	},
	props: ['id'],
	mounted: function() {
		Bus.$emit('changeMenuState', {
			itemCode: 'seminars',
			mode: 'detail',
			backLink: '/judges',
			backTitle: 'К списку судей',
			title: 'Просмотр судьи',
		});
		this.loadData();
	},
	methods: {
		/**
		 * Загрузка данных судьи
		 */
		loadData: function() {
			axios.get('/judges/fetch/' + this.id)
				.then(response => {
					this.data = response.data;
				})
				.catch(error => {
					console.warn('Ошибка при загрузке данных судьи', error);
				})
		},

		/**
		 * @brief Форматированный вывод текущей категории
		 * @param judgeJudgeCategory
		 * @returns {string}
		 */
		activeCategoryFormat: function (judgeJudgeCategory) {

			var categoryName =  judgeJudgeCategory.judgeCategory.name;
			var postfix = '';

			var now = moment();
			var to = moment(judgeJudgeCategory.expires_at);

			if (to.diff(now,'days') < 0) {
				postfix = '<br /><span class="text-danger"> Истёкла ' + Vue.filter('date')(judgeJudgeCategory.expires_at) + '</span>';
			}
			else if (to.diff(now,'days') < 30) {
				postfix = '<br /><span class="text-danger"> Истекает ' + Vue.filter('date')(judgeJudgeCategory.expires_at) + '</span>';
			}

			return categoryName + postfix;
		},
	}
});
