Vue.component('athlete-admission-list', {
    props: ['admissionTypes'],
    data: function() {
        return {
            currentRow: null
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'dictionaries',
            childCode: 'athlete-admissions'
        });
    },
    methods: {
        /**
         * @brief Форматированный вывод типа допуска
         * @param value
         * @param row
         * @returns {*}
         */
        admissionTypeFormat: function (value, row) {
            return this.admissionTypes[value];
        },
        /**
         * @brief Форматированный вывод периода действия
         * @param value
         * @param row
         * @returns {*}
         */
        actionPeriodFormat: function (value, row) {
            return value + ' мес';
        },
        /**
         * Подтверждение удаления разряда
         */
        confirmDelete: function(row) {
            this.currentRow = row;
            this.$refs.confirmModal.init({
                title: 'Перенос разряда в архив',
                confirmText: 'Вы действительно хотите перенести в архив данный тип допуска?',
                confirmButtonTitle: 'Перенeсти',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteAthleteAdmission
            })
        },
        /**
         * Удаление разряда
         */
        deleteAthleteAdmission: function() {
            this.$refs.athleteAdmissionGrid.setLoading(true);
            var self = this;
            axios.delete('/dictionaries/athlete-admissions/' + this.currentRow.id)
                .then(
                    response => {
                        self.$refs.athleteAdmissionGrid.reloadData();
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при удалении типа допуска', error);
                    self.$refs.athleteAdmissionGrid.setLoading(false);
                })
        },
    }
});
