Vue.component('rating-list', {
    props: ['genderShortLabels'],
    mixins: [require('../../competitions/mixins/dateFormatter')],
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'members',
            childCode: 'ratings'
        });
    },
    methods: {
        /**
         * @brief Форматированный вывод пола
         * @param value
         * @param row
         * @returns {*}
         */
        genderTypeFormat: function (value, row) {
            return this.genderShortLabels[value];
        },
        /**
         * @brief Форматированный вывод клуба
         * @param value
         * @param row
         * @returns {*}
         */
        clubFormat: function (value, row) {
            return row.club ? row.club.name : 'Независимый спортсмен';
        },
        /**
         * @brief Форматированный вывод ФИО
         * @param value
         * @param row
         * @returns {*}
         */
        nameFormat: function (value, row) {
            return row.athlete.last_name + ' ' + row.athlete.first_name + ' ' + row.athlete.patronymic;
        },
        /**
         * @brief Форматированный вывод пола
         * @param value
         * @param row
         * @returns {*}
         */
        genderFormat: function (value, row) {
            return this.genderShortLabels[row.athlete.gender];
        },
        /**
         * @brief Форматированный вывод года рождения
         * @param value
         * @param row
         * @returns {*}
         */
        birthYearFormat: function (value, row) {
            let birthDate = new Date(row.athlete.birth_date);
            return birthDate.getFullYear();
        },
        /**
         * @brief Форматированный вывод стиля плавания
         * @param value
         * @param row
         * @returns {*}
         */
        swimProgramFormat: function (value, row) {
            if (!row.swim_program) {
                return '';
            }
            return row.swim_program.name;
        },
        /**
         * Форматированный вывод информации о соревновании
         * @param value
         * @param row
         */
        competitionFormat: function (value, row) {
            if (!row.competition) {
                return '';
            }
            return row.competition.name + '<br /> <b>'
                +  this.getFormattedCompetitionDates(row.competition.date_from, row.competition.date_to, true) + '</b>';
        },
        /**
         * Экспортировать текущий список в excel
         */
        getExportRatingLink: function () {
            let link = '/ratings/export';

            let params = {
                filters: JSON.stringify(this.$parent.$refs.ratingFilters.getFilters()),
                keyword: this.$parent.$refs.ratingFilters.getKeyword()
            };
            let searchParams = new URLSearchParams(params);

            link = link + '?' + searchParams.toString();
            return link;
        },
        /**
         * Формат времени спорстмена
         * @param value
         * @returns {*}
         */
        swimTimeFormat: function (value) {
            return Vue.filter('swimTime')(value);
        }
    }
});

