<template>
    <div class="trusty-grid mt-3 position-relative">
        <div class="trusty-grid-loader" v-show="loading">
            <span class="fa fa-spin fa-spinner"></span>
        </div>
        <trusty-grid-header
            :onFilter="onFilter"
            :total="response.total"
            :showTotal="showTotal"
            :modes="modes"
            :currentMode="mode"
            v-on:changeMode="onChangeMode"
            :actions="actions"
            :useFilter="useFilter"
            :pluralLabels="pluralLabels"
        >
        </trusty-grid-header>

        <slot name="filters"></slot>

        <table class="table">
            <trusty-grid-table-header
                :columns="columns"
                :useActions="useActions"
                :useGroupActions="useGroupActions"
                :orderColumn="orderColumn"
                :orderDirection="orderDirection"
                v-on:changeOrder="changeOrder"
            >
            </trusty-grid-table-header>
            <tbody>
                <trusty-grid-table-row  v-for="(row, index) in response.data"
                        :row="row"
                        :key="row.id"
                        :columns="columns"
                        :rowActions="rowActions"
                        :rowLink="rowLink"
                        :useActions="useActions"
                        :useGroupActions="useGroupActions"
                        :currentMode="mode"
                        :index="calculateIndex(index)"
                >
                </trusty-grid-table-row>
                <tr v-if="response.data && response.data.length == 0">
                    <td :colspan="columnsCount">
                        <div class="no-records-block mb-4">
                            <div class="title">{{emptyTitle}}</div>
                            <img src="/images/icons/no-records.svg">
                            <div class="suggestion" v-if="emptySuggestion">
                                {{emptySuggestion}}
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <trusty-grid-table-footer
                v-if="response.last_page > 1"
                :count="response.last_page ? response.last_page: 0"
                :currentPage="response.current_page"
                :onPaginate='onPaginate'
        >
        </trusty-grid-table-footer>
    </div>
</template>

<script>
    import TrustyGridTableHeader from './table/TrustyGridTableHeader.vue';
    import TrustyGridHeader from './table/TrustyGridHeader.vue';
    import TrustyGridTableFooter from './table/TrustyGridTableFooter.vue';
    import {index} from './mixins/index';
    import TrustyGridTableRow from "./table/TrustyGridTableRow";

    export default {
        components: {
            TrustyGridTableRow,
            TrustyGridTableHeader,
            TrustyGridHeader,
            TrustyGridTableFooter
        },
        props: ['options'],
        mixins: [index]
    }
</script>

<style>
    .pagination {
        display: inline-block;
        padding-left: 0;
        margin: 20px 0;
        border-radius: 4px;
    }
    .pagination > li {
        display: inline;
        margin-left: 4px;
        margin-right: 4px;
        font-size: 16px;
    }
    .pagination > li > a,
    .pagination > li > span {
        outline: none;
        position: relative;
        float: left;
        padding: 6px 12px;
        margin-left: -1px;
        line-height: 1.42857143;
        color: #000;
        text-decoration: none;
        background-color: #fff;
    }

    .pagination > li.active > a {
        font-weight: bold;
        background-color: #eaeaea;
        border-radius: 4px;
        overflow: hidden;
    }
</style>
