<template>
    <div class="modal modal-confirm" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{title}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="form">
                    <form role="form">
                        <div class="modal-body">
                            <p>{{confirmText}}</p>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-sm" :class="confirmButtonClass" @click.prevent="onConfirm">
                                {{confirmButtonTitle}}
                            </button>
                            <button type="button" class="btn btn-light btn-sm mr-auto" data-dismiss="modal">Отменить</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import {ru} from 'vuejs-datepicker/dist/locale'

	export default {
		data: function() {
			return {
				title: '',
                confirmText: '',
                confirmButtonClass: '',
                confirmButtonTitle: '',
                confirmCallback: null,
            }
		},
        methods: {
			/**
             * Инициализация
			 * @param options
			 */
			init: function(options) {
				this.title = options.title || '';
				this.confirmText = options.confirmText || '';
				this.confirmButtonClass = options.confirmButtonClass || 'btn-primary';
				this.confirmButtonTitle = options.confirmButtonTitle || 'Продолжить';
				this.confirmCallback = options.confirmCallback || null;
                $(this.$el).modal('show');
            },
			/**
             * Обработка подтверждения
			 */
			onConfirm: function() {
				if (this.confirmCallback) {
					this.confirmCallback();
                }
				$(this.$el).modal('hide');
            }
        }
	}
</script>
