Vue.component('general-athlete-view', {
    props: ['id', 'emptyLabel'],
    data: function() {
        return {
            processing: false,
            data: {},
            activeSection: 'profile',
        }
    },
    mounted: function() {
        this.loadData();
    },
    methods: {
        /**
         * Проверяем активность секции
         * @param sectionCode
         * @returns {boolean}
         */
        isActiveSection: function(sectionCode) {
            return this.activeSection === sectionCode;
        },
        /**
         * Проставляем активную секцию
         * @param sectionCode
         */
        setActiveSection: function(sectionCode) {
            this.activeSection = sectionCode;
        },
        /**
         * Загрузка данных спортсмена
         */
        loadData: function() {
            this.processing = true;
            axios.get('/general/athletes/fetch/' + this.id)
                .then(response => {
                    this.data = response.data;
                    this.processing = false;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных спортсмена', error);
                    this.processing = false;
                })
        },
        /**
         * загружаем данные результатов соревнований
         */
        openResults: function () {
            this.setActiveSection('results');
            this.$refs.generalAthleteResults.init(this.data.id);
        }
    }
});
