<template>
    <div class="competition-month-block" v-if="competitions.length > 0">
        <div class="competition-month-header">
            <span class="semi-bold">{{ monthName(monthNumber) }}</span>
        </div>

        <div class="competition-list-items">
            <competition-item
                :proposals="proposals"
                v-for="(competition, index) in competitions"
                v-bind:competition="competition"
                v-bind:month-number="index"
                v-bind:key="index"
                v-bind:isLast="index == Object.keys(competitions).length - 1"
            ></competition-item>
        </div>
    </div>
</template>

<script>
    import CompetitionItem from "./CompetitionItem";
    export default {
        name: "CompetitionCalendarMonth",
        components: {CompetitionItem},
        props: ['competitions', 'monthNumber', 'proposals'],
        methods: {
            monthName: function (index) {
                let months = [
                    'Январь',
                    'Февраль',
                    'Март',
                    'Апрель',
                    'Май',
                    'Июнь',
                    'Июль',
                    'Август',
                    'Сентябрь',
                    'Октябрь',
                    'Ноябрь',
                    'Декабрь',
                ];

                return months[index];
            }
        }
    }
</script>

<style scoped>

</style>
