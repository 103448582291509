Vue.component('seminar-view', {
    props: ['id', 'emptyLabel', 'link'],
    mixins: [require('../../competitions/mixins/dateFormatter')],
    data: function () {
        return {
            processing: false,
            deleting: false,
            restoring: false,
            data: {},
            activeSection: 'info',
        }
    },
    mounted: function () {
        Bus.$emit('changeMenuState', {
            itemCode: 'seminars',
            mode: 'detail',
            backLink: '/seminars',
            backTitle: 'К списку семинаров',
            title: 'Карта семинара'
        });
        this.loadData();
    },
    methods: {
        /**
         * Скопировать в буфер обмена ссылку на основной сайт
         */
        copyMainSiteLink: function () {
            let link = this.link + 'seminars/' + this.id;
            var copyTextarea = document.createElement("textarea");
            copyTextarea.style.position = "fixed";
            copyTextarea.style.opacity = "0";
            copyTextarea.textContent = link;

            document.body.appendChild(copyTextarea);
            copyTextarea.select();
            document.execCommand("copy");
            document.body.removeChild(copyTextarea);
        },
        /**
         * Проверяем активность секции
         * @param sectionCode
         * @returns {boolean}
         */
        isActiveSection: function(sectionCode) {
            return this.activeSection === sectionCode;
        },
        /**
         * Проставляем активную секцию
         * @param sectionCode
         */
        setActiveSection: function(sectionCode) {
            this.activeSection = sectionCode;
        },
        /**
         * Загрузка данных семинара
         */
        loadData: function() {
            this.processing = true;
            let url = '/seminars/fetch/' + this.id;
            axios.get(url)
                .then(response => {
                    this.data = response.data;
                    this.processing = false;

                    this.data.judges.sort(function (a, b) {
                        return  a.judge.full_name - b.judge.full_name;
                    });
                    console.log(this.data.judges);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных семинара', error);
                    this.processing = false;
                })
        },

        /**
         * Обработчик нажатия кнопки удаления семинара
         */
        onClickDelete: function() {
            this.$refs.confirmModal.init({
                title: 'Удаление семинара',
                confirmText: 'Вы действительно хотите перенести в архив данный семинар?',
                confirmButtonTitle: 'Удалить',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteSeminar
            })
        },
        /**
         * Удаление семинара
         */
        deleteSeminar: function() {
            this.deleting = true;
            var self = this;
            axios.delete('/seminars/delete/' + this.data.id)
                .then(
                    response => {
                        this.data = response.data;
                        Bus.$emit('pushFlashAlert', {
                            type: 'success',
                            message: 'Семинар перенесен в архив'
                        });
                        self.deleting = false;
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при удалении семинара', error);
                    self.deleting = false;
                })
        },
        /**
         * Восстановление спортсмена
         */
        onClickRestore: function() {
            this.restoring = true;
            var self = this;
            axios.post('/seminars/restore', {id: this.data.id})
                .then(
                    response => {
                        this.data = response.data;
                        Bus.$emit('pushFlashAlert', {
                            type: 'success',
                            message: 'Семинар успешно восстановлен из архива'
                        });
                        self.restoring = false;
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при восстановлении семинара', error);
                    self.restoring = false;
                })
        },
    }
});
