Vue.component('credential-commission-form', {
    props: ['title', 'userId'],
    data: function() {
        return {
            form: new TrustyForm({
                id: '',
                last_name: '',
                first_name: '',
                patronymic: '',
                email: '',
                phone: ''
            }),
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'settings',
            mode: 'detail',
            backLink: '/settings/credential-commission',
            backTitle: 'К списку представителей комиссии по допуску',
            title: this.title
        });
        if (this.userId) {
            this.loadUser();
        }
    },
    computed: {
        /**
         * Урл для отправки запроса
         * @returns {string}
         */
        sendUrl: function () {
            return this.userId
                ? '/settings/users/' + this.userId
                : '/settings/credential-commission';
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            Trusty.post(this.sendUrl, this.form)
                .then(response => {
                    location.href = '/settings/credential-commission';
                })
                .catch(error => {

                })
        },
        /**
         * Загружаем данные о пользователе мандатной комиссии
         */
        loadUser: function() {
            axios.get('/settings/users/fetch/' + this.userId)
                .then(response => {
                    $.extend(true, this.form, response.data);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке представителя', error);
                })
        }
    }
});
