<template>
    <div>
        <div class="alert" role="alert" :class="alertTypes[alertType].class">
            <img :src="alertTypes[alertType].icon"><strong>{{alertTypes[alertType].prefix}}:</strong> {{message}}
            <button type="button" class="close" @click="onClickClose()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</template>

<script>
	export default {
		props: ['alertType', 'message'],
		data: function() {
			return {
				alerts: [],
				alertTypes: {
					success: {
						class: 'alert-success',
						icon: '/images/icons/check-circle-full.svg',
						prefix: 'Успех'
					},
					warning: {
						class: 'alert-warning',
						icon: '/images/icons/warning-open.svg',
						prefix: 'Предупреждение'
					},
					danger: {
						class: 'alert-danger',
						icon: '/images/icons/exclamation-full.svg',
						prefix: 'Предупреждение'
					}
				}
			}
		},
		methods: {
			/**
			 * Удаляем алерт
			 * @param index
			 */
			onClickClose: function () {
				this.$destroy();
				this.$el.parentNode.removeChild(this.$el);
			}
		}
	}
</script>
