Vue.component('exists-clubs-modal', {
    data: function() {
        return {
            application: {},
            clubs: {},
            loading: false,
            constants: require('../club/constants')
        }
    },
    methods: {
        /**
         * Иницилизируем модальный компонент
         * @param application
         */
        init: function (application) {
            this.application = application;
            $('.modal-exists-clubs').modal('show');
            $('.modal-exists-clubs .exists-club-list').slimScroll({height: '350px'});
            this.loadClubs();
        },
        /**
         * Загружаем клубы
         */
        loadClubs: function() {
            this.loading = true;
            axios.get('/clubs/list?mode=all&municipality_id=' + this.application.municipality_id)
                .then(response => {
                    this.clubs = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                })
        }
    }
});
