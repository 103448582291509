Vue.component('seminar-results', {
    props: ['id', 'proposals', 'scoreTypes'],
    data: function () {
        return {
            judgeToAdd: null,
            loaded: false,
            judges: [],
            isDisabled: false,
        }
    },
    mounted: function() {
        this.loadJudges();
    },
    methods: {
        /**
         * Загрузить список судей системы
         */
        loadJudges: function() {
            this.processing = true;
            let url = '/judges/all';
            axios.get(url)
                .then(response => {
                    this.judges = response.data;
                    this.loaded = true;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных судей', error);
                    this.loaded = true;
                })
        },

        /**
         * Добавить новую заявку участия в семинаре
         */
        addProposal: function () {
            if (!this.judgeToAdd) {
                return;
            }

            axios.post('/seminars/proposal-judges/create/' + this.id, {
                judge_id: this.judgeToAdd.id,
            }).then(response => {
                this.proposals.push(response.data);
            }).catch(error => {
                console.warn('Ошибка при добавлении судьи в семинар', error);
            })
        },

        /**
         * Поиск судей
         * @param search
         * @param loading
         */
        searchJudges: function(search, loading) {
            if (!search) {
                return;
            }
            loading && loading(true);
            this.judges = [];
            axios.get('/judges/search-judge-filter?keyword=' + search + '&limit=50')
                .then(response => {
                    this.judges = response.data;
                    loading && loading(false);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке списка судей', error);
                    loading && loading(false);
                })
        },

        /**
         * Сохранить оценки судей
         */
        save: function() {
            const data = this.getData();

            axios.post('/seminars/proposal-judges/save-scores/' + this.id, {proposals: data})
                .then(response => {
                }).catch(error => {
                    console.warn('Ошибка при добавлении судьи в семинар', error);
                })
        },
        /**
         * Получение данных формы
         */
        getData: function() {
            const data = [];
            for (let i = 0; i < this.proposals.length; i++) {
                data.push({
                    'judge_id': this.proposals[i].judge_id,
                    'not_come': this.proposals[i].not_come,
                    'score': this.proposals[i].score,
                });
            }

            return data;
        },
    },
    computed: {
        /**
         * Список судей, доступных для добавления новой записи результатов семинара
         * @return {T[]}
         */
        availableJudges: function() {
            let self = this;
            return this.judges.filter(function(item) {
                let result = self.proposals.find(proposal => proposal.judge_id === item.id);
                return typeof(result) === 'undefined';
            })
        },
    },
});