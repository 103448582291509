import constants from "./results/constants";

Vue.component('competition-tab-results', {
    props: ['competition', 'athleteRanks'],
    mixins: [require('../../common/mixins/user')],
    data: function() {
        return {
            loading: false,
            competitionEntities: [],
            constants: constants,
            mode: 'protocols',
            wizardMode: false
        }
    },
    mounted: function() {
        if (this.$parent.section == 'results') {
            this.init();
        }
    },
    computed: {
        /**
         * Есть ли уже результаты для соревнования
         * @returns {boolean}
         */
        hasResults: function() {
            return this.competitionEntities
                .filter(item => item.status != this.constants.COMPETITION_ENTITY_STATUS_CREATED).length > 0;
        },
        /**
         * Является ли текущий пользователь организатором
         * @returns {any}
         */
        isOwnUser: function() {
            return this.$parent.isOwnUser;
        }
    },
    methods: {
        /**
         * Инициализируем компонент таба результаты соревнования
         */
        init: function () {
            this.loadCompetitionEntities();
        },
        /**
         * Загружаем объекты соревнований
         */
        loadCompetitionEntities: function() {
            this.loading = true;
            axios.get('/competitions/' + this.competition.id + '/entities/list')
                .then(response => {
                    this.competitionEntities = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке объектов соревнования', error);
                    this.loading = false;
                })
        },
        /**
         * Если таб в подменю результатов соревнования активен
         * @param {string} mode
         * @returns {boolean}
         */
        isActiveMode: function(mode) {
            return this.mode === mode;
        },
        /**
         * Смена режима таба
         * @param mode
         */
        setMode: function (mode) {
            this.mode = mode;
        }
    }
});
