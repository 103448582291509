<template>
    <div class="filters competition-filters">
        <age-gender-filter
            ref="ageGenderFilter"
            @applyFilter="applyFilter"
            :gender-short-labels="genderShortLabels"
            dusk="ageGenderFilter"
        ></age-gender-filter>

        <year-filter
            ref="competitionYearFilter"
            @applyFilter="applyFilter"
            :years="years"
            dusk="yearFilter"
        ></year-filter>
    </div>
</template>

<script>
    import AgeGenderFilter from "../../util/filters/AgeGenderFilter";
    import YearFilter from "../../util/filters/YearFilter";
    import MonthFilter from "../../util/filters/MonthFilter";

    export default {
        name: "GeneralCompetitionFilters",
        components: {MonthFilter, YearFilter, AgeGenderFilter},
        props: ['genderShortLabels', 'years'],
        data: function() {
            return {
                filters: {
                    gender: '',
                    dateFrom: '',
                    dateTo: '',
                    year: '',
                    month: ''
                },
            }
        },
        methods: {
            applyFilter: function (filters) {
                let mergedFilters = Object.assign(this.filters, filters);
                this.filters = mergedFilters;
                this.$emit('applyfilters', mergedFilters);
            },
        }
    }
</script>

<style scoped>

</style>
