Vue.component('competition-tab-log', {
    props: ['competitionId'],
    data: function() {
        return {
            enabled: false
        }
    },
    computed: {
        /**
         * Базовый путь для грида
         * @returns {string}
         */
        baseUrl: function() {
          return '/competitions/' + this.competitionId + '/proposals/logs';
        },
    },
    methods: {
        /**
         * Инициализируем компонент таба лог в соревновании
         */
        init: function () {
            if (!this.enabled) {
                this.enabled = true;
            } else {
                this.$refs.logCompetitionProposalGrid.reloadData();
            }
        },
        /**
         * ФИО атлета
         * @param value
         * @param row
         * @returns {*}
         */
        athleteName: function (value, row) {
            if (row.competitionProposalAthlete && row.competitionProposalAthlete.athlete) {
                return row.competitionProposalAthlete.athlete.full_name;
            }
        },
        /**
         * ФИО пользователя
         * @param value
         * @param row
         * @returns {*}
         */
        userName: function (value, row) {
            if (row.user) {
                return row.user.full_name;
            }
        }
    }
});
