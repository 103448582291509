Vue.component('athlete-view', {
    props: ['id', 'emptyLabel', 'expireThreshold'],
    mixins: [require('../../common/mixins/user')],
	data: function() {
		return {
			processing: false,
			deleting: false,
			restoring: false,
			data: {},
            activeSection: 'profile',
		}
	},
	mounted: function() {
        if(!this.isAthleteUser) {
            Bus.$emit('changeMenuState', {
                itemCode: 'members',
                mode: 'detail',
                backLink: '/athletes',
                backTitle: 'К списку спортсменов',
                title: 'Карта спортсмена'
            });
        }
        else {
            Bus.$emit('changeMenuState', {
                itemCode: 'settings',
                childCode: 'settings-athlete-info'
            });
        }
		this.loadData();
	},
	computed: {
		/**
		 * Истекает ли период допуска
		 */
		isAdmissionPeriodExpiring: function() {
			if (!this.isAdmissionPeriodFilled) {
				return false;
			}
			var now = moment();
			var admissionTo = moment(this.data.admission_to);
			return (admissionTo > now && admissionTo.diff(now, 'days') < this.expireThreshold);
		},
		/**
		 * Истек ли период допуска
		 */
		isAdmissionPeriodExpired: function() {
			if (!this.isAdmissionPeriodFilled) {
				return false;
			}
			var now = moment();
			var admissionTo = moment(this.data.admission_to);
			return admissionTo < now;
		},
        /**
         * Заполнен ли допуск
         */
        isAdmissionPeriodFilled: function() {
            if (!this.data.id) {
                return false;
            }
            if (this.data.no_admission) {
                return false;
            }
            if (!this.data.admission_to) {
                return false;
            }
            return true;
        },
        /**
         * Истекает ли период страхового полиса
         */
        isInsurancePolicyPeriodExpiring: function() {
            if (!this.isInsurancePolicyPeriodFilled) {
                return false;
            }
            var now = moment();
            var insurancePolicyTo = moment(this.data.insurance_policy_to);
            return (insurancePolicyTo > now && insurancePolicyTo.diff(now, 'days') < this.expireThreshold);
        },
        /**
         * Истек ли период страхового полиса
         */
        isInsurancePolicyPeriodExpired: function() {
            if (!this.isInsurancePolicyPeriodFilled) {
                return false;
            }
            var now = moment();
            var insurancePolicyTo = moment(this.data.insurance_policy_to);
            return insurancePolicyTo < now;
        },
        /**
         * Заполнен ли страховой полис
         */
        isInsurancePolicyPeriodFilled: function() {
            if (!this.data.id) {
                return false;
            }
            if (this.data.no_insurance_policy) {
                return false;
            }
            if (!this.data.insurance_policy_to) {
                return false;
            }
            return true;
        },
		/**
		 * Истекает ли разряд
		 */
		isRankPeriodExpiring: function() {
			if (!this.isRankPeriodFilled) {
				return false;
			}
			var now = moment();
			var rankTo = moment(this.data.athlete_rank_to);
			return (rankTo > now && rankTo.diff(now, 'days') < this.expireThreshold);
		},
		/**
		 * Истек ли разряд
		 */
		isRankPeriodExpired: function() {
			if (!this.isRankPeriodFilled) {
				return false;
			}
			var now = moment();
			var rankTo = moment(this.data.athlete_rank_to);
			return rankTo < now;
		},
		/**
		 * Заполнен ли период действия разряда
		 */
		isRankPeriodFilled: function() {
			if (!this.data.id) {
				return false;
			}
			if (this.data.no_rank) {
				return false;
			}
			if (!this.data.athlete_rank_to) {
				return false;
			}
			return true;
		},
        /**
         * Может быть удален
         * @returns {*|boolean}
         */
        canDeleted: function() {
            return !this.data.deleted_at && (this.isClubUser && this.data.club_id || this.isRegionalFederationUser && !this.data.club_id);
        },
        /**
         * Может быть восстановлен
         * @returns {*|boolean}
         */
        canRestored: function() {
            return this.data.deleted_at && (this.isClubUser && this.data.club_id || this.isRegionalFederationUser && !this.data.club_id);
        }
	},
	methods: {
        /**
         * Проверяем активность секции
         * @param sectionCode
         * @returns {boolean}
         */
        isActiveSection: function(sectionCode) {
            return this.activeSection === sectionCode;
        },
        /**
         * Проставляем активную секцию
         * @param sectionCode
         */
        setActiveSection: function(sectionCode) {
            this.activeSection = sectionCode;
        },
		/**
		 * Загрузка данных спортсмена
		 */
		loadData: function() {
			this.processing = true;
			let url = this.isAthleteUser ? '/settings/athlete-info/fetch' : '/athletes/fetch/' + this.id;
			axios.get(url)
				.then(response => {
					this.data = response.data;
					this.processing = false;
				})
				.catch(error => {
					console.warn('Ошибка при загрузке данных спортсмена', error);
					this.processing = false;
				})
		},

		/**
		 * Обработчик нажатия кнопки удаления спортсмена
		 */
		onClickDelete: function() {
			this.$refs.confirmModal.init({
				title: 'Удаление спортсмена',
				confirmText: 'Вы действительно хотите перенести в архив данного спортсмена?',
				confirmButtonTitle: 'Удалить',
				confirmButtonClass: 'btn-danger',
				confirmCallback: this.deleteCoach
			})
		},
		/**
		 * Удаление спортсмена
		 */
		deleteCoach: function() {
			this.deleting = true;
			var self = this;
			axios.delete('/athletes/delete/' + this.data.id)
				.then(
					response => {
						this.data = response.data;
						Bus.$emit('pushFlashAlert', {
							type: 'success',
							message: 'Спортсмен перенесен в архив'
						});
						self.deleting = false;
					}
				)
				.catch(error => {
					console.warn('Ошибка при удалении спортсмена', error);
					self.deleting = false;
				})
		},
		/**
		 * Восстановление спортсмена
		 */
		onClickRestore: function() {
			this.restoring = true;
			var self = this;
			axios.post('/athletes/restore', {id: this.data.id})
				.then(
					response => {
						this.data = response.data;
						Bus.$emit('pushFlashAlert', {
							type: 'success',
							message: 'Спортсмен успешно восстановлен из архива'
						});
						self.restoring = false;
					}
				)
				.catch(error => {
					console.warn('Ошибка при восстановлении спортсмена', error);
					self.restoring = false;
				})
		},
        /**
         * загружаем данные результатов соревнований
         */
        openResults: function () {
            this.setActiveSection('results');
            this.$refs.athletesResults.init(this.data.id);
        }
	}
});
