module.exports = {
	data: function() {
		return {
			step: 1,
			form: new TrustyForm({
				id: null,
				last_name: '',
				first_name: '',
				patronymic: '',
				birth_date: '',
				email: '',
				phone: '',
				coaching_category: '',
				coaching_category_doc_number: '',
                coaching_category_doc_file: '',
                coaching_category_doc_link: '',
                coaching_category_doc_name: '',
				coaching_category_order_date: '',
				sport_rank: '',
				sport_rank_doc_number: '',
                sport_rank_doc_file: '',
                sport_rank_doc_link: '',
                sport_rank_doc_name: '',
				sport_rank_order_date: '',
				educational_type: '',
				educational_institution: '',
				educational_doc_number: '',

                data_agreement_doc_id: '',
                data_agreement_doc_file: '',
                data_agreement_doc_name: '',
                data_agreement_doc_link: '',

				employee_type: 1
			}),
		}
	},
	methods: {
		/**
		 * Создание пользователя на 1 этапе
		 */
		create: function () {
			Trusty.post('/coaches', this.form)
				.then(response => {
					if (response.exists) {
						this.$refs.coachExistsModal.init(response, this.getExistsMode(response));
					} else {
						this.step = 2;
						this.form.id = response.id;
					}
				})
				.catch(error => {
				})
		},
		/**
		 * Обновление данных тренера
		 */
		update: function() {
			Trusty.postWithFiles('/coaches/' + this.form.id, this.form, [
				'coaching_category_doc_file',
				'sport_rank_doc_file',
                'data_agreement_doc_file',
			])
				.then(response => {
					location.href = '/coaches';
				})
				.catch(error => {
				})
		},
		/**
		 * Загружаем данные о тренере
		 */
		loadCoach: function() {
			axios.get('/coaches/fetch/' + this.coachId)
				.then(response => {
					$.extend(true, this.form, response.data);
				})
				.catch(error => {
					console.warn('Ошибка при загрузке тренера', error);
				})
		},
		/**
		 * Обработчик смены файла приказа о присвоении категории
		 * @param fileData
		 */
		onChangeCoachingCategoryDocFile: function(fileData) {
			this.form.coaching_category_doc_file = fileData;
		},
		/**
		 * Обработчик смены файла приказа о присвоении спортивного звания
		 * @param fileData
		 */
		onChangeSportRankDocFile: function(fileData) {
			this.form.sport_rank_doc_file = fileData;
		},
        /**
         * Обработчик смены файла пользовательского соглашения
         * @param fileData
         */
        onChangeDataAgreementDocFile: function(fileData) {
            this.form.data_agreement_doc_file = fileData;
        },
		/**
		 * Проверяем привязан ли тренер к клубу или просто есть в системе
		 * @param data
		 * @returns {*}
		 */
		getExistsMode: function(data) {
			if (!data.clubs.length) {
				return 'need_assign';
			}
			for(var index = 0; index < data.clubs.length; index++) {
				if (data.clubs[index].id == this.clubId) {
					return 'assigned';
				}
			}
			return 'need_assign';
		}
	}
}
