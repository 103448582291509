module.exports = {
    COMPETITION_RANK_CITY: 1,
    COMPETITION_RANK_MUNICIPALITY: 2,
    COMPETITION_RANK_REGION: 3,
    COMPETITION_RANK_COUNTRY: 4,
    COMPETITION_RANK_INTERNATIONAL: 5,

    COMPETITION_PROPOSAL_GENERAL_TEAM_STATUS_CREATED: 0,
    COMPETITION_PROPOSAL_GENERAL_TEAM_STATUS_APPROVED: 1,

    COMPETITION_STATUS_DRAFT: 0,
    COMPETITION_STATUS_ACTIVE: 1,

    COMPETITION_ALG_TYPE_DEFAULT: 0,
    COMPETITION_ALG_TYPE_WITH_PRE_PROPOSALS: 1,
};
