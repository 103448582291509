Vue.component('judge-list', {
    props: [],
    data:function () {
        return {
            currentRow: null
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'seminars',
            childCode: 'judges'
        });
    },
    methods: {
        /**
         * Подтверждение удаление судьи
         */
        confirmDelete: function(row) {
            this.currentRow = row;
            this.$refs.confirmModal.init({
                title: 'Перенос судей в архив',
                confirmText: 'Вы действительно хотите перенести в архив выбранного судью?',
                confirmButtonTitle: 'Перенeсти',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteJudge
            })
        },
        /**
         * Удаление судью
         */
        deleteJudge: function() {
            this.$refs.judgeGrid.setLoading(true);
            var self = this;
            axios.delete('/judges/delete/' + this.currentRow.id)
                .then(
                    response => {
                        self.$refs.judgeGrid.reloadData();
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при удалении судьи', error);
                    self.$refs.judgeGrid.setLoading(false);
                })
        },

        /**
         * @brief Форматированный вывод текущей категории
         * @param value
         * @param row
         * @returns {*}
         */
        activeCategoryFormat: function (value, row) {
            if (!row.judgeJudgeCategory) {
                return 'Нет категории';
            }

            var categoryName =  row.judgeJudgeCategory.judgeCategory.name;
            var postfix = '';

            var now = moment();
            var to = moment(row.judgeJudgeCategory.expires_at);

            if (to.diff(now,'days') < 0) {
                postfix = '<br /><span class="text-danger"> Истёкла ' + Vue.filter('date')(row.judgeJudgeCategory.expires_at) + '</span>';
            }
            else if (to.diff(now,'days') < 30) {
                postfix = '<br /><span class="text-danger"> Истекает ' + Vue.filter('date')(row.judgeJudgeCategory.expires_at) + '</span>';
            }

            return categoryName + postfix;
        },
        /**
         * Муниципалитет
         * @param value
         * @param raw
         * @return {*|string}
         */
        municipality: function (value, raw) {
            return raw.municipality ? raw.municipality.name : '';
        }
    }
});
