module.exports = {
	data: function () {
		return {
			step: 1,
			form: new TrustyForm({
				id: null,
				last_name: '',
				first_name: '',
				patronymic: '',
				gender: '',
				birth_date: '',
				coach_ids: [],
                municipality_id: '',
                city_id: '',
                address: '',
				no_rank: 0,
				athlete_rank_id: '',
				athlete_rank_doc_id: '',
                athlete_rank_doc_file: null,
                athlete_rank_doc_link: '',
                athlete_rank_doc_name: '',
				athlete_rank_doc_number: '',
				athlete_rank_from: '',
				athlete_rank_to: '',
				no_admission: 0,
                athlete_admission_id: '',
				admission_doc_id: '',
                admission_doc_file: null,
                admission_doc_link: '',
                admission_doc_name: '',
				admission_from: '',
                no_insurance_policy: 0,
                insurance_policy_doc_id: '',
                insurance_policy_doc_file: null,
                insurance_policy_doc_name: '',
                insurance_policy_doc_link: '',
                insurance_policy_to: '',
                data_agreement_doc_id: '',
                data_agreement_doc_file: '',
                data_agreement_doc_name: '',
                data_agreement_doc_link: '',
				is_employed: 0,
				company: '',
				position: '',
				contract_number: '',
				contract_from: '',
				contract_to: '',
				email: '',
				phone: '',
				social_pages: [],
				proxy_last_name: '',
				proxy_first_name: '',
				proxy_patronymic: '',
				proxy_phone: '',
			}),
            cities: [],
		}
	},
	computed: {
		/**
		 * Возможные разряды для спортсмена по возрасту
		 * @returns {*}
		 */
		availableAthleteRanks: function() {
			if (!this.form.birth_date) {
				return this.athleteRanks;
			}
			const age = Vue.filter('ageYear')(this.form.birth_date);
			return this.athleteRanks.filter(rank => {
				return rank.age_min <= age && age <= rank.age_max;
			})
		},
		/**
		 * Проверяем, нужно ли блокировать контролы формы для заполнения данных о разряде спортсмена
		 * @returns {boolean}
		 */
		rankControlsDisabled: function() {
			return this.form.no_rank ? true : false;
		},
		/**
		 * Проверяем, нужно ли блокировать контролы формы для заполнения данных о допуске спортсмена
		 * @returns {boolean}
		 */
		admissionControlsDisabled: function() {
			return this.form.no_admission ? true : false;
		},
        /**
         * Проверяем, нужно ли блокировать контролы формы для заполнения данных о страховом полисе спортсмена
         * @returns {boolean}
         */
        insurancePolicyControlsDisabled: function() {
            return this.form.no_insurance_policy ? true : false;
        },
		/**
		 * Проверяем, нужно ли блокировать контролы формы для заполнения данных о трудоустройстве спортсмена
		 * @returns {boolean}
		 */
		employmentControlsDisabled: function() {
			return this.form.is_employed ? false : true;
		},
        /**
         * Модель для города
         */
        city: {
            get: function () {
                let filtered = this.cities.filter(item => item.id == this.form.city_id);
                if (filtered.length) {
                    return filtered[0];
                }
                return null;
            },
            set: function (item) {
                if (item) {
                    this.form.city_id = item.id;
                    this.form.municipality_id = item.municipality_id;
                    this.cities = [item];
                } else {
                    this.form.city_id = '';
                    this.form.municipality_id = '';
                }
            }
        },
	},
	methods: {
		/**
		 * Создание спортсмена на 1 этапе
		 */
		create: function () {
			Trusty.post('/athletes', this.form)
				.then(response => {
					if (response.exists) {
						this.$refs.athleteExistsModal.init(response, this.getExistsMode(response));
					} else {
						this.step = 2;
						this.form.id = response.id;
					}
				})
				.catch(error => {
				})
		},
		/**
		 * Обновление данных спортсмена
		 */
		update: function() {
			Trusty.postWithFiles('/athletes/' + this.form.id, this.form, [
				'athlete_rank_doc_file',
				'admission_doc_file',
                'insurance_policy_doc_file',
                'data_agreement_doc_file',
			])
				.then(response => {
					location.href = '/athletes';
				})
				.catch(error => {
				})
		},
		/**
		 * Загружаем данные о спортсмене
		 */
		loadAthlete: function() {
            let url = this.isAthleteUser ? '/settings/athlete-info/fetch' : '/athletes/fetch/' + this.athleteId;
			axios.get(url)
				.then(response => {
					$.extend(true, this.form, response.data);
					this.form.coach_ids = [];
					if (response.data.coaches) {
                        response.data.coaches.forEach((coach) => {
                            this.form.coach_ids.push(coach.id);
                        });
                    }
					if (response.data.city) {
					    let city = response.data.city;
					    if (response.data.municipality) {
					        city.municipality = response.data.municipality;
                        }
					    this.cities = [city];
                    }
				})
				.catch(error => {
					console.warn('Ошибка при загрузке спортсмена', error);
				})
		},
		/**
		 * Обработчик смены файла приказа о присвоении разряда/звания
		 * @param fileData
		 */
		onChangeAthleteRankDocFile: function(fileData) {
			this.form.athlete_rank_doc_file = fileData;
		},
		/**
		 * Обработчик смены файла выписки врача
		 * @param fileData
		 */
		onChangeAdmissionDocFile: function(fileData) {
			this.form.admission_doc_file = fileData;
		},
        /**
         * Обработчик смены файла страхового полиса
         * @param fileData
         */
        onChangeInsurancePolicyDocFile: function(fileData) {
            this.form.insurance_policy_doc_file = fileData;
        },
        /**
         * Обработчик смены файла пользовательского соглашения
         * @param fileData
         */
        onChangeDataAgreementDocFile: function(fileData) {
            this.form.data_agreement_doc_file = fileData;
        },
		/**
		 * Проверяем привязан ли спортсмен к клубу или просто есть в системе
		 * @param data
		 * @returns {*}
		 */
		getExistsMode: function(data) {
			if (data.club_id == this.clubId) {
				return 'assigned';
			}
			return 'need_assign';
		},

		/**
		 * Пересчет даты, до которой октивен разряд
		 */
		recalculateRankTo: function() {
			if (!this.form.athlete_rank_from) {
				this.form.athlete_rank_to = '';
				return;
			}

			let activeRank = null;
			for (let i = 0; i < this.availableAthleteRanks.length && !activeRank; i++) {
				if (this.availableAthleteRanks[i].id === this.form.athlete_rank_id) {
					activeRank = this.availableAthleteRanks[i];
				}
			}

			if (!activeRank || !activeRank.action_period || !this.form.athlete_rank_from) {
				this.form.athlete_rank_to = '';
				return;
			}

			this.form.athlete_rank_to = moment(this.form.athlete_rank_from).add(activeRank.action_period, 'M').format('YYYY-MM-DD');
		},

		/**
		 * Пересчет даты действия допуска врача
		 */
		recalculateAdmissionTo: function() {
			if (!this.form.admission_from) {
				this.form.admission_to = '';
				return;
			}

            let activeAdmission = null;
            for (let i = 0; i < this.athleteAdmissions.length && !activeAdmission; i++) {
                if (this.athleteAdmissions[i].id === this.form.athlete_admission_id) {
                    activeAdmission = this.athleteAdmissions[i];
                }
            }

            let actionPeriod = this.admissionPeriod;
            if (activeAdmission) {
                actionPeriod = activeAdmission.action_period;
            }
            if (!actionPeriod || !this.form.admission_from) {
                this.form.admission_to = '';
                return;
            }

            this.form.admission_to = moment(this.form.admission_from).add(actionPeriod, 'M').format('YYYY-MM-DD');
		},
        /**
         * Загружаем список городов
         */
        searchCities: function(search, loading) {
            if (!search) {
                return;
            }
            loading && loading(true);
            this.cities = [];
            axios.get('/dictionaries/cities/all?keyword=' + search + '&limit=50')
                .then(response => {
                    this.cities = response.data;
                    loading && loading(false);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке списка городов', error);
                    loading && loading(false);
                })
        },
        /**
         * Заголовок для города
         * @param row
         * @returns {string}
         */
        cityLabel: function(option) {
            return option.name + (option.municipality ? ' (' + option.municipality.name + ')' : '');
        }
	}
}
