Vue.component('general-competition-calendar', {
    props: ['years', 'provisions'],
    data: function () {
        return {
            year: this.getFirstAvailableYear(),
            ids: []
        }
    },
    methods: {
        /**
         * Применяем фильтры для календаря
         * @param filters
         */
        applyFilters: function (filters) {
            this.year = filters.year;
            this.$refs.generalCompetitionList.applyFilters(filters);
        },

        /**
         * Обработчик изменения ключевого слова поиска
         * @param keyword
         */
        search: function (keyword) {
            this.$refs.generalCompetitionList.search(keyword);
        },
        /**
         * Первый доступный год в календаре
         * @returns {string|*}
         */
        getFirstAvailableYear: function () {
            if (this.years.length > 0) {
                return this.years[this.years.length - 1];
            }
            return '';
        },
        /**
         * Ссылка на файл положения на выбранный год
         * @returns {string}
         */
        getCompetitionProvisionDocLink: function() {;
            let self = this;
            let provisionDocLink = null;
            this.provisions.forEach(function(element){
                if (element.year == self.year){
                    provisionDocLink = element.provision_doc_link;
                }
            });
            return provisionDocLink;
        }
    }
});
