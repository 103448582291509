/**
 * Вспомогательный класс TrustyForm. Используется для установки общих свойств для всех форм.
 */
window.TrustyForm = function (data) {
    var form = this;

    $.extend(this, data);

    /**
     * Создает экземпляр помощника по ошибкам формы.
     */
    this.errors = new TrustyFormErrors();

    this.busy = false;
    this.successful = false;

    /**
     * Начинает обработку формы.
     */
    this.startProcessing = function () {
        form.errors.forget();
        form.busy = true;
        form.successful = false;
    };

    /**
     * Завершает обработку формы.
     */
    this.finishProcessing = function () {
        form.busy = false;
        form.successful = true;
    };

    /**
     * Сброс ошибок и другого состояния для формы.
     */
    this.resetStatus = function () {
        form.errors.forget();
        form.busy = false;
        form.successful = false;
    };


    /**
     * Установливает ошибки в форме.
     */
    this.setErrors = function (errors) {
        form.busy = false;
        form.errors.set(errors);
    };
};
