<template>
    <div class="input-group" :class="{'is-invalid': hasError}">
        <div class="input-group-prepend">
            <div class="input-group-text">+7</div>
        </div>
        <input type="text" v-model="phone"
               class="form-control" id="inputPhone" placeholder="(900) 809-01-20"
               :class="{'is-invalid': hasError}"
               :dusk="dusk"
               v-mask="'(###) ###-##-##'" maxlength="15"
        >
    </div>
</template>

<script>

	export default {
		props: ['value', 'inputId', 'hasError', 'dusk'],
		data: function() {
			return {
			}
		},
		computed: {
			inputClass: function() {
				return this.hasError ? 'form-control is-invalid' : 'form-control';
			},
			phone: {
				get: function() {
					return this.prepareMaskedPhone(this.value);
				},
				set: function(value) {
					if (!value || value == '(') {
						this.$emit('input', '');
						return;
                    }
					this.$emit('input', '7' + value.replace(/[^0-9.]/g, ""));
				}
			}
		},
        methods: {
			prepareMaskedPhone: function(phone) {
				if (!phone) {
					return '';
                }
                var value = phone.replace(/[^0-9.]/g, "").substr(1, 10);
                if (value.length == 0) {
                	return '';
                }
                var masked = '(';
                if (value.length < 3) {
                	return '(' + value;
                }
                if (value.length == 3) {
                	return '(' + value + ')';
                }
                masked += value.substr(0, 3) + ')';
                if (value.length <= 6) {
                	return masked + ' ' + value.substr(3, 3);
                }
				masked += ' ' + value.substr(3, 3);
				if (value.length <= 8) {
					return masked + '-' + value.substr(6, 2);
				}
				masked += '-' + value.substr(6, 2) + '-' + value.substr(8, 2);
				return masked;
            }
        }
	}
</script>
