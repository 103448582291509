import constants from "../../proposals/constants";

Vue.component('credential-commission-athletes', {
    mixins: [require('../../../common/mixins/user'), require('../../mixins/athleteCheckers')],
    props: [
        'competition',
        'participantTypes',
        'athleteRanks',
        'genderLabels',
        'courses',
        'statusLabels',
    ],
    data: function() {
        return {
            competitionProposal: null,
            competitionProposalAthletes: [],
            savedProposalAthletes: [],
            selectedAthletes:[],
            selectedAthletesParams:[],
            quotas: null,
            loaded: false,
            saving: false,
            team_id: null,
            team_name: '',
            has_protocol: false,
            mode: '',
            athletes: [],
            data: {},
            constants: constants,
            processing: false,
            currentTeam: null,
        }
    },
    computed: {
        /**
         * Получить мод родителя
         * @returns {*}
         */
        getParentMode: function () {
            return this.$parent.mode;
        },
        /**
         * Отбрасываем заявки с удаленными атлетами
         * @returns {T[]}
         */
        availableAthletes: function () {
            return this.athletes.filter(athlete => !!athlete.athlete);
        },
        /**
         * Выбраны ли все спортсмены в списке
         * @returns {boolean}
         */
        isCompetitionProposalAllAthletesStatusChecked: function() {
            return this.availableAthletes.filter(athlete => athlete.status == this.constants.STATUS_CREATED).length == 0;
        },
        /**
         * Ссылка на страницу печати
         * @returns {string}
         */
        printLink: function() {
            return '/competitions/' + this.competition.id + '/credential-commission/print-team-proposal/' + this.team_id;
        }
    },
    methods: {
        /**
         * Инициализируем компонент списка атлетов команды (клуба или города/муниципалитета)
         */
        init: function (team_id, team_name, has_protocol) {
            this.$parent.mode = 'athletes';

            this.team_id = team_id;
            this.team_name = team_name;
            this.has_protocol = has_protocol;

            this.loadAthletes();
        },
        /**
         * Загрузка заявок атлетов
         */
        loadAthletes: function (callback = null) {
            this.athletes = [];
            this.processing = true;
            let self = this;

            let teamId = this.team_id ? this.team_id : 0;
            this.loadCurrentTeam();
            axios.get('/competition/' + this.competition.id + '/proposal-athletes/' + teamId)
                .then(response => {
                    self.athletes = response.data;
                    this.prepareCompetitionProposalAthletes();
                    self.processing = false;
                    callback && callback();
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных заявок спортсменов', error);
                    self.processing = false;
                });
        },
        /**
         * Отобразить код разряда
         * @param athlete
         */
        getRankCode: function (athlete) {
            if (athlete.no_rank || !athlete.athlete_rank_code) {
                return 'Без разряда';
            }
            return athlete.athlete_rank_code;
        },
        /**
         * Получить название клуба атлета
         * @param athlete
         */
        getClubName: function (athlete) {
            return athlete.club ? athlete.club.name : 'Независимый спортсмен';
        },
        /**
         * Получить список имен тренеров атлета
         * @param athlete
         */
        getCoachesNames: function (athlete) {
            let names = [];
            for(let i = 0; athlete.coaches && i < athlete.coaches.length; i++) {
                names.push(this.getInitialName(athlete.coaches[i]));
            }


            return names.length > 0 ? names.join(' ') : 'Без тренера';
        },
        /**
         * Получить фамилию с инициалами
         * @param user
         * @returns {string}
         */
        getInitialName(user) {
            return user.last_name + ' ' + this.getInitialFromString(user.first_name) + ' ' + this.getInitialFromString(user.patronymic);
        },
        /**
         * Получить сокращение для инициалов по имени или отчеству
         * @param name
         * @returns {string}
         */
        getInitialFromString(name) {
            return name.substring(0, 1) + '.';
        },
        /**
         * Отменить редактирование листа спортсменов
         */
        back: function () {
            this.$parent.mode = 'teams';
            this.$parent.loadData(true);
        },
        /**
         * Открыть модалку с инфой об атлете
         * @param {Number} id
         */
        openAthleteInfo: function (id) {
            this.$refs.athleteInfoModal.init(id);
        },
        /**
         * Инициализируем компонент выбора спортсменов
         */
        openAthletesSelection: function() {
            if (!this.isCompetitionCompleted()) {
                let teamId = this.team_id ? this.team_id : 0;
                this.$refs.competitionProposalAthletesSelection.init(null, teamId);
            }
        },
        /**
         * Открываем модальное окно для редактирования видов программ
         * @param athlete
         */
        openProposalSwimPrograms: function (athlete) {
            if (!this.isCompetitionCompleted()) {
                this.$refs.competitionProposalSwimPrograms.init(athlete);
            }
        },
        /**
         * Обработчик события после сохранения выделенных атлетов
         */
        onAthletesSaved: function () {
            let self = this;
            this.loadAthletes(function() {
                let athlete = null;
                let filtered = self.availableAthletes.filter(item => item.swim_programs.length == 0);
                if (filtered.length > 0) {
                    athlete = filtered[0];
                }
                self.$refs.competitionProposalSwimPrograms.init(athlete);
            });
        },
        /**
         * Подготавливаем структуру под отображение атлетов
         */
        prepareCompetitionProposalAthletes: function() {
            let freeAthletes = [];
            let paidAthletes = [];
            for (let index = 0; index < this.availableAthletes.length; index++) {
                if (this.availableAthletes[index].participant_type == constants.PARTICIPANT_TYPE_FREE) {
                    freeAthletes.push(this.availableAthletes[index]);
                } else {
                    paidAthletes.push(this.availableAthletes[index]);
                }
            }
            Vue.set(this.competitionProposalAthletes, constants.PARTICIPANT_TYPE_FREE, freeAthletes);
            Vue.set(this.competitionProposalAthletes, constants.PARTICIPANT_TYPE_PAID, paidAthletes);
        },
        /**
         * Обработчик изменения атлета
         * @param proposalAthlete
         */
        onAthleteUpdated: function(proposalAthlete) {
            let proposalAthletes = this.competitionProposalAthletes[proposalAthlete.participant_type];
            let athleteIndex = -1;
            for (let index = 0; index < proposalAthletes.length; index++) {
                if (proposalAthletes[index].id == proposalAthlete.id) {
                    athleteIndex = index;
                }
            }
            if (athleteIndex >= 0) {
                proposalAthletes[athleteIndex] = proposalAthlete;
            }
            Vue.set(this.competitionProposalAthletes, proposalAthlete.participant_type, proposalAthletes);

            athleteIndex = -1;
            for (let index = 0; index < this.athletes.length; index++) {
                if (this.athletes[index].id == proposalAthlete.id) {
                    athleteIndex = index;
                }
            }
            this.athletes[athleteIndex].swim_programs = proposalAthlete.swim_programs;
        },
        /**
         * Модалка для ввода комментария - причина условного допуска
         * @param row
         */
        changeComment: function(competitionProposalAthlete) {
            if (competitionProposalAthlete && (competitionProposalAthlete.status == this.constants.STATUS_PARTIALLY_ALLOWED) &&
                !this.isCompetitionCompleted()) {
                this.$refs.credentialCommissionAddCommentAthlete.init(competitionProposalAthlete);
            }
        },
        /**
         * Меняем статус всех спортсменов в списке
         * @param event
         */
        changeCompetitionProposalAllAthletesStatus(event) {
            if (!this.isCompetitionCompleted()) {
                let checked = event.target.checked;
                let athleteIds = [];
                let statusCreated = this.constants.STATUS_CREATED;
                let statusAllowed = this.constants.STATUS_DISALLOWED;
                this.athletes.forEach(function (athlete) {
                    if (checked) {
                        if (athlete.status == statusCreated) {
                            athleteIds.push(athlete.id);
                        }
                    } else {
                        if (athlete.status != statusCreated) {
                            athleteIds.push(athlete.id);
                        }
                    }
                });
                axios.post('/competition/' + this.competition.id + '/credential-commission/change-competition-proposal-all-athletes-status', {
                    competition_proposal_athlete_ids: athleteIds,
                    status: checked ? this.constants.STATUS_DISALLOWED : this.constants.STATUS_CREATED
                }).then(response => {
                    this.athletes.forEach(function (athlete) {
                        if (checked) {
                            if (athlete.status == statusCreated) {
                                athlete.status = statusAllowed;
                            }
                        } else {
                            if (athlete.status != statusCreated) {
                                athlete.status = statusCreated;
                                athlete.comment = null;
                            }
                        }
                    });
                }).catch(error => {
                    console.warn('Ошибка при изменения статуса спортсмена', error);
                })
            }
        },
        /**
         * Изменить статус заявленного спортсмена
         * @param competitionProposalAthlete
         * @param status
         */
        changeCompetitionProposalAthleteStatus: function (competitionProposalAthlete, status) {
            if (!this.isCompetitionCompleted()) {
                if (status == null) {
                    status = competitionProposalAthlete.status == this.constants.STATUS_CREATED
                        ? this.constants.STATUS_DISALLOWED
                        : this.constants.STATUS_CREATED;
                }

                axios.post('/competition/' + this.competition.id + '/credential-commission/change-competition-proposal-athlete-status', {
                    competitionProposalAthleteId: competitionProposalAthlete.id,
                    status: status
                }).then(response => {
                    this.athletes.forEach(function (athlete) {
                        if (athlete.id == competitionProposalAthlete.id) {
                            athlete.status = response.data.status;
                            athlete.comment = response.data.comment;
                        }
                    });
                    //открываем окно с комментариями
                    if (status == this.constants.STATUS_PARTIALLY_ALLOWED) {
                        this.$refs.credentialCommissionAddCommentAthlete.init(competitionProposalAthlete);
                    }
                }).catch(error => {
                    console.warn('Ошибка при изменения статуса спортсмена', error);
                })
            }
        },
        /**
         * Получаем статус спортсмена для списка
         * @param athlete
         */
        getCompetitionProposalAthleteStatusSelectValue: function(athlete) {
            return athlete.status == this.constants.STATUS_CREATED
                ? this.constants.STATUS_DISALLOWED
                : athlete.status
        },
        /**
         * Проверка дефолтное ли значение в вывобрке статусов спортсмена
         * @returns {boolean|boolean}
         */
        isDefaultSelectStatus: function (competitionProposalAthleteStatus, statusIndex) {
            //Если у спортсмена статус Создан, задаем в выборке дефолтным Допущен
            if (competitionProposalAthleteStatus == this.constants.STATUS_CREATED && statusIndex == this.constants.STATUS_ALLOWED) {
                return true;
            }
            //Если у спортсмена статус НЕ Создан, задаем в выборке дефолтным его
            if (competitionProposalAthleteStatus != this.constants.STATUS_CREATED && statusIndex == competitionProposalAthleteStatus) {
                return true;
            }
            return false;
        },
        /**
         * Задаем объект текущей команды
         */
        loadCurrentTeam: function () {
            let self = this;
            this.$parent.teams.forEach(function (team) {
                if (team.id == self.team_id) {
                    self.currentTeam = team;
                }
            });
        },
        /**
         * Завершено ли соревнование
         * @returns {boolean}
         */
        isCompetitionCompleted: function () {
            let currentDate = new Date();
            return currentDate > new Date(this.competition.date_to);
        },
        /**
         * Получить название вида программы по id
         */
        getSwimProgramName: function(swimProgramId) {
            let swimProgramName;
            this.competition.swim_programs.forEach(function (swimProgram) {
                if (swimProgram.id == swimProgramId) {
                    swimProgramName = swimProgram.swim_program ? swimProgram.swim_program.name : '';
                }
            });
            return swimProgramName;
        },
        /**
         * Класс статуса допуска для цветовой легенды
         * @param {Object} athlete
         * @returns {string}
         */
        getStatusClass: function (athlete) {
            switch (parseInt(athlete.status)) {
                case this.constants.STATUS_CREATED:
                    return 'athlete-created';
                case this.constants.STATUS_ALLOWED:
                    return 'athlete-allowed';
                case this.constants.STATUS_PARTIALLY_ALLOWED:
                    return 'athlete-partially-allowed';
                case this.constants.STATUS_DISALLOWED:
                    return 'athlete-declined';
                case this.constants.STATUS_NOT_COME:
                    return 'athlete-not-come';
            }
            return '';
        },
    }
});
