/**
 * Trusty form сбор ошибок формы.
 */
window.TrustyFormErrors = function () {
    this.errors = {};

    /**
     * Определяет, есть ли в коллекции какие-либо ошибки.
     */
    this.hasErrors = function () {
        return ! _.isEmpty(this.errors);
    };


    /**
     * Определяет, есть ли в коллекции ошибки для данного поля.
     */
    this.has = function (field) {
        return _.indexOf(_.keys(this.errors), field) > -1;
    };


    /**
     * Получает все необработанные ошибки для коллекции.
     */
    this.all = function () {
        return this.errors;
    };


    /**
     * Получает все ошибки для коллекции в массиве.
     */
    this.flatten = function () {
        return _.flatten(_.toArray(this.errors));
    };


    /**
     * Получает первое сообщение об ошибке для данного поля.
     */
    this.get = function (field) {
        if (this.has(field)) {
            return this.errors[field][0];
        }
    };


    /**
     * Установливает необработанные ошибки для коллекции.
     */
    this.set = function (errors) {
        if (typeof errors === 'object') {
            this.errors = errors;
        } else {
            this.errors = {'form': ['Ошибка при попытке обращения к серверу!']};
        }
    };


    /**
     * Удаляет ошибки из коллекции.
     */
    this.forget = function (field) {
        if (typeof field === 'undefined') {
            this.errors = {};
        } else {
            Vue.delete(this.errors, field);
        }
    };
};
