import constants from './constants';

Vue.component('competition-proposal-wizard', {
    mixins: [require('../mixins/dateFormatter'), require('../../common/mixins/user'), require('../mixins/athleteCheckers')],
    props: [
        'competitionId',
        'participantTypes',
        'athleteRanks',
        'genderLabels',
        'courses'
    ],
    data: function() {
        return {
            competition: null,
            competitionProposal: null,
            competitionProposalAthletes: [],
            quotas: null,
            athletes: [],
            relays: [],
            activeTab: 'athletes',
            loaded: false,
            constants: constants,
            currentProposalAthlete: null,
            currentProposalAthleteIndex: null,
            currentProposalRelay: null,
            currentProposalRelayIndex: null,
            hasChanges: false,
            saving: false,
            totalQuotas: 0,
            competitionConstants: require('../constants'),
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'competitions',
            mode: 'detail',
            backTitle: 'К профайлу соревнования',
            title: 'Заявка на участие в соревнованиях'
        });
        Vue.set(this.competitionProposalAthletes, constants.PARTICIPANT_TYPE_FREE, []);
        Vue.set(this.competitionProposalAthletes, constants.PARTICIPANT_TYPE_PAID, []);
        this.loadWizardData();
    },
    computed: {
        /**
         * Алгоритм распределения квот с предварительными заявками
         * @returns {null|number|boolean}
         */
        isPreQuotasAlgType: function () {
            return this.competition && this.competition.is_use_quotas &&
                this.competition.quotas_alg_type === this.competitionConstants.COMPETITION_ALG_TYPE_WITH_PRE_PROPOSALS;
        },
        /**
         * Проверяем есть ли эстафетные заявки в соревновании
         */
        hasRelaySwimPrograms: function() {
            if (!this.competition || !this.competition.swim_programs) {
                return false;
            }
            return this.competition.swim_programs.filter(item => item.swim_program.relay_count > 1);
        },
        /**
         * Проверяем есть ли квоты
         * @returns {null|number}
         */
        hasQuotas: function() {
            return this.competition
                && this.competition.is_use_quotas
                && this.quotas;
        },
        /**
         * Проверяем возможнсть подачи заявок
         * @returns {boolean|boolean}
         */
        checkProposalDate() {
            if (!this.competition) {
                return false;
            }
            return moment.now() >= moment(this.competition.proposal_from).startOf('day')
                &&  moment.now() <= moment(this.competition.proposal_to).endOf('day')
        }
    },
    methods: {
        /**
         * Метод Геттер для перетаскивания атлетов
         * @param participantType
         * @param listType
         * @returns {*}
         */
        getDraggableParticipantList: function(participantType, listType) {
            if (!this.hasWaitingList(participantType)) {
                return this.competitionProposalAthletes[participantType];
            }
            let code = participantType == this.constants.PARTICIPANT_TYPE_FREE ? 'free_participant_count' : 'paid_participant_count';
            if (listType == this.constants.LIST_TYPE_WAITING) {
                return this.competitionProposalAthletes[participantType].slice(this.quotas[code]);
            }
            return this.competitionProposalAthletes[participantType].slice(0, this.quotas[code]);
        },
        /**
         * Метод-сеттер для перетаскивания атлетов
         * @param event
         * @param participantType
         * @param listType
         */
        setDraggableParticipantList: function(event, participantType, listType) {
        },
        /**
         * Отклонение для порядкового номера для спортсмена
         * @param athlete
         * @returns {number|*}
         */
        getIndexOffset: function (competitionAthlete, index) {
            let originalIndex = -1;
            this.competitionProposalAthletes[competitionAthlete.participant_type].forEach(function(item, i) {
                if (item.id == competitionAthlete.id) {
                    originalIndex = i;
                }
            });
            if (originalIndex > index) {
                return originalIndex - index;
            }
            return 0;
        },
        /**
         * Обработчик изменения порядка спортсменов
         * @param data
         */
        onChangeAthletesOrder: function (participantType, listType, data) {
            this.hasChanges = true;
            let competitionProposalAthletes = this.competitionProposalAthletes[participantType];
            let indexOffset = 0;
            if (listType == this.constants.LIST_TYPE_WAITING) {
                let code = participantType == this.constants.PARTICIPANT_TYPE_FREE ? 'free_participant_count' : 'paid_participant_count';
                indexOffset = this.quotas[code];
            }
            if (data.added) {
                competitionProposalAthletes.splice(data.added.newIndex + indexOffset, 0, data.added.element);
            }
            if (data.removed) {
                if (data.removed.element.id == competitionProposalAthletes[data.removed.oldIndex + indexOffset].id) {
                    competitionProposalAthletes.splice(data.removed.oldIndex + indexOffset, 1);
                }
                else if (data.removed.oldIndex + indexOffset + 1 < competitionProposalAthletes.length && data.removed.element.id == competitionProposalAthletes[data.removed.oldIndex + indexOffset + 1].id) {
                    competitionProposalAthletes.splice(data.removed.oldIndex + indexOffset + 1, 1);
                }
                else if (data.removed.oldIndex + indexOffset - 1 >= 0 && data.removed.element.id == competitionProposalAthletes[data.removed.oldIndex + indexOffset - 1].id) {
                    competitionProposalAthletes.splice(data.removed.oldIndex + indexOffset - 1, 1);
                }
            }
            if (data.moved) {
                competitionProposalAthletes.splice(data.moved.oldIndex + indexOffset, 1);
                competitionProposalAthletes.splice(data.moved.newIndex + indexOffset, 0, data.moved.element);
            }
            Vue.set(this.competitionProposalAthletes, participantType, competitionProposalAthletes);
        },
        /**
         * Проверка соответсвия возрастной группе эстафете
         * @param ageGroup
         * @param relay
         * @returns {boolean}
         */
        isAgeGroupAvailableForRelay: function(ageGroup, relay) {
           if (!ageGroup) {
               return false;
           }
           return relay.gender == 'X' || relay.gender == ageGroup.gender;
        },
        /**
         * Заголовок возрастной группы
         * @param athlete
         * @param participantType
         */
        ageGroupTitle: function(athlete, participantType) {
            let ageGroup = null;
            for (let index = 0; index < this.competition.age_groups.length; index++) {
                if (this.isAgeGroupAvailableForAthlete(this.competition.age_groups[index], athlete, participantType)) {
                    ageGroup = this.competition.age_groups[index];
                }
            }
            if (ageGroup === null) {
                return '';
            }
            if (ageGroup.age_group) {
                return ageGroup.age_group.name;
            }
            return Vue.filter('ageGroupTitle')(ageGroup);
        },
        /**
         * Необходимо показать листы ожидаения
         * @param participantType
         * @returns {null|number|boolean}
         */
        hasWaitingList: function (participantType) {
            let code = participantType == this.constants.PARTICIPANT_TYPE_FREE ? 'free_participant_count' : 'paid_participant_count';
            return this.competition
                && this.competition.is_use_quotas
                && this.quotas && this.quotas[code] < this.competitionProposalAthletes[participantType].length;
        },
        /**
         * Проверяем вхождение в основной или лист ожидания
         * @param participantType
         * @param listType
         * @param index
         * @returns {boolean}
         */
        isListType: function (participantType, listType, index) {
            if (!this.hasWaitingList(participantType)) {
                return listType == this.constants.LIST_TYPE_MAIN;
            }
            let code = participantType == this.constants.PARTICIPANT_TYPE_FREE ? 'free_participant_count' : 'paid_participant_count';
            if (this.quotas[code] <= index) {
                return listType == this.constants.LIST_TYPE_WAITING;
            }
            return listType == this.constants.LIST_TYPE_MAIN;
        },
        /**
         * Сохраняем новый порядок сортировки
         */
        saveChanges: function() {
            this.saving = true;
            let self = this;
            axios.post('/competitions/' + this.competition.id + '/proposals/save-sort-orders', {
                    manual_free_quota: this.quotas.manual_free_quota,
                    athletes: this.competitionProposalAthletes
                })
                .then(response => {
                    self.saving = false;
                    self.loadWizardData();
                    self.hasChanges = false;
                })
                .catch(error => {
                    console.warn('Ошибка при сохранении', error);
                    self.saving = false;
                })
        },
        /**
         * Загружаем данные для
         */
        loadWizardData: function() {
            axios.get('/competitions/' + this.competitionId + '/proposals/wizard-data')
                .then(response => {
                    if (this.competition == null) {
                        Bus.$emit('changeMenuState', {
                            backLink: '/competitions/' + response.data.competition.id
                        });
                    }
                    this.competition = response.data.competition;
                    this.competitionProposal = response.data.competition_proposal;
                    if (this.competitionProposal) {
                        this.prepareCompetitionProposalAthletes();
                        this.competitionProposal.relays = response.data.relays;
                    }
                    this.athletes = response.data.athletes;
                    this.relays = response.data.relays;
                    this.quotas = response.data.quotas;
                    if (
                        this.competition.is_use_quotas &&
                        this.competition.quotas_alg_type === this.competitionConstants.COMPETITION_ALG_TYPE_WITH_PRE_PROPOSALS &&
                        this.quotas
                    ) {
                        this.totalQuotas = this.quotas.paid_participant_count;
                        this.overrideQuotasByPreQuotas();
                    }
                    this.loaded = true;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных заявка', error);
                });
        },
        /**
         * Для нового алгоритма распределения квот в paid в базе храним общее число, а в manual_free_quota - число по общим основаниям
         * Для js пересчитываем paid_participant_count, free_participant_count, чтобы они соответствовали старому формату
         */
        overrideQuotasByPreQuotas: function () {
            this.quotas.paid_participant_count = this.totalQuotas - this.quotas.manual_free_quota;
            this.quotas.free_participant_count = this.quotas.manual_free_quota;
        },
        /**
         * Изменение числа квот по договору
         */
        onManualFreeQuotaChange: function () {
            this.hasChanges = true;
            let intVal = parseInt(this.quotas.manual_free_quota);
            if (isNaN(intVal)) {
                intVal = 0;
            }
            if (intVal > this.totalQuotas) {
                intVal = this.totalQuotas;
            }
            if (intVal < 0) {
                intVal = 0;
            }
            this.quotas.manual_free_quota = intVal;
            this.overrideQuotasByPreQuotas();
        },
        /**
         * Подготавливаем структуру под отображение атлетов
         */
        prepareCompetitionProposalAthletes: function() {
            let freeAthletes = [];
            let paidAthletes = [];
            for (let index = 0; index < this.competitionProposal.athletes.length; index++) {
               if (this.competitionProposal.athletes[index].participant_type == constants.PARTICIPANT_TYPE_FREE) {
                   freeAthletes.push(this.competitionProposal.athletes[index]);
               } else {
                   paidAthletes.push(this.competitionProposal.athletes[index]);
               }
            }
            Vue.set(this.competitionProposalAthletes, constants.PARTICIPANT_TYPE_FREE, freeAthletes);
            Vue.set(this.competitionProposalAthletes, constants.PARTICIPANT_TYPE_PAID, paidAthletes);
        },
        /**
         * Обработчик события после сохранения выделенных атлетов
         */
        onAthletesSaved: function() {
            this.loadWizardData();
        },
        /**
         * Устанавливаем активную секцию
         * @param code
         */
        setActiveTabSubMenu: function (code) {
            this.activeTab = code;
        },
        /**
         * Проверяем активность секции
         * @param code
         * @returns {boolean}
         */
        isActiveTabSubMenu: function (code) {
            return this.activeTab == code;
        },
        /**
         * Инициализируем компонент выбора спортсменов
         */
        openAthletesSelection: function() {
            this.$refs.competitionProposalAthletesSelection.init();
        },
        /**
         * Редактирование состава спортсменов
         */
        editAthletesSelection: function() {
            this.$refs.competitionProposalAthletesSelection.init(this.competitionProposal, null);
        },
        /**
         * Подтверждение удаления спортсмена
         * @param proposalAthlete
         * @param index
         */
        onClickDeleteAthlete: function(proposalAthlete, index) {
            this.currentProposalAthlete = proposalAthlete;
            this.currentProposalAthleteIndex = index;
            this.$refs.confirmModal.init({
                title: 'Удаление спортсмена',
                confirmText: 'Вы действительно хотите удалить спортсмена ' + proposalAthlete.athlete.full_name + ' из заявки?',
                confirmButtonTitle: 'Удалить',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteAthlete
            });
        },
        /**
         * Удаляем спортсмена из заявки
         * @param item
         * @param index
         */
        deleteAthlete: function(item, index) {
            let self = this;
            axios.delete('/competitions/' + this.competition.id + '/proposals/delete-athlete/' + this.currentProposalAthlete.id)
                .then(response => {
                    let proposalAthletes = self.competitionProposalAthletes[self.currentProposalAthlete.participant_type];
                    proposalAthletes.splice(self.currentProposalAthleteIndex, 1);
                    Vue.set(
                        self.competitionProposalAthletes,
                        self.currentProposalAthlete.participant_type,
                        proposalAthletes
                    );
                })
                .catch(error => {
                    console.warn('Ошибка при удалении спортсмена из заявки', error);
                });
        },
        /**
         * Открываем модалку для работы с дистанциями
         * @param proposalAthlete
         */
        openCompetitionProposalSwimPrograms: function(proposalAthlete) {
            this.$refs.competitionProposalSwimPrograms.init(proposalAthlete);
        },
        /**
         * Обработчик изменения атлета
         * @param proposalAthlete
         */
        onAthleteUpdated: function(proposalAthlete) {
            let proposalAthletes = this.competitionProposalAthletes[proposalAthlete.participant_type];
            let athleteIndex = -1;
            for (let index = 0; index < proposalAthletes.length; index++) {
                if (proposalAthletes[index].id == proposalAthlete.id) {
                    athleteIndex = index;
                }
            }
            if (athleteIndex >= 0) {
                proposalAthletes[athleteIndex] = proposalAthlete;
            }
            Vue.set(this.competitionProposalAthletes, proposalAthlete.participant_type, proposalAthletes);
        },
        /**
         * Инициализируем компонент выбора эстафет
         */
        openRelaysSelection: function() {
            this.$refs.competitionProposalRelaysSelection.init([]);
        },
        /**
         * Редактирование состава спортсменов
         */
        editRelaysSelection: function() {
            let relays = this.competitionProposal ? this.competitionProposal.relays : [];
            this.$refs.competitionProposalRelaysSelection.init(relays);
        },
        /**
         * Получаем имя возрастной группы по id
         * @param ageGroupId
         * @returns {string}
         */
        getAgeGroupNameById: function (ageGroupId) {
            let ageGroups = this.competition.age_groups;
            let needleAgeGroupName = '';
            if (ageGroups.length > 0) {
                ageGroups.forEach(function (ageGroup) {
                    if (ageGroupId == ageGroup.id) {
                        if (ageGroup.age_group) {
                            needleAgeGroupName = ageGroup.age_group.name;
                        } else {
                            needleAgeGroupName = Vue.filter('ageGroupTitle')(ageGroup);
                        }
                    }
                });
            }
            return needleAgeGroupName;
        },
        /**
         * Обработчик события после сохранения выделенных эстафет
         */
        onRelaysSaved: function() {
            this.loadWizardData();
        },
        /**
         * Подтверждение удаления эстафеты
         * @param proposalRelay
         */
        onClickDeleteRelay: function(proposalRelay, index) {
            this.currentProposalRelay = proposalRelay;
            this.currentProposalRelayIndex = index;
            this.$refs.confirmModal.init({
                title: 'Удаление эстафеты',
                confirmText: 'Вы действительно хотите удалить эстафету из заявки?',
                confirmButtonTitle: 'Удалить',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteRelay
            });
        },
        /**
         * Удаляем эстафету из заявки
         */
        deleteRelay: function() {
            let self = this;
            axios.delete('/competitions/' + this.competition.id + '/proposals/delete-relay/' + this.currentProposalRelay.id)
                .then(response => {
                    self.relays.splice(self.currentProposalRelayIndex, 1);
                })
                .catch(error => {
                    console.warn('Ошибка при удалении эстафеты из заявки', error);
                });
        }
    }
});
