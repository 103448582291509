Vue.component('athlete-list', {
    props: ['genderShortLabels', 'athleteRanks', 'expireThreshold'],
    data:function () {
        return {
            ids: []
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'members',
            childCode: 'athletes'
        });
    },
    methods: {
        /**
         * @brief Форматированный вывод пола
         * @param value
         * @param row
         * @returns {*}
         */
        genderTypeFormat: function (value, row) {
            return this.genderShortLabels[value];
        },
        /**
         * @brief Форматированный вывод клуба
         * @param value
         * @param row
         * @returns {*}
         */
        clubFormat: function (value, row) {
            return row.club ? row.club.name : '';
        },
        /**
         * @brief Форматированный вывод допуска врача
         * @param value
         * @param row
         * @returns {*}
         */
        admissionFormat: function (value, row) {
            var now = moment();
            var to = moment(row.admission_to);
            if(row.no_admission) {
                return 'Нет допуска';
            }
            else if (row.admission_to && !row.admission_doc_id) {
                return '<img src="/images/icons/warning-open.svg" class="align-middle mr-1" title="Не прикреплен файл допуска"><span class="text-warning align-middle">Файл отсутствует</span>';
            }
			else if (!row.admission_to) {
				return '<span class="text-danger"> Не указан <br>';
			}
			else if (to.diff(now,'days') < 0) {
                return '<span class="text-danger"> Истёк <br>' + Vue.filter('date')(row.admission_to)+'</span>';
            }
            else if (to.diff(now,'days') < this.expireThreshold) {
                return '<span class="text-danger"> Истекает <br>' + Vue.filter('date')(row.admission_to)+'</span>';
            }
            else {
                return '<img src="/images/icons/check-circle-full.svg">'
            }
        },
        /**
         * @brief Форматированный вывод страхового полиса
         * @param value
         * @param row
         * @returns {*}
         */
        insurancePolicyFormat: function (value, row) {
            var now = moment();
            var to = moment(row.insurance_policy_to);
            if(row.no_insurance_policy) {
                return 'Нет страхового полиса';
            }
            else if (row.insurance_policy_to && !row.insurance_policy_doc_id) {
                return '<img src="/images/icons/warning-open.svg" class="align-middle mr-1" title="Не прикреплен файл полиса"><span class="text-warning align-middle">Файл отсутствует</span>';
            }
            else if (!row.insurance_policy_to) {
                return '<span class="text-danger"> Не указан <br>';
            }
            else if (to.diff(now,'days') < 0) {
                return '<span class="text-danger"> Истёк <br>' + Vue.filter('date')(row.insurance_policy_to)+'</span>';
            }
            else if (to.diff(now,'days') < this.expireThreshold) {
                return '<span class="text-danger"> Истекает <br>' + Vue.filter('date')(row.insurance_policy_to)+'</span>';
            }
            else {
                return '<img src="/images/icons/check-circle-full.svg">'
            }
        },
        /**
         * @brief Форматированный вывод разряда спортсмена
         * @param value
         * @param row
         * @returns {*}
         */
        athleteRankFormat: function (value, row) {
            var now = moment();
            var to = moment(row.athlete_rank_to);
			if(row.no_rank) {
				return 'Без разряда';
			}
			else if (!row.athlete_rank_id || !this.athleteRanks[value]) {
                return '<span class="text-danger"> Не указан </span>';
            }
			else if ((row.athlete_rank_id || row.athlete_rank_doc_number || row.athlete_rank_from) && !row.athlete_rank_doc_id) {
                return '<img src="/images/icons/warning-open.svg" class="align-middle mr-1" title="Не прикреплен файл приказа"><span class="text-warning align-middle">Файл отсутствует</span>';
            }
            else if (to.diff(now,'days') < 0) {
                return '<span class="text-danger"> Истёк <br>' + Vue.filter('date')(row.athlete_rank_to)+'</span>';
            }
            else if (to.diff(now,'days') < this.expireThreshold) {
                return '<span class="text-danger"> Истекает <br>' + Vue.filter('date')(row.athlete_rank_to)+'</span>';
            }
            else {
                return this.athleteRanks[value];
            }
        },
        /**
         * Подтверждение группового удаления
         */
        confirmDelete: function(ids) {
            this.ids = ids;
            this.$refs.confirmModal.init({
                title: 'Перенос спортсменов в архив',
                confirmText: 'Вы действительно хотите перенести в архив выбранных спортсменов?',
                confirmButtonTitle: 'Перенeсти',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteAthletes
            })
        },
        /**
         * Удаление спортсменов
         */
        deleteAthletes: function() {
            this.$refs.athleteGrid.setLoading(true);
            var self = this;
            axios.post('/athletes/batch-delete', {ids: this.ids})
                .then(
                    response => {
                        self.$refs.athleteGrid.reloadData();
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при удалении спортсменов', error);
                    self.$refs.athleteGrid.setLoading(false);
                })
        },
        applyFilters: function (filters) {
            this.$refs.athleteGrid.applyFilters(filters);
        },
    }
});
