module.exports = {
    methods: {
        /**
         * @brief Возвращает дату в формате "1 января"
         * @param dateValue
         * @returns {string}
         */
        getFormattedDate: function (dateValue) {
            dateValue = moment(dateValue);
            let monthIndex = dateValue.month();

            return dateValue.format('DD') + ' ' + this.getMonthNameForDate(monthIndex);
        },
        /**
         * @brief Возвращает дату в формате "1 января - 2 января"
         * @param {string} dateFrom
         * @param {string} dateTo
         * @param {Boolean} showYear
         * @returns {string}
         */
        getFormattedCompetitionDates: function (dateFrom, dateTo, showYear) {
            if (typeof(showYear) === 'undefined') {
                showYear = false;
            }
            dateFrom = moment(dateFrom);
            dateTo = moment(dateTo);

            let monthFromIndex = dateFrom.month();
            let monthToIndex = dateTo.month();

            let dayFrom = dateFrom.format('DD');
            let dayTo = dateTo.format('DD');

            let result = '';
            if (monthFromIndex === monthToIndex) {
                let monthName = this.getMonthNameForDate(monthFromIndex);
                result = dayFrom + ' - ' + dayTo + ' ' + monthName;
            }

            if (!result) {
                let monthFromName = this.getMonthNameForDate(monthFromIndex);
                let monthToName = this.getMonthNameForDate(monthToIndex);
                result = dayFrom + ' ' + monthFromName + ' - ' + dayTo + ' ' + monthToName;
            }

            if (showYear) {
                let year = dateFrom.format('YYYY');
                result += ' ' + year;
            }

            return result;
        },
        /**
         * @brief Возвращает месяц по индексу
         * @param dateValue
         * @returns {string}
         */
        getMonthNameForDate: function (monthIndex) {
            let monthDateNames = [
                'Января',
                'Февраля',
                'Марта',
                'Апреля',
                'Мая',
                'Июня',
                'Июля',
                'Августа',
                'Сентября',
                'Октября',
                'Ноября',
                'Декабря',
            ];

            if (monthIndex >=0 && monthIndex < 12) {
                return monthDateNames[monthIndex];
            }

            return '';
        }
    }
};
