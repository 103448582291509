import { render, staticRenderFns } from "./AgeGenderFilter.vue?vue&type=template&id=55519bfc&scoped=true&"
import script from "./AgeGenderFilter.vue?vue&type=script&lang=js&"
export * from "./AgeGenderFilter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55519bfc",
  null
  
)

export default component.exports