<template>
    <div :class="{'is-invalid': hasError}">
        <input type="text" v-model.lazy="swimTime"
               class="form-control"  placeholder="##:##:##.##"
               :id="inputId"
               :disabled="disabled"
               :class="{'is-invalid': hasError}"
               maxlength="11"
        >
    </div>
</template>

<script>
    export default {
        props: ['value', 'inputId', 'hasError', 'disabled'],
        data: function() {
            return {
                baseMask: '##:##:##.##'
            }
        },
        computed: {
            swimTime: {
                get: function() {
                    return this.prepareMasked(this.value);
                },
                set: function(value) {
                    this.$emit('input', this.parseMasked(value));
                }
            },
            mask: function () {
                return this.baseMask.substr(- (this.prepareMasked(this.value).length));
            },
        },
        methods: {
            prepareMasked: function(value) {
                if (!value) {
                    return '';
                }
                value = Math.round(value / 10);
                if (value < 100) {
                    return value;
                }
                if (value < 6000) {
                    return Math.floor(value / 100) + '.' +
                        this.addZero(value % 100);
                }
                if (value < 360000) {
                    return this.addZero(Math.floor(value / 6000)) + ':' +
                        this.addZero(Math.floor((value % 6000) / 100)) +'.' +
                        this.addZero(value % 100);
                }
                return this.addZero(Math.floor(value / (60 * 6000)))  + ':' +
                    this.addZero(Math.floor((value % (60 * 6000)) / (60 * 100))) + ':' +
                    this.addZero(Math.floor((value % 6000) / 100)) + '.' +
                    this.addZero(value % 100);
            },
            parseMasked: function(masked) {
                let value = masked.replace(/[^0-9]/g, "").substr(0, 8);
                if (!value) {
                    return '';
                }
                let parsed = 0;
                let pow = -1;
                for (let index = 2; index <= 8; index+= 2) {
                    if (value.length >= index -1) {
                        parsed += parseInt(
                            value.substr(-index, Math.min(
                                2, Math.abs(value.length - index + 2)
                                )
                            )
                        ) * (pow >= 1 ? Math.pow(60, pow) : 1) * (pow >= 0 ? 100 : 1 );
                        pow++;
                    } else {
                        break;
                    }
                }

                return parsed * 10;
            },
            /**
             * Добавление предстоящего нуля в вывод двузначных чисел
             * @param value
             * @returns {string}
             */
            addZero: function (value) {
                if (value < 10) {
                    return '0' + value;
                }
                return value.toString();
            }
        }
    }
</script>
