Vue.component('seminar-payment-view', {
    props: ['id'],
    data: function () {
        return {
            processing: false,
            refunding: false,
            data: {},
            constants: require('./constants')
        };
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'seminars',
            mode: 'detail',
            backLink: '/seminars/payments',
            backTitle: 'К списку платежей',
            title: 'Платежи'
        });

        this.loadData();
    },
    computed: {
        /**
         * Иконка статуса оплаты
         * @returns {*}
         */
        statusSrc: function() {
            return this.data.status ? this.constants.STATUS_ICONS[this.data.status] : '';
        }
    },
    methods: {
        /**
         * Загрузка данных
         */
        loadData: function() {
            this.processing = true;
            axios.get('/seminars/payments/fetch/' + this.id)
                .then(response => {
                    this.data = response.data;
                    this.processing = false;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных платежа', error);
                    this.processing = false;
                });
        },
        /**
         * Вызов подтверждения возврата
         */
        confirmRefund: function() {
            this.$refs.confirmModal.init({
                title: 'Возвраст платежа',
                confirmText: 'Вы действительно хотите сделать возврат платежа?',
                confirmButtonTitle: 'Да',
                confirmButtonClass: 'btn-primary',
                confirmCallback: this.refund
            })
        },
        /**
         * @brief Сделать возврат
         */
        refund: function() {
            this.refunding = true;
            axios.post('/seminars/payments/' + this.id + '/refund')
                .then(() => {
                    Bus.$emit('pushFlashAlert', {
                        type: 'success',
                        message: 'Запрос на возврат платежа успешно отправлен'
                    });
                    this.refunding = false;
                    this.data.status = this.constants.STATUS_REFUNDED;
                })
                .catch(error => {
                    this.refunding = false;
                    console.warn('Ошибка при возрате платежа', error);
                });
        }
    }
});
