import constants from './constants';

Vue.component('competition-proposal-athlete-wizard', {
    mixins: [require('../mixins/dateFormatter'), require('../mixins/athleteCheckers')],
    props: [
        'competitionId',
        'participantTypes',
        'athleteRanks',
        'genderLabels',
        'courses',
    ],
    data: function () {
        return {
            competition: null,
            competitionProposal: null,
            athlete: null,
            loaded: false,
            constants: constants,
            selectedSwimPrograms: [],
            isAllSwimPrograms: 0,
            swimProgramsParams: {},
            processing: false,
            deleting: false,
            participantType: constants.PARTICIPANT_TYPE_FREE,//По умолчанию - бюджет
            existsSwimTimes: []
        }
    },
    mounted: function () {
        Bus.$emit('changeMenuState', {
            itemCode: 'competitions',
            mode: 'detail',
            backLink: '/competitions/' + this.competitionId,
            backTitle: 'К профайлу соревнования',
            title: 'Заявка на участие в соревнованиях'
        });
        this.loadWizardData();

    },
    computed: {
        /**
         * Разряд спортсмена
         */
        athleteRank: function () {
            if (this.athlete.athlete_rank_id) {
                let filteredRanks = this.athleteRanks.filter(item => item.id == this.athlete.athlete_rank_id);
                if (filteredRanks.length > 0) {
                    return filteredRanks[0].name;
                }
            }
            return 'Без разряда';
        },
        /**
         * Доступные виды соревнований
         */
        availableSwimPrograms: function() {
            let athlete = this.athlete;
            let ageGroupIds = this.availableAgeGroupIds(athlete, this.participantType);
            if (ageGroupIds.length == 0) {
                return [];
            }
            return this.competition.swim_programs.filter(swimProgram => {
                if (swimProgram.swim_program.relay_count > 1) {
                    return false;
                }
                if (swimProgram.gender != athlete.gender) {
                    return false;
                }
                if (swimProgram.is_all_age_groups == 1) {
                    return true;
                }
                return swimProgram.age_groups
                    .filter(ageGroupId => ageGroupIds.indexOf(ageGroupId) !== -1)
                    .length > 0;
            })
        },
        /**
         * Попадает ли атлет в лист ожидания
         * @returns {boolean}
         */
        isAthleteInWaitingList: function() {
            return this.competitionProposal && this.competitionProposal.athletes &&
                this.competitionProposal.athletes.length > 0 && this.competitionProposal.athletes[0].list_type;
        },
        /**
         * Подана ли уже заявка на соревнование
         * @returns {boolean}
         */
        isApplied: function () {
            return !!this.competitionProposal;
        },
    },
    methods: {
        /**
         * Проверяем может ли подавать заявку по типу участника
         * @param participantType
         */
        canBeParticipant: function (participantType) {
            if (this.competition.is_high_level_rank) {
                return true;
            }
            if (!this.athlete) {
                return false;
            }
            return this.availableAgeGroupIds(this.athlete, participantType).length > 0;
        },
        /**
         * Сохраняем настройки
         */
        save: function() {
            this.processing = true;
            let saveData = {
                participant_type: this.participantType,
                swim_programs: []
            };
            if (this.competitionProposal && this.competitionProposal.athletes && this.competitionProposal.athletes.length > 0) {
                saveData.id = this.competitionProposal.athletes[0].id;
            }
            for (let index = 0; index < this.selectedSwimPrograms.length; index++) {
                saveData.swim_programs.push(this.prepareAthleteSwimProgramParam(this.selectedSwimPrograms[index]));
            }
            var self = this;
            axios.post('/competitions/' + this.competition.id + '/proposals/save-athlete-proposal', saveData)
                .then(response => {
                    self.competitionProposal = response.data;
                    self.processing = false;
                    Bus.$emit('pushFlashAlert', {
                        type: 'success',
                        message: 'Заявка успешно сохранена'
                    })
                })
                .catch(error => {
                    self.processing = false;
                    console.warn('Ошибка при сохраниении программ для спортсмена', error);
                });
        },

        /**
         * Подтверждение удаления заявки на участие
         */
        confirmDeleteProposal: function() {
            this.$refs.confirmModal.init({
                title: 'Удаление заявки на участие в соревновании',
                confirmText: 'Вы действительно хотите удалить заявку на участие в данном соревновании?',
                confirmButtonTitle: 'Подтверждаю',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteProposal
            })
        },
        /**
         * Удаление заявки
         */
        deleteProposal: function() {
            var self = this;
            this.deleting = true;
            axios.delete('/competitions/' + this.competition.id + '/proposal')
                .then(
                    response => {
                        Bus.$emit('pushFlashAlert', {
                            type: 'success',
                            message: 'Заявка успешно удалена'
                        });
                        Vue.set(self, 'competitionProposal', null);
                        self.deleting = false;
                        location.href = '/competitions/' + this.competition.id;
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при удалении заявки', error);
                    self.deleting = false;
                })
        },
        /**
         * Загружаем данные для заявки
         */
        loadWizardData: function () {
            axios.get('/competitions/' + this.competitionId + '/proposals/wizard-data')
                .then(response => {
                    this.competition = response.data.competition;
                    this.competitionProposal = response.data.competition_proposal;
                    this.athlete = response.data.athlete;

                    //Устанавливаем значения формы с заявочными временами для самохода
                    this.afterAthleteLoaded();

                    this.loaded = true;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных заявки', error);
                });
        },

        /**
         * Устанавливаем в данные заявки - заявку от единственного самохода, если данные найдены
         */
        afterAthleteLoaded: function() {
            if (!this.competitionProposal || !this.competitionProposal.athletes || this.competitionProposal.athletes.length === 0) {
                //Если не нашли ни 1 заявки от спортсмена - пытаемся загрузить существующие заявочные времена
                this.participantType = this.canBeParticipant(constants.PARTICIPANT_TYPE_FREE) ? constants.PARTICIPANT_TYPE_FREE : constants.PARTICIPANT_TYPE_PAID;
                this.loadAthleteExistsSwimTimes();
                return false;
            }
            let athleteProposal = this.competitionProposal.athletes[0];
            this.selectedSwimPrograms = [];
            this.swimProgramsParams = {};
            for (let index = 0; index < athleteProposal.swim_programs.length; index++) {
                this.selectedSwimPrograms.push(athleteProposal.swim_programs[index].swim_program_id);
                Vue.set(
                    this.swimProgramsParams,
                    athleteProposal.swim_programs[index].swim_program_id,
                    athleteProposal.swim_programs[index]
                );
            }
            this.isAllSwimPrograms = this.selectedSwimPrograms.length === this.availableSwimPrograms.length ? 1 : 0;
            this.participantType = athleteProposal.participant_type;
        },
        /**
         * Подгружаем Заявочные времена из истории самохода
         * @param athlete
         */
        loadAthleteExistsSwimTimes: function () {
            let self = this;
            axios.get('/competitions/proposals/athlete-exists-swim-times/' + this.athlete.id)
                .then(response => {
                    this.existsSwimTimes = response.data.filter(item => item.course == this.competition.course);
                    self.afterExistsSwimTimeLoaded();
                })
                .catch(error => {});
        },
        /**
         * Проставляем заявочные времена после загрузки
         */
        afterExistsSwimTimeLoaded: function () {
            if (!this.existsSwimTimes) {
                return;
            }
            for (let index = 0; index < this.availableSwimPrograms.length; index++) {
                let existsSwimTimes = this.existsSwimTimes.filter(
                    item => item.swim_program_id == this.availableSwimPrograms[index].swim_program_id
                );
                if (existsSwimTimes.length > 0) {
                    let params = this.prepareAthleteSwimProgramParam(this.availableSwimPrograms[index].id);
                    if (!params.swim_time) {
                        Vue.set(this.swimProgramsParams, this.availableSwimPrograms[index].id, {
                            swim_program_id: this.availableSwimPrograms[index].id,
                            swim_time: existsSwimTimes[0].swim_time,
                            course: existsSwimTimes[0].course,
                            event_date: existsSwimTimes[0].event_date,
                            event_place: existsSwimTimes[0].event_place,
                        });
                    }
                }
            }
        },

        /**
         * Обработчик изменения чекбокса вида программы
         * @param event
         */
        onChangeSwimProgramStatus: function (event) {
            this.isAllSwimPrograms = this.selectedSwimPrograms.length == this.availableSwimPrograms.length ? 1 : 0;
        },
        /**
         * Обработчик включения всех дистанций для спортсмена
         */
        onChangeAllSwimPrograms: function () {
            if (this.isAllSwimPrograms) {
                for (let index = 0; index < this.availableSwimPrograms.length; index++) {
                    if (this.selectedSwimPrograms.indexOf(this.availableSwimPrograms[index].id) === -1) {
                        this.selectedSwimPrograms.push(this.availableSwimPrograms[index].id);
                    }
                }
            } else {
                this.selectedSwimPrograms = [];
            }
        },
        /**
         * Получение заявочного времени для программы
         * @param swimProgram
         */
        getAthleteSwimTime: function (swimProgram) {
            let params = this.prepareAthleteSwimProgramParam(swimProgram.id);
            return params.swim_time ? params.swim_time : '';
        },
        /**
         * Проставление заявочного времени для программы
         * @param swimProgram
         */
        setAthleteSwimTime: function (swimProgram, value) {
            let params = this.prepareAthleteSwimProgramParam(swimProgram.id);
            params.swim_time = value;
            Vue.set(this.swimProgramsParams, swimProgram.id, params);
        },
        /**
         * Получение типа бассейна для программы
         * @param swimProgram
         */
        getAthleteCourse: function (swimProgram) {
            let params = this.prepareAthleteSwimProgramParam(swimProgram.id);
            return params.course;
        },
        /**
         * Проставление типа бассейна для программы
         * @param swimProgram
         */
        setAthleteCourse: function (swimProgram, value) {
            let params = this.prepareAthleteSwimProgramParam(swimProgram.id);
            params.course = value;
            Vue.set(this.swimProgramsParams, swimProgram.id, params);
        },
        /**
         * Получение даты достижения заявочного времени для программы
         * @param swimProgram
         */
        getAthleteEventDate: function (swimProgram) {
            let params = this.prepareAthleteSwimProgramParam(swimProgram.id);
            return params.event_date ? params.event_date : '';
        },
        /**
         * Проставление даты достижения для программы
         * @param swimProgram
         */
        setAthleteEventDate: function (swimProgram, value) {
            let params = this.prepareAthleteSwimProgramParam(swimProgram.id);
            params.event_date = value;
            Vue.set(this.swimProgramsParams, swimProgram.id, params);
        },
        /**
         * Получение места достижения заявочного времени для программы
         * @param swimProgram
         */
        getAthleteEventPlace: function (swimProgram) {
            let params = this.prepareAthleteSwimProgramParam(swimProgram.id);
            return params.event_place;
        },
        /**
         * Проставление места достижения для программы
         * @param swimProgram
         */
        setAthleteEventPlace: function (swimProgram, value) {
            let params = this.prepareAthleteSwimProgramParam(swimProgram.id);
            params.event_place = value;
            Vue.set(this.swimProgramsParams, swimProgram.id, params);
        },
        /**
         * Получаем параметры для вида соревнования
         * @param swimProgramId
         * @returns {*}
         */
        prepareAthleteSwimProgramParam: function(swimProgramId) {
            if (!this.swimProgramsParams.hasOwnProperty(swimProgramId)) {
                Vue.set(this.swimProgramsParams, swimProgramId, {
                    swim_program_id: swimProgramId,
                    swim_time: '',
                    course: '',
                    event_date: '',
                    event_place: ''
                });
            }
            return this.swimProgramsParams[swimProgramId];
        }
    }
});
