Vue.component('age-group-list', {
    props: ['genderShortLabels'],
    data: function () {
        return {
            currentRow: null
        }
    },
    mounted() {
        Bus.$emit('changeMenuState', {
            itemCode: 'dictionaries',
            childCode: 'age-groups'
        });
    },
    methods:{
        /**
         * @brief Форматированный вывод пола
         * @param value
         * @param row
         * @returns {*}
         */
        genderTypeFormat: function (value, row) {
            return this.genderShortLabels[value];
        },
        /**
         * Подтверждение удаления возрастной группы
         */
        confirmDelete: function(row) {
            this.currentRow = row;
            this.$refs.confirmModal.init({
                title: 'Перенос возрастной группы в архив',
                confirmText: 'Вы действительно хотите перенести в архив данную возрастную группу?',
                confirmButtonTitle: 'Перенeсти',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteAgeGroup
            })
        },
        /**
         * Удаление возрастной группы
         */
        deleteAgeGroup: function() {
            this.$refs.ageGroupGrid.setLoading(true);
            var self = this;
            axios.delete('/dictionaries/age-groups/' + this.currentRow.id)
                .then(
                    response => {
                        self.$refs.ageGroupGrid.reloadData();
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при удалении возрастной группы', error);
                    self.$refs.ageGroupGrid.setLoading(false);
                })
        },
    }
});
