Vue.component('user-password-change', {
	data: function() {
		return {
			form: new TrustyForm({
				old_password: '',
				password: '',
				password_confirmation: ''
			}),

		}
	},
	mounted: function() {
		Bus.$emit('changeMenuState', {
			itemCode: 'settings',
			childCode: 'settings-password'
		});
	},
	methods: {
		/**
		 * Отправка данных на сервер
		 */
		send: function () {
			Trusty.post('/settings/change-password', this.form)
				.then(response => {
					Bus.$emit('pushFlashAlert', {
						type: 'success',
						message: 'Ваш пароль успешно изменен'
					});
					this.form.password = '';
					this.form.password_confirmation = '';
					this.form.old_password = '';
				})
				.catch(error => {
					console.warn('Ошибка при изменении пароля', error);
				})
		}
	}
});
