Vue.component('athlete-application-list', {
    props: ['genderShortLabels'],
    data: function() {
        return {
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'members',
            childCode: 'athlete-applications'
        });
    },
    methods: {
        /**
         * @brief Форматированный вывод пола
         * @param value
         * @param row
         * @returns {*}
         */
        genderTypeFormat: function (value, row) {
            return this.genderShortLabels[value];
        },
        /**
         * Обработка действия отклонения заявки
         * @param row
         */
        confirmApplicationDecline: function(row) {
            var self = this;
            this.$refs.declineModal.init(row, function () {
                Bus.$emit('reloadMenu');
                self.$refs.athleteApplicationGrid.reloadData();
            });
        },
        /**
         * Обработчик вызова после успешного одобрения заявки
         * @param row
         */
        onApproveSuccess: function(row) {
            Bus.$emit('reloadMenu');
            Bus.$emit('pushFlashAlert', {
                type: 'success',
                message: 'Заявка успешно одобрена'
            })
        }
    }
});
