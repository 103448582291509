Vue.component('club-application-view', {
	data: function() {
		return {
			data: {},
			approving: false,
		}
	},
	props: ['id'],
	mounted: function() {
		Bus.$emit('changeMenuState', {
			itemCode: 'organizations',
			mode: 'detail',
			backLink: '/club-applications',
			backTitle: 'К списку заявок',
			title: 'Заявка на регистрацию клуба'
		});
		this.loadData();
	},
	methods: {
		/**
		 * Загрузка данных заявки клуба
		 */
		loadData: function() {
			axios.get('/club-applications/fetch/' + this.id)
				.then(response => {
					this.data = response.data;
				})
				.catch(error => {
					console.warn('Ошибка при загрузке заявки клуба', error);
				})
		},
		/**
		 * Одобряет заявку
		 */
		approveApplication: function () {
			this.approving = true;
			axios.post('/club-applications/approve', {
				id: this.data.id,
				from_view: true
			}).then(response => {
				location.href = '/club-applications';
				this.approving = false;
			}).catch(error => {
				console.warn('Ошибка при одобрении заявки', error);
			})
		},
		/**
		 * Отклоняем заявку
		 */
		declineApplication: function() {
			this.$refs.declineModal.init(this.data, function () {
				location.href = '/club-applications';
			});
		},
        /**
         * Открываем модальное окно с существующими клубами
         */
        openExistsClubsModal: function() {
            this.$refs.existsClubsModal.init(this.data);
        }
	}
});
