Vue.component('competition-tab-credential-commission', {
    props: [
        'id',
        'competition',
        'athleteRanks'
    ],
    data:function () {
        return {
            constants: require('../../competitions/constants'),
            processing: false,
            teams: [],
            mode: 'teams',
            allowedAthletesCount: 0,
            partiallyAllowedAthletesCount: 0,
            allAthletesCount: 0,
        }
    },
    mounted: function() {
        this.loadData();
    },
    computed: {
        getTeamsHeader: function () {
            if (this.competition.need_general_teams) {
                if (this.competition.competition_rank === this.constants.COMPETITION_RANK_MUNICIPALITY) {
                    return 'Населенные пункты';
                }
                return 'Муниципальные образования';
            }
            return 'Клубы';
        },
        /**
         * Экспорт протокола
         * @returns {string}
         */
        getProtocolExportLink: function () {
            return '/competitions/export-credential-commission/' + this.id;
        },
        /**
         * Экспорт протокола
         * @returns {string}
         */
        getProtocolAthleteRankExportLink: function () {
            return '/competitions/export-credential-commission-athlete-rank/' + this.id;
        },
        /**
         * Экспорт спортсменов в MeetManager
         * @returns {string}
         */
        getMeetManagerExportLink: function () {
            return '/competitions/export-to-lenex/' + this.id;
        },
        /**
         * Экспорт условно-допущенных в pdf
         * @returns {string}
         */
        getPartiallyAllowedAthletesExportLink: function () {
            return '/reports/competitions/' + this.id + '/proposals/partially-allowed-athletes';
        },
        /**
         * Экспорт неоплаченных участников в pdf
         * @returns {string}
         */
        getNotPaidAthletesExportLink: function () {
            return '/reports/competitions/' + this.id + '/proposals/not-paid-athletes';
        },
        /**
         * Список id существующих команд
         * @returns {[]}
         */
        existingTeamIds: function () {
            let result = [];
            for (let i = 0; i < this.teams.length; i++) {
                if (!this.competition.need_general_teams) {
                    result.push(this.teams[i].club_id);
                } else {
                    if (this.competition.competition_rank === this.constants.COMPETITION_RANK_REGION) {
                        result.push(this.teams[i].municipality_id)
                    } else {
                        result.push(this.teams[i].city_id)
                    }
                }

            }
            return result;
        },
    },
    methods: {
        /**
         * Загружаем список команд (клубов для обычной заявки, муниципалитетов или городов в случае сборных)
         * @param bool force
         */
        loadData: function (force = false) {
            if (force) {
                this.teams = [];
            }
            if (this.teams.length === 0) {
                this.processing = true;
                let self = this;
                axios.get('/competition/' + this.id +  '/proposal-teams')
                    .then(response => {
                        self.teams = response.data;
                        self.processing = false;
                        self.calculateCountsOfAthletes();
                    })
                    .catch(error => {
                        console.warn('Ошибка при загрузке данных соревнования', error);
                        self.processing = false;
                    });
            }
        },
        /**
         * Открыть страниу списка спортсменов
         * @param id идентификатор команды (club_id или general_team_id)
         * @param name имя команды
         * @param has_protocol составлен ли протокол на команду
         */
        openAthletes: function (id, name, has_protocol) {
            this.$refs.credentialCommissionAthletesList.init(id, name, has_protocol);
        },
        /**
         * Рассчитать суммы спортсменов по определенным статусам
         */
        calculateCountsOfAthletes: function () {
            let self = this;
            this.allAthletesCount = 0;
            this.allowedAthletesCount = 0;
            this.partiallyAllowedAthletesCount = 0;
            if (this.teams && this.teams.length > 0) {
                this.teams.forEach(function (team) {
                    self.allAthletesCount += team.all_athletes_count;
                    self.allowedAthletesCount += team.allowed_athletes_count;
                    self.partiallyAllowedAthletesCount += team.partially_allowed_athletes_count;
                });
            }
        },
        /**
         * Открываем модалку добавления клуба к комиссии по допуску
         */
        openAddClubModal: function () {
            this.$refs.credentialCommissionAddClubModal.init({
                competition: this.competition,
                existingTeamIds: this.existingTeamIds
            });
        },
    }
});
