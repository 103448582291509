Vue.component('competition-add-result-modal', {
    props: ['athleteRanks'],
    mounted: function() {
        Bus.$on('result-added', this.onResultAdded);
    },
    data: function() {
        return {
            competition: null,
            competitionSwimPrograms: [],
            athleteInfo: null,
            form: new TrustyForm({
            }),
        }
    },
    computed: {
        /**
         * Получить список competitionSwimProgramId на которые заявлен атлет. Предварительные раунды
         */
        getAthleteCompetitionSwimProgramIds: function () {
            if (!this.athleteInfo) {
                return [];
            }
            let result = [];
            for (let i = 0; i < this.athleteInfo.proposal.swim_programs.length; i++) {
                result.push(this.athleteInfo.proposal.swim_programs[i].swim_program_id);
            }
            return result;
        },
        /**
         * Получить список competitionSwimProgramIds, со всеми возвожными раундами, соответствующие заявке спортсмена на предварительные раунды
         */
        getAthleteCompetitionSwimProgramsWithRounds: function () {
            let result = [];
            let preRoundCspIds = this.getAthleteCompetitionSwimProgramIds;
            for (let i = 0; i < preRoundCspIds.length; i++) {
                result = result.concat(this.getCompetitionSwimProgramsByPreRoundCompetitionSwimProgramId(preRoundCspIds[i]));
            }
            return result;
        },
        /**
         * Поиск заявок на дистанции, для которых нет соответствующих результатов в информации об атлете
         * @returns {[]}
         */
        getAthleteLostCompetitionSwimPrograms: function () {
            let proposalsSwimPrograms = this.getAthleteCompetitionSwimProgramsWithRounds;
            let result = [];
            for (let i = 0; i < proposalsSwimPrograms.length; i++) {
                let athleteResult = this.athleteInfo.results.find(item => item.competition_swim_program_id === proposalsSwimPrograms[i].id);
                if (typeof(athleteResult) === 'undefined') {
                    result.push(proposalsSwimPrograms[i]);
                }
            }
            return result;
        },
        /**
         * Есть ли виды программ, для которых еще не записан результат в базу
         * @returns {boolean}
         */
        canAddResult: function () {
            return this.getAthleteLostCompetitionSwimPrograms.length > 0;
        },
    },
    methods: {
        /**
         * Получить список плавательных программ соревнования со всеми раундами по id плавательной программы предварительного раунда
         * @param {int} competitionSwimProgramId
         * @returns {[]}
         */
        getCompetitionSwimProgramsByPreRoundCompetitionSwimProgramId(competitionSwimProgramId) {
            let competitionSwimProgram = this.getCompetitionSwimProgram(competitionSwimProgramId);
            if (!competitionSwimProgram) {
                return [];
            }
            let filtered = this.competitionSwimPrograms.filter(function(item) {
                return item.swim_program_id === competitionSwimProgram.swim_program_id &&
                    item.gender === competitionSwimProgram.gender &&
                    item.is_all_age_groups === competitionSwimProgram.is_all_age_groups;
            });
            let result = [];
            for (let i = 0; i < filtered.length; i++) {
                result.push(filtered[i]);
            }
            return result;
        },
        /**
         * Разряд спортсмена
         */
        athleteRank: function (athlete) {
            if (athlete.athlete_rank_id) {
                let filteredRanks = this.athleteRanks.filter(item => item.id == athlete.athlete_rank_id);
                if (filteredRanks.length > 0) {
                    return filteredRanks[0].name;
                }
            }
            return 'Без разряда';
        },
        /**
         * Получить плавательную программу по id
         * @param {int} swimProgramId
         * @returns {null|Object}
         */
        getCompetitionSwimProgram: function (swimProgramId) {
            if (swimProgramId) {
                let swimProgram = this.competitionSwimPrograms.find(item => item.id == swimProgramId);
                return typeof(swimProgram) !== 'undefined' ? swimProgram : null;
            }
            return null;
        },
        /**
         * Название плавательной программы
         * @param swimProgramId
         * @returns {*}
         */
        getSwimProgramName: function (swimProgramId) {
            let competitionSwimProgram = this.getCompetitionSwimProgram(swimProgramId);
            let swimProgram = competitionSwimProgram ? competitionSwimProgram.swim_program : null;
            return swimProgram ? swimProgram.name : '';
        },
        /**
         * Название раунда плавательной программы
         * @param swimProgramId
         * @returns {*}
         */
        getSwimProgramRound: function (swimProgramId) {
            let competitionSwimProgram = this.getCompetitionSwimProgram(swimProgramId);
            return competitionSwimProgram ? competitionSwimProgram.swim_program_round_label : '';
        },
        /**
         * Получить заявочное время по competitionSwimProgramId
         * @param {int} competitionSwimProgramId
         */
        getProposalSwimTime: function (competitionSwimProgramId) {
            // Ищем все возможные competitionSwimProgram раундов, соответствующие виду программы
            let competitionSwimPrograms = this.getCompetitionSwimProgramsByPreRoundCompetitionSwimProgramId(competitionSwimProgramId);
            let swimProgramsInfo = this.athleteInfo.proposal.swim_programs;

            // Находим ту заяку, которая соответствует хотя бы 1 плавательной программе, и у которой есть заявленное время
            let swimProgramInfo = swimProgramsInfo.find(function(swimProgramProposal) {
                let proposalCompetitionSwimProgramId = swimProgramProposal.swim_program_id;
                let existingCompetitionSwimProgram = competitionSwimPrograms.find(item => item.id === proposalCompetitionSwimProgramId);
                return typeof(existingCompetitionSwimProgram) !== 'undefined';
            });
            if (typeof(swimProgramInfo) === 'undefined') {
                return '';
            }
            return swimProgramInfo.swim_time;
        },
        /**
         * Установка времени заявки
         * @param value
         * @param result
         * @param index
         */
        setProposalSwimTime: function (value, result, index) {
            // do nothing
        },
        /**
         * Установка времени
         * @param result
         * @returns {string}
         */
        getResultSwimTime: function (result) {
            return result.swim_time;
        },
        /**
         * Установка времени результата
         * @param value
         * @param swim_program_id
         */
        setResultSwimTime: function (value, swim_program_id) {
            for (let i = 0; i < this.athleteInfo.results.length; i++) {
                if (this.athleteInfo.results[i].competition_swim_program_id == swim_program_id) {
                    this.athleteInfo.results[i].swim_time = value;
                }
            }
        },
        /**
         * Добавить новый результат в форму результатов
         */
        addResult: function () {
            this.$parent.$refs.competitionResultFormModal.init({
                swimPrograms: this.competitionSwimPrograms,
                athleteInfo: this.athleteInfo,
                competition: this.competition,
            });
        },
        /**
         * Удалить результат по индексу
         * @param index
         */
        removeResult: function (index) {
            this.athleteInfo.results.splice(index, 1);
        },
        /**
         * Данные формы для правки результатов спортсмена
         */
        getData: function () {
            let results = [];
            for (let i = 0; i < this.athleteInfo.results.length; i++) {
                let competitionSwimProgramId = this.athleteInfo.results[i].competition_swim_program_id;
                let competitionSwimProgram = this.getCompetitionSwimProgram(competitionSwimProgramId);
                let result = {
                    competition_swim_program_id: this.athleteInfo.results[i].competition_swim_program_id,
                    swim_program_id: competitionSwimProgram ? competitionSwimProgram.swim_program_id : null,
                    swim_time: this.athleteInfo.results[i].swim_time,
                    athlete_id: this.athleteInfo.athlete.id,
                    club_id: this.athleteInfo.athlete.club_id,
                };
                if (this.athleteInfo.results[i].places) {
                    result.places = this.athleteInfo.results[i].places;
                }
                results.push(result);
            }

            return {
                athlete_id: this.athleteInfo.athlete.id,
                results: results
            };
        },
        /**
         * Отправка формы на сервер
         */
        save: function () {
            let data = this.getData();
            axios.post('/competitions/save-results-data/' + this.competition.id, data)
                .then(response => {
                    let results = response.data.results;
                    Bus.$emit('results-updated', results, data.athlete_id);
                    //отправить событие сохранения в список атллетов с новым списком результатов
                    $('.modal-competition-add-result').modal('hide');
                })
                .catch(error => {
                    console.warn('Ошибка при сохранении результатов', error);
                })
        },
        /**
         * Инициализация модалки клонирования
         * @param {Object} data
         */
        init: function (data) {
            this.competition = data.competition;
            this.competitionSwimPrograms = data.swimPrograms;
            let copy = JSON.parse(JSON.stringify(data.athleteInfo));
            this.athleteInfo = copy;
            $('.modal-competition-add-result').modal('show');
        },
        /**
         * Обработка события добавления результата
         * @param {Object} result
         */
        onResultAdded: function (result) {
            this.athleteInfo.results.push(result);
        }
    },
    beforeDestroy() {
        Bus.$off('result-added', this.onResultAdded);
    }
});
