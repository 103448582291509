<template>
    <div>
        <div class="row competition-item mt-3 ml-2 pb-2" @click="toCompetitionViewPage()">
            <div class="col-md-2">
                <span class="competition-calendar-item-header semi-bold">{{getFormattedCompetitionDates(this.competition.date_from, this.competition.date_to)}}</span>
                <span v-if="competition.city">{{competition.city.name}},</span><br />
                <span v-if="competition.city && competition.city.region">{{competition.city.region.name}}</span>
            </div>
            <div class="col-md-7">
                <span class="competition-calendar-item-header semi-bold">{{competition.name}} </span>
                <div class="competition-calendar-item-ranks">
                    <span
                        v-for="(competitionAgeGroup,index) in competition.age_groups"
                        v-bind:key="competitionAgeGroup.id"
                    >{{getAgeGroupLabel(competitionAgeGroup)}}</span>
                </div>
                <div class="competition-calendar-item-docs mt-2 row">
                    <div class="detail-value col-md-3">
                        <span v-if="competition.regulation_doc_id || competition.regulation_doc_link">
                            <a :href="competition.regulation_doc_link" class="btn-link btn-block" target="_blank">
                                <img src="/images/icons/icon-document.svg" class="mr-1 mb-1">
                                <strong>Регламент</strong>
                            </a>
                        </span>
                    </div>
                    <div class="detail-value col-md-5">
                        <span v-if="isCompetitionStarted()">
                            <a :href="'/competitions/' + competition.id + '?section=results'" class="btn-link btn-block">
                                <img src="/images/icons/blue-list.svg" class="mr-1 mb-1">
                                <strong>Результаты соревнований</strong>
                            </a>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="text-right" v-if="isCredentialCommissionUser">
                    <a v-if="isCompetitionCompleted(competition)"
                       :href="'/competitions/' + competition.id"
                       class="btn btn-light"
                       :dusk="'holdCommission' + competition.id"
                    >Просмотр результатов комиссии</a>
                    <a v-else
                       :href="'/competitions/' + competition.id"
                       class="btn btn-primary"
                       :dusk="'holdCommission' + competition.id"
                    >Провести комиссию по допуску</a>
                </div>

                <div v-if="!isApplied() && competition.can_apply_proposal && checkProposalDate(competition)">
                    <a :href="'/competitions/' + competition.id + '/proposals/wizard'" class="btn btn-primary" dusk="apply">Подать заявку</a>
                </div>

                <div v-if="!isApplied() && !competition.can_apply_proposal && checkProposalDate(competition) && (isClubUser || isAthleteUser)">
                    <button @click.prevent.stop="showProposalApplyRestrictions" class="btn btn-light">Подать заявку</button>
                </div>

                <div v-if="isApplied() && competition.can_apply_proposal">
                    <span class="semi-bold"><img src="/images/icons/check-circle-full.svg" class="mr-1 mb-1"> Заявка подана</span>
                    <a :href="'/competitions/' + competition.id + '/proposals/wizard'" class="btn btn-link">Посмотреть заявкy</a>
                </div>
            </div>
        </div>
        <hr v-if="!isLast" />
    </div>

</template>

<script>
    export default {
        name: "CompetitionItem",
        props: ['competition', 'isLast', 'proposals'],
        mixins: [require('./mixins/dateFormatter'), require('../common/mixins/user')],
        methods: {
            /**
             * Проверяем сооответсвие дат подачи заявки
             */
            checkProposalDate(competition) {
                return moment.now() >= moment(competition.proposal_from).startOf('day')
                    &&  moment.now() <= moment(competition.proposal_to).endOf('day')
            },
            /**
             * Подана ли уже заявка на соревнование
             * @returns {boolean}
             */
            isApplied: function () {
                let self = this;
                let isAvailable = this.proposals.find(function (element) {
                    return element.competition_id == self.competition.id;
                });

                return typeof(isAvailable) !== 'undefined';
            },
            /**
             * Формируем наименование возрастной группы соревнования
             * @param competitionAgeGroup
             * @returns {*}
             */
            getAgeGroupLabel: function(competitionAgeGroup) {
                if (competitionAgeGroup.age_group) {
                    return competitionAgeGroup.age_group.name;
                }
                return Vue.filter('ageGroupTitle')(competitionAgeGroup);
            },
            /**
             * Перейти на страницу редактирования соревнования
             */
            toCompetitionViewPage: function () {
                location.href = '/competitions/' + this.competition.id;
            },
            /**
             * Началось ли соревнование
             */
            isCompetitionStarted: function () {
               let currentDate = new Date();
               return currentDate >= new Date(this.competition.date_from)
            },
            /**
             * Закончилось ли соревнование
             */
            isCompetitionCompleted: function () {
                let currentDate = new Date();
                return currentDate > new Date(this.competition.date_to);
            },
            /**
             * Показываем список ограничений для участия в соревновании
             */
            showProposalApplyRestrictions: function() {
                this.$parent.$parent.$refs.competitionProposalApplyRestrictions.init(this.competition);
            }
        }
    }
</script>

<style scoped>

</style>
