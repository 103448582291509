import proposalConstants from './../proposals/constants';

Vue.component('competition-tab-all-pre-proposals', {
    props: ['genderShortLabels'],
    mixins: [require('../../common/mixins/user')],
    mounted: function () {
        Bus.$on('reassign-quotas', this.recalculateQuotas);
    },
    data: function() {
        return {
            data: {
                pre_proposals: [],
                quotas: [],
                clubs: [],
            },
            groups: {
                moreQuotas: [],
                lessQuotas: [],
                noCompete: [],
                noPreProposal: [],
            },
            proposalConstants: proposalConstants,
            defaultTotalQuotas: 0, // сколько всего квот было распределено при дефолтном распределении по всем клубам. Нужно для процентного добавления квот
        }
    },
    computed: {
        /**
         * Загружены ли данные предварительных заявок или нет
         * @return bool
         */
        loaded: function () {
            return this.data.hasOwnProperty('pre_proposals');
        },
        /**
         * Общее число распределенных квот: все квоты, кроме тех, кто отказался от участия
         * @returns {number}
         */
        getNewAssignedQuotasSum: function() {
            let sum = 0;
            for (let key in this.groups) {
                for (let i = 0; i < this.groups[key].length; i++) {
                    if (key !== 'noCompete') {
                        let newQuota = parseInt(this.groups[key][i].newQuota);
                        if (isNaN(newQuota)) {
                            newQuota = 0;
                        }
                        if (newQuota < 0) {
                            newQuota = 0;
                        }
                        sum += newQuota;
                    }
                }
            }
            return sum;
        },
        /**
         * Общее число не распределенных квот. Если распределено больше чем доступно, выводим 0
         * @returns {number}
         */
        getNotAssignedQuotasSum: function() {
            return Math.max(0, this.data.paid_participant_count - this.getNewAssignedQuotasSum);
        },
        /**
         * Общее число не распределенных квот. Если распределено больше чем доступно, выводим отрицательное значение
         * @returns {number}
         */
        getNotAssignedQuotasSumWithNegative: function () {
            return this.data.paid_participant_count + this.data.extra_participant_count - this.getNewAssignedQuotasSum;
        },
        /**
         * Общее число квот, которое можно менять при распределении
         * @returns {number}
         */
        getNotLockedQuotasSum: function() {
            let sum = 0;
            for (let key in this.groups) {
                for (let i = 0; i < this.groups[key].length; i++) {
                    if (key === 'moreQuotas') {
                        let isLocked = !!(this.groups[key][i].quota.is_locked);
                        if (!isLocked) {
                            let newQuota = parseInt(this.groups[key][i].newQuota);
                            newQuota = Math.max(0, newQuota);
                            sum += newQuota;
                        }

                    }
                }
            }
            return sum;
        },
        /**
         * Общее число квот соревнования в текущем состоянии
         * @returns {number|*}
         */
        getTotalQuotasSum: function () {
            if (!this.data) {
                return 0;
            }
            return this.data.paid_participant_count + this.data.extra_participant_count;
        },
        /**
         * Сумма квот, присоединенных к клубам, но которые не поменяются при пересчете:
         * Клубы, которые запросили меньше, клубы, которые не отправили запрос, заблокированные из тех, кто запрашивал больше
         * @returns {number}
         */
        getLockedQuotasSum: function () {
            return this.getNewAssignedQuotasSum - this.getNotLockedQuotasSum;
        },
        /**
         * Проверка, что можем сохранить новые значения квот.
         * Не можем сохранить, если есть новые значения квот, меньшие, чем уже подано заявок от клуба
         * @returns {boolean}
         */
        isValid: function () {
            for (let key in this.groups) {
                for (let i = 0; i < this.groups[key].length; i++) {
                    if (!this.isNewQuotaValid(this.groups[key][i])) {
                        return false;
                    }
                }
            }
            return true;
        },
    },
    methods: {
        /**
         * Инициализиция данных для компонента
         * @param data
         */
        init: function(data) {
            this.data = data;
            this.parseByGroups(data.pre_proposals, data.quotas);
        },
        /**
         * Количество квот в заявке от клуба
         * @param clubId
         * @returns {*}
         */
        getAllParticipantCount: function (clubId) {
            let clubInfo = this.getClubInfo(clubId);
            if (!clubInfo) {
                return 0;
            }
            let paidCount = parseInt(clubInfo.paid_participant_count);
            if (isNaN(paidCount)) {
                paidCount = 0;
            }
            let freeCount = parseInt(clubInfo.free_participant_count);
            if (isNaN(freeCount)) {
                freeCount = 0;
            }
            return paidCount + freeCount;
        },
        /**
         * Инфа о клубе
         * @param clubId
         * @returns {null|T}
         */
        getClubInfo: function (clubId) {
            if (clubId == null) {
                clubId = 0;
            }
            let filtered = this.data.clubs.filter(item => item.id == clubId);
            if (filtered.length > 0) {
                return filtered[0];
            }
            return null;
        },

        /**
         * Распределить заявки по группам: запрошено больше, чем дано, меньше, отказ от квот или нет ответа.
         * Вместе с этим считаем общее число отданных клубам квот для дальнейшего распределения по процентам
         * @param preProposals
         * @param quotas
         */
        parseByGroups: function (preProposals, quotas) {
            this.defaultTotalQuotas = 0;
            let indexedQuotas = {};
            for (let i = 0; i < quotas.length; i++) {
                this.defaultTotalQuotas += quotas[i].default_paid_count;
                indexedQuotas[quotas[i].club_id] = quotas[i];
            }

            for (let i = 0; i < preProposals.length; i++) {
                let quota = indexedQuotas[preProposals[i].club_id];
                let groupType = this.getGroupType(preProposals[i], quota);

                let newQuota = quota.paid_participant_count;
                // Если юзер запросил меньше и не было изменений от админа, то ставим меньше
                if (
                    preProposals[i].target_quota < quota.default_paid_count
                    && quota.default_paid_count === quota.paid_participant_count
                ) {
                    newQuota = preProposals[i].target_quota;
                }

                this.groups[groupType].push({
                    preProposal: preProposals[i],
                    quota: quota,
                    newQuota: newQuota,
                    previousNewQuota: newQuota
                });
            }
        },

        /**
         * Открыть модалку для пересчета квот
         */
        openRecalculateModal: function () {
            this.$parent.$refs.competitionQuotaReassignModal.init({
                competition: this.data
            });
        },
        /**
         * Пересчитать квоты пропорционально между клубами для распределения
         * @param data сколько всего квот распределить
         */
        recalculateQuotas: function (data) {
            // Число добавленных квот, или убранных. Если квоты убираются, то будет отрицательное значение count
            let count = data.count;
            this.data.extra_participant_count += count;

            //Берем все клубы, среди которых будет распределение
            let clubsData = this.groups.moreQuotas;

            // Вычисляем число свободных квот, которое нужно пропорционально распределить

            let getNotAssignedSum = this.getNotAssignedQuotasSumWithNegative;
            let quotasToAssign = 0;
            if (getNotAssignedSum < 0 && count >= 0) {
                quotasToAssign = count;
            } else {
                quotasToAssign = count >= 0 ? this.getNotAssignedQuotasSumWithNegative : count;
            }

            this.reassignQuotasByCount(clubsData, quotasToAssign);
        },

        /**
         * Разделить пропорционально между нуждающимися клубами доступное число квот. В процентном отношении отдаем квоты.
         * Может получиться, что при округлении в процентах будет 0 квот и не все квоты раздадутся
         * @param clubsData
         * @param quotasToAssign
         * @returns {number}
         */
        reassignQuotasByCount: function (clubsData, quotasToAssign) {
            let totalAssigned = 0;

            let totalPaidCount = 0;
            for (let i = 0; i < clubsData.length; i++) {
                totalPaidCount += parseInt(clubsData[i].quota.default_paid_count);
            }

            for (let i = 0; i < clubsData.length; i++) {
                clubsData[i].percent = clubsData[i].quota.default_paid_count / totalPaidCount;
            }

            // Распределение процентов долей клубов по распределенным квотам
            for (let i = 0; i < clubsData.length; i++) {
                // пересчитываем квоты только для клубов, которые не заблокированы и у которых новое число квот, меньше запрошенных квот
                if (!clubsData[i].quota.is_locked) {

                    // Изменения нужны, если мы добавляем квоту и есть недостаток у клуба, или мы отнимаем квоты и есть избыток
                    let newAssigned = parseInt(clubsData[i].newQuota);
                    let needChange = (newAssigned - clubsData[i].preProposal.target_quota < 0 && quotasToAssign > 0) ||
                        (newAssigned - clubsData[i].preProposal.target_quota >= 0 && quotasToAssign < 0);
                    if (needChange) {
                        // Процент квот клуба в дефолтном распределении
                        let percent = clubsData[i].percent;
                        // Число квот, которое можно добавить при пропорциональном распределении
                        let multiple = quotasToAssign > 0 ? 1 : -1;
                        let countToAdd =  multiple * Math.floor(Math.abs(percent * quotasToAssign));

                        if (multiple > 0) {
                            // Если добавляем, проверяем, что добавляем не больше чем необходимо
                            let diff = clubsData[i].preProposal.target_quota - clubsData[i].newQuota;
                            if (countToAdd > diff) {
                                countToAdd = diff;
                            }
                        } else {
                            // Если отнимаем, то проверяем, что остается не меньше 0
                            if (Math.abs(countToAdd) > clubsData[i].newQuota) {
                                countToAdd = multiple * clubsData[i].newQuota;
                            }
                        }

                        clubsData[i].newQuota = parseInt(clubsData[i].newQuota) + countToAdd;
                        totalAssigned += countToAdd;
                    }
                }
            }

            // Если не распределены все квоты, то упорядочиваем массив клубов по убвыыанию квот, проходим по массиву
            // На каждом шаге добавляем одну квоту, если идет добавление, или отнимаем в другом случае
            // Продолжаем обход, пока не закончились квоты
            if (totalAssigned !== quotasToAssign) {
                let sortIndex = [];
                for (let i = 0; i < clubsData.length; i++) {
                    sortIndex.push({index: i, quota: clubsData[i].quota.default_paid_count});
                }
                sortIndex.sort(function (a,b) {
                    if (a.quota < b.quota) {
                        return 1;
                    }
                    if (a.quota > b.quota) {
                        return -1;
                    }
                    return 0;
                });

                if (quotasToAssign > 0) {
                    // Режим добавления квот
                    while(totalAssigned < quotasToAssign) {
                        let iterationTotalChanged = 0;
                        for (let i = 0; i < sortIndex.length; i++) {
                            let index = sortIndex[i].index;

                            let needChange = clubsData[index].preProposal.target_quota > clubsData[index].newQuota && !clubsData[i].quota.is_locked;

                            if (needChange) {
                                clubsData[index].newQuota++;
                                totalAssigned++;
                                iterationTotalChanged++;
                                if (totalAssigned >= quotasToAssign) {
                                    break;
                                }
                            }
                        }
                        // Если за весь проход не изменили ни 1 квоты, то прерываем проход
                        if (iterationTotalChanged === 0) {
                            break;
                        }
                    }
                } else {
                    // Режим отъема квот
                    while(totalAssigned > quotasToAssign) { // отрицательные числа
                        let iterationTotalChanged = 0;
                        for (let i = 0; i < sortIndex.length; i++) {
                            let index = sortIndex[i].index;
                            let needChange = clubsData[index].newQuota > 0 && !clubsData[i].quota.is_locked;

                            if (needChange) {
                                clubsData[index].newQuota--;
                                totalAssigned--;
                                iterationTotalChanged++;
                                if (totalAssigned <= quotasToAssign) {
                                    break;
                                }
                            }
                        }
                        // Если за весь проход не изменили ни 1 квоты, то прерываем проход
                        if (iterationTotalChanged === 0) {
                            break;
                        }
                    }
                }

            }

            return totalAssigned;
        },

        /**
         * Есть ли клубы, которые нуждаются в квотах
         * @param clubsData
         * @returns {boolean}
         */
        thereAreClubsWithNeedToAssign: function (clubsData) {
            let result = [];
            for (let i = 0; i < clubsData.length; i++) {
                if (!clubsData[i].quota.is_locked && clubsData[i].preProposal.target_quota > clubsData[i].newQuota) {
                    result.push(clubsData[i]);
                }
            }

            return result.length > 0;
        },

        /**
         * Получить данные нового распределения квот
         */
        getData: function() {
            let result = [];
            for (let key in this.groups) {
                for (let i = 0; i < this.groups[key].length; i++) {
                    let needRecalculate = 0;
                    let newQuota = parseInt(this.groups[key][i].newQuota);
                    if (isNaN(newQuota)) {
                        newQuota = 0;
                    }

                    let previousNewQuota = parseInt(this.groups[key][i].previousNewQuota);
                    if (isNaN(previousNewQuota)) {
                        previousNewQuota = 0;
                    }

                    let clubId = this.groups[key][i].quota.club_id;
                    let clubProposalsCount = this.getAllParticipantCount(clubId);

                    // если уже был превышен лимит, но квота была увеличено, то надо пересчитать
                    needRecalculate = clubProposalsCount > previousNewQuota && newQuota > previousNewQuota;

                    result.push({
                        id: this.groups[key][i].quota.id,
                        paid_participant_count: newQuota,
                        is_locked: parseInt(this.groups[key][i].quota.is_locked),
                        need_recalculate: needRecalculate,
                    });
                }
            }

            return result;
        },

        /**
         * Отправить запрос сохранения квот
         */
        saveData: function() {
            // if (!this.isValid) {
            //     return false;
            // }
            let data = this.getData();
            axios.post('/competitions/save-pre-proposals/' + this.data.id, data)
                .then(
                    response => {
                        self.processing = false;
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при назначении представителя команды для соревнования', error);
                    self.processing = false;
                })
        },

        /**
         * Выбор типа группу по соотношению запрошенных и выделенных квот
         * @param preProposal
         * @param quota
         * @returns {string}
         */
        getGroupType: function (preProposal, quota) {
            if (!preProposal.initiator_user_id) {
                return 'noPreProposal';
            }

            if (preProposal.no_compete) {
                return 'noCompete';
            }

            if (preProposal.target_quota > quota.default_paid_count) {
                return 'moreQuotas';
            }

            return 'lessQuotas';
        },

        /**
         * Проверка, если ли проблема с квотой
         * @param {Object} groupData
         */
        checkQuota: function(groupData) {
            return groupData.preProposal.target_quota <= groupData.newQuota;
        },


        /**
         * Сменить значение блокировки
         * @param quota
         */
        changeLockState: function (quota) {
            let newValue = !quota.is_locked;
            quota.is_locked = newValue ? 1 : 0;
        },

        /**
         * Картинка иконки блокировки
         * @param quota
         * @returns {string}
         */
        getLockImage: function (quota) {
            return quota.is_locked ? '/images/icons/lock.svg' : '/images/icons/lock_open.svg';
        },
        /**
         * Проверяем, валидно ли число новых квот для клуба (число уже поданных заявок от клуба меньше, чем новая квота). Или число квот было увеличено
         * @param {Object} groupData
         * @returns {boolean}
         */
        isNewQuotaValid(groupData) {
            let clubId = groupData.quota.club_id;
            let newQuota = parseInt(groupData.newQuota);
            if (isNaN(newQuota)) {
                newQuota = 0;
            }

            let previousNewQuota = parseInt(groupData.previousNewQuota);
            if (isNaN(previousNewQuota)) {
                previousNewQuota = 0;
            }

            let clubProposalsCount = this.getAllParticipantCount(clubId);

            return clubProposalsCount <= newQuota || previousNewQuota <= newQuota;
        },
        /**
         * Класс для input поля нового числа квот. Если от клуба уже подано больше заявок, чем в новой квоте, то окрашиваем Input красным
         * @param {Object} groupData
         */
        newQuotaInputClass (groupData) {
            return this.isNewQuotaValid(groupData) ? '' : 'text-danger';
        },
        newQuotaInputTitle (groupData) {
            if (this.isNewQuotaValid(groupData)) {
                return '';
            }

            let clubId = groupData.quota.club_id;
            let clubProposalsCount = this.getAllParticipantCount(clubId);

            return 'От клуба уже заявлено ' + clubProposalsCount + ' спортсменов!';
        },
        /**
         * Посчитать общее число дефолтно данных квот в группе
         * @param groupData
         */
        getDefaultQuotaTotal(groupData) {
            let sum = 0;
            for (let i = 0; i < groupData.length; i++) {
                sum += parseInt(groupData[i].quota.default_paid_count);
            }
            return sum;
        },
        /**
         * Посчитать общее число запрошенных квот в группе
         * @param groupData
         */
        getTargetQuotaTotal(groupData) {
            let sum = 0;
            for (let i = 0; i < groupData.length; i++) {
                sum += parseInt(groupData[i].preProposal.target_quota);
            }
            return sum;
        },
        /**
         * Посчитать общее число квот группы в перераспределении
         * @param groupData
         */
        getNewQuotaTotal(groupData) {
            let sum = 0;
            for (let i = 0; i < groupData.length; i++) {
                let val = parseInt(groupData[i].newQuota);
                if (isNaN(val)) {
                    val = 0;
                }
                sum += val;
            }
            return sum;
        },
    },
    beforeDestroy() {
        Bus.$off('reassign-quotas', this.recalculateQuotas);
    },
});
