Vue.component('example-form', {
    data: function() {
        return {
            form: new TrustyForm({
                email: '',
                password: '',
            })
        }
    },
    methods: {
        send: function () {
            Trusty.post('/dev/components/form-process', this.form)
                .then(response => {
                })
                .catch(error => {

                })
        }
    }
});