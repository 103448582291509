Vue.component('time-standard-group-form', {
    props: ['title', 'timeStandardGroupId', 'ageGroups', 'groupTypes', 'athleteRanks', 'courseTypes'],
    data: function() {
        return {
            form: new TrustyForm({
                id: '',
                name: '',
                age_group_id: '',
                group_type: 1,
                athlete_rank_id: 1,
                time_standards: []
            }),
            time_groups: [],
            swimPrograms: [],
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'dictionary',
            mode: 'detail',
            backLink: '/dictionaries/time-standard-groups',
            backTitle: 'К списку нормативов',
            title: this.title
        });
        if (this.timeStandardGroupId) {
            this.loadTimeStandardGroup();
        }
        this.loadSwimPrograms();
    },
    computed: {
        /**
         * Модель для возрастной группы
         */
        age_group: {
            get: function() {
                let filtered = this.ageGroups.filter(item => item.id == this.form.age_group_id);
                if (filtered.length) {
                    return filtered[0];
                }
                return null;
            },
            set: function (item) {
                this.form.age_group_id = item ? item.id : '';
            }
        },
        /**
         * Урл для отправки запроса
         * @returns {string}
         */
        sendUrl: function () {
            return this.timeStandardGroupId
                ? '/dictionaries/time-standard-groups/' + this.timeStandardGroupId
                : '/dictionaries/time-standard-groups';
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            Trusty.post(this.sendUrl, this.form)
                .then(response => {
                    location.href = '/dictionaries/time-standard-groups';
                })
                .catch(error => {

                })
        },
        /**
         * Загружаем данные о нормативах
         */
        loadTimeStandardGroup: function() {
            axios.get('/dictionaries/time-standard-groups/fetch/' + this.timeStandardGroupId)
                .then(response => {
                    $.extend(true, this.form, response.data);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке нормативов', error);
                })
        },
        /**
         * Загружаем список видов программ
         */
        loadSwimPrograms: function() {
            axios.get('/dictionaries/swim-programs/all')
                .then(response => {
                    this.swimPrograms = response.data;
                })
                .catch(error => {});
        },

        getSwimTime: function(course, swimProgram) {
            let filteredTimeStandards = this.form.time_standards.filter(item => (item.course == course &&  item.swim_program_id == swimProgram.id));
            if (filteredTimeStandards.length > 0) {
                return filteredTimeStandards[0].swim_time;
            }
            return '';
        },
        setSwimTime: function(value, course, swimProgram) {
            let currentIndex = -1;
            let filteredTimeStandards = this.form.time_standards.filter(
                (item, index) => {
                    if (item.course == course && item.swim_program_id == swimProgram.id) {
                        currentIndex = index;
                        return true;
                    }
                    return false;
                });
            if (filteredTimeStandards.length > 0) {
                let currentTimeStandard = filteredTimeStandards[0];
                currentTimeStandard.swim_time = value;
                Vue.set(this.form.time_standards, currentIndex, currentTimeStandard);
                return ;
            }
            this.form.time_standards.push({
                course: course,
                swim_program_id: swimProgram.id,
                swim_time: value
            });
        },
    }
});
