Vue.component('general-competition-view', {
    mixins: [require('../../competitions/mixins/dateFormatter')],
    props: ['id', 'athleteRanks'],
    data: function () {
        return {
            processing: false,
            data: {},
            activeTab: 'description'
        }
    },
    mounted: function() {
        this.loadData();
    },
    computed: {
        /**
         * Проверяем начались ли соревнования
         * @returns {boolean}
         */
        checkDateFromHasCome: function() {
            return moment.now() >= moment(this.data.date_from).startOf('day');
        }
    },
    methods: {
        /**
         * Если таб в подменю соревнования активен
         * @param tab
         * @returns {boolean}
         */
        isActiveTab: function(tab) {
            return this.activeTab === tab;
        },
        /**
         * Делаем активным таб в подменю соревнования
         * @param tab
         * @returns {boolean}
         */
        setActiveTab: function(tab) {
            this.activeTab = tab;
        },
        /**
         * Загрузка данных соревнования - Описание
         */
        loadData: function() {
            this.processing = true;
            axios.get('/general/competitions/' + this.id + '/fetch')
                .then(response => {
                    this.data = response.data;
                    this.processing = false;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных соревнования', error);
                    this.processing = false;
                });
        },
        /**
         * Загрузка данных для вкладки результаты соревнований
         */
        openResults: function() {
            this.$refs.generalCompetitionResults.init();
            this.setActiveTab('results');
        },
    }
});
