Vue.component('coach-exists-modal', {
	data: function() {
		return {
			mode: 'assigned',
			assigning: false,
			data: null,
		};
	},
	methods: {
		/**
		 * Инициализация модального уведомления о том что тренер уже в системе
		 * @param data
		 * @param mode
		 */
		init: function (data, mode) {
			this.data = data;
			this.mode = mode;
			$('.modal-coach-exists').modal('show');
		},
		/**
		 * Привязка тренера к клубу
		 */
		onClickAssign: function() {
			this.assigning = true;
			axios.post('/coaches/assign', {id: this.data.id})
				.then(response => {
					location.href = '/coaches/' + this.data.id;
				})
				.catch(error => {
					console.warn('Ошибка при привязке тренера к клубу', error);
				})
		}
	}
});
