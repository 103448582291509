Vue.component('judge-category-list', {
    props: [],
    data: function () {
        return {
            currentRow: null
        }
    },
    mounted() {
        Bus.$emit('changeMenuState', {
            itemCode: 'seminars',
            childCode: 'judge-categories'
        });
    },
    methods:{
        /**
         * Подтверждение удаления судейской категории
         */
        confirmDelete: function(row) {
            this.currentRow = row;
            this.$refs.confirmModal.init({
                title: 'Перенос судейской категории в архив',
                confirmText: 'Вы действительно хотите перенести в архив данную судейскую категорию?',
                confirmButtonTitle: 'Перенeсти',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteJudgeCategory
            })
        },
        /**
         * Удаление судейской категории
         */
        deleteJudgeCategory: function() {
            this.$refs.judgeCategoryGrid.setLoading(true);
            var self = this;
            axios.delete('/seminars/judge-categories/' + this.currentRow.id)
                .then(
                    response => {
                        self.$refs.judgeCategoryGrid.reloadData();
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при удалении судейской категории', error);
                    self.$refs.judgeCategoryGrid.setLoading(false);
                })
        },
    }
});
