<template>
    <div class="trusty-file-uploader" :class="{'is-invalid': hasError}">
        <div class="trusty-file-input-container mb-2">
            <input type="file" @change="onChangeFile($event)" class="trusty-file-input" :accept="fileAccept" :disabled="disabled"/>
            <button class="btn btn-primary btn-sm" type="button">Прикрепить</button>
        </div>
        <div class="trusty-file-title" v-html="fileTitle">
        </div>
        <div class="trusty-file-suggestion">
            {{fileSuggestion}}
        </div>
    </div>
</template>

<script>
	export default {
		props: ['suggestion', 'accept', 'hasError', 'disabled', 'fileName', 'fileLink'],
		data: function() {
			return {
				fileData: {},
			}
		},
        computed: {
			/**
             *  Заголовок выбранного файла
             */
		    fileTitle: function () {
                if (this.fileData.name) {
                	return this.fileData.name;
                }
                if (this.fileName && this.fileLink) {
                   return '<a href="' + this.fileLink + '">' + this.fileName + '</a>';
                }
                return 'Файл не выбран';
			},
            /**
             * Текст-подсказка
             */
            fileSuggestion: function () {
                if (this.suggestion) {
                	return this.suggestion;
                }
                return 'Формат: doc, docx или pdf. Не более 5 мб';
			},
            /**
             * Возвожные форматы
             */
            fileAccept: function () {
            	if (this.accept) {
            		return this.accept;
                }
            	return '.doc,.docx,.pdf';
            }
        },
		methods: {
			/**
             * @brief Обработчик смены файла
			 */
			onChangeFile: function (e) {
				var files = e.target.files || e.dataTransfer.files;
				if (!files.length)
					return;
				this.fileData = files[0];
				this.$emit('change', this.fileData);
			}
		}
	}
</script>
