Vue.component('athlete-application-view', {
    data: function() {
        return {
            data: {},
            approving: false
        }
    },
    props: ['id'],
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'members',
            mode: 'detail',
            backLink: '/athlete-applications',
            backTitle: 'К списку заявок',
            title: 'Заявка на регистрацию спортсмена'
        });
        this.loadData();
    },
    methods: {
        /**
         * Загрузка данных заявки спортсмена
         */
        loadData: function() {
            axios.get('/athlete-applications/fetch/' + this.id)
                .then(response => {
                    this.data = response.data;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке заявки спортсмена', error);
                })
        },
        /**
         * Одобряет заявку
         */
        approveApplication: function () {
            this.approving = true;
            axios.post('/athlete-applications/approve', {
                id: this.data.id,
                from_view: true
            }).then(response => {
                location.href = '/athlete-applications';
                this.approving = false;
            }).catch(error => {
                console.warn('Ошибка при одобрении заявки', error);
            })
        },
        /**
         * Отклоняем заявку
         */
        declineApplication: function() {
            this.$refs.declineModal.init(this.data, function () {
                location.href = '/athlete-applications';
            });
        }
    }
});
