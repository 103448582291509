<template>
    <thead>
        <tr>
            <th v-if="useGroupActions">
                <div class="form-check">
                    <input class="form-check-input position-static" type="checkbox" :value="true" @change="onChangeCheckAllRows">
                </div>
            </th>
            <th v-for="column in columns" :class="column.class">
                {{column.label}}
                <template v-if="column.allowSort">
                    <span class="ml-2 fa" @click="onClickOrderIcon(column.attribute)"
                        :class="{'fa-sort': column.attribute != orderColumn, 'fa-sort-up': column.attribute == orderColumn && orderDirection == 'asc', 'fa-sort-down': column.attribute == orderColumn && orderDirection == 'desc'}"
                        ></span>
                </template>
            </th>
            <th v-if="useActions">

            </th>
        </tr>
    </thead>
</template>

<script>
    export default {
        props: [
            'columns',
            'useActions',
            'useGroupActions',
            'orderColumn',
            'orderDirection',
        ],
        methods: {
            /**
             * Обработчик смены статуса чекбокса групповых действий
             */
            onChangeCheckAllRows: function() {
                this.$parent.checkRowsAll = !this.$parent.checkRowsAll;
                this.$parent.onCheckAllRows();
            },
            /**
             * Обработка смены сортировки
             */
            onClickOrderIcon: function(column) {
                var direction = 'asc';
                if (column == this.orderColumn) {
                    direction = this.orderDirection == 'asc' ? 'desc' : 'asc';
                }
                this.$parent.changeOrder(column, direction);
            }
        }
    }
</script>
