Vue.component('fina-point-group-view', {
    mixins: [require('../mixins/dateFormatter')],
    props: ['id', 'emptyLabel'],
    data: function () {
        return {
            processing: false,
            data: {},
            swimPrograms: []
        };
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'competitions',
            mode: 'detail',
            backLink: '/competitions/fina-points',
            backTitle: 'К списку очков WA',
            title: 'Очки WA'
        });

        this.loadData();
    },
    methods: {
        /**
         * Загрузка данных очков WA
         */
        loadData: function() {
            this.processing = true;
            axios.get('/competitions/fina-points/fetch/' + this.id)
                .then(response => {
                    this.data = response.data;
                    this.loadSwimPrograms();
                    this.processing = false;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных очков WA', error);
                    this.processing = false;
                });
        },
        /**
         * Загружаем список видов программ
         */
        loadSwimPrograms: function() {
            axios.get('/dictionaries/swim-programs/all')
                .then(response => {
                    this.swimPrograms = response.data;
                    //Сортировка по дистанции
                    this.swimPrograms.sort(function (a, b) {
                        return  a.distance - b.distance;
                    });
                    this.getFormattedPoints();
                })
                .catch(error => {});
        },
        /**
         * Получить индекс программы плавания
         * @param swimProgramId
         * @returns {null}
         */
        getSwimProgramIndex: function (swimProgramId) {
            let swimProgramIndex = null;
            if (this.swimPrograms && this.swimPrograms.length > 0) {
                this.swimPrograms.forEach(function (swimProgram, index) {
                    if (swimProgram.id == swimProgramId) {
                        swimProgramIndex = index;
                    }
                });
            }
            return swimProgramIndex;
        },
        /**
         * Получение массива объектов программ плавания с базовым временем
         * @returns {[]}
         */
        getFormattedPoints: function () {
            let finaPoints = this.data && this.data.fina_points? this.data.fina_points: null;
            let swimProgramIndex = null;
            let self = this;
            //Формирование массива объектов программ плавания
            if (finaPoints) {
                finaPoints.forEach(function (point) {
                    if (point.swim_program){
                        swimProgramIndex = self.getSwimProgramIndex(point.swim_program.id);
                        if (swimProgramIndex !== null) {
                            if (point.gender == 'M') {
                                self.swimPrograms[swimProgramIndex].male = point.base_swim_time;
                            } else {
                                self.swimPrograms[swimProgramIndex].female = point.base_swim_time;
                            }
                        }
                    }
                });
            }
        }
    }
});
