Vue.component('register-club-form', {
    data: function() {
        return {
            form: new TrustyForm({
                name: '',
                short_name: '',
                legal_address: '',
                fact_address: '',
                email: '',
                phone: '',
                owner_last_name: '',
                owner_first_name: '',
                owner_patronymic: '',
                proxy_last_name: '',
                proxy_first_name: '',
                proxy_patronymic: '',
                proxy_phone: '',
                proxy_email: '',
                is_agreement_accepted: 0,
                municipality_id: '',
                city_id: '',
            }),
            municipalities: [],
            cities: [],
        }
    },
    computed: {
        /**
         * Модель для города
         */
        city: {
            get: function () {
                let filtered = this.cities.filter(item => item.id == this.form.city_id);
                if (filtered.length) {
                    return filtered[0];
                }
                return null;
            },
            set: function (item) {
                if (item) {
                    this.form.city_id = item.id;
                    this.form.municipality_id = item.municipality_id;
                    this.cities = [item];
                } else {
                    this.form.city_id = '';
                    this.form.municipality_id = '';
                }
            }
        },
    },
    methods: {
        /**
         * Загружаем список городов
         */
        searchCities: function(search, loading) {
            if (!search) {
                return;
            }
            loading && loading(true);
            this.cities = [];
            axios.get('/dictionaries/cities/all?keyword=' + search + '&limit=50')
                .then(response => {
                    this.cities = response.data;
                    loading && loading(false);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке списка городов', error);
                    loading && loading(false);
                })
        },
        /**
         * Заголовок для города
         * @param row
         * @returns {string}
         */
        cityLabel: function(option) {
            return option.name + (option.municipality ? ' (' + option.municipality.name + ')' : '');
        },
        /**
         * Отправка формы на сервер
         */
        send: function () {
            Trusty.post('/register-club', this.form)
                .then(response => {
                })
                .catch(error => {

                })
        }
    }
});
