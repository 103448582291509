Vue.component('competition-payment-refund-modal', {
    data: function () {
        return {
            processing: false,
            competition: null,
            competitions: [],
            paymentsCount: 0,
        };
    },
    methods: {
        /**
         * Инициализируем окно возврата платежей
         */
        init: function () {
            $('.modal-competition-payment-refund').modal('show');
        },
        /**
         * Поиск соревнования
         */
        searchCompetitions: function(search, loading) {
            if (!search) {
                return;
            }
            loading && loading(true);
            axios.get('/competitions/search?keyword=' + search)
                .then(response => {
                    this.competitions = response.data;
                    loading && loading(false);
                })
                .catch(error => {
                    console.warn('Ошибка при подгрузке соревнований', error);
                    loading && loading(false);
                })
        },
        /**
         * Обработчик смены выбранного соревнования
         */
        competitionChanged: function() {
            this.paymentsCount = 0;
            if (this.competition) {
                axios.post('/competitions/payments/payments-count', {
                    competition_id: this.competition.id
                }).then(response => {
                    this.paymentsCount = response.data.payments_count;
                }).catch(error => {
                    console.warn('Ошибка при подсчете количества транзакций', error);
                })
            }
        },
        /**
         * Возврат платежей для соревнования
         */
        batchRefund: function() {
            this.processing = true;
            if (this.competition) {
                axios.post('/competitions/payments/batch-refund', {
                    competition_id: this.competition.id
                }).then(response => {
                    $('.modal-competition-payment-refund').modal('hide');
                    Bus.$emit('pushFlashAlert', {
                        type: 'success',
                        message: 'Возврат платежей за соревнования успешно запущен.'
                    })
                    this.processing = false;
                }).catch(error => {
                    console.warn('Ошибка при возврате платежей', error);
                    this.processing = false;
                })
            }
        }
    }
})
