Vue.component('region-list', {
    data: function () {
        return {
            currentRow: null
        }
    },
    mounted() {
        Bus.$emit('changeMenuState', {
            itemCode: 'dictionaries',
            childCode: 'regions'
        });
    },
    methods:{
        /**
         * Подтверждение удаления региона
         */
        confirmDelete: function(row) {
            this.currentRow = row;
            this.$refs.confirmModal.init({
                title: 'Перенос региона в архив',
                confirmText: 'Вы действительно хотите перенести в архив данный регион?',
                confirmButtonTitle: 'Перенeсти',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteRegion
            })
        },
        /**
         * Удаление региона
         */
        deleteRegion: function() {
            this.$refs.regionGrid.setLoading(true);
            var self = this;
            axios.delete('/dictionaries/regions/' + this.currentRow.id)
                .then(
                    response => {
                        self.$refs.regionGrid.reloadData();
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при удалении региона', error);
                    self.$refs.regionGrid.setLoading(false);
                })
        },

    }
});
