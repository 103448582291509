Vue.component('coach-list', {
    data: function() {
        return {
            ids: []
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'members',
            childCode: 'coaches'
        });
    },
    methods: {
        /**
         * Форматированный вывод контактов тренера
         * @param value
         * @param row
         * @returns {string}
         */
        coachContacts: function(value, row) {
            var contacts = [];
            if (value) {
                contacts.push(Vue.filter('phone')(value));
            }
            if (row.email) {
                contacts.push(row.email);
            }
            return contacts.join('<br>');
        },
		/**
		 * Подтверждение группового удаления
		 */
		confirmDelete: function(ids) {
		    this.ids = ids;
			this.$refs.confirmModal.init({
				title: 'Перенос тренеров в архив',
				confirmText: 'Вы действительно хотите перенести в архив выбранных тренеров?',
				confirmButtonTitle: 'Перенeсти',
				confirmButtonClass: 'btn-danger',
				confirmCallback: this.deleteCoach
			})
		},
		/**
		 * Удаление тренера
		 */
		deleteCoach: function() {
			this.$refs.coachGrid.setLoading(true);
			var self = this;
			axios.post('/coaches/batch-delete', {ids: this.ids})
				.then(
					response => {
                        self.$refs.coachGrid.reloadData();
					}
				)
				.catch(error => {
					console.warn('Ошибка при удалении тренеров', error);
					self.$refs.coachGrid.setLoading(false);
				})
		},
    }
});
