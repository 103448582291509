Vue.component('competition-tab-competition-quota', {
    props: ['id'],
    data: function() {
        return {
            processing: false,
            data: {
                quotas: null,
                proposals: null
            },
            constants: require('../constants'),
        }
    },
    computed: {
        /**
         * Есть ли данные квот у соревнования
         */
        hasQuotas: function () {
            return this.data.quotas && this.data.quotas.length > 0;
        },
    },
    methods: {
        /**
         * Инициализируем компонент таба квоты в соревновании
         */
        init: function (data) {
            this.data = data;
            this.processing = true;
            if (!this.data.quotas) {
                let self = this;
                axios.get('/competitions/quotas/' + this.id)
                    .then(response => {
                        Vue.set(self.data, 'quotas', response.data.quotas);
                        Vue.set(self.data, 'proposals', response.data.proposals);
                        self.processing = false;
                    })
                    .catch(error => {
                        console.warn('Ошибка при загрузке данных соревнования', error);
                        self.processing = false;
                    });
            }
        },
        /**
         * Сумма двух чисел. Если каждое из слагаемых не определно или не число - берем его = 0
         */
        sumSafely: function (term1, term2) {
            return this.getIntOrZero(term1) + this.getIntOrZero(term2);
        },
        /**
         * Получаем инт значение от переменное, или 0, если число не определено
         * @param value
         * @returns {number}
         */
        getIntOrZero: function (value) {
            let result = parseInt(value);
            if (isNaN(result)) {
                result = 0;
            }
            return result;
        },
        /**
         * Находим массиве данных с поданными заявками данные о клубе с искомым club_id
         * @param clubId
         * @returns {null|*}
         */
        getClubProposalsData: function (clubId) {
            return this.data.proposals.find(function (element) {
                return element.club_id == clubId;
            });
        },
        /**
         * Получаем количество поданных заявок на бюджет для определенного клуба
         * @param clubId
         * @returns {number}
         */
        getFreeAddedProposals: function (clubId) {
            let proposalCountData = this.getClubProposalsData(clubId);
            return proposalCountData ? proposalCountData.free_participant_count : 0;
        },
        /**
         * Получаем количество поданных заявок на коммерцию для определенного клуба
         * @param clubId
         * @returns {number}
         */
        getPaidAddedProposals: function (clubId) {
            let proposalCountData = this.getClubProposalsData(clubId);
            return proposalCountData ? proposalCountData.paid_participant_count : 0;
        },
        /**
         * Получаем сумму поданных заявок для определенного клуба
         * @param clubId
         * @returns {number}
         */
        getSumAddedProposals: function (clubId) {
            return this.getFreeAddedProposals(clubId) + this.getPaidAddedProposals(clubId);
        },
        /**
         * Проверяем, больше ли поданных заявок, чем возможно
         * @param quotaData
         * @returns {boolean}
         */
        checkAllQuotasLimitExceeded: function (quotaData) {
            let allQuotaCount = this.sumSafely(quotaData.free_participant_count, quotaData.paid_participant_count);
            let allAddedProposalsCount = this.getSumAddedProposals(quotaData.club_id);

            return allAddedProposalsCount > allQuotaCount;
        },
        /**
         * Проверяем, больше ли поданных заявок на бюджет, чем возможно
         * @param quotaData
         * @returns {boolean}
         */
        checkFreeQuotasLimitExceeded: function (quotaData) {
            let allQuotaCount = quotaData.free_participant_count;
            let allAddedProposalsCount = this.getFreeAddedProposals(quotaData.club_id);

            return allAddedProposalsCount > allQuotaCount;
        },
        /**
         * Проверяем, больше ли поданных заявок на коммерцию, чем возможно
         * @param quotaData
         * @returns {boolean}
         */
        checkPaidQuotasLimitExceeded: function (quotaData) {
            let allQuotaCount = quotaData.paid_participant_count;
            let allAddedProposalsCount = this.getPaidAddedProposals(quotaData.club_id);

            return allAddedProposalsCount > allQuotaCount;
        },
        /**
         * Получаем суммарное количество поданных заявок от всех клубов на бюджет
         * @returns {number}
         */
        countSumFreeAddedProposals: function () {
            let sum = 0;
            if (this.data.proposals && this.data.proposals.length > 0) {
                this.data.proposals.forEach(function (element) {
                    sum += element.free_participant_count;
                });
            }
            return sum;
        },
        /**
         * Получаем суммарное количество поданных заявок от всех клубов на коммерцию
         * @returns {number}
         */
        countSumPaidAddedProposals: function () {
            let sum = 0;
            if (this.data.proposals && this.data.proposals.length > 0) {
                this.data.proposals.forEach(function (element) {
                    sum += element.paid_participant_count;
                });
            }
            return sum;
        },
        /**
         * Получаем суммарное количество поданных заявок от всех клубов на бюджет и коммерцию
         * @returns {number}
         */
        countSumAddedProposals: function () {
            return  this.countSumPaidAddedProposals() + this.countSumFreeAddedProposals();
        }
    }
});
