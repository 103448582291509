Vue.component('credential-commission-add-comment-athlete', {
    props: [
        'competition',
    ],
    data: function() {
        return {
            competitionProposalAthlete: null,
            processing: false,
            comment: '',
        }
    },
    methods: {
        /**
         * Сохранить комментарий у заявленного спортсмена
         */
        save: function () {
            this.processing = true;
            let self = this;
            axios.post('/competition/' + this.competition.id + '/credential-commission/change-competition-proposal-athlete-comment', {
                competitionProposalAthleteId: this.competitionProposalAthlete.id,
                comment: this.comment,
            }).then(response => {
                self.processing = false;
                if (self.$parent.athletes){
                    self.$parent.athletes.forEach(function (athlete) {
                        if (athlete.id == self.competitionProposalAthlete.id) {
                            athlete.comment = self.comment;
                        }
                    });
                }
                $('.modal-credential-commission-add-comment-athlete').modal('hide');
            }).catch(error => {
                console.warn('Ошибка при изменения статуса спортсмена', error);
            })
        },
        /**
         * Инициализируем компонент всплывашки Комментарий
         */
        init: function (competitionProposalAthlete) {
            this.competitionProposalAthlete = competitionProposalAthlete;
            this.comment = competitionProposalAthlete.comment;
            $('.modal-credential-commission-add-comment-athlete').modal('show');
        }
    }
});
