/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');
window.Bus = new Vue();

/**
 * Define the Vue filters.
 */
require('./filters');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

require('./trusty/forms/bootstrap')
require('jquery-slimscroll');

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);
import vSelect from 'vue-select'
vSelect.props.components.default = () => ({
	OpenIndicator: {
		render: createElement => createElement('span', {class: {'toggle': true, 'fa fa-caret-down': true}}),
	},
});
Vue.component('v-select', vSelect);
import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);
import draggable from 'vuedraggable'
Vue.component('draggable', draggable);
Vue.component('trusty-grid', require('./trusty/components/grid/TrustyGrid.vue').default);
Vue.component('flash-alerts', require('./components/util/FlashAlerts.vue').default);
Vue.component('file-input', require('./components/util/FileInput.vue').default);
Vue.component('date-picker', require('./components/util/DatePicker.vue').default);
Vue.component('phone-picker', require('./components/util/PhonePicker.vue').default);
Vue.component('confirm-modal', require('./components/util/ConfirmModal.vue').default);
Vue.component('multi-select', require('./components/util/MultiSelect.vue').default);
Vue.component('multi-input', require('./components/util/MultiInput.vue').default);
Vue.component('athlete-filters', require('./components/members/athletes/AthleteFilters.vue').default);
Vue.component('alert', require('./components/util/Alert.vue').default);
Vue.component('swim-time', require('./components/util/SwimTime.vue').default);

Vue.component('competition-filters', require('./components/competitions/CompetitionFilters.vue').default);
Vue.component('competition-calendar-list', require('./components/competitions/CompetitionCalendarList.vue').default);
Vue.component('general-competition-filters', require('./components/general/competitions/GeneralCompetitionFilters.vue').default);
Vue.component('general-competition-list', require('./components/general/competitions/GeneralCompetitionList.vue').default);

require('./components/dev/form/example-form');

require('./components/clubs/club-applications/register-club-form');
require('./components/clubs/club-applications/club-application-list');
require('./components/clubs/club-applications/club-application-view');
require('./components/clubs/club-applications/club-application-decline-form');
require('./components/clubs/club-applications/exists-clubs-modal');

require('./components/members/athlete-applications/register-athlete-form');
require('./components/members/athlete-applications/athlete-application-list');
require('./components/members/athlete-applications/athlete-application-view');
require('./components/members/athlete-applications/athlete-application-decline-form');

require('./components/clubs/club/club-list');
require('./components/clubs/club/club-view');
require('./components/clubs/club/club-turn-off-form')

require('./components/members/coaches/coach-list');
require('./components/members/coaches/coach-edit-form');
require('./components/members/coaches/coach-create-form');
require('./components/members/coaches/coach-view');
require('./components/members/coaches/coach-exists-modal');

require('./components/members/athletes/athlete-list');
require('./components/members/athletes/athlete-info-modal');
require('./components/members/athletes/athlete-create-form');
require('./components/members/athletes/athlete-edit-form');
require('./components/members/athletes/athlete-view');
require('./components/members/athletes/tabs/athletes-results');
require('./components/members/athletes/athlete-transfer-form');
require('./components/members/athletes/athlete-exists-modal');

// судьи
require('./components/seminars/judges/judge-form');
require('./components/seminars/judges/judge-add-category-modal');
require('./components/seminars/judges/judge-list');
require('./components/seminars/judges/judge-view');

// семинары
require('./components/seminars/seminars/seminar-form');
require('./components/seminars/seminars/seminar-list');
require('./components/seminars/seminars/seminar-view');
require('./components/seminars/seminars/seminar-results');

require('./components/seminars/payments/seminar-payment-list');
require('./components/seminars/payments/seminar-payment-view');
require('./components/seminars/payments/seminar-payment-refind-modal');

require('./components/competitions/competition-create-form');
require('./components/competitions/competition-create-from-lenex-form');
require('./components/competitions/competition-edit-form');
require('./components/competitions/competition-swim-program-setup');
require('./components/competitions/modals/competition-clone-modal');
require('./components/competitions/modals/competition-request-quotas-modal');
require('./components/competitions/modals/competition-quota-reassign-modal');
require('./components/competitions/modals/competition-add-result-modal');
require('./components/competitions/modals/competition-result-form-modal');

require('./components/system-requests/system-request-list');
require('./components/system-requests/system-request-view');
require('./components/system-requests/system-request-decline-form');

require('./components/settings/club-info');
require('./components/settings/user-password-change');
require('./components/settings/user-list');
require('./components/settings/user-form');
require('./components/settings/user-delete-modal');
require('./components/settings/credential-commission-list');
require('./components/settings/credential-commission-form');
require('./components/settings/competition-provisions/competition-provision-form');
require('./components/settings/competition-provisions/competition-provision-list');
require('./components/nav/main-menu');

require('./components/notifications/notification-list');
require('./components/notifications/notification-read-list');
require('./components/notifications/notification-view');

require('./components/dictionaries/athlete-ranks/athlete-rank-list');
require('./components/dictionaries/athlete-ranks/athlete-rank-form');
require('./components/dictionaries/athlete-admissions/athlete-admission-list');
require('./components/dictionaries/athlete-admissions/athlete-admission-form');
require('./components/dictionaries/age-groups/age-group-list');
require('./components/dictionaries/age-groups/age-group-form');
require('./components/dictionaries/swim-programs/swim-program-list');
require('./components/dictionaries/swim-programs/swim-program-form');
require('./components/dictionaries/cities/city-list');
require('./components/dictionaries/cities/city-form');
require('./components/dictionaries/regions/region-list');
require('./components/dictionaries/regions/region-form');
require('./components/dictionaries/municipalities/municipality-list');
require('./components/dictionaries/municipalities/municipality-form');
require('./components/dictionaries/time-standard-groups/time-standard-group-list');
require('./components/dictionaries/time-standard-groups/time-standard-group-form');
require('./components/dictionaries/time-standard-groups/time-standard-group-import-form');
require('./components/seminars/judge-categories/judge-category-list');
require('./components/seminars/judge-categories/judge-category-form');
require('./components/seminars/judge-roles/judge-role-list');
require('./components/seminars/judge-roles/judge-role-form');

require('./components/general/competitions/general-competition-calendar');
require('./components/general/competitions/general-competition-view');
require('./components/general/competitions/partial/general-competition-description');
require('./components/general/competitions/partial/general-competition-results');
require('./components/general/competitions/partial/results/general-result-protocols');
require('./components/general/members/athletes/general-athlete-list');
require('./components/general/members/athletes/general-athlete-view');
require('./components/general/members/athletes/partial/general-athlete-results');

require('./components/competitions/competition-view');
require('./components/competitions/competition-calendar');
require('./components/competitions/tabs/competition-tab-general-team-proposal');
require('./components/competitions/tabs/competition-tab-competition-quota');
require('./components/competitions/tabs/competition-tab-pre-proposal');
require('./components/competitions/tabs/competition-tab-my-proposal');
require('./components/competitions/tabs/competition-tab-description');
require('./components/competitions/tabs/competition-tab-all-quotas');
require('./components/competitions/tabs/competition-tab-all-proposals');
require('./components/competitions/tabs/competition-tab-all-pre-proposals');
require('./components/competitions/tabs/competition-tab-credential-commission');
require('./components/competitions/tabs/competition-tab-log');
require('./components/competitions/tabs/competition-tab-judges');
require('./components/competitions/tabs/credential-commission/credential-commission-add-club');
require('./components/competitions/tabs/credential-commission/credential-commission-athletes');
require('./components/competitions/tabs/credential-commission/credential-commission-add-comment-athlete');
require('./components/competitions/tabs/competition-tab-results');

require('./components/competitions/tabs/results/result-import-wizard');
require('./components/competitions/tabs/results/wizard/result-wizard-common-settings');
require('./components/competitions/tabs/results/wizard/result-wizard-report-settings');
require('./components/competitions/tabs/results/wizard/result-wizard-import-file');
require('./components/competitions/tabs/results/result-protocols');
require('./components/competitions/tabs/results/result-athletes');

require('./components/competitions/requests/competition-request-list');
require('./components/competitions/requests/competition-request-view');

require('./components/competitions/my-competition-list');

require('./components/competitions/proposals/competition-proposal-create-form');
require('./components/competitions/proposals/competition-proposal-swim-programs');
require('./components/competitions/proposals/competition-proposal-athletes-selection');
require('./components/competitions/proposals/competition-proposal-relays-selection');
require('./components/competitions/proposals/competition-proposal-wizard');
require('./components/competitions/proposals/competition-proposal-athlete-wizard');
require('./components/competitions/competition-results-import-form');

require('./components/competitions/selection-standard-groups/selection-standard-group-list');
require('./components/competitions/selection-standard-groups/selection-standard-group-form');

require('./components/competitions/fina-point-groups/fina-point-group-list');
require('./components/competitions/fina-point-groups/fina-point-group-view');
require('./components/competitions/fina-point-groups/fina-point-group-import-form');

require('./components/competitions/payments/competition-payment-list');
require('./components/competitions/payments/competition-payment-view');
require('./components/competitions/payments/competition-payment-refund-modal');

require('./components/members/ratings/rating-list');
require('./components/members/ratings/rating-filters');
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

const app = new Vue({
    el: '#app',
});

$(document).on('show.bs.modal', '.modal', function () {
    var zIndex = 1040 + (10 * $('.modal:visible').length);
    $(this).css('z-index', zIndex);
    setTimeout(function() {
        $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
    }, 0);
});
