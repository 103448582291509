Vue.component('club-application-list', {
	data: function() {
		return {
		}
	},
	mounted: function() {
		Bus.$emit('changeMenuState', {
			itemCode: 'organizations',
			childCode: 'club-applications'
		});
	},
	methods: {
		/**
		 * Обработка действия отклонения заявки
		 * @param row
		 */
		confirmApplicationDecline: function(row) {
			var self = this;
			this.$refs.declineModal.init(row, function () {
				Bus.$emit('reloadMenu');
				self.$refs.clubApplicationGrid.reloadData();
			});
		},
		/**
		 * Обработчик вызова после успешного одобрения заявки
		 * @param row
		 */
		onApproveSuccess: function(row) {
			Bus.$emit('reloadMenu');
			Bus.$emit('pushFlashAlert', {
				type: 'success',
				message: 'Заявка успешно одобрена'
			})
		}
	}
});
