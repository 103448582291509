module.exports = {
    methods: {
        /**
         * Проверяем, одинаковые ли файлы
         * @param oldFileIdCompetition
         * @param newFileIdCompetition
         * @returns {boolean}
         */
        checkFileChanged: function (oldFileIdCompetition, newFileIdCompetition)
        {
            if (this.valueIsEmpty(oldFileIdCompetition) && this.valueIsEmpty(newFileIdCompetition)) {
                return false;
            }
            if (this.valueIsEmpty(newFileIdCompetition)) {
                return false;
            }
            return oldFileIdCompetition != newFileIdCompetition;
        },
        /**
         * Проверяем является ли значение пустым
         * @param value
         * @returns {boolean}
         */
        valueIsEmpty: function (value) {
            return typeof(value) === 'undefined' || value === null || value === 'null' || value === '';
        },
        /**
         * Вывод строки файл соревнования до изменений
         * @param fileId
         * @param fileName
         * @returns {string|*}
         */
        getFormattedFileLineOldCompetition: function (fileId, fileName)
        {
            if (fileId) {
                return `<a class="btn-link" target="_blank" href="/files/${fileId}"><strong>${fileName}</strong></a>`;
            }
            return this.emptyLabel;
        },
        /**
         * Вывод строки новый файл соревнования в столбце с изменениями
         * @param newFileId
         * @param newFileName
         * @param oldFileId
         * @param oldFileName
         * @returns {string|*}
         */
        getFormattedFileLineNewCompetition: function (newFileId, newFileName, oldFileId, oldFileName)
        {
            if ((newFileId !== null) && (newFileId !== undefined)) {
                return `<a class="btn-link" target="_blank" href="/files/${newFileId}"><strong>${newFileName}</strong></a>`;
            } else if (!newFileId && oldFileId) {
                return `<a class="btn-link" target="_blank" href="/files/${oldFileId}"><strong>${oldFileName}</strong></a>`;
            }
            return this.emptyLabel;
        },
        /**
         * Проверяем по id есть ли изменения в возрастной группе
         * @param oldAgeGroupsMas
         * @param newAgeGroupsMas
         * @returns {boolean}
         */
        checkAgeGroupsChanged: function (oldAgeGroupsMas, newAgeGroupsMas)
        {
            //Сохраняем старые id возрастных групп в массив
            let oldAgeGroupsIds = [];
            let lengthOldAgeGroups = oldAgeGroupsMas.length;
            for (let i=0; i < lengthOldAgeGroups; i++) {
                oldAgeGroupsIds.push(oldAgeGroupsMas[i].age_group_id);
            }

            //Сохраняем новые id возрастных групп из контекста в массив
            let newAgeGroupsIds = [];
            let lengthNewAgeGroups = newAgeGroupsMas.length;
            for (let i=0; i < lengthNewAgeGroups; i++) {
                newAgeGroupsIds.push(newAgeGroupsMas[i].age_group_id);
            }

            return this.checkArrayChanged(oldAgeGroupsIds, newAgeGroupsIds);
        },
        /**
         * Проверяем, что массивы чисел не эквивалентны (не содержат одинаковый набор элементов без учета порядка)
         * @param oldArray
         * @param newArray
         */
        checkArrayChanged: function (oldArray, newArray) {
            let sortedOldArray = (oldArray.sort((a, b) => b - a)).join(',');
            let sortedNewArray = (newArray.sort((a, b) => b - a)).join(',');
            return sortedOldArray !== sortedNewArray;
        },
        /**
         * Проверяем одинаковые ли сессии
         * @param oldSessionsObj
         * @param newSessionsObj
         * @returns {boolean}
         */
        checkSessionsChanged: function (oldSessionsObj, newSessionsObj)
        {
            if (oldSessionsObj.length != newSessionsObj.length){
                return true;
            }
        },
        /**
         * Проверяем изменился ли соревновательный день
         * @param oldSessionObj
         * @param newSessionObj
         * @returns {boolean}
         */
        checkSessionChanged: function (oldSessionObj, newSessionObj) {
            if (typeof(oldSessionObj) === 'undefined') {
                return true;
            }

            if (oldSessionObj.session_date !== newSessionObj.session_date) {
                return true;
            }

            let result = oldSessionObj.swim_programs.length !== newSessionObj.swim_programs.length;
            for (let i = 0; i < newSessionObj.swim_programs.length && !result; i++) {
                result = this.checkSwimProgramChanged(oldSessionObj.swim_programs[i], newSessionObj.swim_programs[i]);
            }
            return result;
        },
        /**
         * Проверяем изменилась ли программа соревновательного дня
         * @param oldSwimProgram
         * @param newSwimProgram
         * @returns {boolean}
         */
        checkSwimProgramChanged: function (oldSwimProgram, newSwimProgram) {
            //Проверяем, что совпадает ссылка на плавательную программу, пол
            if (
                oldSwimProgram.swim_program_id !== newSwimProgram.swim_program_id ||
                oldSwimProgram.gender !== newSwimProgram.gender
            ) {
                return true;
            }
            //Проверяем совпадение набора возрастных групп
            if (oldSwimProgram.is_all_age_groups && newSwimProgram.is_all_age_groups) {
                //Если в обоих объектах выбраны все возможные группы - то изменений нет
                return false;
            }

            return this.checkArrayChanged(oldSwimProgram.age_groups, newSwimProgram.age_groups);
        },
        /**
         * Получаем название программы плавания в дистанции
         * @param contextSwimProgram
         * @returns {*}
         */
        getSwimProgramName: function (contextSwimProgram)
        {
            if (typeof contextSwimProgram != 'undefined') {
                if (typeof contextSwimProgram.swim_program == 'undefined') {
                    let swimProgramName = '';
                    this.data.context.swim_programs.forEach(function (swimProgram) {
                        if (swimProgram.id == contextSwimProgram.swim_program_id) {
                            swimProgramName = swimProgram.name;
                        }
                    });
                    return swimProgramName;
                } else {
                    return this.swimPrograms[(contextSwimProgram.swim_program_id - 1)].name;
                }
            }
        },
        /**
         * Получаем название возрастной группы
         * @param competitionAgeGroup
         * @returns {string|*}
         */
        getAgeGroupName: function(competitionAgeGroup)
        {
            if (competitionAgeGroup.age_group && competitionAgeGroup.age_group.name){
                return competitionAgeGroup.age_group.name;
            }
            if (competitionAgeGroup.age_group_id !== null) {
                let ageGroupName = '';
                this.data.context.age_groups.forEach(function (ageGroup) {
                    if (ageGroup.id == competitionAgeGroup.age_group_id) {
                        ageGroupName = ageGroup.name;
                    }
                });
                return ageGroupName;
            }
            return Vue.filter('ageGroupTitle')(competitionAgeGroup);
        },
        /**
         * Проверяем одинаковы ли значения у чекбокса квот
         * @param oldQuotaValue
         * @param newQuotaValue
         * @returns {boolean}
         */
        checkQuotasValueChanged: function (oldQuotaValue, newQuotaValue)
        {
            if (this.valueIsEmpty(oldQuotaValue) && this.valueIsEmpty(newQuotaValue)) {
                return false;
            }
            return oldQuotaValue != newQuotaValue;
        },
        /**
         * Проверяем одинаковы ли значения у определенного вида квоты
         * @param oldQuotasSubField
         * @param newQuotasSubField
         * @param oldQuotaValue
         * @param newQuotaValue
         * @returns {boolean}
         */
        checkQuotasSubFieldChanged: function (oldQuotasSubField, newQuotasSubField, oldQuotaValue, newQuotaValue)
        {
            return (oldQuotasSubField != newQuotasSubField) && (oldQuotaValue == 1) && (newQuotaValue == 1) || this.checkQuotasValueChanged(oldQuotaValue, newQuotaValue);
        },
        showChangeWarningIcon: function () {
            return '<img src="/images/icons/attention-red.svg" alt="" class="attention-red-icon" />';
        }
    }
};
