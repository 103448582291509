Vue.component('general-competition-description', {
    mixins: [require('../../../competitions/mixins/dateFormatter')],
    props: ['id', 'emptyLabel', 'genderShortLabels', 'data', 'athleteRanks'],
    data: function() {
        return {
            constants: require('../../../competitions/constants')
        }
    },
    methods: {
        /**
         * @brief Получение количества возрастных групп у определенной сессии
         * @param gender
         * @param isAllAgeGroups
         * @param ageGroups
         * @returns {number}
         */
        getAgeGroupsCount: function(gender, isAllAgeGroups, ageGroups) {
            let objs = this.availableAgeGroups(gender, isAllAgeGroups, ageGroups)
            return objs.length;
        },
        /**
         * @brief Получение массива возрастных групп у определенной сессии
         * @param gender
         * @param isAllAgeGroups
         * @param ageGroups
         * @returns {methods.selectedAgeGroups|[]|*[]|*}
         */
        availableAgeGroups: function(gender, isAllAgeGroups, ageGroups) {
            if (!this.data.age_groups) {
                return [];
            }
            if  (isAllAgeGroups) {
                return this.data.age_groups.filter(item => item.gender == gender);
            } else if (ageGroups.length > 0) {
                let ageGroupsData = this.data.age_groups;
                let objAgeGroups = [];
                ageGroupsData.forEach(function (ageGroup) {
                    if (ageGroups.includes(ageGroup.id)) {
                        objAgeGroups.push(ageGroup);
                    }
                });
                return objAgeGroups;
            }
            return [];
        },
        /**
         * Формируем наименование возрастной группы соревнования
         * @param competitionAgeGroup
         * @returns {*}
         */
        getAgeGroupLabel: function(competitionAgeGroup) {
            if (competitionAgeGroup.age_group) {
                return competitionAgeGroup.age_group.name;
            }
            return Vue.filter('ageGroupTitle')(competitionAgeGroup);
        },
        /**
         * Получаем название разряда по ID
         * @param athleteRankId
         * @returns {string|*}
         */
        getAthleteRankName: function(athleteRankId) {
            let filtered = this.athleteRanks.filter(item => item.id === athleteRankId);
            if (filtered.length) {
                return filtered[0].name;
            }
            return '';
        }
    }
});
