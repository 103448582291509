module.exports = {
    PARTICIPANT_TYPE_FREE: 0,
    PARTICIPANT_TYPE_PAID: 1,
    LIST_TYPE_MAIN: 0,
    LIST_TYPE_WAITING: 1,
    STATUS_CREATED: 0,
    STATUS_PARTIALLY_ALLOWED: 2,
    STATUS_ALLOWED: 3,
    STATUS_NOT_COME: 4,
    STATUS_DISALLOWED: 1,
    GENDER_MALE: 'M',
    GENDER_FEMALE: 'F',
    PAYMENT_STATUS_NOT_PAID: 0,
    PAYMENT_STATUS_PAID: 1,
    PAYMENT_STATUS_REFUNDED: 2
}
