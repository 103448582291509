Vue.component('system-request-view', {
	data: function() {
		return {
			processing: false,
			approving: false,
			data: {},
		}
	},
	props: ['id'],
	mounted: function() {
		Bus.$emit('changeMenuState', {
			itemCode: 'members',
			mode: 'detail',
			backLink: '/system-requests',
			backTitle: 'К списку заявок'
		});
		this.loadData();
	},
	computed: {
		/**
		 * Ожидает одобрения
		 */
		isStatusCreated: function() {
			return this.data.request_status == 1;
		},
		/**
		 * Отклонен
		 */
		isStatusDeclined: function() {
			return this.data.request_status == 3;
		},
		/**
		 * Является ли входящей заявкой
		 */
		isModeInbox: function() {
			return this.data.mode == 'inbox';
		}
	},
	methods: {
		/**
		 * Загрузка данных заявки
		 */
		loadData: function() {
			this.processing = true;
			axios.get('/system-requests/fetch/' + this.id)
				.then(response => {
					this.data = response.data;
					this.processing = false;
					Bus.$emit('changeMenuState', {
						title: this.data.request_type_title
					});
				})
				.catch(error => {
					console.warn('Ошибка при загрузке данных заявки', error);
					this.processing = false;
				})
		},
		/**
		 * Подтверждение одобрения
		 */
		confirmApprove: function() {
			this.$refs.confirmModal.init({
				title: this.data.title,
				confirmText: 'Вы действительно хотите одобрить данную заявку?',
				confirmButtonTitle: 'Одобрить',
				confirmButtonClass: 'btn-primary',
				confirmCallback: this.approve
			})
		},
		/**
		 * Одобрение заявки
		 */
		approve: function() {
			this.approving = true;
			var self = this;
			axios.post('/system-requests/approve', {id: this.data.id})
				.then(response => {
					self.approving = false;
					self.data = response.data;
					Bus.$emit('pushFlashAlert', {
						type: 'success',
						message: 'Заявка успешно обработана'
					});
					Bus.$emit('reloadMenu');
				})
				.catch(error => {
					self.approving = false;
				})
		},
		/**
		 * Подтверждение удаления
		 */
		confirmDecline: function() {
			var self = this;
			this.$refs.declineModal.init(this.data, function(responseData) {
				Bus.$emit('pushFlashAlert', {
					type: 'success',
					message: 'Заявка успешно отклонена'
				});
				Bus.$emit('reloadMenu');
				self.data = responseData;
			});
		}
	}
});