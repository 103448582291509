<template>
    <tr>
        <td v-if="useGroupActions">
            <div class="form-check">
                <input class="form-check-input position-static"
                   type="checkbox"
                   :value="row.id"
                   v-model="checkedValue"
                >
            </div>
        </td>
        <td v-for="column in columns" @click="onClickRow(row)">
            <template v-if="column.isIndex">
                <span>{{index}}</span>
            </template>
            <template v-else-if="column.format">
                <span v-html="applyFormat(row[column.attribute], row, column.format)"></span>
            </template>
            <template v-else-if="column.filter">
                {{applyFilter(row[column.attribute], column.filter)}}
            </template>
            <template v-else>
                {{row[column.attribute]}}
            </template>
        </td>
        <td v-if="useActions" class="text-right trusty-grid-row-actions">
            <template  v-for="action in rowActions" v-if="actionIsActive(action, row)">
                <template v-if="action.type == 'button'">
                    <a href="javascript:void(0)" @click.prevent="onClickAction(action, row)" class="mr-2" :class="action.class">
                        {{action.label}}
                    </a>
                </template>
                <template v-else-if="action.type == 'img'">
                    <a href="javascript:void(0)" @click.prevent="onClickAction(action, row)" class="mr-2 pl-2 pr-2">
                        <img :src="action.src" :title="action.label">
                    </a>
                </template>
                <template v-else-if="action.type == 'label'">
                    <label class="mr-2 pl-2 pr-2" :class="action.class">
                        {{action.label(row)}}
                    </label>
                </template>
                <template v-else>
                    <a href="javascript:void(0)" @click="onClickAction(action, row)" class="mr-2">
                        <span :class="action.icon" :title="action.label" aria-hidden="true"></span>
                    </a>
                </template>
            </template>
        </td>
    </tr>
</template>

<script>
    export default {
        props: [
            'row',
            'columns',
            'useActions',
            'useGroupActions',
            'rowActions',
            'rowLink',
            'currentMode',
            'index'
        ],
        computed:{
            /**
             * Свойство для работы с чекбоксами
             */
            checkedValue: {
                get() {
                    var self = this;
                    return this.$parent.checkedRows.filter(function(value) {return value == self.row.id}).length;
                },
                set(id) {
                    var self = this;
                    if (this.checkedValue) {
                        this.$parent.checkedRows = this.$parent.checkedRows.filter(function(value) {return value != self.row.id})
                    } else {
                        this.$parent.checkedRows.push(this.row.id);
                    }
                }
            }
        },
        methods: {
        	/**
             * Обработчик нажатия на строку в контроле
             * @param row
             */
        	onClickRow: function (row) {
                if (this.rowLink) {
                	location.href = this.rowLink.replace(':id', row.id);
                }
            },
            /**
             * Обработка нажатия на кнопку действия
             * @param action
             */
            onClickAction: function (action, row) {
            	if (action.callback) {
            		action.callback(row);
                } else if (action.async == true) {
                    this.$parent.onAsyncRowAction(
                        action.url.replace(':id', row.id),
                        action.method || 'post',
                        {id: row.id},
                        function() {
                        	if (action.successCallback) {
                        		action.successCallback(row);
                            }
                        }
                    );
                } else {
                    location.href = action.url.replace(':id', row.id);
                }
            },
            /**
             * Является ли активным данное действие
             * @param action
             * @returns {boolean}
             */
            actionIsActive: function(action, row) {
            	if (action.hasOwnProperty('isActive')) {
            		if (!action.isActive(row)) {
            			return false;
                    }
                }
                if (action.hasOwnProperty('modes')) {
                    return action.modes.indexOf(this.currentMode) >= 0;
                }
                return true;
            },
			/**
			 * Динамически применяем фильтры
			 * @param value
			 * @param filter
			 * @returns {*}
			 */
			applyFilter: function (value, filter) {
				return Vue.filter(filter)(value);
			},
			/**
			 * Динамически применяем форматы
			 * @param value
			 * @param row
			 * @param format
			 * @returns {*}
			 */
			applyFormat: function (value, row, format) {
				return format(value, row);
			}
        }
    }
</script>
