Vue.component('register-athlete-form', {
    props:['genderTypes'],
    data: function() {
        return {
            form: new TrustyForm({
                last_name: '',
                first_name: '',
                patronymic: '',
                gender: '',
                birth_date: '',
                email: '',
                phone: '',
                is_agreement_accepted: 0,
            }),
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            Trusty.post('/register-athlete', this.form)
                .then(response => {
                })
                .catch(error => {

                })
        }
    }
});
