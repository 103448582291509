Vue.component('competition-result-form-modal', {
    props: ['athleteRanks'],
    data: function() {
        return {
            competition: null,
            competitionSwimPrograms: [],
            athleteInfo: null,
            form: new TrustyForm({
                swim_time: '',
                competitionSwimProgram: null,
            }),
            places: [],
        }
    },
    computed: {
        /**
         * Получить список competitionSwimProgramId на которые был заявлен атлет. Заявляют спортсмена только на предварительные раунды
         */
        getAthleteCompetitionSwimProgramIds: function () {
            if (!this.athleteInfo) {
                return [];
            }
            let result = [];
            for (let i = 0; i < this.athleteInfo.proposal.swim_programs.length; i++) {
                result.push(this.athleteInfo.proposal.swim_programs[i].swim_program_id);
            }
            return result;
        },
        /**
         * Получить список competitionSwimProgramIds, со всеми возвожными раундами, соответствующие заявке спортсмена на предварительные раунды
         */
        getAthleteCompetitionSwimProgramIdsWithRounds: function () {
            let result = [];
            let preRoundCspIds = this.getAthleteCompetitionSwimProgramIds;
            for (let i = 0; i < preRoundCspIds.length; i++) {
                result = result.concat(this.getCompetitionSwimProgramIdsByPreRoundCompetitionSwimProgramId(preRoundCspIds[i]));
            }
            return result;
        },
        /**
         * Поиск заявок на дистанции, для которых нет соответствующих результатов в информации об атлете
         * @returns {[]}
         */
        getAthleteLostCompetitionSwimProgramIds: function () {
            let proposalsSwimProgramIds = this.getAthleteCompetitionSwimProgramIdsWithRounds;
            let result = [];
            for (let i = 0; i < proposalsSwimProgramIds.length; i++) {
                let athleteResult = this.athleteInfo.results.find(item => item.competition_swim_program_id === proposalsSwimProgramIds[i]);
                if (typeof(athleteResult) === 'undefined') {
                    result.push(proposalsSwimProgramIds[i]);
                }
            }
            return result;
        },
        /**
         * Список плавательных программ с раундами
         * @returns {[]}
         */
        getAvailableCompetitionSwimPrograms: function () {
            let result = [];
            for (let i = 0; i < this.getAthleteLostCompetitionSwimProgramIds.length; i++) {
                let id = this.getAthleteLostCompetitionSwimProgramIds[i];
                let competitionSwimProgram = this.getCompetitionSwimProgram(id);
                if (competitionSwimProgram) {
                    result.push({
                        id: id,
                        name: competitionSwimProgram.swim_program.name + ', ' + this.getSwimProgramRound(id)
                    });
                }
            }
            return result;
        },
        /**
         * Список возрастных групп соревнования
         */
        competitionAgeGroups: function () {
            let result = [];
            for (let i = 0; i < this.competition.age_groups.length; i++) {
                result.push({
                    id: this.competition.age_groups[i].id,
                    name: this.getAgeGroupLabel(this.competition.age_groups[i])
                });
            }
            return result;
        },
    },
    methods: {
        /**
         * Формируем наименование возрастной группы соревнования
         * @param competitionAgeGroup
         * @returns {*}
         */
        getAgeGroupLabel: function(competitionAgeGroup) {
            if (competitionAgeGroup.age_group) {
                return competitionAgeGroup.age_group.name;
            }
            return Vue.filter('ageGroupTitle')(competitionAgeGroup);
        },
        /**
         * Получить плавательную программу соревнования по id
         * @param {int} competitionSwimProgramId
         * @returns {null|Object}
         */
        getCompetitionSwimProgram: function (competitionSwimProgramId) {
            if (competitionSwimProgramId) {
                let competitionSwimProgram = this.competitionSwimPrograms.find(item => item.id == competitionSwimProgramId);
                return typeof(competitionSwimProgram) !== 'undefined' ? competitionSwimProgram : null;
            }
            return null;
        },
        /**
         * Получить список плавательных программ соревнования со всеми раундами по id плавательной программы предварительного раунда
         * @param {int} competitionSwimProgramId
         * @returns {[]}
         */
        getCompetitionSwimProgramIdsByPreRoundCompetitionSwimProgramId(competitionSwimProgramId) {
            let competitionSwimProgram = this.getCompetitionSwimProgram(competitionSwimProgramId);
            if (!competitionSwimProgram) {
                return [];
            }
            let filtered = this.competitionSwimPrograms.filter(function(item) {
                return item.swim_program_id === competitionSwimProgram.swim_program_id &&
                    item.gender === competitionSwimProgram.gender &&
                    item.is_all_age_groups === competitionSwimProgram.is_all_age_groups;
            });
            let result = [];
            for (let i = 0; i < filtered.length; i++) {
                result.push(filtered[i].id);
            }
            return result;
        },
        /**
         * Название плавательной программы
         * @param swimProgramId
         * @returns {*}
         */
        getSwimProgramName: function (swimProgramId) {
            let competitionSwimProgram = this.getCompetitionSwimProgram(swimProgramId);
            let swimProgram = competitionSwimProgram ? competitionSwimProgram.swim_program : null;
            return swimProgram ? swimProgram.name : '';
        },
        /**
         * Название раунда плавательной программы
         * @param swimProgramId
         * @returns {*}
         */
        getSwimProgramRound: function (swimProgramId) {
            let competitionSwimProgram = this.getCompetitionSwimProgram(swimProgramId);
            return competitionSwimProgram ? competitionSwimProgram.swim_program_round_label : '';
        },
        /**
         * Получить заявочное время по competitionSwimProgramId
         * @param {int} swimProgramId
         */
        getProposalSwimTime: function (swimProgramId) {
            let swimProgramsInfo = this.athleteInfo.proposal.swim_programs;
            let swimProgramInfo = swimProgramsInfo.find(item => item.swim_program_id == swimProgramId);
            if (typeof(swimProgramInfo) === 'undefined') {
                return '';
            }
            return swimProgramInfo.swim_time;
        },
        /**
         * Добавить новый результат в форму результатов
         */
        addPlace: function () {
            let result = {
                place: '',
                ageGroup: null
            };
            return this.places.push(result);
        },
        /**
         * Удалить место по индексу
         * @param index
         */
        removePlace: function (index) {
            this.places.splice(index, 1);
        },
        /**
         * Данные формы для правки результатов спортсмена
         */
        getData: function () {
            return {
                competition_swim_program_id: this.form.competitionSwimProgram ? this.form.competitionSwimProgram.id : null,
                swim_time: this.form.swim_time,
                places: this.getPlacesData()
            };
        },
        /**
         * Данные мест
         * @returns {[]}
         */
        getPlacesData: function () {
            let result = [];
            for (let i = 0; i < this.places.length; i++) {
                let place = parseInt(this.places[i].place);
                if (isNaN(place)) {
                    place = 0;
                }
                result.push({
                    place: place,
                    competition_age_group_id: this.places[i].ageGroup ? this.places[i].ageGroup.id : null
                });
            }
            return result;
        },
        /**
         * Добавить результат в форму результатов
         */
        addResult: function () {
            let data = this.getData();
            Bus.$emit('result-added', data);
            $('.modal-competition-result-form').modal('hide');
        },
        /**
         * Инициализация модалки клонирования
         * @param {Object} data
         */
        init: function (data) {
            this.competition = data.competition;
            this.competitionSwimPrograms = data.swimPrograms;
            this.competition = data.competition;
            let copy = JSON.parse(JSON.stringify(data.athleteInfo));
            this.athleteInfo = copy;

            this.form = new TrustyForm({
                swim_time: '',
                competitionSwimProgram: null,
            });
            this.places = [];

            $('.modal-competition-result-form').modal('show');
        }
    }
});
