Vue.component('competition-create-from-lenex-form', {
    props: ['competitionRankLabels'],
    data: function() {
        return {
            form: new TrustyForm({
                competition_rank: 4,
                lenex_file: '',
            })
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'competitions',
            mode: 'detail',
            backLink: '/my-competitions',
            backTitle: 'К списку соревнований',
            title: 'Добавление нового соревнования'
        });
    },
    methods: {
        /**
         * Отправляем запрос на создание соревнования
         */
        create: function() {
            Trusty.postWithFiles('/competitions/create-from-lenex', this.form, [
                'lenex_file',
            ])
                .then(response => {
                    location.href = '/competitions/' + response.id;
                })
                .catch(error => {
                })
        },
        /**
         * Обработчик смены lenex файла
         * @param fileData
         */
        onChangeLenexFile: function(fileData) {
            this.form.lenex_file = fileData;
        },
    }
})
