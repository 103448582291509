Vue.component('club-view', {
	data: function() {
		return {
			processing: false,
			data: {},
		}
	},
	props: ['id'],
	mounted: function() {
		Bus.$emit('changeMenuState', {
			itemCode: 'organizations',
			mode: 'detail',
			backLink: '/clubs',
			backTitle: 'К списку клубов',
			title: 'Просмотр клуба'
		});
		this.loadData();
	},
	methods: {
		/**
		 * Загрузка данных клубов
		 */
		loadData: function() {
			axios.get('/clubs/fetch/' + this.id)
				.then(response => {
					this.data = response.data;
				})
				.catch(error => {
					console.warn('Ошибка при загрузке данных клуба', error);
				})
		},
		/**
		 * Одобряет заявку
		 */
		turnOnClub: function () {
			this.processing = true;
			axios.post('/clubs/turn-on', {
				id: this.data.id
			}).then(response => {
				this.data = response.data;
				this.processing = false;
				Bus.$emit('pushFlashAlert', {
					type: 'success',
					message: 'Клуб успешно активирован'
				})
			}).catch(error => {
				console.warn('Ошибка при активации клуба', error);
			})
		},
		/**
		 * Отклоняем заявку
		 */
		turnOffClub: function() {
			var self = this;
			this.$refs.clubTurnOffModal.init(this.data, function (response) {
				self.data = response;
				Bus.$emit('pushFlashAlert', {
					type: 'success',
					message: 'Клуб успешно отключен'
				})
			});
		}
	}
});
