Vue.component('competition-payment-list', {
    props: ['statusLabels'],
    mounted() {
        Bus.$emit('changeMenuState', {
            itemCode: 'competitions',
            childCode: 'competition-payments'
        });
    },
    data() {
        return {
            filters: {
                statuses: [],
                club: null,
                municipality: null,
            },
            clubs: [],
            municipalities: [],
            constants: require('./constants')
        }
    },
    methods:{
        /**
         * Форматированный вывод наименования соревнования
         * @param value
         * @param row
         * @returns {*}
         */
        competitionName: function(value, row) {
            return row.competition ? row.competition.name : '';
        },
        /**
         * Форматированный вывод статуса транзакции
         * @param value
         * @param row
         * @returns {*}
         */
        statusLabel: function(value, row) {
            return '<img src="' + this.constants.STATUS_ICONS[value] + '" title="' + this.statusLabels[value] + '">';
        },
        /**
         * Форматированный вывод статуса чека
         * @param value
         * @param row
         * @returns {*}
         */
        paymentCheckLabel: function(value, row) {
            if (this.constants.CHECK_STATUS_ICONS[value]) {
                return '<img src="' + this.constants.CHECK_STATUS_ICONS[value] + '">';
            }
        },
        /**
         * Наименование атлетов
         * @param value
         * @param row
         * @returns {string}
         */
        athleteName: function (value, row) {
            return row.athlete ? (row.athlete.last_name + ' ' + row.athlete.first_name) : '';
        },
        /**
         * Форматированный вывод номера транзакции и карты
         * @param value
         * @param row
         * @returns {*}
         */
        transaction: function (value, row) {
            let html = value;
            if (row.card_type) {
                html += '<br/>';
                html += '<span class="badge badge-primary p-2">' + row.card_type + '   ***-' + row.card_last_four + '</span>';
            }
            return html;
        },
        /**
         * Форматированный вывод названия команды
         * @param value
         * @param row
         * @returns {string|*}
         */
        teamName: function (value, row) {
            if (row.club) {
                return row.club.short_name;
            }
            if (row.competition_proposal_general_team) {
                return row.competition_proposal_general_team.full_name;
            }
            return '';
        },
        /**
         * Применяем фильтры
         */
        applyFilters: function () {
            let filters = {
                statuses: this.filters.statuses,
                club_id: this.filters.club ? this.filters.club.id : null,
                municipality_id: this.filters.municipality ? this.filters.municipality.id : null
            };
            this.$refs.competitionPaymentsGrid.applyFilters(filters);
        },
        /**
         * Получаем клубы для показа
         * @param search
         * @param loading
         */
        searchClubs: function(search, loading) {
            if (!search) {
                return;
            }
            loading && loading(true);
            this.clubs = [];
            axios.get('/clubs/search-club-filter?keyword=' + search + '&limit=50')
                .then(response => {
                    this.clubs = response.data;
                    loading && loading(false);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке списка клубов', error);
                    loading && loading(false);
                })
        },
        /**
         * Получаем список муниципалитетов
         * @param search
         * @param loading
         */
        searchMunicipalities: function(search, loading) {
            loading && loading(true);
            var self = this;
            axios.get('/dictionaries/municipalities/search-municipality-filter?keyword=' + search)
                .then(response => {
                    self.municipalities = response.data;
                    loading && loading(false);
                })
                .catch(error => {
                    loading && loading(false);
                })
        },
        /**
         * Показываем окнов формой группового возврата
         */
        showRefundModal: function () {
            this.$refs.competitionPaymentRefundModal.init();
        }
    }
});
