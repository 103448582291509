Vue.component('competition-request-quotas-modal', {
    data: function() {
        return {
            data: null,
            preProposal: null,
            form: new TrustyForm({
                target_quota: null,
                no_compete: 0,
            }),
        }
    },
    computed: {
        /**
         * Дефолтное число квот, выделенное для клуба на соревнование
         * @returns {number}
         */
        competitionDefaultQuotas: function () {
            if (!this.data || !this.data.quotas) {
                return 0;
            }
            return this.data.quotas.free_participant_count + this.data.quotas.paid_participant_count;
        },
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            this.form.id = this.data.id;
            var self = this;
            Trusty.post('/competitions/pre-proposal-save-club-data/' + this.data.id, this.form)
                .then(response => {
                    $.extend(true, self.form, response);
                    Vue.set(this.data, 'preProposal', response);
                    // Событие изменения предварительной заявки
                    Bus.$emit('pre-proposal-updated', response);
                    $('.modal-competition-request-quotas').modal('hide');
                })
                .catch(error => {

                })
        },
        /**
         * Инициализация модалки запроса квот
         * @param {Object} data
         */
        init: function (data) {
            this.data = data;
            this.form.target_quota = data.my_pre_proposal.target_quota;
            $('.modal-competition-request-quotas').modal('show');
        }
    }
});
