<template>
    <div class="row">
        <div class="col-md-3" v-if="modes.length">
            <div>
                <template v-for="mode in modes">
                    <button class="btn mr-2"
                        @click="onChangeMode(mode.code)"
                        :class="{'btn-grid-active-mode': mode.code == currentMode}">
                        {{mode.label}}
                    </button>
                </template>
            </div>
        </div>
        <div class="col-md-2 pt-2">
            <p v-if="showTotal && total" class="pl-2">
                Всего: {{total | pluralize(pluralLabels) }}
            </p>
        </div>
        <div class="col-md-3" v-if="useFilter">
            <div class="form-group">
                <input type="text"
                    dusk="inputSearch"
                    class="form-control"
                    placeholder="Поиск"
                    @input="filter"
                />
            </div>
        </div>
        <div class="text-right" :class="{'col-md-4': modes.length, 'col-md-7': !modes.length}">
            <template  v-for="action in actions">
                <button class="mr-2" :class="action.class" v-if="actionIsActive(action)" @click="onClickAction(action)">
                    {{action.label}}
                </button>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'total',
            'showTotal',
            'onFilter',
            'modes',
            'currentMode',
            'actions',
            'useFilter',
            'pluralLabels'
        ],
        methods: {
            /**
             * Обработка фильтрации
             * @param type
             * @param target
             */
            filter: function({ type, target }) {
                this.onFilter(target.value);
            },
            /**
             * Смена режима грида
             * @param mode
             */
            onChangeMode: function (mode) {
                this.$emit('changeMode', mode);
            },
            /**
             * Обработка нажатия на кнопку действия
             * @param action
             */
            onClickAction: function (action, row) {
                if (action.method) {
                    action.method();
                } else if (action.callback) {
					if (this.$parent.checkedRows.length > 0) {
						action.callback(this.$parent.checkedRows);
					}
                } else if (action.isGroup == true) {
                    if (this.$parent.checkedRows.length > 0) {
                        this.$parent.onAsyncRowAction(
                            action.url,
                            action.method || 'post',
                            {
                                ids: this.$parent.checkedRows
                            }
                        );
                        this.$parent.checkedRows = [];
                    }
                } else {
                    location.href = action.url;
                }
            },
            /**
             * Является ли активным данное действие
             * @param action
             * @returns {boolean}
             */
            actionIsActive: function(action) {
            	if (action.isGroup == true) {
            		if (this.$parent.checkedRows.length == 0) {
            			return false;
                    }
                }
                if (action.hasOwnProperty('modes')) {
                    return action.modes.indexOf(this.currentMode) >= 0;
                }
                return true;
            }
        }
    }
</script>
