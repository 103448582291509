Vue.component('athlete-create-form', {
    props:['genderTypes', 'coaches', 'positionTypes', 'athleteRanks', 'athleteAdmissions', 'clubId', 'admissionPeriod', 'insurancePolicyPeriod'],
    mixins: [require('./mixins/form'), require('../../common/mixins/user')],
    mounted: function () {
        Bus.$emit('changeMenuState', {
            itemCode: 'members',
            mode: 'detail',
            backLink: '/athletes',
            backTitle: 'К списку спортсменов',
            title: 'Регистрация нового спортсмена'
        });
    },
})
