import constants from "../../../competitions/tabs/results/constants";

Vue.component('general-competition-results', {
    props: ['competition'],
    data: function() {
        return {
            loading: false,
            competitionEntities: [],
            constants: constants,
        }
    },
    computed: {
        /**
         * Есть ли уже результаты для соревнования
         * @returns {boolean}
         */
        hasResults: function() {
            return this.competitionEntities
                .filter(item => item.status != this.constants.COMPETITION_ENTITY_STATUS_CREATED).length > 0;
        }
    },
    methods: {
        /**
         * Инициализируем компонент таба результаты соревнования
         */
        init: function () {
            if (this.competitionEntities.length == 0) {
                this.loadCompetitionEntities();
            }
        },
        /**
         * Загружаем объекты соревнований
         */
        loadCompetitionEntities: function() {
            this.loading = true;
            axios.get('/general/competitions/' + this.competition.id + '/entities/list')
                .then(response => {
                    this.competitionEntities = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке объектов соревнования', error);
                    this.loading = false;
                })
        }
    }
});
