import constants from "./constants";

Vue.component('my-competition-list', {
    mixins: [require('../common/mixins/user')],
    props: ['competitionRankTypes'],
    data: function () {
        return {
            currentRow: null,
            constants: constants,
            deleting: false,
        }
    },
    mounted() {
        Bus.$emit('changeMenuState', {
            itemCode: 'competitions',
            childCode: 'my-competitions'
        });
    },
    methods: {
        /**
         * @brief Форматированный вывод ранга
         * @param value
         * @param row
         * @returns {*}
         */
        competitionRankTypeFormat: function(value, row) {
            return this.competitionRankTypes[value];
        },
        /**
         * Проверяем, можеn ли клуб редактировать свое соревнование
         * @param row
         */
        canEditCompetition: function (competition) {
            //Можем редактировать заявки в статусе черновик и активное, но не находящиеся на рассмотрении
            return competition.status == this.constants.COMPETITION_STATUS_DRAFT || competition.status == this.constants.COMPETITION_STATUS_ACTIVE;
        },
        /**
         * Проверяем, может ли клуб/рег.федерация удалить свое соревнование в статусе черновик
         * @param row
         */
        canDeleteCompetition: function (row)
        {
            return row && (row.status == this.constants.COMPETITION_STATUS_DRAFT);
        },
        /**
         * Обработчик нажатия кнопки удаления соревнования
         */
        confirmDelete: function(row) {
            if (this.canDeleteCompetition(row)) {
                this.currentRow = row;
                this.$refs.confirmModal.init({
                    title: 'Удаление соревнования',
                    confirmText: 'Вы действительно хотите удалить данное соревнование?',
                    confirmButtonTitle: 'Удалить',
                    confirmButtonClass: 'btn-danger',
                    confirmCallback: this.deleteCompetition
                })
            }
        },
        /**
         * Удаление соревнования
         */
        deleteCompetition: function() {
            if (this.canDeleteCompetition(this.currentRow)) {
                this.deleting = true;
                var self = this;
                axios.delete('/competitions/delete/' + this.currentRow.id)
                    .then(
                        response => {
                            self.$refs.myCompetitionsGrid.reloadData();
                        }
                    )
                    .catch(error => {
                        console.warn('Ошибка при удалении соревнования', error);
                        self.deleting = false;
                        self.$refs.myCompetitionsGrid.setLoading(false);
                    })
            } else {
                console.warn('Ошибка при удалении соревнования');
            }
        },
    }
});
