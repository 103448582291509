import proposalConstants from './../proposals/constants';
Vue.component('competition-tab-all-proposals', {
    props: ['genderShortLabels'],
    mixins: [require('../../common/mixins/user')],
    data: function() {
        return {
            data: {},
            mode: 'clubs',
            filterGender: null,
            filterTeamName: null,
            proposalConstants: proposalConstants,
            constants: require('../constants'),
            teamStatusOptions: [
                {id: 0, name: 'Формируется'},
                {id: 1, name: 'Одобрена'},
            ]
        }
    },
    computed: {
        isPreProposalsAlg: function () {
            return this.data.quotas_alg_type == this.constants.COMPETITION_ALG_TYPE_WITH_PRE_PROPOSALS;
        },
        /**
         * Загружены ли данные или нет
         * @return bool
         */
        loaded: function () {
            return this.data.hasOwnProperty('all_proposals');
        },
        /**
         * Спортсмены по общим основаниям
         */
        freeAthletes: function() {
            return this.data.all_proposals.athletes.filter(
                item => (item.participant_type == this.proposalConstants.PARTICIPANT_TYPE_FREE && item.list_type == this.proposalConstants.LIST_TYPE_MAIN)
            );
        },
        /**
         * Спортсмены по договору
         */
        paidAthletes: function() {
            return this.data.all_proposals.athletes.filter(
                item => (item.participant_type == this.proposalConstants.PARTICIPANT_TYPE_PAID && item.list_type == this.proposalConstants.LIST_TYPE_MAIN)
            );
        },
        /**
         * Заявленные команды на соревнование
         * @returns {*}
         */
        generalTeams: function() {
            if (!this.data.all_proposals.hasOwnProperty('general_teams')) {
                return [];
            }
            return _.sortBy(this.data.all_proposals.general_teams, function(item) {
                return moment(item.competition_proposal_date)
            });
        },
        /**
         * Заявленные клубы на соревнование
         * @returns {*}
         */
        clubs: function() {
            if (!this.data.all_proposals.hasOwnProperty('clubs')) {
                return [];
            }
            return _.sortBy(this.data.all_proposals.clubs, function(item) {
                return moment(item.competition_proposal_date)
            });
        },
        /**
         * Квоты для соревнования
         * @returns {competition-tab-all-proposals.computed.quotas|null|*[]}
         */
        quotas: function() {
            let self = this;
            if (!this.data.all_proposals.hasOwnProperty('quotas')) {
                return [];
            }
            return _.sortBy(this.data.all_proposals.quotas, function (item) {
                return (-1) * (self.getQuotasTotalCount(item));
            });
        }
    },
    methods: {
        getTeamStatus: function (index) {
            let value = this.generalTeams[index].status;
            return this.teamStatusOptions[value];
        },
        setTeamStatus: function (value, index) {
            let status = value.id;
            let id = this.generalTeams[index].id;
            axios.post('/competitions/' + this.data.id + '/proposals/set-team-status', {id:id, status: status})
                .then(response => {
                })
                .catch(error => {
                    console.warn('Ошибка при сохранении результатов', error);
                });
            let generalTeam = this.data.all_proposals.general_teams.find(item => item.id === id);
            if (typeof(generalTeam) !== 'undefined') {
                generalTeam.status = status;
            }
        },
        /**
         * Инициализиция данных для компонента
         * @param data
         */
        init: function(data) {
            this.data = data;
            if (this.data.need_general_teams) {
                this.mode = 'teams';
            }
        },
        /**
         * Количество участников по договору
         * @param clubId
         * @returns {*}
         */
        getPaidParticipantCount: function (clubId) {
            let clubInfo = this.getClubInfo(clubId);
            return clubInfo !== null ? clubInfo.paid_participant_count : 0;
        },
        /**
         * Количество участников по общему положению
         * @param clubId
         * @returns {*}
         */
        getFreeParticipantCount: function (clubId) {
            let clubInfo = this.getClubInfo(clubId);
            return clubInfo !== null ? clubInfo.free_participant_count : 0;
        },
        /**
         * Количество всех участников
         * @param clubId
         * @returns {*}
         */
        getAllParticipantCount: function (clubId) {
            return this.getFreeParticipantCount(clubId) + this.getPaidParticipantCount(clubId);
        },
        /**
         * Количество эстафет
         * @param clubId
         * @returns {number}
         */
        getRelayCount: function (clubId) {
            let clubInfo = this.getClubInfo(clubId);
            return clubInfo !== null ? clubInfo.relay_count : 0;
        },
        /**
         * Получаем данные по клубу
         * @param clubId
         * @returns {null|T}
         */
        getClubInfo: function (clubId) {
            if (clubId == null) {
                clubId = 0;
            }
            let filtered = this.clubs.filter(item => item.id == clubId);
            if (filtered.length > 0) {
                return filtered[0];
            }
            return null;
        },
        /**
         * Задаем пол спортсмена, по которому фильтровать
         * @param event
         */
        selectFilterGender: function (event) {
            if (event.target.checked) {
                if (this.filterGender) {
                    this.filterGender = null; //оба пола
                } else {
                    if (event.target.value == this.proposalConstants.GENDER_MALE) {
                        this.filterGender = this.proposalConstants.GENDER_MALE;
                    }
                    if (event.target.value == this.proposalConstants.GENDER_FEMALE) {
                        this.filterGender = this.proposalConstants.GENDER_FEMALE;
                    }
                }
            } else {
                if (this.filterGender) {
                    this.filterGender = null; //оба пола
                } else {
                    if (event.target.value == this.proposalConstants.GENDER_MALE) {
                        this.filterGender = this.proposalConstants.GENDER_FEMALE;
                    }
                    if (event.target.value == this.proposalConstants.GENDER_FEMALE) {
                        this.filterGender = this.proposalConstants.GENDER_MALE;
                    }
                }
            }
        },
        /**
         * Фильтруем спортсменов по полу и команде/клубу
         * @param athlete
         * @returns {boolean}
         */
        filterAthletes: function (athlete) {
            let isGenderSatisfying = (athlete.athlete.gender == this.filterGender) || (this.filterGender == null);
            let isTeamSatisfying;
            if (this.generalTeams && this.generalTeams.length > 0) {
                isTeamSatisfying = (this.filterTeamName == null)
                    || (this.filterTeamName == athlete.competition_proposal.competition_proposal_general_team.full_name);
            } else {
                if (athlete.athlete.club) {
                    isTeamSatisfying = (this.filterTeamName == null) || (this.filterTeamName == athlete.athlete.club.name);
                } else {
                    isTeamSatisfying = (this.filterTeamName == null) || (this.filterTeamName == 'Независимые спортсмены');
                }
            }
            return isGenderSatisfying && isTeamSatisfying;
        },
        /**
         * Выводим название команды/клуба в фильтре
         * @param event
         */
        selectTeamName(event) {
            if (event) {
                this.filterTeamName = event.name;
            } else {
                this.filterTeamName = null;
            }
        },
        getQuotasTotalCount(quota) {
            return quota.free_participant_count + quota.paid_participant_count;
        },
        getQuotasTotalClass(quota) {
            let proposalCount = this.getAllParticipantCount(quota.club_id);
            if (this.getQuotasTotalCount(quota) < proposalCount) {
                return 'text-danger';
            }
            if (proposalCount > 0) {
                return 'text-success';
            }

            return '';
        }
    }
});
