Vue.component('user-list', {
    props: ['teamRoleLabels', 'teamOwner'],
    data: function() {
        return {

        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'settings',
            childCode: 'settings-users'
        });
    },
    methods: {
        /**
         * @brief Формат для заголовка командной роли
         * @param value
         * @param row
         */
        teamRoleTitle: function (value, row) {
            return this.teamRoleLabels[value];
        },
		/**
         * Удаление ответсвенного невозможно
		 * @param row
		 * @returns {boolean}
		 */
		isActionDeleteActive: function(row) {
            return row.id != this.teamOwner;
        },
		/**
		 * Подтверждение удаления клуба
		 * @param row
		 */
		confirmDelete: function(row) {
			var self = this;
			this.$refs.userDeleteModal.init(row, function () {
				self.$refs.userGrid.reloadData();
				Bus.$emit('pushFlashAlert', {
					type: 'success',
					message: 'Пользователь успешно удален'
				})
			});
		},
		userContacts: function(value, row) {
			var contacts = value;
			if (row.phone) {
				contacts += '<br>' + row.phone;
			}
			return contacts;
		}
    }
});
