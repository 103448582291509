Vue.component('club-info', {
    data: function() {
        return {
            data: {},
            mode: 'view',
            form: new TrustyForm({
                created_at: '',
                proxy_phone: '',
                phone: '',
                municipality_id: '',
                city_id: ''
            }),
            settings: [],
            cities: [],
        }
    },
    props: ['id'],
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'settings',
            childCode: 'settings-club-info'
        });
        this.loadData();
    },
    computed: {
        /**
         * Модель для города
         */
        city: {
            get: function () {
                let filtered = this.cities.filter(item => item.id == this.form.city_id);
                if (filtered.length) {
                    return filtered[0];
                }
                return null;
            },
            set: function (item) {
                if (item) {
                    this.form.city_id = item.id;
                    this.form.municipality_id = item.municipality_id;
                    this.cities = [item];
                } else {
                    this.form.city_id = '';
                    this.form.municipality_id = '';
                }
            }
        },
    },
    methods: {
        /**
         * Загрузка данных клуба
         */
        loadData: function() {
            axios.get('/settings/club-info/fetch')
                .then(response => {
                    this.data = response.data;

                    /**
                     * Даные поля формы
                     */
                    this.form.id = response.data.id;
                    this.form.name = response.data.name;
                    this.form.code = response.data.code;
                    this.form.short_name = response.data.short_name;
                    this.form.municipality_id = response.data.municipality_id;
                    this.form.city_id = response.data.city_id;
                    this.form.owner_first_name = response.data.owner_first_name;
                    this.form.owner_last_name = response.data.owner_last_name;
                    this.form.owner_patronymic = response.data.owner_patronymic;
                    this.form.proxy_last_name = response.data.proxy_last_name;
                    this.form.proxy_first_name = response.data.proxy_first_name;
                    this.form.proxy_patronymic = response.data.proxy_patronymic;
                    this.form.proxy_phone = response.data.proxy_phone;
                    this.form.legal_address = response.data.legal_address;
                    this.form.fact_address = response.data.fact_address;
                    this.form.email = response.data.email;
                    this.form.phone = response.data.phone;
                    if (response.data.city) {
                        let city = response.data.city;
                        if (response.data.municipality) {
                            city.municipality = response.data.municipality;
                        }
                        this.cities = [city];
                    }
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке клуба', error);
                })
        },
        /**
         * Загружаем список городов
         */
        searchCities: function(search, loading) {
            if (!search) {
                return;
            }
            loading && loading(true);
            this.cities = [];
            axios.get('/dictionaries/cities/all?keyword=' + search + '&limit=50')
                .then(response => {
                    this.cities = response.data;
                    loading && loading(false);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке списка городов', error);
                    loading && loading(false);
                })
        },
        /**
         * Заголовок для города
         * @param row
         * @returns {string}
         */
        cityLabel: function(option) {
            return option.name + (option.municipality ? ' (' + option.municipality.name + ')' : '');
        },
		/**
         * Отправка данных на сервер
		 */
		send: function () {
            Trusty.post('/settings/club-info', this.form)
                .then(response => {
					Bus.$emit('pushFlashAlert', {
						type: 'success',
						message: 'Данные успешно сохранены'
					});
                    this.mode = 'view';
                    this.data = response;
                })
                .catch(error => {
                    console.warn('Ошибка при обновлении клуба', error);
                })
        },
		/**
         * Смена режима отображения
		 * @param mode
		 */
		changeMode: function(mode) {
            this.mode = mode;
        }
    }
});
