Vue.component('athlete-exists-modal', {
    data: function() {
        return {
            mode: 'assigned',
            assigning: false,
            data: null,
        };
    },
    methods: {
        /**
         * Инициализация модального уведомления о том что спортсмен уже в системе
         * @param data
         * @param mode
         */
        init: function (data, mode) {
            this.data = data;
            this.mode = mode;
            $('.modal-athlete-exists').modal('show');
        }
    }
});
