Vue.component('region-form', {
    props: ['title', 'regionId'],
    data: function() {
        return {
            form: new TrustyForm({
                id: '',
                name: '',
            }),
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'dictionary',
            mode: 'detail',
            backLink: '/dictionaries/regions',
            backTitle: 'К списку регионов',
            title: this.title
        });
        if (this.regionId) {
            this.loadRegion();
        }
    },
    computed: {
        /**
         * Урл для отправки запроса
         * @returns {string}
         */
        sendUrl: function () {
            return this.regionId
                ? '/dictionaries/regions/' + this.regionId
                : '/dictionaries/regions';
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            Trusty.post(this.sendUrl, this.form)
                .then(response => {
                    location.href = '/dictionaries/regions';
                })
                .catch(error => {

                })
        },
        /**
         * Загружаем данные о городе
         */
        loadRegion: function() {
            axios.get('/dictionaries/regions/fetch/' + this.regionId)
                .then(response => {
                    $.extend(true, this.form, response.data);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке региона', error);
                })
        }
    }
});
