Vue.component('athlete-application-decline-form', {
    data: function() {
        return {
            data: {},
            successCallback: null,
            form: new TrustyForm({
                declined_reason: ''
            }),
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            this.form.id = this.data.id;
            var self = this;
            Trusty.post('/athlete-applications/decline', this.form)
                .then(response => {
                    $('.modal-athlete-application-decline').modal('hide');
                    self.successCallback();
                })
                .catch(error => {
                    console.warn('Ошибка при отклонении заявки', error);
                })
        },
        init: function (data, successCallback) {
            this.data = data;
            this.successCallback = successCallback;
            $('.modal-athlete-application-decline').modal('show');
        }
    }
});
