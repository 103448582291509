Vue.component('athlete-edit-form', {
    props:['genderTypes', 'coaches', 'positionTypes', 'athleteRanks', 'athleteAdmissions', 'athleteId', 'clubId', 'admissionPeriod', 'insurancePolicyPeriod'],
    mixins: [require('./mixins/form'), require('../../common/mixins/user')],
    mounted: function() {
        if(!this.isAthleteUser) {
            Bus.$emit('changeMenuState', {
                itemCode: 'members',
                mode: 'detail',
                backLink: '/athletes',
                backTitle: 'К списку спортсменов',
                title: 'Редактирование спортсмена'
            });
        }
        else {
            Bus.$emit('changeMenuState', {
                itemCode: 'settings',
                childCode: 'settings-athlete-info'
            });
        }
        if (this.athleteId) {
            this.loadAthlete();
        }
    },
    computed: {
    },
    methods: {
        /**
         * Обновление данных спортсмена
         */
        update: function() {
            let url = this.isAthleteUser ? '/settings/athlete-info/edit' : '/athletes/' + this.form.id;
            Trusty.postWithFiles(url, this.form, [
                'athlete_rank_doc_file',
                'admission_doc_file',
                'insurance_policy_doc_file',
                'data_agreement_doc_file',
            ])
                .then(response => {
                    if (response.exists) {
						this.$refs.athleteExistsModal.init(response, this.getExistsMode(response));
					} else {
                        if (this.isAthleteUser) {
                            location.href = '/settings/athlete-info'
                        }
                        else {
                            location.href = '/athletes';
                        }
					}
				})
                .catch(error => {
                })
        }
    }
});
