<template>
    <div class="multi-select-control" :class="{'is-invalid': hasError}">
        <div v-for="selectedId in selectedIds" class="multi-select-item">
            {{optionLabel(selectedId)}}
            <span class="remove-item" @click="onClickRemoveItem(selectedId)">&times;</span>
        </div>
        <v-select :options="availableOptions" placeholder="Выбрать из списка" v-model="selectModel">
            <div slot="no-options">Нет подходящих записей</div>
        </v-select>
    </div>
</template>

<script>
    export default {
        props: ['options', 'hasError', 'value', 'inputId'],
        name: "MultiSelect",
        data: function() {
            return {
                selectValue: '',
            }
        },
        computed: {
            /**
             * Массив выбранных ID
             * @returns {*[]|*}
             */
            selectedIds: function() {
                if (this.value) {
                    return this.value;
                }
                return [];
            },
            /**
             * Модель для контрола
             */
            selectModel: {
                get: function() {
                    return this.selectValue;
                },
                set: function(option) {
                    if (option) {
                        var currentIds = this.selectedIds;
                        currentIds.push(option.value);
                        this.$emit('input', currentIds);
                    }
                    this.selectValue = null;
                }
            },
            /**
             * Доступные опции для выбора
             */
            availableOptions: function() {
            	let availableOptions = [];
				this.options.forEach((option) => {
					if (this.isOptionAvailable(option.value)) {
						availableOptions.push(option);
					}
				});
            	return availableOptions;
            }
        },
        methods: {
        	/**
             * Заголовок по значению
             */
        	optionLabel: function(value) {
				let selectedItem = this.options.filter(function(item) {
						return item.value == value;
					});
				if (selectedItem.length > 0) {
					return selectedItem[0].label;
                }
                return '';
            },
            /**
             * Проверка доступности
             * @param index
             * @returns {boolean}
             */
            isOptionAvailable: function(index) {
                return this.selectedIds.filter(function(value) {
                    return value == index;
                }).length == 0;
            },
            /**
             * Удаление элемента из выбранных
             * @param index
             */
            onClickRemoveItem: function(index) {
                var currentIds = this.selectedIds;
                currentIds = currentIds.filter(function(value) {
                    return value != index;
                });
                this.$emit('input', currentIds);
            }
        }
    }
</script>
