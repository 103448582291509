module.exports = {
    data: function () {
        return {
            constants:  require('../constants')
        }
    },
    computed: {
        /**
         * @brief Является ли текущий пользователь админом
         * @returns {boolean}
         */
        isAdminUser: function() {
            return Trusty.user.role == this.constants.ROLE_ADMIN;
        },

        /**
         * @brief Является ли текущий пользователь региональной федерацией
         * @returns {boolean}
         */
        isRegionalFederationUser: function() {
            return Trusty.user.role == this.constants.ROLE_REGIONAL_FEDERATION;
        },

        /**
         * @brief Является ли текущий пользователь ФСО
         * @returns {boolean}
         */
        isClubUser: function() {
            return Trusty.user.role == this.constants.ROLE_CLUB;
        },

        /**
         * @brief Является ли текущий пользователь спортсменом самоходом
         * @returns {boolean}
         */
        isAthleteUser: function() {
            return Trusty.user.role == this.constants.ROLE_ATHLETE;
        },

        /**
         * @brief Является ли текущий пользователь мандатной комиссией
         * @returns {boolean}
         */
        isCredentialCommissionUser: function() {
            return Trusty.user.role == this.constants.ROLE_CREDENTIAL_COMMISSION;
        },
    }
};
