Vue.component('competition-clone-modal', {
    props: ['quotasAlgTypes'],
    data: function() {
        return {
            competition: null,
            form: new TrustyForm({
                is_use_quotas: 0,
                quotas_alg_type: 0,
                participant_count: 0,
                reserve_participant_count: 0,
                free_participant_count: 0,
                paid_participant_count: 0,
            }),
            datesDiff: 0,
            constants: require('../constants')
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            this.form.id = this.competition.id;
            var self = this;
            Trusty.post('/competitions/' + this.competition.id + '/clone', this.form)
                .then(response => {
                    $('.modal-competition-clone').modal('hide');
                    if (response.success) {
                        location.href = '/competitions/' + response.id
                    }
                })
                .catch(error => {
                    console.warn('Ошибка при клонировании заявки', error);
                })
        },
        /**
         * Обработка события изменения даты начала соревнований
         * @param value
         */
        onDateFromChange: function (value) {
            let dateFrom = moment(value);
            let dateTo = dateFrom.add(this.datesDiff, 'days').format('YYYY-MM-DD');
            if (this.form.date_to !== dateTo) {
                this.form.date_to = dateTo;
            }
        },
        /**
         * Обработка события изменения даты окончания соревнований
         * @param value
         */
        onDateToChange: function (value) {
            let dateTo = moment(value);
            let dateFrom = dateTo.subtract(this.datesDiff, 'days').format('YYYY-MM-DD');
            if (this.form.date_from !== dateFrom) {
                this.form.date_from = dateFrom;
            }
        },

        /**
         * Пересчитываем платные места
         */
        recalculateParticipants: function () {
            if (this.form.quotas_alg_type == this.constants.COMPETITION_ALG_TYPE_DEFAULT) {
                this.form.paid_participant_count = Math.max(0,
                    parseInt(this.form.participant_count) - parseInt(this.form.reserve_participant_count) - parseInt(this.form.free_participant_count)
                );
            } else {
                this.form.paid_participant_count = Math.max(0,
                    parseInt(this.form.participant_count) - parseInt(this.form.reserve_participant_count)
                );
            }

        },
        /**
         * Инициализация модалки клонирования
         * @param {Object} data
         */
        init: function (data) {
            this.competition = data.competition;

            // Копируем значения квот
            this.form.is_use_quotas = this.competition.is_use_quotas;
            this.form.quotas_alg_type = this.competition.quotas_alg_type;
            this.form.participant_count = this.competition.participant_count;
            this.form.reserve_participant_count = this.competition.reserve_participant_count;
            this.form.free_participant_count = this.competition.free_participant_count;
            this.form.paid_participant_count = this.competition.paid_participant_count;

            // Высчитываем разницу между датами начала и конца в датах соревнования
            let dateFrom = moment(this.competition.date_from);
            let dateTo = moment(this.competition.date_to);
            this.datesDiff = dateTo.diff(dateFrom, 'days');
            $('.modal-competition-clone').modal('show');
        }
    }
});
