import constants from "../proposals/constants";
import competitionConstants from "../constants";

Vue.component('competition-tab-general-team-proposal', {
    mixins: [require('../mixins/dateFormatter')],
    props: [
        'id',
        'athleteRanks',
        'genderLabels'
    ],
    data: function() {
        return {
            data: {},
            processing: false,
            approving: false,
            competition: null,
            athletes: [],
            competitionProposal: {},
            freeAthletes: [],
            paidAthletes: [],
            constants: constants,
            constants_for_competition: competitionConstants,
            currentProposalRelayIndex: null,
            currentProposalRelay: null
        }
    },
    computed: {
        /**
         * Проверяем, является ли текущий пользователь представителем команды соревнования
         * @returns {boolean}
         */
        isProxyUser: function() {
            return this.data.general_team && this.data.general_team.proxy && this.data.general_team.proxy && Trusty.user.id === this.data.general_team.proxy_id;
        },
        /**
         * Проверяем наличие видов программ для эстафет
         * @returns {*[]}
         */
        hasRelaySwimPrograms: function() {
            if (!this.competition || !this.competition.swim_programs) {
                return false;
            }
            return this.competition.swim_programs.filter(item => item.swim_program.relay_count > 0);
        },
        /**
         * Ссылка для печати заявки команды
         * @returns {string}
         */
        printLink: function() {
            return '/competitions/' + this.id + '/print-team-proposal';
        }
    },
    methods: {
        /**
         * Инициализируем компонент таба заявки в соревновании
         */
        init: function (data) {
            let self = this;
            this.data = data;
            this.competition = data;
            this.competition.swim_programs = [];

            //Сохраняем виды программ плавания
            if (data.sessions){
                data.sessions.forEach(function (session) {
                    if (session && session.swim_programs) {
                        session.swim_programs.forEach(function (swim_program) {
                            self.competition.swim_programs.push(swim_program);
                        });
                    }
                })
            }
            this.processing = true;
            if (!this.data.athletes) {
                let self = this;
                let url = '/competitions/competition-team-proposal/' + this.id;
                axios.get(url)
                    .then(response => {
                        Vue.set(self.data, 'athletes', response.data);
                        self.athletes = self.data.athletes;
                        self.competitionProposal.athletes = self.data.athletes;
                        this.getAthletesSortedData();
                        self.processing = false;
                    })
                    .catch(error => {
                        console.warn('Ошибка при загрузке данных соревнования', error);
                        self.processing = false;
                    });
            }

            //Для представителя загружаем состав эстафет из командной заявки
            if (this.isProxyUser) {
                this.loadGeneralTeamRelays();
            }
        },
        loadGeneralTeamRelays: function () {
            let self = this;
            this.processing = true;
            axios.get('/competitions/competition-team-proposal-relays/' + this.id)
                .then(response => {
                    Vue.set(self.data, 'relays', response.data);
                    self.processing = false;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных соревнования', error);
                    self.processing = false;
                });
        },
        /**
         * Доступность возрасной группы для вида программы
         * @param ageGroup
         * @param relay
         * @returns {boolean}
         */
        isAgeGroupAvailableForRelay: function(ageGroup, relay) {
            if (!ageGroup) {
                return false;
            }
            return relay.gender == 'X' || relay.gender == ageGroup.gender;
        },
        /**
         * Разбиваем атлетов по бюджету и коммерцию на 2 массива
         * @returns {number}
         */
        getAthletesSortedData: function () {
            let allAthletesMas = this.data.athletes;
            let self = this;
            if (allAthletesMas.length > 0) {
                allAthletesMas.forEach(function (athlete) {
                    if (typeof(athlete.participant_type) !== 'undefined'){
                        if (athlete.participant_type == self.constants.PARTICIPANT_TYPE_PAID) {
                            self.paidAthletes.push(athlete);
                        }
                        if (athlete.participant_type == self.constants.PARTICIPANT_TYPE_FREE) {
                            self.freeAthletes.push(athlete);
                        }
                    }
                });
            }
        },
        /**
         * Получаем количество атлетов
         * @returns {number}
         */
        getAthletesCount: function (athletesMas) {
            let athleteMasLength = athletesMas.length;
            if (athleteMasLength > 0) {
                return athleteMasLength;
            }
            return 0;
        },
        /**
         * Проверяем, данное соревнование регионального ли уровня
         * @returns {boolean}
         */
        isRegionalCompetition: function () {
            if (this.data && this.data.competition_rank){
                return this.data.competition_rank == this.constants_for_competition.COMPETITION_RANK_REGION;
            }
            return false;
        },
        /**
         * Проверяем, данное соревнование муниципального ли уровня
         * @returns {boolean}
         */
        isMunicipalityCompetition: function () {
            if (this.data && this.data.competition_rank){
                return this.data.competition_rank == this.constants_for_competition.COMPETITION_RANK_MUNICIPALITY;
            }
            return false;
        },
        /**
         * Модальное окно при одобрении сводной заявки представителем команды
         */
        confirmCompetitionProposalApproveByProxy: function () {
            this.$parent.$refs.confirmModal.init({
                title: 'Одобрение заявки представителем',
                confirmText: 'Вы действительно хотите одобрить данную командную заявку на соревнование? После одобрения вы не сможете вносить изменения в заявку.',
                confirmButtonTitle: 'Одобрить',
                confirmButtonClass: 'btn-primary',
                confirmCallback: this.competitionProposalApproveByProxy
            })
        },
        /**
         * Одобрение сводной заявки представителем команды
         */
        competitionProposalApproveByProxy: function () {
            if (this.isProxyUser) {
                this.approving = true;
                var self = this;
                axios.post('/competitions/approve-competition-proposal-by-proxy/' + this.data.id)
                    .then(
                        response => {
                            self.approving = false;
                            Vue.set(self.data, 'general_team', response.data);
                            Bus.$emit('pushFlashAlert', {
                                type: 'success',
                                message: 'Заявка сборной команды одобрена'
                            });
                        }
                    )
                    .catch(error => {
                        console.warn('Ошибка при одобрении заявки на соревнование', error);
                        self.approving = false;
                    })
            }
        },
        /**
         * Инициализируем компонент выбора эстафет
         */
        openRelaysSelection: function() {
            let generalTeamId = this.data.general_team.id;
            this.$refs.competitionProposalRelaysSelection.init(this.data.relays, generalTeamId);
        },
        /**
         * Обработчик события после сохранения выделенных эстафет
         */
        onRelaysSaved: function() {
            this.loadGeneralTeamRelays();
        },
        /**
         * Подтверждение удаления эстафеты
         * @param proposalRelay
         */
        onClickDeleteRelay: function(proposalRelay, index) {
            this.currentProposalRelay = proposalRelay;
            this.currentProposalRelayIndex = index;
            this.$parent.$refs.confirmModal.init({
                title: 'Удаление эстафеты',
                confirmText: 'Вы действительно хотите удалить эстафету из заявки?',
                confirmButtonTitle: 'Удалить',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteRelay
            });
        },
        /**
         * Удаляем эстафету из заявки
         */
        deleteRelay: function() {
            let self = this;
            axios.delete('/competitions/' + this.id + '/proposals/general-team-relay-delete/' + this.currentProposalRelay.id)
                .then(response => {
                    self.data.relays.splice(self.currentProposalRelayIndex, 1);
                })
                .catch(error => {
                    console.warn('Ошибка при удалении эстафеты из заявки', error);
                });
        },
        /**
         * Получаем имя возрастной группы по id
         * @param ageGroupId
         * @returns {string}
         */
        getAgeGroupNameById: function (ageGroupId) {
            let ageGroups = this.data.age_groups;
            let needleAgeGroupName = '';
            if (ageGroups.length > 0) {
                ageGroups.forEach(function (ageGroup) {
                    if (ageGroupId == ageGroup.id) {
                        if (ageGroup.age_group) {
                            needleAgeGroupName = ageGroup.age_group.name;
                        } else {
                            needleAgeGroupName = Vue.filter('ageGroupTitle')(ageGroup);
                        }
                    }
                });
            }
            return needleAgeGroupName;
        }
    }
});
