<template>
    <div class="competition-month-block">
        <competition-calendar-month
            v-for="(items, index) in competitions"
            v-bind:competitions="items"
            v-bind:month-number="index"
            v-bind:key="index"
            :proposals="proposals"
        ></competition-calendar-month>
        <competition-proposal-apply-restrictions ref="competitionProposalApplyRestrictions"></competition-proposal-apply-restrictions>
    </div>
</template>

<script>

    import CompetitionCalendarMonth from "./CompetitionCalendarMonth";
    import CompetitionProposalApplyRestrictions from "./CompetitionProposalApplyRestrictions";
    export default {
        name: "CompetitionCalendarList",
        components: {CompetitionProposalApplyRestrictions, CompetitionCalendarMonth},
        props: ['proposals', 'genderShortLabels', 'options'],
        data:function () {
            return {
                competitions: [],
                // competitionIdsWithProposal: [],
                loading: false,
                filters: {
                    year: ''
                },
                keyword: ''
            }
        },
        mounted: function() {
            this.reloadData();
        },
        computed:  {
            /**
             * Урл для получения данных
             * @returns {*}
             */
            dataUrl: function () {
                return this.options.baseUrl;
            },
            /**
             * Получение параметров для запроса обновления данных
             */
            getDataParams: function () {
                return {
                    filters: this.filters,
                    keyword: this.keyword
                };
            },
        },
        methods: {
            /**
             * Применение фильтров к соревнованиям
             */
            applyFilters: function (filters) {
                this.filters = filters;
                this.filters.year = filters.year;
                this.reloadData();
            },
            search: function (keyword) {
                this.keyword = keyword;
                this.reloadData();
            },
            /**
             * Обновление данных грида
             */
            reloadData: function () {
                this.loading = true;
                let data = this.getDataParams;

                axios.get(this.dataUrl, {params: this.getDataParams})
                    .then(response => {
                        this.competitions = response.data;
                        // if (response.data.hasOwnProperty('meta')) {
                        //     $.extend(true, this.response, response.data.meta);
                        // }
                        this.loading = false;
                    })
                    .catch(error => {
                        console.warn('Ошибка при получении данных с сервера', error);
                        this.loading = false;
                    })
            },
        }
    }
</script>

<style scoped>

</style>
