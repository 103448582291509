Vue.component('notification-list', {
    data: function() {
        return {
            notifications: [],
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'notifications',
            childCode: 'new-notifications'
        });
        this.loadNewNotifications();
    },
    methods: {
        /**
         * Загружаем новые уведомления
         */
        loadNewNotifications: function() {
            axios.get('/notifications/list')
                .then(response => {
                    this.notifications = response.data;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке новых уведомлений', error);
                })
        },
		/**
         * Урл просмотра уведомления
		 * @param notification
		 * @returns {string}
		 */
		url: function (notification) {
            return '/notifications/' + notification.id;
		},
		/**
         * Нужно ли выводить заголовочные даты
		 * @param index
		 * @param notification
		 */
		needDateHeadline: function(index, notification) {
            if (index == 0) {
                return true;
            }
            if (Vue.filter('date')(notification.created_at) != Vue.filter('date')(this.notifications[index - 1].created_at)) {
                return true;
            }
            return false;
        },
		/**
		 * Отмечаем прочитанным 1 уведомление
		 * @param notification
		 */
		onClickMarkAsRead: function(notification) {
			this.markAsRead({
				id: notification.id
			});
		},
		/**
		 * Отмечаем прочитанным все уведомления
		 */
		onClickMarkAsReadAll: function() {
			this.markAsRead({
				all: true
			});
		},
		/**
		 * Отмечаем уведомление как прочитанное
		 */
		markAsRead: function (options) {
			axios.post('/notifications/mark-as-read', options)
				.then(response => {
					this.loadNewNotifications();
					Bus.$emit('reloadMenu');
				}).catch(error => {
					console.warn('Ошибка при отметки о прочтении уведомления', error);
				})
		},
    }
});
