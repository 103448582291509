Vue.component('result-wizard-report-settings', {
    props: ['competitionEntities', 'competition', 'reportDefaultOptions', 'defaultOrganizer', 'athleteRanks', 'selectionStandardGroups'],
    mixins: [require('../../../mixins/dateFormatter')],
    data: function() {
        return {
            defaultHeaderHeight: 40,
            defaultPrintShortName: false,
            entities: [],
            selectedIndex: 0,
            saving: false,
            competitionSwimPrograms: [],
            competitionPlaces: [],
            competitionAthleteRanks: [],
            availableReportCodes: {},
            individualEventSplitsOptions: [
                {
                    value: 0,
                    label: 'Нет предварительных результатов'
                },
                {
                    value: 1,
                    label: 'Предварительные результаты на дистанции 100м и более'
                },
                {
                    value: 2,
                    label: 'Предварительные результаты на дистанции 200м и более'
                },
                {
                    value: 3,
                    label: 'Предварительные результаты на дистанции 400м и более'
                },
                {
                    value: 4,
                    label: 'Предварительные результаты на дистанции 800м и более'
                },
                {
                    value: 5,
                    label: 'Все предварительные результаты (с дельтой)'
                },
                {
                    value: 6,
                    label: 'Все предварительные результаты (без дельты)'
                }
            ],
            only100mSplitsOptions: [
                {
                    value: 0,
                    label: '200 метров'
                },
                {
                    value: 1,
                    label: '400 метров'
                },
                {
                    value: 2,
                    label: '800 метров'
                },
                {
                    value: 3,
                    label: '1500 метров'
                },
                {
                    value: 4,
                    label: 'Все 50 метров'
                }
            ],
            separateTime24Options: [
                {
                    value: 0,
                    label: 'Время различия'
                },
                {
                    value: 1,
                    label: 'Накопленное время'
                }
            ],
            relayEventsOptions: [
                {
                    value: 0,
                    label: 'Без имен/раздельное время'
                },
                {
                    value: 1,
                    label: 'Только имена пловцов эстафеты'
                },
                {
                    value: 2,
                    label: 'Только раздельное время'
                },
                {
                    value: 3,
                    label: 'Имена и раздельное время'
                },
            ]
        };
    },
    mounted: function() {
        this.prepareAvailableReportCodes();
        this.prepareEntities();
    },
    computed: {
        /**
         * Виды программ задействованные в соревновании
         */
        availableSwimPrograms: function() {
            let swimPrograms = [];
            let swimProgramIds = [];
            this.competition.swim_programs.forEach(function(item) {
                if (swimProgramIds.indexOf(item.swim_program.id) == -1) {
                    swimProgramIds.push(item.swim_program.id);
                    swimPrograms.push(item.swim_program);
                }
            });
            return _.sortBy(swimPrograms, function(item) {
                return item.relay_count * 10000 + item.distance;
            });
        },
    },
    methods: {
        /**
         * Добавление нового объекта соревнования в визард
         */
        addEntity: function() {
            let newEntity = {}
            if (this.entities.length > 0) {
                newEntity = JSON.parse(JSON.stringify(this.entities[this.entities.length - 1]));
                newEntity.name = this.competition.name;
                newEntity.id = null;
                newEntity.place_name = this.competition.city.name + ', ' + this.getFormattedCompetitionDates(this.competition.date_from, this.competition.date_to);
                newEntity.active_report = 'result_protocol';
            } else {
                newEntity = {
                    id: null,
                    save_results: 1,
                    name: this.competition.name,
                    place_name: this.competition.city.name + ', ' + this.getFormattedCompetitionDates(this.competition.date_from, this.competition.date_to),
                    organizer: this.defaultOrzanizer,
                    header_height: this.defaultHeaderHeight,
                    print_short_name: this.defaultPrintShortName,
                    age_groups: [],
                    swim_programs: [],
                    active_report: 'result_protocol',
                    reports: {}
                };
                for (let reportCode in this.reportDefaultOptions) {
                    newEntity.reports[reportCode] = {
                        is_public: true,
                        is_turned_off: false,
                        code: reportCode,
                        options: this.reportDefaultOptions[reportCode]
                    };
                }
            }

            newEntity.age_groups = [];
            this.competition.age_groups.forEach(function(item) {
                newEntity.age_groups.push(item.id);
            })
            newEntity.swim_programs = [];
            this.availableSwimPrograms.forEach(function(item) {
                newEntity.swim_programs.push(item.id);
            })
            this.entities.push(newEntity);
        },

        /**
         * Подготовка данных для формы
         */
        prepareEntities: function() {
            for (let index = 0; index < this.competitionEntities.length; index++) {
                let competitionEntity = this.competitionEntities[index];
                let data = {
                    id: competitionEntity.id,
                    save_results: competitionEntity.save_results,
                    name: competitionEntity.name,
                    place_name: competitionEntity.place_name,
                    organizer: competitionEntity.organizer,
                    header_height: competitionEntity.header_height,
                    print_short_name: competitionEntity.print_short_name,
                    age_groups: competitionEntity.age_groups,
                    swim_programs: competitionEntity.swim_programs,
                    active_report: 'result_protocol',
                    reports: {}
                };
                let self = this;
                competitionEntity.reports.forEach(function(report) {
                    data.reports[report.code] = {
                        code: report.code,
                        is_public: report.is_public ? true : false,
                        is_turned_off: report.is_turned_off,
                        options: JSON.parse(JSON.stringify($.extend(true, self.reportDefaultOptions[report.code],  report.options)))
                    };
                });
                this.entities.push(data);
            }
        },
        /**
         * В зависимости от ранга соревнования разные отчеты
         */
        prepareAvailableReportCodes: function() {
            this.availableReportCodes = {
                result_protocol: true,
                team_scores: true,
                selection_standards: true,
                medals: true,
                medals_by_event: true,
                medals_by_team: true,
                multi_medalists: true
            };
            if (this.competition.is_high_level_rank) {
                this.availableReportCodes.team_scores = false;
                this.availableReportCodes.medals = false;
                this.availableReportCodes.medals_by_team = false;
            }
        },
        /**
         * Аппрув удаления объекта соревнования
         */
        onClickDeleteEntity: function(index) {
            this.selectedIndex = index;
            this.$refs.confirmModal.init({
                title: 'Удаление соревнования',
                confirmText: 'Вы действительно хотите удалить соревнование?',
                confirmButtonTitle: 'Удалить',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteEntity
            });
        },
        /**
         * Удаляем объект соревнования
         */
        deleteEntity: function () {
            this.entities.splice(this.selectedIndex, 1);
        },
        /**
         * Вернуться назад
         */
        prev: function () {
            this.$parent.setStep(1);
        },
        /**
         * Перейти к 3 шагу
         */
        next: function() {
            this.saving = true;
            axios.post('/competitions/' + this.competition.id + '/entities/update',{
                    entities: this.entities
                })
                .then(response => {
                    this.$parent.setStep(3);
                    this.$parent.selectedEntityIds = response.data;
                    this.saving = false;
                })
                .catch(error => {
                    console.warn('Ошибка при сохранении данных объектов соревнований', error);
                    this.saving = false;
                });
        },
        /**
         * Формируем наименование возрастной группы соревнования
         * @param competitionAgeGroup
         * @returns {*}
         */
        getAgeGroupLabel: function(competitionAgeGroup) {
            if (competitionAgeGroup.age_group) {
                return competitionAgeGroup.age_group.name;
            }
            return Vue.filter('ageGroupTitle')(competitionAgeGroup);
        },
        /**
         * @brief Получаем массив добавленных программ в соревнование
         * @param swimPrograms
         * @param index
         * @param type
         * @returns {*}
         */
        addedSwimProgramIds: function (swimPrograms, index, type) {
            this.competitionSwimPrograms[type] = [];
            this.competitionSwimPrograms[type][index] = swimPrograms;
            if (this.competitionSwimPrograms[type][index] && (this.competitionSwimPrograms[type][index].length == 0)) {
                this.competitionSwimPrograms[type][index].push(this.availableSwimPrograms[0].id);
            }
            return this.competitionSwimPrograms[type][index];
        },
        /**
         * @brief Добавить программу в соревнование
         * @param type
         * @param index
         */
        addSwimProgram: function (type, index) {
            this.competitionSwimPrograms[type][index].push(this.availableSwimPrograms[0].id);
        },
        /**
         * @brief Удалить программу из соревнования
         * @param type
         * @param index
         * @param swimProgramIndex
         */
        deleteSwimProgram: function (type, index, swimProgramIndex) {
            this.competitionSwimPrograms[type][index].splice(swimProgramIndex, 1);
        },
        /**
         * @brief Получаем массив добавленных мест в соревнование
         * @param placeScores
         * @param index
         * @returns {*}
         */
        addedPlaces: function (placeScores, index) {
            this.competitionPlaces[index] = placeScores;
            return this.competitionPlaces[index];
        },
        /**
         * @brief Добавить место в соревнование
         * @param entity
         * @param index
         */
        addPlace: function (entity, index) {
            this.competitionPlaces[index].push(0);
        },
        /**
         * @brief Удалить место спортсменов из соревнования
         * @param entity
         * @param index
         * @param placeIndex
         */
        deletePlace: function (entity, index, placeIndex) {
            this.competitionPlaces[index].splice(placeIndex, 1);
        },
        /**
         * Показать превью
         */
        showPreview: function (index) {
            let height = parseInt(this.entities[index].header_height);
            if (isNaN(height)) {
                height = 40; // дефолтное значение
            }

            let data = {
                height: height,
                name: this.entities[index].name,
                organizer: this.entities[index].organizer,
                place_name: this.entities[index].place_name,
            };

            let params = new URLSearchParams(data).toString();
            window.open('/reports/competitions/' + this.competition.id + '/results/preview?' + params, '_blank');
            return false;
        },
    }
});
