/**
 * Format the given date.
 */
Vue.filter('date', value => {
    return value ? moment(value).format('DD.MM.YYYY') : '';
});


/**
 * Format the given date as a timestamp.
 */
Vue.filter('datetime', value => {
	return moment(value).format('DD.MM.YYYY HH:mm');
});

/**
 * Format the given date as a timestamp.
 */
Vue.filter('time', value => {
    return moment(value).format('HH:mm');
});

/**
 * Формат для вывода года из даты
 */
Vue.filter('dateyear', value => {
	return moment(value).format('YYYY');
});

/**
 * Формат для вывода пола 1 буквой
 */
Vue.filter('gender_short', value => {
	return value == 'M' ? 'М' : 'Ж';
});

/**
 * Формат для вывода пола
 */
Vue.filter('gender', value => {
    return value == 'M' ? 'Мужской' : 'Женский';
});

/**
 * Формат для вывода возраста
 */
Vue.filter('age', value => {
    return moment().diff(value, 'years');
});

/**
 * Число лет спортсмену на конец года
 */
Vue.filter('ageYear', value => {
    let endOfYear = moment();
    endOfYear = endOfYear.set('month', 11);  // April
    endOfYear = endOfYear.set('date', 31);
    return endOfYear.diff(value, 'years');
});

/**
 * Format the given date as a timestamp.
 */
Vue.filter('strdate', value => {
	if (moment(value).format('DD.MM.YYYY') == moment().format('DD.MM.YYYY')) {
		return 'Сегодня';
	}
	return moment(value).format('DD MMMM YYYY');
});

/**
 * Фильтр для вывода телефона
 */
Vue.filter('phone', value => {
	if (!value) {
		return '';
	}
	var value = value.replace(/[^0-9.]/g, "").substr(1, 10);
	var masked = '+7 (';
	if (value.length < 3) {
		return masked + value;
	}
	if (value.length == 3) {
		return masked + value + ')';
	}
	masked += value.substr(0, 3) + ')';
	if (value.length <= 6) {
		return masked + ' ' + value.substr(3, 3);
	}
	masked += ' ' + value.substr(3, 3);
	if (value.length <= 8) {
		return masked + '-' + value.substr(6, 2);
	}
	masked += '-' + value.substr(6, 2) + '-' + value.substr(8, 2);
	return masked;
});


/**
 * Format the given date into a relative time.
 */
Vue.filter('relative', value => {
    return moment(value).locale('en-short').fromNow();
});


/**
 * Convert the first character to upper case.
 *
 * Source: https://github.com/vuejs/vue/blob/1.0/src/filters/index.js#L37
 */
Vue.filter('capitalize', value => {
    if (! value && value !== 0) {
        return '';
    }

    return value.toString().charAt(0).toUpperCase()
        + value.slice(1);
});

/**
 * Формируем заголовок для возрастных групп
 */
Vue.filter('ageGroupTitle', value => {
    let title = Vue.filter('agePeriodTitle')(value);
    if (value.gender == 'M') {
        return title + ', ' + Vue.filter('maleTitle')(value.age_max);
    } else if (value.gender == 'F') {
        return title + ', ' + Vue.filter('femaleTitle')(value.age_max);
    } else {
        return title + ', Микст';
    }
});

/**
 * Заголовок для муж пола
 */
Vue.filter('maleTitle', ageMax => {
    return ageMax > 0 && ageMax <= 16 ? 'Юноши' : 'Мужчины';
});

/**
 * Заголовок для женского пола
 */
Vue.filter('femaleTitle', ageMax => {
    return ageMax > 0 && ageMax <= 16 ? 'Девочки' : 'Женщины';
});

/**
 * Заголовок для возрастного периода
 */
Vue.filter('agePeriodTitle', value => {
    if (value.age_min == 0 && value.age_max == 99 || value.age_min == -1 && value.age_max == -1) {
        return 'Открытые';
    }
    if (value.age_min == -1) {
        return 'До ' + value.age_max + ' лет';
    }
    if (value.age_max == -1) {
        return 'От ' + value.age_min + ' лет';
    }
    return value.age_min + ' - ' + value.age_max + ' лет';
});

Vue.filter('pluralize', (value, labels) => {
	let n = Math.abs(value);
	n %= 100;
	if (n >= 5 && n <= 20) {
		return value + ' ' + labels[2];
	}
	n %= 10;
	if (n === 1) {
		return value + ' ' + labels[0];
	}
	if (n >= 2 && n <= 4) {
		return value + ' ' + labels[1];
	}
	return value + ' ' + labels[2];
});

/**
 * Время плавания в формате чч:мм:сс.млсмлс
 */
Vue.filter('swimTime', function (value) {

    /**
     * Добавление предстоящего нуля в вывод двузначных чисел
     * @param value
     * @returns {string}
     */
    function addZero(value) {
        if (value < 10) {
            return '0' + value;
        }
        return value.toString();
    }

    if (!value) {
        return '';
    }
    value = Math.round(value / 10);
    if (value < 100) {
        return value;
    }
    if (value < 6000) {
        return Math.floor(value / 100) + '.' +
            addZero(value % 100);
    }
    if (value < 360000) {
        return addZero(Math.floor(value / 6000)) + ':' +
            addZero(Math.floor((value % 6000) / 100)) +'.' +
            addZero(value % 100);
    }
    return addZero(Math.floor(value / (60 * 6000)))  + ':' +
        addZero(Math.floor((value % (60 * 6000)) / (60 * 100))) + ':' +
        addZero(Math.floor((value % 6000) / 100)) + '.' +
        addZero(value % 100);
});
