<template>
    <div class="filter-container">
        <div class="filter-label"
             :class="isNotEmpty ? 'filter-active' : ''"
             :dusk="dusk"
             aria-haspopup="true"
             aria-expanded="false"
             data-toggle="dropdown"
        >{{getTitle}}</div>

        <div v-on:click.stop.self class="dropdown-menu filter-content filter-gender-age" aria-labelledby="dropdownMenuButton">
            <div class="filter-gender">
                <div class="checkbox-container">
                    <input id="filter-male" type="checkbox" dusk="M" v-model="maleActive" v-bind:true-value="1" v-bind:false-value="0" />
                    <label class="checkbox-label" for="filter-male">М</label>
                </div>
                <div class="checkbox-container">
                    <input id="filter-female" type="checkbox" dusk="F" v-model="femaleActive" v-bind:true-value="1" v-bind:false-value="0" />
                    <label class="checkbox-label" for="filter-female">Ж</label>
                </div>
            </div>

            <div class="filter-age">
                <input class="form-control" type="number" dusk="filterYearFrom" maxlength="4" v-model="yearFrom" />
                <hr />
                <input class="form-control" type="number" dusk="filterYearTo" maxlength="4" v-model="yearTo" />
            </div>

            <hr />
            <div class="filter-buttons">
                <span v-on:click="resetFilter" class="filter-action-span">
                    Очистить
                </span>
                <button dusk='ageGenderSave' class="btn btn-primary float-right" v-on:click="applyFilter">
                    Сохранить
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AgeGenderFilter",
        props: ['genderMaps', 'genderShortLabels', 'dusk'],
        data: function () {
            return {
                yearFrom: '',
                yearTo: '',
                maleActive: 0,
                femaleActive: 0,
                filters: {}
            }
        },
        methods: {
            applyFilter: function (send = true) {
                this.filters = {
                    gender: this.getGender(),
                    yearFrom: this.yearFrom,
                    yearTo: this.yearTo
                };
                let filters = {
                    gender: this.getGender(),
                    yearFrom: this.yearFrom,
                    yearTo: this.yearTo
                };

                if (send) {
                    this.$emit('applyFilter', filters);
                }
                this.closeDropDown();
            },
            resetFilter: function (apply = true) {
                this.yearFrom = '';
                this.yearTo = '';
                this.maleActive = 0;
                this.femaleActive = 0;

                this.applyFilter(apply);
            },
            getGender: function () {
                //Если обе галочки выбраны, или обе не выбраны - возвращаем всех
                //@todo change
                if (this.maleActive === this.femaleActive) {
                    return '';
                }
                if (this.maleActive) {
                    return 'M';
                }
                return 'F';
            },
            getDateStr: function () {
                let yearFrom = this.filters.yearFrom ? this.filters.yearFrom : '';
                let yearTo = this.filters.yearTo ? this.filters.yearTo : '';
                return yearFrom + ' - ' + yearTo;

            },
            getGenderStr: function () {
                let gender = this.filters.gender;
                if (!gender) {
                    return '';
                }

                return this.genderShortLabels[gender];
            },
            closeDropDown: function () {
                $('.show').removeClass('show');
            }
        },
        computed: {
            getTitle: function() {
                if (!this.filters.yearFrom && !this.filters.yearTo && !this.filters.gender) {
                    return 'Возраст и пол';
                }

                let dateStr = this.getDateStr();
                let genderStr = this.getGenderStr();

                if (dateStr && genderStr) {
                    return dateStr + ', ' + genderStr;
                }

                return dateStr + genderStr;
            },
            isNotEmpty: function () {
                return this.filters.yearFrom || this.filters.yearTo || this.filters.gender;
            }
        }

    }
</script>

<style scoped>

</style>
