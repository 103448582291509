/**
 * Инициализируйте точки расширения формы Trusty.
 */
Trusty.forms = {
    registerClub: {},
};

/**
 * Загружает вспомогательный класс TrustyForm.
 */
require('./form');

/**
 * Определите класс коллекции TrustyFormError.
 */
require('./errors');

/**
 * Добавляет дополнительные помощники HTTP / формы к объекту Trusty.
 */
$.extend(Trusty, require('./http'));
