Vue.component('system-request-decline-form', {
	data: function() {
		return {
			data: {},
			successCallback: null,
			form: new TrustyForm({
				id: null,
				declined_reason: ''
			}),
		}
	},
	methods: {
		/**
		 * Отправка формы на сервер
		 */
		send: function () {
			this.form.id = this.data.id;
			var self = this;
			Trusty.post('/system-requests/decline', this.form)
				.then(response => {
					$('.modal-system-request-decline').modal('hide');
					self.successCallback(response);
				})
				.catch(error => {
					console.warn('Ошибка при отклонении заявки', error);
				})
		},
		init: function (data, successCallback) {
			this.data = data;
			this.successCallback = successCallback;
			$('.modal-system-request-decline').modal('show');
		}
	}
});
