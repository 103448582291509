Vue.component('club-list', {
    data: function() {
        return {

        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'organizations',
            childCode: 'clubs'
        });
    },
    methods: {
		/**
		 * Обработка действия отключения клуба
		 * @param row
		 */
		confirmClubTurnOff: function(row) {
			var self = this;
			this.$refs.clubTurnOffModal.init(row, function () {
				self.$refs.clubGrid.reloadData();
				Bus.$emit('pushFlashAlert', {
					type: 'success',
					message: 'Клуб успешно отключен'
				})
			});
		},
		/**
		 * Обработчик вызова после успешной активации клуба
		 * @param row
		 */
		onTurnOnSuccess: function(row) {
			Bus.$emit('pushFlashAlert', {
				type: 'success',
				message: 'Клуб успешно активирован'
			})
		}
    }
});
