<template>
    <div>
        <div class="row competition-item mt-3 ml-2 pb-2" @click="toCompetitionViewPage()">
            <div class="col-md-2">
                <span class="competition-calendar-item-header semi-bold">
                    {{getFormattedCompetitionDates(this.competition.date_from, this.competition.date_to)}}
                </span>
                <span>{{ this.competition.city.name }},</span><br />
                <span>{{ this.competition.city.region.name }}</span>
            </div>
            <div class="col-md-7">
                <span class="competition-calendar-item-header semi-bold">{{competition.name}} </span>
                <div class="competition-calendar-item-ranks">
                    <span
                        v-for="competitionAgeGroup in competition.age_groups"
                        :key="competitionAgeGroup.id"
                    >{{getAgeGroupLabel(competitionAgeGroup)}}</span>
                </div>
                <div class="competition-calendar-item-docs mt-2">
                    <div class="detail-value">
                        <span v-if="competition.regulation_doc_id || competition.regulation_doc_link">
                            <a :href="competition.regulation_doc_link" class="btn-link btn-block" target="_blank">
                                <img src="/images/icons/icon-document.svg" class="mr-1 mb-1">
                                <strong>Регламент</strong>
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <hr v-if="!isLast" />
    </div>
</template>

<script>
    export default {
        name: "GeneralCompetitionItem",
        props: ['competition', 'isLast'],
        mixins: [require('../../competitions/mixins/dateFormatter')],
        methods: {
            /**
             * Формируем наименование возрастной группы соревнования
             * @param competitionAgeGroup
             * @returns {*}
             */
            getAgeGroupLabel: function(competitionAgeGroup) {
                if (competitionAgeGroup.age_group) {
                    return competitionAgeGroup.age_group.name;
                }
                return Vue.filter('ageGroupTitle')(competitionAgeGroup);
            },
            /**
             * Перейти на страницу просмотра соревнования
             */
            toCompetitionViewPage: function () {
                location.href = '/general/competitions/' + this.competition.id;
            },
        }
    }
</script>

<style scoped>

</style>
