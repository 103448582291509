Vue.component('notification-read-list', {
    data: function() {
        return {
            notifications: [],
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'notifications',
            childCode: 'read-notifications'
        });
    },
    methods: {
		/**
         * Заголовок уведомления из модуля и названия
		 * @param value
		 * @param row
		 */
		notificationTitle: function(value, row) {
            return "<strong>" + row.module_title + "</strong>: " + value;
        }
    }
});
