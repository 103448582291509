Vue.component('general-result-protocols', {
    props: ['competitionEntities'],
    data: function() {
        return {
        }
    },
    computed: {
        /**
         * Загружены ли результаты в систему
         * @returns {any}
         */
        hasResults: function() {
            return this.$parent.hasResults;
        }
    },
});
