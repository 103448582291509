Vue.component('swim-program-form', {
    props: ['title', 'swimProgramId', 'swimStrokes'],
    data: function() {
        return {
            form: new TrustyForm({
                id: '',
                name: '',
                distance: 0,
                relay_count: 1,
                swim_stroke_id: 0,
            }),
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'dictionary',
            mode: 'detail',
            backLink: '/dictionaries/swim-programs',
            backTitle: 'К списку программ',
            title: this.title
        });
        if (this.swimProgramId) {
            this.loadSwimProgram();
        }
    },
    computed: {
        /**
         * Урл для отправки запроса
         * @returns {string}
         */
        sendUrl: function () {
            return this.swimProgramId
                ? '/dictionaries/swim-programs/' + this.swimProgramId
                : '/dictionaries/swim-programs';
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            Trusty.post(this.sendUrl, this.form)
                .then(response => {
                    location.href = '/dictionaries/swim-programs';
                })
                .catch(error => {

                })
        },
        /**
         * Загружаем данные о программе
         */
        loadSwimProgram: function() {
            axios.get('/dictionaries/swim-programs/fetch/' + this.swimProgramId)
                .then(response => {
                    $.extend(true, this.form, response.data);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке программы', error);
                })
        }
    }
});
