Vue.component('user-form', {
    props: ['title', 'teamRoleLabels', 'userId'],
    data: function() {
        return {
            form: new TrustyForm({
                id: '',
                last_name: '',
                first_name: '',
                patronymic: '',
                team_role: 2,
				email: '',
				phone: ''
            }),
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'settings',
            mode: 'detail',
            backLink: '/settings/users',
            backTitle: 'К списку пользователей',
            title: this.title
        });
        if (this.userId) {
            this.loadUser();
        }
    },
    computed: {
        /**
         * Урл для отправки запроса
         * @returns {string}
         */
        sendUrl: function () {
            return this.userId
                ? '/settings/users/' + this.userId
                : '/settings/users';
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            Trusty.post(this.sendUrl, this.form)
                .then(response => {
                    location.href = '/settings/users';
                })
                .catch(error => {

                })
        },
        /**
         * Загружаем данные о пользователе
         */
        loadUser: function() {
            axios.get('/settings/users/fetch/' + this.userId)
                .then(response => {
                    $.extend(true, this.form, response.data);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке пользователя', error);
                })
        }
    }
});
