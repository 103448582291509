let constants = require('./../proposals/constants');

module.exports = {
    methods: {
        /**
         * Доступные возрастные группы для выбранного спортсмена
         */
        availableAgeGroupIds: function(athlete, participantType) {
            let ids = [];
            for (let index = 0; index < this.competition.age_groups.length; index++) {
                if (this.isAgeGroupAvailableForAthlete(this.competition.age_groups[index], athlete, participantType)) {
                    ids.push(this.competition.age_groups[index].id);
                }
            }
            return ids;
        },
        /**
         * Проверяем доступность возрастной группы для атлета
         * @param competitionAgeGroup
         * @param athlete
         * @param participantType
         * @returns {boolean}
         */
        isAgeGroupAvailableForAthlete: function(competitionAgeGroup, athlete, participantType) {
            if (competitionAgeGroup.gender != athlete.gender) {
                return false;
            }

            if (participantType == constants.PARTICIPANT_TYPE_FREE &&  competitionAgeGroup.athlete_rank_id) {
                if (!athlete.athlete_rank_id) {
                    return false;
                }
                if (athlete.athlete_rank_to && moment(athlete.athlete_rank_to) < moment(this.competition.date_to)) {
                    return false
                }
                let needRankOrder = -1;
                let athleteRankOrder = 0;
                for (let index = 0; index < this.athleteRanks.length; index++) {
                    if (this.athleteRanks[index].id == competitionAgeGroup.athlete_rank_id) {
                        needRankOrder = this.athleteRanks[index].sort_order;
                    }
                    if (this.athleteRanks[index].id == athlete.athlete_rank_id) {
                        athleteRankOrder = this.athleteRanks[index].sort_order;
                    }
                }
                if (athleteRankOrder > needRankOrder) {
                    return false;
                }
            }

            if (competitionAgeGroup.age_min == -1 && competitionAgeGroup.age_max == -1) {
                return true;
            }

            let age = moment(this.competition.date_from).year() - moment(athlete.birth_date).year();
            if (competitionAgeGroup.age_min != -1 && competitionAgeGroup.age_min > age) {
                return false;
            }
            if (competitionAgeGroup.age_max != -1 && competitionAgeGroup.age_max < age) {
                return false;
            }

            return true;
        }
    }
};
