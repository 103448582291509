Vue.component('athletes-results', {
    data: function() {
        return {
            processing: false,
            id: null,
            filterDateUsed: false,
            filterPlaceUsed: false,
            filterDateFrom : '',
            filterDateTo : '',
            filterDefaultDateFrom : '',
            filterDefaultDateTo : '',
            filterPlaceFrom : 1,
            filterPlaceTo : 1,
            filterDefaultPlaceFrom : 1,
            filterDefaultPlaceTo : 1,
            results: [],
            filteredResults: [],
            data: {
                sortedByCompetitionResults: []
            }
        }
    },
    computed: {
        /**
         * Заголовок для фильтра по датам
         */
        filterDateLabel: function() {
            if (this.filterDateUsed) {
                return 'Дата: ' + (this.filterDateFrom ? moment(this.filterDateFrom).format('DD.MM.YYYY') : '')
                    + ' - ' + (this.filterDateTo ? moment(this.filterDateTo).format('DD.MM.YYYY') : '');
            }
            return 'Дата';
        },
        /**
         * Заголовок фильтра по местам
         */
        filterPlaceLabel: function() {
            if (this.filterPlaceUsed) {
                if (this.filterPlaceFrom == this.filterDateTo) {
                    return this.filterPlaceFrom + ' место';
                }
                return 'Места' + (this.filterPlaceFrom ? ' от ' + this.filterPlaceFrom : '')
                    + (this.filterPlaceTo ? ' до ' + this.filterPlaceTo : '');
            }
            return 'Место';
        }
    },
    methods: {
        /**
         * Инициализируем компонент результатов соревнования спортсмена
         */
        init: function (athleteId) {
            if (this.results.length == 0) {
                this.id = athleteId;
                this.processing = true;
                axios.get('/athletes/' + this.id + '/competition-results')
                    .then(response => {
                        this.results = response.data;
                        this.updateFilteredResults();
                        this.processing = false;
                    })
                    .catch(error => {
                        console.warn('Ошибка при загрузке данных результатов спортсмена', error);
                        this.processing = false;
                    });
            }
        },
        /**
         * Обнулем фильтр по дате
         */
        clearFilterDate: function () {
            this.filterDateUsed = false;
            this.filterDateFrom = this.filterDefaultDateFrom;
            this.filterDateTo = this.filterDefaultDateTo;
            this.updateFilteredResults();
        },
        /**
         * Сохраняем параметры фильтра по датам
         */
        saveFilterDate: function () {
            this.filterDateUsed = true;
            this.updateFilteredResults();
        },
        /**
         * Обнулем фильтр по местам
         */
        clearFilterPlace: function () {
            this.filterPlaceFrom = this.filterDefaultPlaceFrom;
            this.filterPlaceTo = this.filterDefaultPlaceTo;
            this.filterPlaceUsed = false;
            this.updateFilteredResults();
        },
        /**
         * Показываем выбранные значения в фильтре по месту
         */
        saveFilterPlace: function () {
            this.filterPlaceUsed = true;
            this.updateFilteredResults();
        },
        /**
         * Обновляем отфильтрованные данные
         */
        updateFilteredResults: function() {
            this.filteredResults = [];
            let competitionIdMaps = {};
            if (this.results.length > 0) {
                for (let resultIndex = 0; resultIndex < this.results.length; resultIndex++) {
                    let competitionAthleteResult = this.results[resultIndex];
                    if (competitionAthleteResult.competition_athlete_places && competitionAthleteResult.competition_athlete_places.length > 0) {
                        for (let placeIndex = 0; placeIndex < competitionAthleteResult.competition_athlete_places.length; placeIndex++) {
                            let competitionAthletePlace = competitionAthleteResult.competition_athlete_places[placeIndex];
                            if (this.checkPlaceByFilters(competitionAthletePlace)) {
                                if (!competitionIdMaps.hasOwnProperty(competitionAthletePlace.competition_entity_id)) {
                                    competitionIdMaps[competitionAthletePlace.competition_entity_id] = this.filteredResults.length;
                                    this.filteredResults.push({
                                        competition_date: competitionAthletePlace.competition_entity.competition_date_from,
                                        competition_city_name: competitionAthletePlace.competition_entity.competition_city_name,
                                        competition_name: competitionAthletePlace.competition_entity.name,
                                        places: []
                                    });
                                }
                                this.filteredResults[competitionIdMaps[competitionAthletePlace.competition_entity_id]].places.push({
                                    place: competitionAthletePlace.place,
                                    status_label: competitionAthleteResult.status_label,
                                    swim_time: competitionAthleteResult.swim_time,
                                    athlete_rank: competitionAthleteResult.athlete_rank ? competitionAthleteResult.athlete_rank.code : '',
                                    swim_program_name: competitionAthleteResult.competition_swim_program.swim_program.name,
                                    swim_program_round_label: competitionAthleteResult.competition_swim_program.swim_program_round_label
                                });
                            }
                        }
                    }
                }
            }
        },
        /**
         * Проверяем место спортсмена по данным фильтра
         * @param competitionAthletePlace
         * @returns {boolean}
         */
        checkPlaceByFilters: function (competitionAthletePlace) {
            if (this.filterDateUsed) {
                if (this.filterDateFrom && moment(this.filterDateFrom) > moment(competitionAthletePlace.competition_entity.competition_date_from)) {
                    return false;
                }
                if (this.filterDateTo && moment(this.filterDateTo) < moment(competitionAthletePlace.competition_entity.competition_date_from)) {
                    return false;
                }
            }
            if (this.filterPlaceUsed) {
                if (this.filterPlaceFrom && this.filterPlaceFrom > competitionAthletePlace.place) {
                    return false;
                }
                if (this.filterPlaceTo && this.filterPlaceTo < competitionAthletePlace.place) {
                    return false;
                }
            }

            return true;
        }
    }
});
