Vue.component('competition-tab-description', {
    mixins: [require('../mixins/dateFormatter')],
    props: ['id', 'emptyLabel', 'genderShortLabels', 'ageGroups', 'data', 'athleteRanks', 'roundLabels'],
    data: function() {
        return {
            constants: require('../constants'),
        }
    },
    methods: {
        /**
         * @brief Получение количества возрастных групп у определенной сессии
         * @param genderValue
         * @param isAllAgeGroupsValue
         * @param ageGroupsMas
         * @returns {number}
         */
        getCountAgeGroups: function(genderValue, isAllAgeGroupsValue, ageGroupsMas) {
            let objs = this.availableAgeGroups(genderValue, isAllAgeGroupsValue, ageGroupsMas)
            return objs.length;
        },
        /**
         * @brief Получение массива возрастных групп у определенной сессии
         * @param genderValue
         * @param isAllAgeGroupsValue
         * @param ageGroupsMas
         * @returns {methods.selectedAgeGroups|[]|*[]|*}
         */
        availableAgeGroups: function(genderValue, isAllAgeGroupsValue, ageGroupsMas) {
            if (!this.data.age_groups) {
                return [];
            }
            if  (isAllAgeGroupsValue == 1) {
                return this.data.age_groups.filter(item => item.gender == genderValue);
            } else if (ageGroupsMas.length > 0) {
                let dataAgeGroups = this.data.age_groups;
                let objAgeGroups = [];
                dataAgeGroups.forEach(function (ageGroup) {
                    if (ageGroupsMas.includes(ageGroup.id)) {
                        objAgeGroups.push(ageGroup);
                    }
                });
                return objAgeGroups;
            }
            return [];
        },
        /**
         * Формируем наименование возрастной группы соревнования
         * @param competitionAgeGroup
         * @returns {*}
         */
        getAgeGroupLabel: function(competitionAgeGroup) {
            if (competitionAgeGroup.age_group) {
                return competitionAgeGroup.age_group.name;
            }
            return Vue.filter('ageGroupTitle')(competitionAgeGroup);
        },
        /**
         * Получаем название разряда по ID
         * @param athleteRankId
         * @returns {string|*}
         */
        athleteRankName: function(athleteRankId) {
            let filtered = this.athleteRanks.filter(item => item.id === athleteRankId);
            if (filtered.length) {
                return filtered[0].name;
            }
            return '';
        }
    }
});
