Vue.component('seminar-list', {
    props: [],
    data:function () {
        return {
            currentRow: null
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'seminars',
            childCode: 'seminars'
        });
    },

    methods: {
        /**
         * Заголовок статуса семинара
         * @param row
         * @returns {*}
         */
        statusFormat: function(value, row) {
            return row.status_title;
        },
        /**
         * Подтверждение удаление семинара
         */
        confirmDelete: function(row) {
            this.currentRow = row;
            this.$refs.confirmModal.init({
                title: 'Перенос семинара в архив',
                confirmText: 'Вы действительно хотите перенести в архив выбранный семинар?',
                confirmButtonTitle: 'Перенeсти',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteSeminar
            })
        },
        /**
         * Удаление семинара
         */
        deleteSeminar: function() {
            this.$refs.seminarGrid.setLoading(true);
            var self = this;
            axios.delete('/seminars/delete/' + this.currentRow.id)
                .then(
                    response => {
                        self.$refs.seminarGrid.reloadData();
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при удалении семинара', error);
                    self.$refs.seminarGrid.setLoading(false);
                })
        },
    }
});
