Vue.component('age-group-form', {
    props: ['title', 'genderTypes', 'ageGroupId'],
    data: function() {
        return {
            form: new TrustyForm({
                id: '',
                name: '',
                age_min: '',
                age_max: '',
                rank_type: 0,
                action_period: '',
                sort_order: 0,
            }),
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'dictionary',
            mode: 'detail',
            backLink: '/dictionaries/age-groups',
            backTitle: 'К списку возрастных групп',
            title: this.title
        });
        if (this.ageGroupId) {
            this.loadAgeGroup();
        }
    },
    computed: {
        /**
         * Урл для отправки запроса
         * @returns {string}
         */
        sendUrl: function () {
            return this.ageGroupId
                ? '/dictionaries/age-groups/' + this.ageGroupId
                : '/dictionaries/age-groups';
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            Trusty.post(this.sendUrl, this.form)
                .then(response => {
                    location.href = '/dictionaries/age-groups';
                })
                .catch(error => {

                })
        },
        /**
         * Загружаем данные о возрастной группе
         */
        loadAgeGroup: function() {
            axios.get('/dictionaries/age-groups/fetch/' + this.ageGroupId)
                .then(response => {
                    $.extend(true, this.form, response.data);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке возрастной группы', error);
                })
        }
    }
});
