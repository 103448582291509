Vue.component('athlete-transfer-form', {
	props: ['coaches', 'athleteId'],
	data: function () {
		return {
			athleteList: [],
			athlete: null,
			form: new TrustyForm({
				athlete_id: null,
				coach_ids: [],
			}),
		}
	},
	mounted: function () {
		Bus.$emit('changeMenuState', {
			itemCode: 'members',
			mode: 'detail',
			backLink: '/athletes',
			backTitle: 'К списку спортсменов',
			title: 'Перевод спортсмена из другого клуба'
		});
		if (this.athleteId) {
			this.fetchAthletes('', null, this.athleteId);
		}
	},
	methods: {
		/**
		 * Получаем спортсменов для показа
		 * @param search
		 * @param loading
		 */
		fetchAthletes: function(search, loading, athleteId) {
			loading && loading(true);
			var self = this;
			axios.get('/athletes/search-for-transfer?keyword=' + search + (athleteId ? '&athlete_id=' + athleteId : ''))
				.then(response => {
					self.athleteList = [];
					response.data.forEach((row, index) => {
						row.label = row.last_name + ' ' + row.first_name + ' ' + row.patronymic + ', ' + Vue.filter('date')(row.birth_date);
						self.athleteList[index] = row;
						if (athleteId && athleteId == row.id) {
							self.athlete = row;
						}
					})
					loading && loading(false);
				})
				.catch(error => {
					loading && loading(false);
				})
		},
		/**
		 * Создание заявки на перевод спортсмена
		 */
		create: function() {
			this.form.athlete_id = this.athlete.id;
			Trusty.post('/athletes/transfer', this.form)
				.then(response => {
					location.href = '/system-requests/' + response.id;
				})
				.catch(error => {
					console.warn('Ошибка при создании заявки на перевод спортсмена', error);
				})
		}
	}
})
