Vue.component('result-athletes', {
    props: ['competition'],
    mixins: [require('../../../common/mixins/user')],
    mounted: function() {
        this.loadData();
        Bus.$on('results-updated', this.onResultsUpdated);
    },
    data: function() {
        return {
            searchText: '',
            filteredAthletes: [],
            baseAthletesInfo: [],
            swimPrograms: [],
        }
    },
    computed: {
        athletesInfo: function () {
            let keyword = this.searchText.toLowerCase();
            return this.baseAthletesInfo.filter(item => {
                return item.athlete && item.athlete.full_name.toLowerCase().indexOf(keyword) >= 0;
            })
        },
        /**
         * Загружены ли результаты в систему
         * @returns {any}
         */
        hasResults: function() {
            return this.$parent.hasResults;
        },
        /**
         * Является ли текущий пользователь организатором
         * @returns {any}
         */
        isOwnUser: function() {
            return this.$parent.isOwnUser;
        }
    },
    methods: {
        /**
         * Загружаем данные о всех результатах соревнования и о заявках спортсмена на дистанции
         */
        loadData: function() {
            if (!this.isRegionalFederationUser) {
                return false;
            }
            axios.get('/competitions/results-data/' + this.competition.id)
                .then(response => {
                    this.baseAthletesInfo = response.data.athletes;
                    this.swimPrograms = response.data.swimPrograms;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке заявки клуба', error);
                })
        },
        /**
         * Получить список competitionSwimProgramId
         * @param athleteInfo
         */
        getSwimProgramIds: function (athleteInfo) {
            let result = [];
            for (let i = 0; i < athleteInfo.proposal.swim_programs.length; i++) {
                result.push(athleteInfo.proposal.swim_programs[i].swim_program_id);
            }
            return result;
        },
        /**
         * Поиск заявок на дистанции, для которых нет соответствующих результатов в информации об атлете
         * @param {Object} athleteInfo
         * @returns {[]}
         */
        getLostSwimProgramIds: function (athleteInfo) {
            let proposalsSwimProgramIds = this.getSwimProgramIds(athleteInfo);
            let result = [];
            for (let i = 0; i < proposalsSwimProgramIds.length; i++) {
                let athleteResult = athleteInfo.results.find(item => item.competition_swim_program_id === proposalsSwimProgramIds[i]);
                if (typeof(athleteResult) === 'undefined') {
                    result.push(proposalsSwimProgramIds[i]);
                }
            }
            return result;
        },
        /**
         * Нужно ли выводить предупреждение о записи
         * @param {Object} athleteInfo
         * @returns {boolean}
         */
        hasWarning: function (athleteInfo) {
            return this.getLostSwimProgramIds(athleteInfo).length > 0;
        },
        /**
         * Получить название клуба атлета
         * @param athlete
         */
        getClubName: function (athlete) {
            return athlete.club ?  (athlete.club.short_name ? athlete.club.short_name : athlete.club.name) : 'Независимый спортсмен';
        },
        /**
         * Открыть модалку добавления результата по спортсмену
         * @param athleteInfo
         */
        openAthleteModal: function (athleteInfo) {
            this.$parent.$parent.$refs.competitionAddResultModal.init({
                swimPrograms: this.swimPrograms,
                athleteInfo: athleteInfo,
                competition: this.competition,
            });
        },
        /**
         * Обработка события сохранения результатов для спортсмена
         * @param results
         * @param athlete
         */
        onResultsUpdated: function (results, athleteId) {
            let athleteInfo = this.baseAthletesInfo.find(item => item.athlete && item.athlete.id === athleteId);
            if (typeof(athleteInfo) !== 'undefined') {
                athleteInfo.results = results;
            }
        },
    },
    beforeDestroy() {
        Bus.$off('results-updated', this.onResultsUpdated);
    }
});
