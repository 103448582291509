import constants from './constants';

Vue.component('competition-proposal-relays-selection', {
    mixins: [require('../mixins/athleteCheckers'), require('../../common/mixins/user')],
    props: [
        'competition',
        'competitionProposal',
        'genderShortLabels',
        'athleteRanks'
    ],
    data: function() {
        return {
            keyword: '',
            selectedSwimPrograms: [],
            selectedSwimProgramsParams: {},
            processing: false,
            relays: [],
            generalTeamId: null,
            constants: constants
        }
    },
    mounted: function() {

    },
    computed: {
        /**
         * Доступные программы плавания с учетом поиска
         */
        availableCompetitionSwimPrograms: function () {
            if (!this.competition) {
                return [];
            }
            return this.competition.swim_programs.filter(swim_program => {
                return swim_program.swim_program.relay_count > 1;
            });
        },
        /**
         * Доступные эстафетные виды программ
         * @returns array
         */
        availableCompetitionRelays: function () {
            if (!this.keyword) {
                return this.availableCompetitionSwimPrograms;
            }
            let keyword = this.keyword.toLowerCase();
            return this.availableCompetitionSwimPrograms.filter(swim_program => {
                return (swim_program.swim_program.name.toLowerCase().indexOf(keyword) >= 0);
            });
        },
        /**
         * Получаем количество доступных эстафет для клуба
         * @returns array
         */
        getCountOfAvailableCompetitionRelays: function () {
            let relays = this.availableCompetitionSwimPrograms.filter(swim_program => {
                return this.getCountOfPossibleTeam(swim_program);
            });
            if (relays && relays.length) {
                return relays.length;
            }
        }
    },
    methods: {
        /**
         * Инициализация данных и открытие модалки
         */
        init: function (relays, generalTeamId) {
            if (relays || generalTeamId) {
                this.initProperties(relays, generalTeamId);
            }
            $('.modal-competition-proposal-relays-selection').modal('show');
            $('.form-proposals-relays__list').slimScroll({
                height: '350px'
            });
        },
        /**
         * Иниаилазция параметров
         * @param relays
         * @param generalTeamId
         */
        initProperties: function(relays, generalTeamId) {
            this.generalTeamId = typeof(generalTeamId) !== 'undefined' && generalTeamId;
            this.selectedSwimPrograms = [];
            this.selectedSwimProgramsParams = {};
            if (relays) {
                for (let index = 0; index < relays.length; index++) {
                    let proposalRelay = relays[index];
                    this.selectedSwimPrograms.push(proposalRelay.competition_swim_program_id);
                    Vue.set(this.selectedSwimProgramsParams, proposalRelay.competition_swim_program_id, {
                        competition_swim_program_id: proposalRelay.competition_swim_program_id,
                        team_count: proposalRelay.team_count
                    });
                }
            }
        },
        /**
         * Проверка соответсвия возрастной группе эстафете
         * @param ageGroup
         * @param relay
         * @returns {boolean}
         */
        isAgeGroupAvailableForRelay: function(ageGroup, relay) {
            if (!ageGroup) {
                return false;
            }
            return relay.gender == 'X' || relay.gender == ageGroup.gender;
        },
        /**
         * Получаем имя возрастной группы по id
         * @param ageGroupId
         * @returns {string}
         */
        getAgeGroupNameById: function (ageGroupId) {
            let ageGroups = this.competition.age_groups;
            let needleAgeGroupName = '';
            if (ageGroups.length > 0) {
                ageGroups.forEach(function (ageGroup) {
                    if (ageGroupId == ageGroup.id) {
                        if (ageGroup.age_group) {
                            needleAgeGroupName = ageGroup.age_group.name;
                        } else {
                            needleAgeGroupName = Vue.filter('ageGroupTitle')(ageGroup);
                        }
                    }
                });
            }
            return needleAgeGroupName;
        },
        /**
         * Проверяем подходит ли спортсмен под возрастную группу данной эстафеты
         * @param athlete
         * @param swimProgram
         */
        isRelayAvailableForAthlete: function (athlete, swimProgram) {
            if (!athlete) {
                return false;
            }
            if (swimProgram.gender != 'X' && swimProgram.gender != athlete.gender) {
                return false;
            }
            let availableAgeGroupIds = this.availableAgeGroupIds(athlete);
            if (availableAgeGroupIds.length == 0) {
                return false;
            }
            if (swimProgram.is_all_age_groups) {
                return true;
            }
            return swimProgram.age_groups
                .filter(ageGroupId => availableAgeGroupIds.indexOf(ageGroupId) !== -1)
                .length > 0;
        },
        /**
         * Получаем количество возможных команд на 1 программу плавания
         * @param competitionSwimProgram
         */
        getCountOfPossibleTeam: function (competitionSwimProgram) {
            let countOfPossibleTeam;
            // Необходимое количество спортсменов для создания команды
            let competitionRelayCount = competitionSwimProgram.swim_program.relay_count;

            // Какого пола нужна команда
            let competitionGender = competitionSwimProgram.gender;

            // Количество существующих спортсменов
            let countOfAthletes = 0;
            if (this.competitionProposal && this.competitionProposal.athletes) {
                countOfAthletes = this.competitionProposal.athletes.length;
            }

            //если спортсменов нет или их меньше, чем нужно в 1 команду
            if ((countOfAthletes == 0) || (competitionRelayCount > countOfAthletes)) {
                countOfPossibleTeam = 0;
            }

            if (competitionGender == 'X') {
                countOfPossibleTeam = this.getCountOfPossibleMixTeam(competitionSwimProgram);
            } else {
                countOfPossibleTeam = this.getCountOfPossibleOneGenderTeam(competitionSwimProgram);
            }
            return countOfPossibleTeam;
        },
        /**
         * Получить возможное количество команд мужского и женского пола
         * @returns {number}
         */
        getCountOfPossibleMixTeam: function (competitionSwimProgram) {
            let self = this;

            // Необходимое количество спортсменов для создания команды
            let competitionRelayCount = competitionSwimProgram.swim_program.relay_count;

            // Количество спортсменов женского пола, подходящих под условия
            let approvedAthletesFemaleSum = 0;

            // Количество спортсменов мужского пола, подходящих под условия
            let approvedAthletesMaleSum = 0;

            // 1/2 необходимого количества спортсменов для эстафеты, т.к. спортсменов м и ж должно быть поровну
            let halfOfCompetitionRelayCount = competitionRelayCount / 2;

            //Сохраняем количество подходящих спортсменов разделяя по полу
            if (this.competitionProposal && this.competitionProposal.athletes) {
                this.competitionProposal.athletes.forEach(function (competitionAthlete) {
                    if (self.isRegionalFederationUser || (competitionAthlete.list_type == self.constants.LIST_TYPE_MAIN) && self.isRelayAvailableForAthlete(competitionAthlete.athlete, competitionSwimProgram)) {
                        if (competitionAthlete.athlete.gender == 'M') {
                            approvedAthletesMaleSum++;
                        }
                        if (competitionAthlete.athlete.gender == 'F') {
                            approvedAthletesFemaleSum++;
                        }
                    }
                });
            }

            // Женская половина команды
            let femaleHalfOfTeam = Math.floor(approvedAthletesFemaleSum / halfOfCompetitionRelayCount);

            // Мужская половина команды
            let maleHalfOfTeam = Math.floor(approvedAthletesMaleSum / halfOfCompetitionRelayCount);

            return Math.min(femaleHalfOfTeam, maleHalfOfTeam);
        },
        /**
         * Получить возможное количество команд мужского или женского пола
         * @param competitionSwimProgram
         * @returns {number}
         */
        getCountOfPossibleOneGenderTeam: function (competitionSwimProgram) {
            let self = this;

            // Необходимое количество спортсменов для создания команды
            let competitionRelayCount = competitionSwimProgram.swim_program.relay_count;

            //Количество спортсменов определенного пола, подходящих под условия
            let approvedAthletesSum = 0;

            if (this.competitionProposal && this.competitionProposal.athletes) {
                this.competitionProposal.athletes.forEach(function (competitionAthlete) {
                    if (self.isRegionalFederationUser || competitionAthlete.list_type == self.constants.LIST_TYPE_MAIN && self.isRelayAvailableForAthlete(competitionAthlete.athlete, competitionSwimProgram)) {
                        approvedAthletesSum++;
                    }
                });
            }

            return Math.floor(approvedAthletesSum / competitionRelayCount);
        },
        /**
         * Изменение выбранных программ плавания
         * @param event
         */
        changeSelectedCompetitionSwimPrograms: function (event) {
            if (event.target.checked) {
                Vue.set(this.selectedSwimProgramsParams, event.target.value, {
                    competition_swim_program_id: event.target.value,
                    team_count: 1
                });
            } else {
                delete this.selectedSwimProgramsParams[event.target.value];
            }
        },
        /**
         * Выбрана ли программа плавания/эстафета в списке
         * @param competitionSwimProgramId
         */
        isSwimProgramSelected: function (competitionSwimProgramId) {
            return this.selectedSwimPrograms.filter(item => item == competitionSwimProgramId).length;
        },
        /**
         * Получаем число - количсетво команды, выбранных в эстафете
         * @param competitionSwimProgramId
         * @returns {number}
         */
        getSelectedRelayTeamCount: function (competitionSwimProgramId) {
            let competitionSwimProgramParamTeamCount = null;
            if (this.competitionProposal && this.competitionProposal.relays) {
                this.competitionProposal.relays.forEach(function (relay) {
                    if (relay.competition_swim_program_id && (relay.competition_swim_program_id == competitionSwimProgramId)) {
                        competitionSwimProgramParamTeamCount = relay.team_count;
                    }
                });
            }

            return competitionSwimProgramParamTeamCount;
        },
        /**
         * Присваиваем количество команд в эстафете
         * @param competitionSwimProgramParamId
         * @param event
         */
        setCountOfPossibleTeam: function (competitionSwimProgramParamId, event) {
            let competitionSwimProgramParam = this.selectedSwimProgramsParams[competitionSwimProgramParamId];
            competitionSwimProgramParam.team_count = event.target.value;
            Vue.set(this.selectedSwimProgramsParams, competitionSwimProgramParamId, competitionSwimProgramParam);
        },
        /**
         * Получаем количество команд в эстафете
         * @param competitionSwimProgramParamId
         * @returns {*}
         */
        getSelectedPossibleTeam: function (competitionSwimProgramParamId) {
            let competitionSwimProgramParam = this.selectedSwimProgramsParams[competitionSwimProgramParamId];
            return competitionSwimProgramParam ? competitionSwimProgramParam.team_count : ''
        },
        /**
         * Сохранение выделенных эстафет в заявку
         */
        save: function() {
            this.processing = true;
            let self = this;
            let url = this.generalTeamId
                ? '/competitions/' + this.competition.id + '/proposals/general-team-relays-save'
                : '/competitions/' + this.competition.id + '/proposals/relays-save';
            let postData  = {
                relays: this.selectedSwimProgramsParams
            };
            if (this.generalTeamId) {
                postData.generalTeamId = this.generalTeamId;
            }
            axios.post(url, postData)
                .then(response => {
                    self.$emit('saved');
                    self.processing = false;
                    $('.modal-competition-proposal-relays-selection').modal('hide');
                })
                .catch(error => {
                    console.warn('Ошибка при сохранении', error);
                    self.processing = false;
                });
        }
    }
});
