Vue.component('coach-edit-form', {
    props: ['coachingCategories', 'sportRanks', 'educationalTypes', 'coachId', 'employeeTypes', 'clubId'],
	mixins: [require('./mixins/form')],
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'members',
            mode: 'detail',
            backLink: '/coaches',
            backTitle: 'К списку тренеров',
            title: 'Редактирование тренера'
        });
        if (this.coachId) {
            this.loadCoach();
        }
    },
    methods: {
        /**
         * Обновление данных тренера
         */
        update: function() {
            Trusty.postWithFiles('/coaches/' + this.form.id, this.form, [
                'coaching_category_doc_file',
                'sport_rank_doc_file',
                'data_agreement_doc_file',
            ])
                .then(response => {
					if (response.exists) {
						this.$refs.coachExistsModal.init(response, this.getExistsMode(response));
					} else {
						location.href = '/coaches';
					}
                })
                .catch(error => {
                })
        },
    }
});
