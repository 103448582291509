Vue.component('city-form', {
    props: ['title', 'cityId', 'regions'],
    data: function() {
        return {
            form: new TrustyForm({
                id: '',
                prefix: '',
                name: '',
                region_id: '',
                municipality_id: '',
            }),
            municipalities: [],
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'dictionary',
            mode: 'detail',
            backLink: '/dictionaries/cities',
            backTitle: 'К списку населенных пунктов',
            title: this.title
        });
        if (this.cityId) {
            this.loadCity();
        }
    },
    computed: {
        /**
         * Модель для региона
         */
        region: {
            get: function() {
                let filtered = this.regions.filter(item => item.id == this.form.region_id);
                if (filtered.length) {
                    return filtered[0];
                }
                return null;
            },
            set: function (item) {
                this.form.region_id = item ? item.id : '';
                this.loadMunicipalities();
            }
        },
        /**
         * Модель для муниципалитета
         */
        municipality: {
            get: function() {
                let filtered = this.municipalities.filter(item => item.id == this.form.municipality_id);
                if (filtered.length) {
                    return filtered[0];
                }
                return null;
            },
            set: function (item) {
                this.form.municipality_id = item ? item.id : '';
            }
        },
        /**
         * Урл для отправки запроса
         * @returns {string}
         */
        sendUrl: function () {
            return this.cityId
                ? '/dictionaries/cities/' + this.cityId
                : '/dictionaries/cities';
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            Trusty.post(this.sendUrl, this.form)
                .then(response => {
                    location.href = '/dictionaries/cities';
                })
                .catch(error => {

                })
        },
        /**
         * Загружаем данные о городе
         */
        loadCity: function() {
            axios.get('/dictionaries/cities/fetch/' + this.cityId)
                .then(response => {
                    $.extend(true, this.form, response.data);
                    this.loadMunicipalities();
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке города', error);
                })
        },
        /**
         * Загружаем муниципалитеты
         */
        loadMunicipalities: function() {
            if (!this.form.region_id) {
                this.municipalities = [];
                return;
            }
            axios.get('/dictionaries/municipalities/all?region_id=' + this.form.region_id)
                .then(response => {
                    this.municipalities = response.data;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке списка муниципалитетов', error);
                })
        }
    }
});
