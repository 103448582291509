Vue.component('credential-commission-add-club', {
    props: [],
    data: function() {
        return {
            constants: require('../../../competitions/constants'),
            club: null,
            clubs: [],
            processing: false,
            competition: null,
            existingTeamIds: [],
            form: {
                name: ''
            },
        }
    },
    methods: {
        /**
         * Получаем клубы для показа
         * @param search
         * @param loading
         */
        searchClubs: function(search, loading) {
            if (!search) {
                return;
            }
            loading && loading(true);
            this.clubs = [];

            let generalTeamPart = '';
            if (this.competition.need_general_teams) {
                let excludeIds = [];
                for (let i = 0; i < this.existingTeamIds.length; i++) {
                    excludeIds.push(this.existingTeamIds[i]);
                }
                generalTeamPart = '&team_competition_id=' + this.competition.id + '&competition_rank=' + this.competition.competition_rank;
                if (excludeIds.length > 0) {
                    generalTeamPart += '&exclude_ids=' + excludeIds.join(',');
                }
            }
            axios.get('/clubs/search-club-filter?keyword=' + search + generalTeamPart + '&limit=50')
                .then(response => {
                    let allClubs = response.data;
                    if (!this.competition.need_general_teams) {
                        this.clubs = allClubs.filter(item => !this.existingTeamIds.includes(item.id));
                    } else {
                        this.clubs = allClubs;
                    }
                    loading && loading(false);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке списка клубов', error);
                    loading && loading(false);
                })
        },
        /**
         * Сохранить комментарий у заявленного спортсмена
         */
        addClub: function () {
            if (!this.club) {
                return false;
            }
            this.processing = true;
            let self = this;
            axios.post('/competition/' + this.competition.id + '/credential-commission/add-club', {
                club_id: this.club.id,
            }).then(response => {
                self.processing = false;

                let team = {
                    id: response.data.id,
                    club_id: this.club.id,
                    allowed_athletes_count: 0,
                    all_athletes_count: 0,
                    partially_allowed_athletes_count: 0,
                    has_protocol: false,
                };

                if (!this.competition.need_general_teams) {
                    team.name =  this.club.name;
                } else {
                    if (this.competition.competition_rank === this.constants.COMPETITION_RANK_REGION) {
                        team.name = response.data.municipality.name;
                        team.municipality_id = response.data.municipality.id;
                    } else {
                        team.name = response.data.city.name;
                        team.city_id = response.data.city_id;
                    }
                }

                if (self.$parent.teams) {
                    self.$parent.teams.push(team);
                }
                this.club = null;
                $('.modal-credential-commission-add-club').modal('hide');
            }).catch(error => {
                console.warn('Ошибка при изменения статуса спортсмена', error);
            })
        },
        /**
         * Инициализируем компонент всплывашки Комментарий
         */
        init: function (data) {
            this.competition = data.competition;
            this.existingTeamIds = data.existingTeamIds;
            $('.modal-credential-commission-add-club').modal('show');
        },

    }
});
