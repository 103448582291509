<template>
    <div class="filter-container">
        <div class="filter-label"
             :class="isNotEmpty ? 'filter-active' : ''"
             aria-haspopup="true"
             aria-expanded="false"
             data-toggle="dropdown"
        >{{getTitle}}</div>

        <div v-on:click.stop.self class="dropdown-menu filter-content athlete-club-filter" aria-labelledby="dropdownMenuButton">
            <div class="athlete-club-filter">
                <input v-on:change="changeAssignType" id="filter-self-employed" type="radio" v-model="clubAssignType" value="1" />
                <label for="filter-self-employed">Независимые спортсмены</label>
                <br>
                <input v-on:change="changeAssignType" id="filter-all-clubs" type="radio" v-model="clubAssignType" value="2" />
                <label for="filter-all-clubs">Все клубы</label>
            </div>
            <div class="select-container">
                <v-select v-on:input="changeClub" v-model="club" :options="clubList" @search="fetchClubs" placeholder="Начните вводить название клуба...">
                    <div slot="no-options">Клуб не найден</div>
                </v-select>
            </div>

            <hr />
            <div class="filter-buttons">
                <span v-on:click="resetFilter" class="filter-action-span">
                    Очистить
                </span>
                <button class="btn btn-primary float-right" v-on:click="applyFilter">
                    Сохранить
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AthleteClubFilter",
        data: function () {
            return {
                clubList: [],
                clubAssignType: 0,
                club: '',
                filters: {}
            }
        },
        methods: {
            applyFilter: function (send = true) {
                this.filters = {
                    clubAssignType: this.clubAssignType,
                    club: this.club
                };
                let filters = {
                    clubAssignType: this.clubAssignType,
                    club: this.club
                };

                if (send) {
                    this.$emit('applyFilter', filters);
                }
                this.closeDropDown();
            },
            resetFilter: function (apply = true) {
                this.clubAssignType = 0;
                this.club = '';

                this.applyFilter(apply);
            },
            closeDropDown: function () {
                $('.show').removeClass('show');
            },
            /**
             * Получаем клубы для показа
             * @param search
             * @param loading
             */
            fetchClubs: function(search, loading) {
                this.clubAssignType=0;
                loading && loading(true);
                var self = this;
                axios.get('/clubs/search-club-filter?keyword=' + search)
                    .then(response => {
                        self.clubList = [];
                        response.data.forEach((row, index) => {
                            row.label = row.name;
                            self.clubList[index] = row;
                        })
                        loading && loading(false);
                    })
                    .catch(error => {
                        loading && loading(false);
                    })

            },
            /**
             * Очищаем radio когда выбран клуб
             */
            changeClub: function () {
                this.clubAssignType = 0;
            },
            /**
             * Очищаем select клуба, когда выбран статус
             */
            changeAssignType: function () {
                this.club = '';
            },
        },
        computed: {
            getTitle: function() {
                if(this.clubAssignType == 1) {
                    return 'Независимые спортсмены';
                }
                if(this.clubAssignType == 2) {
                    return 'Все клубы';
                }
                if(this.club) {
                    return this.club.name;
                }
                return 'Клуб';
            },
            isNotEmpty: function () {
                if (this.filters.clubAssignType || this.filters.club) {
                    return true;
                }

            }
        }
    }
</script>

<style scoped>

</style>
