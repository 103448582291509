Vue.component('result-protocols', {
    props: ['competition', 'competitionEntities', 'athleteRanks'],
    data: function() {
        return {
            wizardMode: false
        }
    },
    computed: {
        /**
         * Загружены ли результаты в систему
         * @returns {any}
         */
        hasResults: function() {
            return this.$parent.hasResults;
        },
        /**
         * Является ли текущий пользователь организатором
         * @returns {any}
         */
        isOwnUser: function() {
            return this.$parent.isOwnUser;
        }
    },
    methods: {
        /**
         * Переключаемся на визард по загрузке результатов
         */
        initWizard: function() {
            this.wizardMode = true;
            this.$parent.wizardMode = true;
            this.$refs.resultImportWizard.init();
        },
        /**
         * Закрываем визард
         */
        wizardClosed: function() {
            this.wizardMode = false;
            this.$parent.wizardMode = false;
        }
    }
});
