Vue.component('result-import-wizard', {
    props: ['competition', 'competitionEntities', 'athleteRanks'],
    data: function() {
        return {
            step: 1,
            selectedEntityIds: [],
            selectionStandardGroups: []
        }
    },
    computed: {
    },
    methods: {
        /**
         * Инициализируем компонент визарда
         */
        init: function () {
            this.step = 1;
            if (this.selectionStandardGroups.length == 0) {
                this.loadSelectionStandardGroups();
                this.$refs.resultsWizardCommonSettings.loadCompetitionReportSetting();
            }
        },
        /**
         * Смена шага визарда
         * @param step
         */
        setStep: function (step) {
            this.step = step;
        },
        /**
         * Загружаем группы отборочных нормативов
         */
        loadSelectionStandardGroups: function() {
            axios.get('/competitions/selection-standard-groups/all')
                .then(response => {
                    this.selectionStandardGroups = response.data;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузка отборочных нормативов', error);
                })
        }
    }
});
