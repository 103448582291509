Vue.component('athlete-rank-list', {
    props: ['rankTypes'],
    data: function() {
        return {
            currentRow: null
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'dictionaries',
            childCode: 'athlete-ranks'
        });
    },
    methods: {
        /**
         * @brief Форматированный вывод типа разряда
         * @param value
         * @param row
         * @returns {*}
         */
        rankTypeFormat: function (value, row) {
            return this.rankTypes[value];
        },
        /**
         * @brief Форматированный вывод периода действия
         * @param value
         * @param row
         * @returns {*}
         */
        actionPeriodFormat: function (value, row) {
            return value + ' мес';
        },
        /**
         * Подтверждение удаления разряда
         */
        confirmDelete: function(row) {
            this.currentRow = row;
            this.$refs.confirmModal.init({
                title: 'Перенос разряда в архив',
                confirmText: 'Вы действительно хотите перенести в архив данный разряд?',
                confirmButtonTitle: 'Перенeсти',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteAthleteRank
            })
        },
        /**
         * Удаление разряда
         */
        deleteAthleteRank: function() {
            this.$refs.athleteRankGrid.setLoading(true);
            var self = this;
            axios.delete('/dictionaries/athlete-ranks/' + this.currentRow.id)
                .then(
                    response => {
                        self.$refs.athleteRankGrid.reloadData();
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при удалении разряда', error);
                    self.$refs.athleteRankGrid.setLoading(false);
                })
        },
    }
});
