import api from './api';

export var index = {
    data() {
        return {
            response: {},
            mode: 'default',
            query: '',
            page: 1,
            checkedRows: [],
            checkRowsAll: false,
            orderColumn: 'id',
            orderDirection: 'asc',
            pluralLabels: ['запись', 'записи', 'записей'],
            loading: false,
            filters: {},
        }
    },
    mounted: function () {
        this.syncOptions();
        /**
         * Получаем начальные данные
         */
        this.reloadData();
    },
    computed: {
        /**
         * Получение параметров для запроса обновления данных
         */
        getDataParams: function () {
            return {
                page: this.page,
                per_page: this.perPage,
                mode: this.mode,
                keyword: this.query,
                order_column: this.orderColumn,
                order_direction: this.orderDirection,
                filters: this.filters,
            };
        },
        /**
         * Урл для получения данных
         * @returns {*}
         */
		dataUrl: function () {
			return this.options.baseUrl;
		},
		rowLink: function () {
			return this.options.rowLink;
		},
        /**
         * Количество объектов на одной странице
         * @returns {index.computed.perPage|(function())|perPage|number}
         */
        perPage: function () {
            return this.options.perPage || 10;
        },
        /**
         * Столбцы таблицы из настроек
         * @returns {*}
         */
        columns: function () {
            return this.options.columns;
        },
        /**
         * Необходимость использования действий над данными
         * @returns {*}
         */
        useActions: function () {
            if (this.options.hasOwnProperty('useActions')) {
                return this.options.useActions;
            }
            return true;
        },
        /**
         * Список возможных действий для записи
         * @returns {*}
         */
        rowActions: function() {
            if (this.options.hasOwnProperty('rowActions')) {
                return this.options.rowActions;
            }
            return [];
        },
        /**
         * Необходимость использования групповых действий
         * @returns {*}
         */
        useGroupActions: function () {
            if (this.options.hasOwnProperty('useGroupActions')) {
                return this.options.useGroupActions;
            }
            return true;
        },
        /**
         * Количество столбцов в таблице
         * @returns {number}
         */
        columnsCount: function() {
            let columnsCount = this.columns.length;
            if (this.useActions) {
                columnsCount++;
            }
            if (this.useGroupActions) {
                columnsCount++;
            }
            return columnsCount;
        },
        /**
         * Список режимов для представления
         */
        modes: function() {
            if (this.options.hasOwnProperty('modes')) {
                return this.options.modes;
            }
            return [];
        },
		/**
         * Данные текущего режима
		 * @returns {null}
		 */
		currentMode: function() {
			if (this.modes.length > 0) {
				var currentMode = this.modes.filter(value => value.code == this.mode);
				if (currentMode.length == 1) {
				    return currentMode[0];
				}
			}
			return null;
        },
        /**
         * Список дейтствий
         */
        actions: function() {
            if (this.options.hasOwnProperty('actions')) {
                return this.options.actions;
            }
            return [];
        },
		/**
         * Нужно ли использовать фильтр
		 * @return bool
		 */
		useFilter: function () {
			if (this.options.hasOwnProperty('useFilter')) {
				return this.options.useFilter;
			}
			return false;
		},
		/**
		 * Заголовок пустоты записей
		 * @return string
		 */
		emptyTitle: function() {
			if (this.currentMode && this.currentMode.hasOwnProperty('emptyTitle')) {
				return this.currentMode.emptyTitle;
			}
			if (this.options.hasOwnProperty('emptyTitle')) {
				return this.options.emptyTitle;
			}
			return 'Нет записей для отображения';
		},
        /**
         * Показывать ли число записей в заголовке
         * @returns {boolean|index.computed.showTotal}
         */
        showTotal: function () {
            if (this.options.hasOwnProperty('showTotal')) {
                return this.options.showTotal;
            }
            return true;
        },
		/**
		 * Подзаголовок пустоты записей
		 * @return string
		 */
		emptySuggestion: function() {
			if (this.currentMode && this.currentMode.hasOwnProperty('emptySuggestion')) {
				return this.currentMode.emptySuggestion;
			}
			if (this.options.hasOwnProperty('emptySuggestion')) {
				return this.options.emptySuggestion;
			}
			return '';
		}
    },
    methods: {
		/**
         * Смена статуса загрузки грида
		 * @param loading
		 */
		setLoading: function (loading) {
            this.loading = loading;
        },
		/**
		 * Синхронизация внутреннего состояния с входными параметрами
		 */
		syncOptions: function() {
			if (this.options.orderColumn) {
				this.orderColumn = this.options.orderColumn;
			}
			if (this.options.orderDirection) {
				this.orderDirection = this.options.orderDirection;
			}
			if (this.options.mode) {
				this.mode = this.options.mode;
			}
			if (this.options.pluralLabels) {
				this.pluralLabels = this.options.pluralLabels;
			}
		},
        /**
         * Обновление данных грида
         */
        reloadData: function () {
            this.loading = true;
            api.getData(this.dataUrl, this.getDataParams)
                .then(response => {
                    this.response = response.data;
                    if (response.data.hasOwnProperty('meta')) {
                        $.extend(true, this.response, response.data.meta);
                    }
                    this.checkedRows = [];
                    this.checkRowsAll = false;
                    this.loading = false;
                })
                .catch(error => {
                    console.warn('Ошибка при получении данных с сервера', error);
                    this.loading = false;
                })
        },
        /**
         * Применить inline фидьтры и перезазгрузить данные
         * @param filters
         */
        applyFilters: function (filters) {
            this.filters = JSON.parse(JSON.stringify(filters));//@todo remove - конвертация observable контейнера просто в объект
            this.page = 1; //После изменения фильтров - сбрасываем показ на 1 страницу
            this.reloadData();
        },
        /**
         * Обработка перехода по постраничной навигации
         * @param page
         */
        onPaginate: function (page) {
            this.page = page;
            this.reloadData();
        },
        /**
         * Смена режима
         * @param page
         */
        onChangeMode: function (mode) {
            this.mode = mode;
            this.page = 1;
            this.reloadData();
        },
        /**
         * Обработка изменения данных фильтра поиска
         * @param value
         */
        onFilter: function (value) {
            this.query = value;
            this.page = 1;
            this.checkRows = [];
            this.checkRowsAll = false;
            this.reloadData();
        },
        /**
         * Асинхронное действие над записью таблицы
         * @param url
         * @param method
         * @param params
         * @param successCallback
         */
        onAsyncRowAction: function(url, method, params, successCallback) {
            var self = this;
            this.loading = true;
            api.sendRequest(url, method, params)
                .then(response => {
                    self.reloadData();
                    successCallback && successCallback()
                })
                .catch(error => {
                    console.warn('Ошибка при получении данных с сервера', error);
                    self.loading = false;
                })
        },
        /**
         * Обработка события выделения всех строк в таблице
         */
        onCheckAllRows: function () {
            if(this.checkRowsAll) {
                this.checkedRows = [];
                for (index in this.response.data) {
                    this.checkedRows.push(this.response.data[index].id);
                }
            } else {
                this.checkedRows = [];
            }
        },
        /**
         * Смена поля и порядка сортировки
         * @param column
         * @param direction
         */
        changeOrder: function(column, direction) {
            this.orderColumn = column;
            this.orderDirection = direction;
            this.reloadData();
        },
        /**
         * Обработка изменений данных фильтров
         * @param filterName
         * @param value
         */
        changeFilters: function (filterName, value) {
            this.filters[filterName] = value;
        },
        /**
         * Сброс фильтров
         */
        resetFilters: function () {
            this.filters = {};
            this.reloadData();
        },
        calculateIndex: function (index) {
            return (this.page - 1) * this.perPage + index + 1;
        },
    }
}
