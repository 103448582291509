<template>
    <div class="filters athlete-filters">
        <age-gender-filter
            v-on:applyFilter="applyFilter"
            :gender-maps="genderMaps"
            :gender-short-labels="genderShortLabels"
            dusk="ageGenderFilter"
            ref="ageGenderFilter"
        ></age-gender-filter>

        <athlete-admission-filter
            v-on:applyFilter="applyFilter"
            :admission-statuses-list="admissionStatuses"
            dusk="athleteAdmissionFilter"
            ref="athleteAdmissionFilter"
        ></athlete-admission-filter>

        <athlete-insurance-policy-filter
            v-on:applyFilter="applyFilter"
            :insurance-policy-statuses-list="insurancePolicyStatuses"
            dusk="athleteInsurancePolicyFilter"
            ref="athleteInsurancePolicyFilter"
        ></athlete-insurance-policy-filter>

        <athlete-rank-filter
            v-on:applyFilter="applyFilter"
            :athlete-ranks-list="athleteRanks"
            dusk="athleteRankFilter"
            ref="athleteRankFilter"
        ></athlete-rank-filter>

        <athlete-municipality-filter
            v-if="isRegionalFederationUser"
            v-on:applyFilter="applyFilter"
            ref="athleteMunicipalityFilter"
        ></athlete-municipality-filter>

        <athlete-club-filter
            v-if="isRegionalFederationUser"
            v-on:applyFilter="applyFilter"
            ref="athleteClubFilter"
        ></athlete-club-filter>

        <span v-on:click="resetFilters" class="filter-action-span filters-reset">
            Очистить все фильтры
        </span>
    </div>
</template>

<script>

    import AgeGenderFilter from "../../util/filters/AgeGenderFilter";
    import AthleteAdmissionFilter from "../../util/filters/AthleteAdmissionFilter";
    import AthleteInsurancePolicyFilter from "../../util/filters/AthleteInsurancePolicyFilter";
    import AthleteRankFilter from "../../util/filters/AthleteRankFilter";
    import AthleteMunicipalityFilter from "../../util/filters/AthleteMunicipalityFilter";
    import AthleteClubFilter from "../../util/filters/AthleteClubFilter";

    export default {
        name: "AthleteFilters",
        mixins: [require('../../common/mixins/user')],
        components: {AthleteAdmissionFilter, AgeGenderFilter, AthleteRankFilter, AthleteInsurancePolicyFilter, AthleteMunicipalityFilter, AthleteClubFilter},
        props: ['genderMaps', 'genderShortLabels', 'athleteRanks', 'admissionStatuses', 'insurancePolicyStatuses'],
        data: function() {
            return {
                filters: {
                    gender: '',
                    dateFrom: '',
                    dateTo: '',
                    athleteRanks: [],
                    admissionStatuses: [],
                    insurancePolicyStatuses: [],
                    clubType: 0,
                    clubId: '',

                },
            }
        },
        methods: {
            applyFilter: function (filters) {
                let mergedFilters = Object.assign(this.filters, filters);
                this.filters = mergedFilters;
                this.$emit('applyfilters', mergedFilters);
            },
            resetFilters: function () {
                //Очистить все дочерние фильтры
                this.$refs.ageGenderFilter.resetFilter(false);
                this.$refs.athleteRankFilter.resetFilter(false);
                this.$refs.athleteAdmissionFilter.resetFilter(false);
                this.$refs.athleteInsurancePolicyFilter.resetFilter(false);
                if (this.$refs.athleteMunicipalityFilter) {
                    this.$refs.athleteMunicipalityFilter.resetFilter(false);
                }

                if (this.$refs.athleteClubFilter) {
                    //Только если фильтр существует для данной роли
                    this.$refs.athleteClubFilter.resetFilter(false);
                }


                this.filters = {};
                this.$emit('applyfilters', this.filters);
            },
        }
    }
</script>

<style scoped>

</style>
