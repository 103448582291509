Vue.component('rating-filters', {
    mixins: [require('../../common/mixins/user'), require('../../competitions/mixins/dateFormatter')],
    props: ['genderShortLabels', 'courses'],
    data: function () {
        return {
            competitions: [],
            checkedCompetitions: [],
            ageGroups: [],
            swimPrograms: [],
            filteredSwimPrograms: [],
            municipalities: [],
            filteredMunicipalities: [],
            clubs: [],
            loading: false,
            swimProgram: '',
            yearFrom: '',
            yearTo: '',
            competitionFrom: '',
            competitionTo: '',
            municipality: '',
            club: '',
            course: '',
            keyword: '',
            maleActive: 0,
            femaleActive: 0,
            onlyActive: 0,
            filters: {},
            showCompetitionsFilter: false,
        }
    },
    mounted: function() {
        this.loadSwimPrograms();
        this.loadMunicipalities();
        this.loadCompetitions();
    },
    methods: {
        getCompetitionIds: function() {
            let activeCompetitions = this.competitions.filter(competition => competition.selected);

            let result = [];
            for(let i = 0; i < activeCompetitions.length; i++) {
                result.push(activeCompetitions[i].id);
            }

            if (result.length > 0) {
                return result;
            }

            return null;
        },
        /**
         * Применение фильтров
         */
        applyFilters: function (send = true) {
            let filters = {
                gender: this.getGender(),
                yearFrom: this.yearFrom,
                yearTo: this.yearTo,
                competitionFrom: this.competitionFrom,
                competitionTo: this.competitionTo,
                municipality: this.municipality ? this.municipality.id : '',
                club: this.club ? this.club.id : '',
                course: this.course,
                swimProgram: this.swimProgram ? this.swimProgram.id : '',
                onlyActive: this.onlyActive,
                keyword: this.keyword,
            };
            let competitionsIds = this.getCompetitionIds();
            if (competitionsIds) {
                filters.competition_ids = competitionsIds;
            }
            this.filters = filters;

            let ratingListComp =  this.$parent.$refs.ratingList;
            ratingListComp.$refs.ratingGrid.applyFilters(filters);
        },
        getFilters: function () {
            let filters = {
                gender: this.getGender(),
                yearFrom: this.yearFrom,
                yearTo: this.yearTo,
                competitionFrom: this.competitionFrom,
                competitionTo: this.competitionTo,
                municipality: this.municipality ? this.municipality.id : '',
                club: this.club ? this.club.id : '',
                course: this.course,
                swimProgram: this.swimProgram ? this.swimProgram.id : '',
                onlyActive: this.onlyActive,
                keyword: this.keyword,
            }
            let competitionsIds = this.getCompetitionIds();
            if (competitionsIds) {
                filters.competition_ids = competitionsIds;
            }

            return filters;
        },
        getKeyword: function () {
            return this.keyword;
        },
        /**
         * Очистить фильтры
         */
        resetFilters: function (apply = true) {
            this.swimProgram = '';
            this.yearFrom = '';
            this.yearTo = '';
            this.keyword = '';
            this.municipality = '';
            this.club = '';
            this.course = '';
            this.keyword = '';
            this.maleActive = 0;
            this.femaleActive = 0;
            this.onlyActive = 0;
            this.applyFilters(apply);
        },
        getGender: function () {
            //Если обе галочки выбраны, или обе не выбраны - возвращаем всех
            if (this.maleActive === this.femaleActive) {
                return '';
            }
            if (this.maleActive) {
                return 'M';
            }
            return 'F';
        },
        /**
         * Загружаем список дистанций
         */
        loadSwimPrograms: function() {
            axios.get('/dictionaries/swim-programs/all')
                .then(response => {
                    this.filteredSwimPrograms = this.swimPrograms = response.data;
                    let self = this;
                    response.data.forEach((item) => {
                        self.swimProgramsByKey[item.id] = item;
                    })
                })
                .catch(error => {});
        },
        /**
         * Получаем дистанции для показа
         * @param search
         * @param loading
         */
        searchSwimPrograms: function(search, loading) {
            if (!search) {
                return;
            }
            loading && loading(true);
            search = search.toLowerCase();
            this.filteredSwimPrograms = this.swimPrograms.filter(function (e) {
                return e.name.toLowerCase().indexOf(search) > -1;
            });
            loading && loading(false);
        },
        /**
         * Загружаем муниципалитеты
         */
        loadMunicipalities: function() {
            axios.get('/dictionaries/municipalities/all?region_id=')
                .then(response => {
                    this.filteredMunicipalities = this.municipalities = response.data;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке списка муниципалитетов', error);
                })
        },
        loadCompetitions: function() {
            axios.get('/competitions/filters')
                .then(response => {
                    this.competitions = response.data;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке списка соревнований', error);
                })
        },
        /**
         * Получаем муниципальные объединения для показа
         * @param search
         * @param loading
         */
        searchMunicipalities: function(search, loading) {
            if (!search) {
                return;
            }
            search = search.toLowerCase();
            loading && loading(true);
            this.filteredMunicipalities = this.municipalities.filter(function (e) {
                return e.name.toLowerCase().indexOf(search) > -1;
            });
            loading && loading(false);
        },
        /**
         * Получаем клубы для показа
         * @param search
         * @param loading
         */
        searchClubs: function(search, loading) {
            if (!search) {
                return;
            }
            loading && loading(true);
            this.clubs = [];
            axios.get('/clubs/search-club-filter?keyword=' + search + '&limit=50')
                .then(response => {
                    this.clubs = response.data;
                    loading && loading(false);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке списка клубов', error);
                    loading && loading(false);
                })
        },
    }
});
