<template>
    <div class="filter-container">
        <div class="filter-label"
             :class="isNotEmpty ? 'filter-active' : ''"
             :dusk="dusk"
             aria-haspopup="true"
             aria-expanded="false"
             data-toggle="dropdown"
        >{{getTitle}}</div>

        <div v-on:click.stop.self class="dropdown-menu filter-content filter-gender-age" aria-labelledby="dropdownMenuButton">
            <div class="filter-date-month">
                <div>
                    <div v-for="yearOption in getAvailableYears()">
                        <input :name="'radio-year'" type="radio" class="form-control"
                               :id="'filter-year-' + yearOption"
                               :value="yearOption"
                               :key="'radio-year' + yearOption"
                               v-model="year"
                        />
                        <label for="'filter-year-' + yearOption" v-bind:key="'label-year' + yearOption">{{getYearTitle(yearOption)}}</label>
                    </div>
                </div>


                <select name="month" v-model="month" class="form-control">
                    <option value="">Все</option>
                    <option v-for="(month, index) in getMonthList()" :value="index" v-bind:key="index">{{month}}</option>
                </select>
            </div>

            <hr />
            <div class="filter-buttons">
                <span v-on:click="resetFilter" class="filter-action-span">
                    Очистить
                </span>
                <button dusk="yearMonthSave" class="btn btn-primary float-right" v-on:click="applyFilter">
                    Сохранить
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "YearFilter",
        props: ['years', 'dusk'],
        data: function () {
            return {
                year: this.getFilterDefaultYear(),
                month: '',
                filters: {
                    year: 'Текущий'
                }
            }
        },
        methods: {
            getMonthList: function () {
                return ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
            },
            getMonthName: function (index) {
                let months = this.getMonthList();
                return months[index];
            },
            getAvailableYears: function () {
                return this.years;
            },
            applyFilter: function (send = true) {
                this.filters = {
                    year: this.year,
                    month: this.month,
                };
                let filters = {
                    year: this.year,
                    month: this.month,
                };

                if (send) {
                    this.$emit('applyFilter', filters);
                }
                this.closeDropDown();
            },
            resetFilter: function (apply = true) {
                this.year = this.getFilterDefaultYear();
                this.month = '';
                this.applyFilter(apply);
            },
            closeDropDown: function () {
                $('.show').removeClass('show');
            },
            monthDefined: function () {
                return typeof(this.filters.month) !== 'undefined' && this.filters.month !== ''
            },
            getCurrentYear: function () {
                return moment().year();
            },
            getYearTitle(year) {
                return year == this.getCurrentYear() ? 'Текущий' : year;
            },
            getFilterDefaultYear: function () {
                if (this.years.length > 0) {
                    return this.years.includes(this.getCurrentYear()) ? this.getCurrentYear() : this.years[0];
                }
                return '';
            }
        },
        computed: {
            getTitle: function() {
                if (!this.filters.year && !this.monthDefined()) {
                    return 'Год, Месяц';
                }
                if (this.filters.year && this.monthDefined()) {
                    return this.getMonthName(this.filters.month) + ' ' + this.filters.year;
                } else {
                    if (this.filters.year) {
                        return this.filters.year;
                    }
                    return this.getMonthName(this.filters.month);
                }
            },
            isNotEmpty: function () {
                return this.filters.year || this.monthDefined();
            }
        }
    }
</script>

<style scoped>

</style>
