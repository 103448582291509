Vue.component('athlete-admission-form', {
    props: ['title', 'admissionTypes', 'athleteAdmissionId'],
    data: function() {
        return {
            form: new TrustyForm({
                id: '',
                name: '',
                action_period: '',
            }),
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'dictionary',
            mode: 'detail',
            backLink: '/dictionaries/athlete-admissions',
            backTitle: 'К списку типов допусков врача',
            title: this.title
        });
        if (this.athleteAdmissionId) {
            this.loadAthleteAdmission();
        }
    },
    computed: {
        /**
         * Урл для отправки запроса
         * @returns {string}
         */
        sendUrl: function () {
            return this.athleteAdmissionId
                ? '/dictionaries/athlete-admissions/' + this.athleteAdmissionId
                : '/dictionaries/athlete-admissions';
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            Trusty.post(this.sendUrl, this.form)
                .then(response => {
                    location.href = '/dictionaries/athlete-admissions';
                })
                .catch(error => {

                })
        },
        /**
         * Загружаем данные о типе допуска
         */
        loadAthleteAdmission: function() {
            axios.get('/dictionaries/athlete-admissions/fetch/' + this.athleteAdmissionId)
                .then(response => {
                    $.extend(true, this.form, response.data);
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке разряда', error);
                })
        }
    }
});
