Vue.component('competition-quota-reassign-modal', {
    data: function() {
        return {
            competition: null,
            form: new TrustyForm({
                reassign_type: 1,
                count: 20,
            }),
        }
    },
    methods: {
        /**
         * Отправка формы на сервер
         */
        send: function () {
            // Событие перераспределения квот
            let val = parseInt(this.form.count);
            if (isNaN(val)) {
                val = 0;
            }
            let count = parseInt(this.form.reassign_type) * val;
            Bus.$emit('reassign-quotas', {
                count: count
            });
            $('.modal-competition-quota-reassign').modal('hide');
        },

        init: function (data) {
            this.competition = data.competition;
            $('.modal-competition-quota-reassign').modal('show');
        }
    }
});
