Vue.component('competition-tab-my-proposal', {
    props: ['id'],
    mixins: [require('../../common/mixins/user')],
    mounted: function () {
        Bus.$on('pre-proposal-updated', this.setPreProposal);
    },
    data: function() {
        return {
            data: {},
            processing: false,
            constants: require('./../proposals/constants')
        }
    },
    computed: {
        /**
         * Проверяем соответствие дат подачи заявки
         */
        checkProposalDate() {
            return this.$parent.checkProposalDate;
        },
        /**
         * Ссылка для печати
         * @returns {string}
         */
        printLink: function() {
            return '/competitions/' + this.id + '/print-my-proposal';
        },
        /**
         * Показывать ли начальный экран предварительной заявки. Показываем, если выбор еще не сделан
         */
        needShowPreProposalInitialScreen: function () {
            let preProposal = this.data.my_pre_proposal;
            if (!preProposal) {
                return false;
            }
            return !preProposal.initiator_user_id;
        },
        /**
         * Показывать экран, когда квоты уже запрошены
         * @returns {boolean}
         */
        needShowPreProposalQuotasRequestedScreen: function () {
            let preProposal = this.data.my_pre_proposal;
            if (!preProposal) {
                return false;
            }
            return preProposal.initiator_user_id && !preProposal.no_compete;
        },
        /**
         * Показывать экран, когда отказался от квот
         * @returns {boolean}
         */
        needShowPreProposalQuotasDeclinedScreen: function () {
            let preProposal = this.data.my_pre_proposal;
            if (!preProposal) {
                return false;
            }
            return preProposal.initiator_user_id && preProposal.no_compete;
        },
        /**
         * Дефолтное число квот, выделенное для клуба на соревнование
         * @returns {number}
         */
        competitionDefaultQuotas: function () {
            if (!this.data.quotas) {
                return 0;
            }
            return this.data.quotas.free_participant_count + this.data.quotas.paid_participant_count;
        },
    },
    methods: {
        /**
         * Инициализируем компонент таба моей заявки в соревновании
         */
        init: function (data) {
            this.data = data;
            this.processing = true;
            if (!this.data.my_competition_proposal) {
                let self = this;
                axios.get('/competitions/my-proposal/' + this.id)
                    .then(response => {
                        Vue.set(self.data, 'my_competition_proposal', response.data);
                        self.processing = false;
                    })
                    .catch(error => {
                        console.warn('Ошибка при загрузке данных соревнования', error);
                        self.processing = false;
                    });
            }
        },
        /**
         * Проверка соответсвия возрастной группе эстафете
         * @param ageGroup
         * @param relay
         * @returns {boolean}
         */
        isAgeGroupAvailableForRelay: function(ageGroup, relay) {
            if (!ageGroup) {
                return false;
            }
            return relay.gender == 'X' || relay.gender == ageGroup.gender;
        },
        /**
         * Получаем название программы плавания по id
         * @param swimProgram
         * @returns {string}
         */
        getNameSwimProgram: function(swimProgram) {
            let swimProgramName = '';
            if (this.data.swim_programs) {
                this.data.swim_programs.forEach(function (swimProgramItem) {
                    if (swimProgramItem.id === swimProgram.swim_program_id) {
                        swimProgramName = swimProgramItem.swim_program.name;
                        return;
                    }
                });
            }
            return swimProgramName;
        },
        /**
         * Получаем имя возрастной группы по id
         * @param ageGroupId
         * @returns {string}
         */
        getAgeGroupNameById: function (ageGroupId) {
            let ageGroups = this.data.age_groups;
            let needleAgeGroupName = '';
            if (ageGroups && ageGroups.length > 0) {
                ageGroups.forEach(function (ageGroup) {
                    if (ageGroupId === ageGroup.id) {
                        if (ageGroup.age_group) {
                            needleAgeGroupName = ageGroup.age_group.name;
                        } else {
                            needleAgeGroupName = Vue.filter('ageGroupTitle')(ageGroup);
                        }
                        return;
                    }
                });
            }
            return needleAgeGroupName;
        },
        /**
         * Открыть форму запроса квот
         */
        openPreProposalModal: function () {
            this.$parent.$refs.competitionRequestQuotasModal.init(this.data);
        },
        /**
         * Отправить запрос отказа от соревнования
         */
        declinePreProposal: function () {
            axios.post('/competitions/pre-proposal-save-club-data/' + this.data.id, {
                'no_compete': 1,
            })
                .then(response => {
                    // Событие изменения предварительной заявки
                    Bus.$emit('pre-proposal-updated', response.data);
                })
                .catch(error => {

                })
        },
        /**
         * Обновить объект предварительной заявки
         * @param preProposal
         */
        setPreProposal: function (preProposal) {
            Vue.set(this.data, 'my_pre_proposal', preProposal);
        },
    },
    beforeDestroy() {
        Bus.$off('pre-proposal-updated', this.setPreProposal);
    },
});
